import React, { Component } from 'react';
import {
  Accordion,
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Icon,
  Image,
  Input,
  TextArea,
} from 'semantic-ui-react';
import API from './api';
import CargoApi from '../shared-api-adapters/cargo-api';
import { getLeadImagePossibles } from '../utilities/auction-maker-utils';
import PropTypes from 'prop-types';
import { resolvePhotosDataStructure } from '../utilities/helpers/connect';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';

const LabelInput = ({ title, children }) => (
  <div className={'mb-2'}>
    <div>
      <strong>{title}</strong>
    </div>
    {children}
  </div>
);

LabelInput.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

class Header extends Component {
  render() {
    const { handleChange, startTime, endTime } = this.props;

    return (
      <div className={'flex fd-row ai-center'}>
        <div className={'mr-2'}>
          <LabelInput title={'Start Time'}>
            <DateInput
              dateFormat={'dddd, MMMM Do, YYYY'}
              name={'startTime'}
              value={startTime}
              onChange={handleChange}
              clearable={true}
              closable={true}
              closeOnMouseLeave={false}
            />
          </LabelInput>
        </div>
        <LabelInput title={'End Time'}>
          <DateInput
            dateFormat={'dddd, MMMM Do, YYYY'}
            name={'endTime'}
            value={endTime}
            onChange={handleChange}
            clearable={true}
            closable={true}
            closeOnMouseLeave={false}
          />
        </LabelInput>
      </div>
    );
  }
}

Header.propTypes = {
  handleChange: PropTypes.func.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
};

class Footer extends Component {
  render() {
    const {
      governorState,
      handleChange,
      handleLeadItemClick,
      selected,
      handleAccordionClick,
      submit,
      fetch,
    } = this.props;
    const {
      title,
      frontPageText,
      invoiceText,
      paymentInfo,
      removalInfo,
      address,
      city,
      state,
      zip,
      leadItem,
      activeIndex,
      startTime,
      endTime,
      taxRate,
      category,
      timezone,
      affiliateId,
      buyersPremium,
    } = governorState;

    const selectedIds = selected.map(item => item.nellisAuctionId);

    return (
      <>
        <LabelInput title={'Title'}>
          <Input fluid name={'title'} placeholder={'Title'} value={title} onChange={handleChange} />
        </LabelInput>
        <LabelInput title={'Type'}>
          <Dropdown options={[{ text: 'Timed Auction', value: 0 }]} value={0} selection />
        </LabelInput>
        <LabelInput title={'Front Page Text'}>
          <Form>
            <TextArea name={'frontPageText'} value={frontPageText} onChange={handleChange} />
          </Form>
        </LabelInput>
        <LabelInput title={'Invoice Text'}>
          <Form>
            <TextArea name={'invoiceText'} value={invoiceText} onChange={handleChange} />
          </Form>
        </LabelInput>
        <LabelInput title={'Payment Info'}>
          <Form>
            <TextArea name={'paymentInfo'} value={paymentInfo} onChange={handleChange} />
          </Form>
        </LabelInput>
        <LabelInput title={'Removal Info'}>
          <Form>
            <TextArea name={'removalInfo'} value={removalInfo} onChange={handleChange} />
          </Form>
        </LabelInput>
        <API.Query queries={[{ route: 'saleTypes' }]} api={new CargoApi()}>
          {({ data: [saleTypes] }) => (
            <LabelInput title={'Category'}>
              <Dropdown
                selection
                name={'category'}
                options={saleTypes.map(({ name, id }) => ({ text: name, value: id }))}
                value={category}
                onChange={handleChange}
              />
            </LabelInput>
          )}
        </API.Query>

        <div className={'flex fd-row jc-between'}>
          <LabelInput title={'Address'}>
            <Input name={'address'} value={address} onChange={handleChange} />
          </LabelInput>
          <LabelInput title={'City'}>
            <Input name={'city'} value={city} onChange={handleChange} />
          </LabelInput>
          <LabelInput title={'State'}>
            <Input name={'state'} value={state} onChange={handleChange} />
          </LabelInput>
          <LabelInput title={'Zip Code'}>
            <Input name={'zip'} value={zip} onChange={handleChange} />
          </LabelInput>
        </div>
        <div>
          <LabelInput title={'Buyers Premium'}>
            <Input name={'buyersPremium'} value={buyersPremium} onChange={handleChange} />
          </LabelInput>
          <LabelInput title={'Tax Rate'}>
            <Input name={'taxRate'} value={taxRate} onChange={handleChange} />
          </LabelInput>
        </div>
        <API.Query
          // hard coded hostname because we pull from auction900 on both dev and prod
          api={new CargoApi(null, 'https://dev-api.cargo.helex.io:3000')}
          queries={getLeadImagePossibles(selected).map(p => ({
            route: 'itemByInventoryNumber',
            id: p.internalId,
          }))}
        >
          {({ data: i }) => {
            const items = i.flat().map(item => resolvePhotosDataStructure(item));

            return (
              <>
                {leadItem && (
                  <Image
                    src={
                      items.find(item => item.inventoryNumber === leadItem.inventoryNumber)
                        .photos[0].url
                    }
                    size={'small'}
                  />
                )}
                <Accordion>
                  <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={handleAccordionClick}
                  >
                    <Icon name="dropdown" />
                    Lead Item Select
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 0}>
                    <Card.Group itemsPerRow={5}>
                      {items.map((item, key) => (
                        <Card
                          key={key}
                          link
                          raised
                          name={item.inventoryNumber}
                          onClick={() => handleLeadItemClick(item)}
                        >
                          <Image src={item.photos[0].url} />
                          <Card.Content>
                            <Card.Header>{item.leadDescription}</Card.Header>
                            <Card.Meta>$ {item.retailPrice}</Card.Meta>
                            <Card.Meta>{item.inventoryNumber.slice(-6)}</Card.Meta>
                          </Card.Content>
                          <Card.Content extra>
                            Usage: {item.usageTypeId}, Functional: {item.functional}, Damage:{' '}
                            {item.damageTypeId}
                          </Card.Content>
                        </Card>
                      ))}
                    </Card.Group>
                  </Accordion.Content>
                </Accordion>
              </>
            );
          }}
        </API.Query>

        <Button
          content={`Create Auction with ${new Set(selectedIds).size} unique items`}
          onClick={async () => {
            const result = await submit({
              title,
              address,
              city,
              state,
              zip,
              timezone,
              featured: '1',
              start_date_time: moment(startTime, 'dddd, MMMM Do, YYYY').format(
                'YYYY-MMMM-DD h:mmA'
              ),
              end_date_time: moment(endTime, 'dddd, MMMM Do, YYYY')
                .hour(18)
                .format('YYYY-MMMM-DD h:mmA'),
              front_page_text: frontPageText,
              tax_rate: taxRate,
              payment_info: paymentInfo,
              removal_info: removalInfo,
              category_id: category,
              auction_group_type: '1',
              starting_bid: '5',
              deposit_required: '0',
              lead_image_filename: leadItem ? leadItem.image_url : '',
              affiliate_id: affiliateId,
              invoice_text: invoiceText,
              allow_extended_bidding: '1',
              buyer_premium: buyersPremium,
              item_ids: selectedIds,
              invoice_available_to_bidder: '1',
              item_detail: {
                // featured: '1', // "0" or "1"
                taxable: '1', // "0" or "1"
                tax_rate: taxRate,
                premium_rate: buyersPremium,
                tax_premium_only: '0',
                no_cc_payment: '1',
                display_consignor_name: '0',
                // description: 'Test item description',
                quantity: '1',
                // buy_now: '1500.00',
                // starting_bid: '250.00',
                // reserve_price: '800.00',
                deposit_required: '0.00',
                // consignor_id: '0',
                address,
                city,
                // state_id: '135',
                // foreign_state: '',
                zip_code: zip,
                is_live: '1',
                // extra_info: '',
              },
            });

            if (result) {
              fetch();
            }
          }}
        />
        <Divider />
      </>
    );
  }
}

Footer.defaultProps = {
  handleChange: () => {},
};

Footer.propTypes = {
  governorState: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleLeadItemClick: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  handleAccordionClick: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
};

const AuctionMakerLayout = {
  Footer,
  Header,
};

export default AuctionMakerLayout;
