import { connect } from 'react-redux';
import actions from '../../store/actions';
import QuickStartInput from './quick-start-input';

export default connect(
  ({ inventoryEditor, router, auth }) => ({ ...inventoryEditor, ...router, ...auth }),
  {
    ...actions.inventoryEditor,
    ...actions.router,
  }
)(QuickStartInput);
