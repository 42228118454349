import React from 'react';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const MessageBlock = ({ messages, onDismiss, header, type, timeout = 0 }) => {
  let timer = null;

  if (timeout > 0) {
    timer = setTimeout(onDismiss, timeout);
  }

  if (messages.length === 0 && timer) {
    clearTimeout(timer);
  }

  return (
    <Message
      hidden={messages.length === 0}
      error={type === 'error'}
      success={type === 'success'}
      onDismiss={onDismiss}
    >
      <Message.Header>{header || type === 'error' ? 'Error' : 'Success'}</Message.Header>
      <Message.List items={messages} />
    </Message>
  );
};

MessageBlock.defaultProps = {
  header: '',
  onDismiss: null,
};

MessageBlock.propTypes = {
  header: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDismiss: PropTypes.func,
  timeout: PropTypes.number,
  type: PropTypes.oneOf(['success', 'error']).isRequired,
};

export default MessageBlock;
