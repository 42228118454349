import React from 'react';
import { Button, Card, Icon, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

class SessionNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minutes: this.getTimeDifference(),
    };

    this._isMounted = true;
  }

  handleSessionNavClick = () => {
    const { history, inventoryNumber } = this.props;

    history.push(`/item/${inventoryNumber}/form/main`);
  };

  getTimeDifference = () => {
    const { date } = this.props;

    return moment().diff(date, 'minutes');
  };

  componentDidMount() {
    setInterval(() => {
      const minutes = this.getTimeDifference();

      if (this._isMounted) {
        this.setState({ minutes });
      }
    }, 60000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      inventoryNumber,
      className,
      date,
      image,
      description,
      nav,
      onClick,
      action,
    } = this.props;
    const { minutes } = this.state;

    return (
      <Card link onClick={onClick} className={className}>
        <Card.Content onClick={nav ? this.handleSessionNavClick : null}>
          <Image floated={'right'} size="mini" src={image.url} />
          <Card.Header>{inventoryNumber}</Card.Header>
          <Card.Meta>{`${date.toDateString()}  ${date.toLocaleTimeString()}`}</Card.Meta>
          <Card.Description>{description}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <div className={`inline-flex fw-bolder ai-center ${minutes > 3 ? 'pulse' : ''}`}>
            <Icon name={'sync'} loading />
            {`${minutes} minutes`}
          </div>
          {action && (
            <Button floated={'right'} content={'Clear Session'} onClick={action} primary />
          )}
        </Card.Content>
      </Card>
    );
  }
}

SessionNav.defaultProps = {
  nav: true,
  onClick: () => {},
  action: null,
};

SessionNav.propTypes = {
  inventoryNumber: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object,
  nav: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  action: PropTypes.func,
  history: PropTypes.object.isRequired,
};

export default withRouter(SessionNav);
