import React, { Component, Fragment } from 'react';
import CargoApi from '../shared-api-adapters/cargo-api';
import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
import { Button, Step, Input } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import PurchaseOrderReportTable from './purchase-order-report-table';
import PurchaseOrderVehicleTable from './purchase-order-vehicle-table';
import PurchaseOrderReportExpenseTable from './purchase-order-report-expense-table';
import PoAuction from './po-auction';

class PurchaseOrderReportGenerator extends Component {
  constructor(props) {
    super(props);

    this.cargoApi = new CargoApi();
    this.nellisAuctionApi = new NellisAuctionApi();

    this.state = {
      accountingCodes: [],
      auctionIds: [],
      auctionsToAffiliates: [],
      auctions: [],
      auctionTotals: [],
      completedSteps: [],
      creditCardFees: 0.0,
      swipedCreditCardFees: 0.0,
      endDate: null,
      filtering: false,
      loads: [],
      locations: [],
      rawData: [],
      reconciledVehicles: [],
      reportTotals: {},
      startDate: null,
      step: 0,
      totals: {},
      vehicleCost: 0.0,
      vehicleData: [],
      vehiclesOnHand: [],
      auctionsTax: [],
    };
  }

  componentDidMount = () => this.fetchStaticData();

  fetchStaticData = async () => {
    this.setState({
      locations: await this.cargoApi.get(this.cargoApi.routes.locations),
      programs: await this.cargoApi.get(this.cargoApi.routes.programs),
      vehiclesOnHand: await this.cargoApi.get(this.cargoApi.routes.inventoryVehiclesOnHand),
    });
  };

  fetchAuctionData = async () => {
    this.setState({ filtering: true });

    const { endDate, startDate } = this.state;

    let auctions = await this.cargoApi.get(
      `${this.cargoApi.routes.auctionMethodAuctions}/start/${startDate}/end/${endDate}`
    );

    auctions = auctions.map(
      auc => (/Online (Returns|Retailer)/.test(auc.title) ? { ...auc, programId: 1 } : auc)
    );

    const auctionIds = [...new Set(auctions.map(({ auctionId }) => auctionId))];

    const auctionsTax = await this.cargoApi.get(
      `${this.cargoApi.routes.auctionMethodTax}/start/${startDate}/end/${endDate}`,
      null,
      {
        auctionIds: JSON.stringify(auctionIds),
      }
    );

    const creditCardTotals = await this.cargoApi.get(
      `${this.cargoApi.routes.auctionMethodCreditCardTotals}/start/${startDate}/end/${endDate}`
    );

    await this.setState({
      auctions,
      creditCardTotals,
      filtering: false,
      step: 1,
      auctionsTax,
    });

    this.fetchVehicleData();
  };

  fetchVehicleData = async () => {
    const { startDate, endDate } = this.state;

    this.setState({
      vehicleData: await this.cargoApi.get(
        `${this.cargoApi.routes.auctionMethodVehicles}/start/${startDate}/end/${endDate}`
      ),
    });
  };

  updateAuctionVehicles = (event, inputData) => {
    const { reconciledVehicles, vehicleData } = this.state;

    const itemId = inputData.name.split('-')[0];

    this.setState({
      vehicleData: vehicleData.map(
        av => (av.itemId === Number(itemId) ? { ...av, auctionVehicleId: inputData.value } : av)
      ),
      reconciledVehicles: [...reconciledVehicles, inputData.value],
    });
  };

  handleInputChange = (event, data) => this.setState({ [data.name]: data.value });

  affiliateId = locationId => this.state.locations.find(({ id }) => id === locationId);

  progress = step =>
    this.setState({ step: step + 1, completedSteps: [...this.state.completedSteps, step] });

  setStep = step => this.setState({ step });

  /**
   * Create affiliateTotals object
   * @return object
   */
  createAffiliateTotalsObject = () => {
    const { auctions } = this.state;

    const affiliateTotals = {};

    auctions.map(al => {
      const { affiliateId, accountingCode = null } = al;

      if (!affiliateTotals[affiliateId]) {
        affiliateTotals[affiliateId] = {};
      }

      if (accountingCode !== null) {
        affiliateTotals[affiliateId][accountingCode] = 0.0;
        affiliateTotals[affiliateId][`${accountingCode}.1`] = 0.0;
        affiliateTotals[affiliateId].tax = 0.0;
      }

      return null;
    });

    return affiliateTotals;
  };

  generateReport = () => {
    const { auctions, auctionsTax } = this.state;

    const reportTotals = auctions.reduce(
      (acc, { auctionId, affiliateId, payments, refunds, accountingCode }) => {
        if (!accountingCode) return acc;

        const result = auctionsTax.find(at => at.auctionId === auctionId);

        return {
          ...acc,
          [affiliateId]: {
            ...acc[affiliateId],
            [accountingCode]: acc[affiliateId][accountingCode] + payments,
            [`${accountingCode}.1`]: acc[affiliateId][`${accountingCode}.1`] + refunds,
            [`tax`]: acc[affiliateId].tax + (result ? result.tax : 0.0),
          },
        };
      },
      this.createAffiliateTotalsObject()
    );

    // create an array of sorted accountingCodes for the report
    const accountingCodes = [
      ...new Set(
        Object.keys(reportTotals)
          .map(rt => rt)
          .map(rtIndex => Object.keys(reportTotals[rtIndex]))
          .flat()
      ),
    ].sort();

    let totals = { payments: 0.0, refunds: 0.0, tax: 0.0 };

    Object.keys(reportTotals).map(rt =>
      accountingCodes.map(aCode => {
        let { payments, refunds, tax } = totals;

        if (isNaN(reportTotals[Number(rt)][aCode])) return null;

        if (/^\d+\.1$/.test(aCode)) {
          refunds += reportTotals[Number(rt)][aCode];
        } else if (aCode === 'tax') {
          tax += reportTotals[Number(rt)].tax;
        } else {
          payments += reportTotals[Number(rt)][aCode];
        }

        totals = { payments, refunds, tax };

        return totals;
      })
    );

    this.setState({ reportTotals, accountingCodes, totals });
  };

  /**
   * Updates the location of the auction
   * @param event
   * @param inputData
   */
  updateLocation = (event, inputData) => {
    const { auctions } = this.state;
    const auctionId = Number(inputData.name.split('-')[1]);
    const { affiliateId } = this.affiliateId(inputData.value);

    this.setState({
      auctions: auctions.map(
        auction => (auction.auctionId === auctionId ? { ...auction, affiliateId } : auction)
      ),
    });
  };

  updateProgram = (event, inputData) => {
    const { auctions, programs } = this.state;
    const auctionId = Number(inputData.name.split('-')[1]);
    const programId = Number(inputData.value);

    let { accountingCode } = programs.find(program => program.id === programId);

    if (!accountingCode) accountingCode = null;

    this.setState({
      auctions: auctions.map(
        auction =>
          auction.auctionId === auctionId ? { ...auction, programId, accountingCode } : auction
      ),
    });
  };

  addVehiclesToReport = () => {
    const { reconciledVehicles, vehiclesOnHand } = this.state;

    const amounts = reconciledVehicles.map(rv => {
      const { amount } = vehiclesOnHand.find(voh => voh.id === rv);

      return amount;
    });

    this.setState({ vehicleCost: amounts.reduce((acc, value) => acc + value, 0.0) });
  };

  render() {
    const {
      auctions,
      completedSteps,
      creditCardFees,
      creditCardTotals,
      locations,
      programs,
      endDate,
      filtering,
      reportTotals,
      startDate,
      step,
      swipedCreditCardFees,
      totals,
      vehicleCost,
      vehicleData,
      vehiclesOnHand,
    } = this.state;

    return (
      <>
        <h1 className={'Cargo-header'}>Report Generator</h1>
        <div className={'mb-4'}>
          <DateInput
            dateFormat={'YYYYMMDD'}
            name={'startDate'}
            placeholder={'Select a start date'}
            value={startDate || ''}
            popupPosition="bottom left"
            onChange={this.handleInputChange}
            clearable={true}
            closable={true}
            closeOnMouseLeave={false}
            className={'m-2'}
          />
          <DateInput
            dateFormat={'YYYYMMDD'}
            name={'endDate'}
            placeholder={'Select an end date'}
            value={endDate || ''}
            popupPosition="bottom left"
            onChange={this.handleInputChange}
            clearable={true}
            closable={true}
            closeOnMouseLeave={false}
            className={'m-2'}
          />
          <Button
            icon={'filter'}
            className={'m-2'}
            content={'Filter'}
            onClick={this.fetchAuctionData}
            loading={filtering}
          />
        </div>
        <div className={'m-2 mt-4 mb-4'}>
          <Step.Group ordered fluid>
            <Step
              completed={completedSteps.includes(1)}
              active={step === 1}
              onClick={() => this.setStep(1)}
              disabled={step !== 1 && !completedSteps.includes(1)}
            >
              <Step.Content>
                <Step.Title>Reconcile Locations and Programs</Step.Title>
              </Step.Content>
            </Step>
            <Step
              completed={completedSteps.includes(2)}
              active={step === 2}
              onClick={() => this.setStep(2)}
              disabled={step !== 2 && !completedSteps.includes(2)}
            >
              <Step.Content>
                <Step.Title>Reconcile Vehicles</Step.Title>
              </Step.Content>
            </Step>
            <Step
              completed={completedSteps.includes(3)}
              active={step === 3}
              onClick={() => {
                this.setStep(3);
              }}
              disabled={step !== 3 && !completedSteps.includes(4)}
            >
              <Step.Content>
                <Step.Title>Finalize Report</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </div>
        {step === 1 && (
          <PoAuction
            auctions={auctions}
            locations={locations}
            programs={programs}
            updateLocation={this.updateLocation}
            updateProgram={this.updateProgram}
          />
        )}
        {step === 2 && (
          <PurchaseOrderVehicleTable
            data={vehicleData}
            onHand={vehiclesOnHand}
            onChange={this.updateAuctionVehicles}
          />
        )}
        {step === 3 &&
          auctions.length > 0 && (
            <>
              <div>
                <Input
                  className={'m-2'}
                  name={`creditCardFees`}
                  label={'CC Fees: $'}
                  type={'number'}
                  step={0.01}
                  onChange={this.handleInputChange}
                  value={creditCardFees}
                />
                <Input
                  className={'m-2'}
                  name={`swipedCreditCardFees`}
                  label={'Swiped CC Fees: $'}
                  type={'number'}
                  step={0.01}
                  onChange={this.handleInputChange}
                  value={swipedCreditCardFees}
                />
              </div>
              <div>
                <Button className={'m-2'} onClick={this.generateReport}>
                  Generate Report
                </Button>
              </div>
            </>
          )}

        {step === 3 &&
          auctions.length > 0 && (
            <>
              <PurchaseOrderReportTable
                locations={locations}
                reportTotals={reportTotals}
                totals={totals}
              />
              <PurchaseOrderReportExpenseTable
                affiliates={reportTotals}
                creditCardTotals={creditCardTotals}
                fees={{ creditCardFees, swipedCreditCardFees }}
                locations={locations}
                vehicleCost={vehicleCost}
              />
            </>
          )}

        {step !== 3 &&
          auctions.length > 0 && (
            <div>
              <Button
                className={'m-2'}
                onClick={() => {
                  if (step === 2) {
                    this.addVehiclesToReport();
                  }

                  return this.progress(step);
                }}
              >
                Next Step
              </Button>
            </div>
          )}
      </>
    );
  }
}

/*
 ******** version 1.1 **********
 * todo report saving:
 *    decide on data structure
 *    db table
 *    api route
 *    implementation of pulling in report from db and rerunning
 *    account for onHand in inventory being a zero
 * todo update styling
 */

export default PurchaseOrderReportGenerator;
