import firebaseApp from './firebase-app';
import firebaseAuth from './firebase-auth';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/performance';

const firebase = firebaseApp();

firebase.performance();

export default {
  firestore: firebase.firestore(),
  firebaseDatabase: firebase.database(),
  firebaseStorage: firebase.storage(),
  firebaseApp: firebase,
  firebaseAuth: firebaseAuth(),
  auctionMethodApi: new AuctionMethodApi(),
};
