import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FirebaseItems from './firebase-items';
import SessionNav from './session-nav';
import { Button, Header, Message, Modal } from 'semantic-ui-react';

class ActiveSessions extends React.Component {
  state = {
    open: false,
    confirmationValue: null,
  };

  openModal = invNum => this.setState({ open: true, confirmationValue: invNum });

  closeModal = () => this.setState({ open: false });

  render() {
    const {
      openModal,
      closeModal,
      props: { userId, authority },
      state: { open, confirmationValue },
    } = this;

    return (
      <FirebaseItems filterBy={{ key: 'sessionUserId', value: userId }}>
        {({ items, removeItem }) => {
          if (items.length === 0)
            return <Message warning content={'No active sessions for user!'} />;
          return (
            <Fragment>
              <div className={'flex fd-row ai-baseline jc-start flex-wr'}>
                {items
                  .sort((a, b) => new Date(b.sessionStartTime) - new Date(a.sessionStartTime))
                  .map(
                    ({ id, inventoryNumber, sessionStartTime, leadDescription, photos }, key) => (
                      <div className={'mr-3 mb-3'} key={key}>
                        <SessionNav
                          date={new Date(sessionStartTime)}
                          inventoryNumber={inventoryNumber}
                          description={leadDescription || ''}
                          image={photos ? Object.values(photos)[0] : { url: '' }}
                          action={id || authority ? () => openModal(inventoryNumber) : null}
                        />
                      </div>
                    )
                  )}
              </div>
              <Modal open={open} size={'tiny'}>
                <Modal.Content>
                  <Header>
                    You are about to clear session {confirmationValue} and all of its data! Are you
                    sure you want to proceed?
                  </Header>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    secondary
                    content={'No, it was an accident'}
                    onClick={() => {
                      closeModal();
                    }}
                  />
                  <Button
                    primary
                    content={'Yes, remove it'}
                    onClick={() => {
                      closeModal();
                      removeItem(confirmationValue);
                    }}
                  />
                </Modal.Actions>
              </Modal>
            </Fragment>
          );
        }}
      </FirebaseItems>
    );
  }
}

ActiveSessions.defaultProps = {
  authority: false,
};

ActiveSessions.propTypes = {
  userId: PropTypes.number.isRequired,
  authority: PropTypes.bool,
};

export default ActiveSessions;
