import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class RefundsMetricsTable extends React.Component {
  renderRows = data =>
    data.map(({ amount, userId, description, createdAt, inventoryNumber, refundType }, key) => (
      <Table.Row key={key}>
        <Table.Cell>{`${this.userName(userId)}`}</Table.Cell>
        <Table.Cell>
          <a href={`/item/${inventoryNumber}/form/main`} target={'_blank'} rel="noreferrer">
            {inventoryNumber}
          </a>
        </Table.Cell>
        <Table.Cell>{description}</Table.Cell>
        <Table.Cell>{createdAt}</Table.Cell>
        <Table.Cell>{refundType === 1 ? 'Refund' : 'Relist'}</Table.Cell>
        <Table.Cell>
          {amount
            ? `$${amount.toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}`
            : ''}
        </Table.Cell>
      </Table.Row>
    ));

  userName = userId => {
    const user = this.props.data.users.find(u => u.id === userId);

    return user ? `${user.lastName}, ${user.firstName}` : userId;
  };

  render() {
    const { data } = this.props;

    const { refunds } = data;

    if (refunds.length <= 0) {
      return <div className={'missing-stats'}>No refunds found using the provided filters</div>;
    }

    return (
      <Table basic={'very'} striped celled className={'stats-table'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User ID</Table.HeaderCell>
            <Table.HeaderCell>Inventory Number</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderRows(refunds)}</Table.Body>
      </Table>
    );
  }
}

RefundsMetricsTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RefundsMetricsTable;
