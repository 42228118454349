import React from 'react';
import { Statistic, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class StatRow extends React.Component {
  render() {
    const { statOne, statTwo, label } = this.props;

    const statOneString = statOne ? `${statOne}%` : null;
    const statTwoString = statTwo ? `${statTwo}%` : null;
    const statThreeString = statTwo ? `${(statOne - statTwo).toFixed(2)}%` : null;
    const showAsStatistic = !!(statOne - statTwo > 10.0 || statOne - statTwo < -10.0) && statTwo;

    return (
      <Table.Row>
        <Table.Cell className={'left-align'}>{label}</Table.Cell>
        <Table.Cell>{statOneString}</Table.Cell>
        <Table.Cell>{statTwoString}</Table.Cell>
        <Table.Cell>
          {showAsStatistic && (
            <Statistic size={'mini'}>
              <Statistic.Value>{statThreeString}</Statistic.Value>
            </Statistic>
          )}
          {!showAsStatistic && statThreeString}
        </Table.Cell>
      </Table.Row>
    );
  }
}

StatRow.propTypes = {
  statOne: PropTypes.string,
  statTwo: PropTypes.string,
  label: PropTypes.string,
};

export default StatRow;
