import React, { Component, Fragment } from 'react';
import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
import { Icon, Input, Header, Menu, Divider } from 'semantic-ui-react';
import ActiveSessions from './active-sessions';
import MultiToggle from '../shared-components/multi-toggle';
import API from './api';
import DataFilter from './data-filter';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class OpenSessions extends Component {
  renderUserSession = ({ firstName, lastName, id }) => (
    <Fragment key={id}>
      <Header>
        <Icon name={'user'} />
        <Header.Content>{`${firstName} ${lastName}`}</Header.Content>
      </Header>
      <ActiveSessions userId={id} authority={true} />
    </Fragment>
  );

  render() {
    const { user } = this.props;

    return (
      <API.Query
        queries={[{ route: 'users' }, { route: 'supervisors' }, { route: 'inventorySupervisors' }]}
        api={new NellisAuctionApi(user.token)}
      >
        {({ data: [users, supervisors, inventorySupervisors] }) => {
          let nestedTeams = [];

          const allSupeIds = [...supervisors, ...inventorySupervisors].map(supe => supe.id);

          const usersTeam = users.filter(u => u.supervisorId === user.id);

          if (user.roleId === 2) {
            nestedTeams = usersTeam.map(teamMember => ({
              ...teamMember,
              team: users.filter(u => u.supervisorId === teamMember.id),
            }));
          }

          return (
            <MultiToggle indexes={['me']}>
              {({ toggle, indexes }) => (
                <DataFilter
                  data={nestedTeams.length > 0 ? nestedTeams : usersTeam}
                  filterBy={'firstName'}
                >
                  {({ filteredData, handleSearchValueChange, handleSearchValueClear, value }) => (
                    <div className={'mt-5'}>
                      <Header content={'Open Sessions'} />
                      <Menu attached="top" tabular className={'mb-4'}>
                        <Menu.Item
                          name={'me'}
                          icon={'user'}
                          active={indexes.includes('me')}
                          onClick={() => {
                            toggle('team');
                            toggle('me');
                          }}
                        />
                        <Menu.Item
                          name={'team'}
                          icon={'users'}
                          disabled={!allSupeIds.includes(user.id)}
                          active={indexes.includes('team')}
                          onClick={() => {
                            toggle('team');
                            toggle('me');
                          }}
                        />
                        <Menu.Menu position="right">
                          <Menu.Item>
                            <Input
                              transparent
                              disabled={!allSupeIds.includes(user.id)}
                              icon={{
                                name: value.length > 0 ? 'close' : 'search',
                                link: true,
                                onClick: handleSearchValueClear,
                              }}
                              placeholder="Search users..."
                              onChange={handleSearchValueChange}
                              value={value}
                            />
                          </Menu.Item>
                        </Menu.Menu>
                      </Menu>
                      <div className={'mt-3'}>
                        {indexes.includes('me') && <ActiveSessions userId={user.id} />}
                        {indexes.includes('team') &&
                          filteredData.map((u, key) => (
                            <div key={key} className={'mt-5'}>
                              {this.renderUserSession(u)}
                              {u.team &&
                                u.team.length > 0 && (
                                  <div
                                    className={
                                      'border-l-2 border-l-dotted border-l-grey ml-4 pt-3 pb-3 pl-3'
                                    }
                                  >
                                    <Header>{`${u.firstName}'s Team`}</Header>
                                    <Divider />
                                    {u.team.map(t => this.renderUserSession(t))}
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </DataFilter>
              )}
            </MultiToggle>
          );
        }}
      </API.Query>
    );
  }
}

OpenSessions.propTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(({ auth }) => ({ ...auth }), {})(OpenSessions);
