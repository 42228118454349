import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class TopRetailTable extends React.Component {
  renderRows = data =>
    data.map(({ id, inventoryNumber, retailPrice }) => (
      <Table.Row key={id}>
        <Table.Cell>{id}</Table.Cell>
        <Table.Cell>
          <a href={`/item/${inventoryNumber}/form/main`} target={'_blank'} rel="noreferrer">
            {inventoryNumber}
          </a>
        </Table.Cell>
        <Table.Cell>${parseFloat(retailPrice).toFixed(2)}</Table.Cell>
      </Table.Row>
    ));

  render() {
    const { data } = this.props;

    return (
      <Table basic={'very'} striped celled className={'stats-table'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Item ID</Table.HeaderCell>
            <Table.HeaderCell>Inventory Number</Table.HeaderCell>
            <Table.HeaderCell>Retail Price</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderRows(data)}</Table.Body>
      </Table>
    );
  }
}

TopRetailTable.propTypes = {
  data: PropTypes.array,
};

export default TopRetailTable;
