import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Form, Image, Segment } from 'semantic-ui-react';
import InventoryEditor from '../inventory-editor';
import { getFeaturedImage } from '../../utilities/helpers/item-identification';
import CargoApi from '../../shared-api-adapters/cargo-api';
import './quick-start-input.css';
import { isEmpty } from 'lodash/fp';
import dependencies from '../../dependencies';

class QuickStartInput extends React.Component {
  constructor(props) {
    super(props);

    this.cargoApiService = new CargoApi();

    this.itemRef = dependencies.firebaseDatabase
      .ref('items')
      .orderByKey()
      .limitToFirst(1);

    this.searchInputRef = null;

    this.state = {
      newItem: true,
      barcode: '',
      item: {},
      modalOpen: false,
      searchPending: false,
    };

    this._isMounted = true;
  }

  onBarcodeInputChange = (event, target) => {
    const barcode = target.value;
    const newState = { barcode };

    if (!QuickStartInput.validateBarcode(barcode)) {
      this.setState(newState);
      return;
    }

    this.setState({ ...newState, searchPending: true, newItem: true });

    this.cargoApiService
      .get(this.cargoApiService.routes.itemByInventoryNumber, barcode)
      .then(([item = {}]) => {
        if (isEmpty(item)) {
          this.itemRef.equalTo(barcode).once('value', ds => {
            const value = ds.val();

            if (this._isMounted) {
              this.setState({
                data: value ? value[barcode] : null,
                newItem: value === null,
                searchPending: false,
              });
            }
          });
        } else {
          if (this._isMounted)
            this.setState({
              item,
              newItem: false,
              searchPending: false,
            });
        }

        // force form to be submitted when barcode is complete
        setTimeout(() => {
          if (QuickStartInput.validateBarcode(this.state.barcode)) {
            this.onButtonClick();
          }
        }, 1000);
      });
  };

  onButtonClick = () => {
    this.openItem();

    if (this._isMounted) this.setState({ barcode: '', newItem: true, modalOpen: false });
  };

  openItem = () => {
    const { item, barcode } = this.state;

    if (!QuickStartInput.validateBarcode(barcode)) {
      return;
    }

    if (!isEmpty(item.return) || Number(item.salePrice)) {
      this.props.push(`/itemDetail/${item.inventoryNumber}`, item);
      return;
    }

    this.props.push(InventoryEditor.getRoute(barcode));
  };

  static validateBarcode(barcode) {
    return /^\d{10}$/.test(barcode);
  }

  componentDidMount() {
    const { state: pathState = {} } = this.props.location;

    this.searchInputRef.focus();

    if (pathState.barcode) {
      this.onBarcodeInputChange({}, { value: pathState.barcode });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getNotFound() {
    const { barcode } = this.state;

    return (
      <Segment>
        <Button type="button" onClick={this.onButtonClick} fluid color="green">
          Create item {barcode}
        </Button>
        <strong>Item #{barcode} Not Found</strong>
      </Segment>
    );
  }

  getSearchResult() {
    const { item, barcode } = this.state;

    return (
      <Segment>
        <Button type="button" onClick={this.onButtonClick} color="blue">
          Open
        </Button>
        <Image
          src={getFeaturedImage(item.photos).url}
          inline
          avatar
          spaced="right"
          verticalAlign="middle"
        />
        <span>
          <strong>Item #{barcode}</strong>: {item.leadDescription}
        </span>
      </Segment>
    );
  }

  render() {
    const { barcode, item, searchPending } = this.state;

    let itemDetails = null;
    let invalid = false;
    let icon = 'search';

    if (!searchPending && barcode !== '') {
      if (!isEmpty(item)) {
        icon = 'checkmark';
        itemDetails = this.getSearchResult();
      } else if (!QuickStartInput.validateBarcode(barcode)) {
        invalid = true;
        icon = 'exclamation';
      } else if (isEmpty(item)) {
        icon = 'question';
        itemDetails = this.getNotFound();
      }
    }

    return (
      <Form className="QuickStartInput" onSubmit={this.openItem}>
        <Form.Field>
          <Input
            label={
              <Button
                color="red"
                icon="remove"
                type="button"
                onClick={() => this.onBarcodeInputChange(null, { value: '' })}
              />
            }
            labelPosition="left"
            loading={searchPending}
            error={invalid}
            icon={icon}
            ref={ref => (this.searchInputRef = ref)}
            placeholder="Scan 10-digit inventory number..."
            onChange={this.onBarcodeInputChange}
            value={barcode}
          />
        </Form.Field>
        {itemDetails}
      </Form>
    );
  }
}

QuickStartInput.propTypes = {
  push: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default QuickStartInput;
