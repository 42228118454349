import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import reduxLogger from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';
import reducers from './reducers';
import history from '../history';

const middleware = [thunkMiddleware, routerMiddleware(history)];
let store = null;

// if (process.env.NODE_ENV === 'development') {
// middleware.push(reduxLogger);
// }

export default () => {
  if (store) {
    return store;
  }

  store = createStore(combineReducers(reducers), {}, applyMiddleware(...middleware));

  return store;
};
