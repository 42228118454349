import React from 'react';
import CargoApi from '../../shared-api-adapters/cargo-api';
import NellisAuctionApi from '../../shared-api-adapters/nellis-auction-api';
import { Accordion, Button, Dropdown, Icon } from 'semantic-ui-react';
import './stat-city.css';
import PropTypes from 'prop-types';
import Snackbar from '../../shared-components/snackbar';
import { DateInput } from 'semantic-ui-calendar-react';
import ProgramAveragesTable from '../program-averages-table';
import LoadTotalsTable from '../load-totals-table';
import TopRetailTable from '../top-retail-table';
import RetailVsRawTable from '../retail-vs-raw-table';
import StatTable from '../stat-table';
import ItemTimesTable from '../item-times-table';
import RefundsMetricsTable from '../refunds-metrics-table';
import PNLstatement from '../pnl-statement';

class StatCity extends React.Component {
  constructor(props) {
    super(props);

    this.cargoApiService = new CargoApi(this.props.user.token);

    this.nellisAuctionApi = new NellisAuctionApi(this.props.user.token);

    this.state = {
      showSnackbar: false,
      message: '',
      options: {
        loads: [],
        locations: [],
        programs: [],
        supervisors: [],
        users: [],
      },
      placeholders: {
        endDate: { initial: 'End date', current: 'End date' },
        loadId: { initial: 'All loads', current: 'All loads' },
        locationId: { initial: 'All locations', current: 'All locations' },
        programId: { initial: 'All programs', current: 'All programs' },
        reportTypeId: { initial: 'Select a report type', current: 'Select a report type' },
        supervisorId: { initial: 'All supervisors', current: 'All supervisors' },
        startDate: { initial: 'Start date', current: 'Start date' },
        userId: { initial: 'All users', current: 'All users' },
      },
      reportFilters: {
        endDate: null,
        loadId: null,
        locationId: null,
        programId: null,
        reportTypeId: null,
        startDate: null,
        supervisorId: null,
        userId: null,
      },
      loads: [],
      percentages: [],
      programs: [],
      metrics: {},
      statistics: {
        assemblyRequired: {
          loading: true,
          label: 'Assembly Required',
          color: '#23f948',
          type: 'bar',
        },
        damage: {
          loading: true,
          label: 'Damage',
          type: 'pie',
        },
        functional: {
          loading: true,
          label: 'Functional',
          color: '#0080ff',
          type: 'bar',
        },
        lightningSubmission: {
          loading: true,
          label: 'Lightning Submission',
          color: '#0080ff',
          type: 'bar',
        },
        missingParts: {
          loading: true,
          label: 'Missing Parts',
          type: 'pie',
        },
        packaging: {
          loading: true,
          label: 'Packaging',
          type: 'pie',
        },
        returns: {
          loading: true,
          label: 'Returns',
          type: 'bar',
        },
        usage: {
          loading: true,
          label: 'Usage',
          type: 'pie',
        },
      },
      supervisors: [],
      companyMetrics: null,
      loading: false,
      loadingLoadTotals: false,
      loadingMetricTotals: false,
      searched: false,
      users: [],
    };
  }

  componentDidMount() {
    this.fetchInitialData().then(true);
  }

  async fetchInitialData() {
    const locations = await this.nellisAuctionApi.get(this.cargoApiService.routes.locations);
    const supervisors = await this.nellisAuctionApi.get(
      this.nellisAuctionApi.routes.inventorySupervisors
    );
    const users = await this.nellisAuctionApi.get(this.nellisAuctionApi.routes.users);
    const programs = await this.cargoApiService.get(this.cargoApiService.routes.programs);
    const loads = await this.cargoApiService.get(this.cargoApiService.routes.loads);

    this.setState({
      options: {
        ...this.state.options,
        loads: loads.map(load => ({
          text: load.id,
          value: load.id,
        })),
        locations: locations.map(location => ({
          text: location.name,
          value: location.id,
        })),
        programs: programs.map(program => ({
          text: program.name,
          value: program.id,
        })),
        users: this.formatOptionsForDropdown(users),
        supervisors: this.formatOptionsForDropdown(supervisors),
      },
      loads,
      programs,
      supervisors,
      users,
    });
  }

  getUserIdsBySupervisor = supervisorId => {
    const { reportFilters, users } = this.state;

    let userIds = null;

    if (reportFilters.userId === null) {
      const userId = users.filter(user => user.supervisorId === supervisorId).map(user => user.id);

      if (userId.length <= 0) return {};

      userIds = JSON.stringify(userId);
    }

    return userIds;
  };

  formatOptionsForDropdown = users =>
    users.map(user => ({
      text: `${user.firstName} ${user.lastName}`,
      value: user.id,
    }));

  formatLoadsForDropdown = loads =>
    loads.map(load => ({
      text: load.id,
      value: load.id,
    }));

  resetDropdown = name => {
    const { initial } = this.state.placeholders[name];

    this.setState({
      placeholders: { ...this.state.placeholders, [name]: { current: initial, initial } },
      reportFilters: { ...this.state.reportFilters, [name]: null },
    });
  };

  handleDropdownChange = (event, data) => {
    if (data.value === '') return this.resetDropdown(data.name);

    return this.setState({
      reportFilters: { ...this.state.reportFilters, [data.name]: data.value },
    });
  };

  handleProgramChange = (event, data) => {
    const { initial } = this.state.placeholders.loadId;

    const value = data.value === '' ? null : data.value;

    const options = data.value === '' ? this.state.loads : this.programsLoad(value);

    const label = data.value === '' ? initial : `Program's load`;

    return this.setState({
      reportFilters: { ...this.state.reportFilters, programId: value, loadId: null },
      options: { ...this.state.options, loads: this.formatLoadsForDropdown(options) },
      placeholders: {
        ...this.state.placeholders,
        loadId: { current: label, initial },
      },
    });
  };

  handleSupervisorChange = (event, data) => {
    const { initial } = this.state.placeholders.userId;

    const value = data.value === '' ? null : data.value;

    const options = data.value === '' ? this.state.users : this.supervisorsTeam(value);

    const label = data.value === '' ? initial : `Supervisor's team`;

    return this.setState({
      reportFilters: { ...this.state.reportFilters, supervisorId: value, userId: null },
      options: { ...this.state.options, users: this.formatOptionsForDropdown(options) },
      placeholders: {
        ...this.state.placeholders,
        userId: { current: label, initial },
      },
    });
  };

  handleSubmit = async () => {
    const { reportFilters } = this.state;

    const { userId, supervisorId } = reportFilters;

    if (supervisorId && userId === null) {
      reportFilters.userId = this.getUserIdsBySupervisor(supervisorId);
    }

    delete reportFilters.supervisorId;

    this.setState({
      loadingCostOfGoodsSold: true,
      loadingItemTimes: true,
      loadingLaborTotals: true,
      loadingLoadTotals: true,
      loadingMetricTotals: true,
      loadingRetailValues: true,
      loadingRetailVsRaw: true,
      loadingRefunds: true,
      loadingRefundTotals: true,
      loadingSalesTotals: true,
      searched: true,
      activeIndex: null,
    });

    // Remove userId and locationId from companyMetricFilters so that we get all metrics
    const companyMetricFilters = { ...reportFilters };

    delete companyMetricFilters.userId;
    delete companyMetricFilters.locationId;

    // fetch metric totals using filters
    this.cargoApiService
      .get(this.cargoApiService.routes.metrics, null, reportFilters)
      .then(metricTotals => {
        // fetch company metrics
        this.cargoApiService
          .get(this.cargoApiService.routes.metrics, null, companyMetricFilters)
          .then(companyMetrics => {
            this.setState({
              companyMetrics: companyMetrics[0],
              metricTotals: { ...this.state.metricTotals, ...metricTotals[0] },
              loadingMetricTotals: false,
            });
          });
      });

    // fetch retailValues
    this.cargoApiService
      .get(this.cargoApiService.routes.metricsRetailPrices, null, reportFilters)
      .then(retailPrices => this.setState({ retailPrices, loadingRetailValues: false }));

    // fetch returns
    this.cargoApiService
      .get(this.cargoApiService.routes.metricsRefunds, null, reportFilters)
      .then(refunds => this.setState({ refunds, loadingRefunds: false }));

    // fetch loadTotals
    this.cargoApiService
      .get(this.cargoApiService.routes.metricsLoadTotals, null, reportFilters)
      .then(loadTotals => this.setState({ loadTotals, loadingLoadTotals: false }));

    // fetch average elapsed time by program
    this.cargoApiService
      .get(this.cargoApiService.routes.metricsProgramAverageElapsedTime, null, reportFilters)
      .then(pTimes => {
        this.setState({
          programsAverageTimeElapsed: pTimes.filter(
            ({ averageElapsedTime }) => averageElapsedTime !== null
          ),
          loadingProgramsAverageTimeElapsed: false,
        });
      });

    // fetch retailVsRaw
    this.cargoApiService
      .get(this.cargoApiService.routes.metricsRetailVsRaw, null, reportFilters)
      .then(retailVsRaw => this.setState({ retailVsRaw, loadingRetailVsRaw: false }));

    // fetch itemTimes
    this.cargoApiService
      .get(this.cargoApiService.routes.metricsItemTimes, null, reportFilters)
      .then(itemTimes => this.setState({ itemTimes, loadingItemTimes: false }));

    // fetch salesTotals
    this.cargoApiService
      .get(this.cargoApiService.routes.metricsProgramSalesTotals, null, reportFilters)
      .then(salesTotals => this.setState({ salesTotals, loadingSalesTotals: false }));

    // fetch refundTotals
    this.cargoApiService
      .get(this.cargoApiService.routes.metricsProgramRefundTotals, null, reportFilters)
      .then(refundTotals => this.setState({ refundTotals, loadingRefundTotals: false }));

    // fetch costOfGoodsSold
    this.cargoApiService
      .get(this.cargoApiService.routes.metricsProgramCostOfGoodsSold, null, reportFilters)
      .then(costOfGoodsSold => this.setState({ costOfGoodsSold, loadingCostsOfGoodsSold: false }));

    // fetch laborTotals
    this.cargoApiService
      .get(this.cargoApiService.routes.metricsProgramLaborTotals, null, reportFilters)
      .then(laborTotals => this.setState({ laborTotals, loadingLaborTotals: false }));
  };

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  displayAccordion = (active, index, searched, loading, data, title, comp = null) => (
    <div className={'Stat-City-Data-Wrapper'}>
      <Accordion fluid styled>
        <Accordion.Title
          active={active === index}
          index={index}
          onClick={this.handleAccordionClick}
        >
          <Icon name="dropdown" />
          {title}
          {loading && (
            <Icon name={'spinner'} loading={true} className={'float-right'} size={'large'} />
          )}
          {!loading &&
            searched && (
              <Icon name={'checkmark'} className={'float-right'} size={'large'} color={'green'} />
            )}
        </Accordion.Title>
        <Accordion.Content active={active === index}>
          {searched && data && !comp && <pre>{JSON.stringify(data, null, 2)}</pre>}
          {searched && data && comp}
        </Accordion.Content>
      </Accordion>
    </div>
  );

  programsLoad = programId => this.state.loads.filter(load => load.programId === programId);

  supervisorsTeam = supervisorId =>
    this.state.users.filter(user => user.supervisorId === supervisorId);

  render() {
    const {
      activeIndex,
      companyMetrics,
      costOfGoodsSold,
      itemTimes,
      laborTotals,
      loadingCostsOfGoodsSold,
      loadingLaborTotals,
      loadingItemTimes,
      loadingLoadTotals,
      loadingMetricTotals,
      loadingProgramsAverageTimeElapsed,
      loadingRetailValues,
      loadingRetailVsRaw,
      loadingRefunds,
      loadingRefundTotals,
      loadingSalesTotals,
      options,
      placeholders,
      loadTotals,
      metricTotals,
      programsAverageTimeElapsed,
      retailPrices,
      retailVsRaw,
      refunds,
      salesTotals,
      refundTotals,
      searched,
      showSnackbar,
      users,
      message,
      reportFilters,
    } = this.state;

    return (
      <div>
        <h1 className={'Stat-City-header'}>Stat City</h1>
        <div className={'Stat-City-buttons'}>
          <Dropdown
            className={'icon'}
            labeled
            name={'supervisorId'}
            // icon={'id badge outline'}
            clearable
            search
            options={options.supervisors}
            placeholder={placeholders.supervisorId.current}
            onChange={this.handleSupervisorChange}
            selection
          />
          <Dropdown
            className={'icon'}
            floating
            labeled
            // icon={'user outline'}
            clearable
            search
            name={'userId'}
            onChange={this.handleDropdownChange}
            options={options.users}
            placeholder={placeholders.userId.current}
            selection
          />
          <Dropdown
            className={'icon'}
            floating
            labeled
            name={'locationId'}
            // icon={'chart line'}
            clearable
            placeholder={placeholders.locationId.current}
            onChange={this.handleDropdownChange}
            options={options.locations}
            selection
          />
          <Dropdown
            className={'icon'}
            floating
            labeled
            // icon={'truck'}
            name={'programId'}
            clearable
            placeholder={placeholders.programId.current}
            options={options.programs}
            onChange={this.handleProgramChange}
            selection
          />
        </div>
        <div className={'Stat-City-buttons'}>
          <DateInput
            dateFormat={'YYYYMMDD'}
            name={'startDate'}
            placeholder={placeholders.startDate.current}
            value={reportFilters.startDate || ''}
            popupPosition="bottom left"
            onChange={this.handleDropdownChange}
            clearable={true}
            closable={true}
            closeOnMouseLeave={false}
          />
          <DateInput
            dateFormat={'YYYYMMDD'}
            name={'endDate'}
            placeholder={placeholders.endDate.current}
            value={reportFilters.endDate || ''}
            popupPosition="bottom left"
            onChange={this.handleDropdownChange}
            clearable={true}
            closable={true}
            closeOnMouseLeave={false}
          />
          <Dropdown
            className={'icon'}
            floating
            labeled
            // icon={'truck'}
            search
            name={'loadId'}
            clearable
            placeholder={placeholders.loadId.current}
            options={options.loads}
            onChange={this.handleDropdownChange}
            selection
          />
          <Button
            content={'Generate report'}
            icon={'cogs'}
            onClick={this.handleSubmit}
            color={'green'}
          />
        </div>
        {this.displayAccordion(
          activeIndex,
          7,
          searched,
          loadingSalesTotals ||
            loadingRefundTotals ||
            loadingCostsOfGoodsSold ||
            loadingLaborTotals,
          salesTotals,
          'P&L Statement',
          <PNLstatement data={{ costOfGoodsSold, salesTotals, refundTotals, laborTotals }} />
        )}
        {this.displayAccordion(
          activeIndex,
          0,
          searched,
          loadingMetricTotals,
          metricTotals,
          'Tendencies',
          <StatTable metrics={metricTotals} allMetrics={companyMetrics} />
        )}
        {this.displayAccordion(
          activeIndex,
          1,
          searched,
          loadingLoadTotals,
          loadTotals,
          'Load Totals',
          <LoadTotalsTable data={loadTotals} />
        )}
        {this.displayAccordion(
          activeIndex,
          2,
          searched,
          loadingRetailValues,
          retailPrices,
          'Top Retail Items',
          <TopRetailTable data={retailPrices} />
        )}
        {this.displayAccordion(
          activeIndex,
          3,
          searched,
          loadingRetailVsRaw,
          retailVsRaw,
          'Retail vs. Raw',
          <RetailVsRawTable data={retailVsRaw} />
        )}
        {this.displayAccordion(
          activeIndex,
          4,
          searched,
          loadingRefunds,
          refunds,
          'Refunds',
          <RefundsMetricsTable data={{ refunds, users }} />
        )}
        {this.displayAccordion(
          activeIndex,
          5,
          searched,
          loadingProgramsAverageTimeElapsed,
          programsAverageTimeElapsed,
          'Program Averages',
          <ProgramAveragesTable data={programsAverageTimeElapsed} />
        )}
        {this.displayAccordion(
          activeIndex,
          6,
          searched,
          loadingItemTimes,
          itemTimes,
          'Item Times',
          <ItemTimesTable data={{ itemTimes, users }} />
        )}
        <Snackbar visible={showSnackbar} message={message} />
      </div>
    );
  }
}

StatCity.propTypes = {
  user: PropTypes.object,
  computedMatch: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }),
};

export default StatCity;
