import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Container, Form, Message, Sidebar } from 'semantic-ui-react';
import LoginButton from '../login-button';

import './login-form.css';
import { NavLink } from 'react-router-dom';
import config from '../../config';

const LoginForm = ({ onLogin, error, username, password, updateUsername, updatePassword }) => (
  <div className="AppHeaderTopBanner">
    <div className="AppHeaderLogoContainer ml-5">
      <span>
        <NavLink to={'/'} className="AppName ml-2">
          Cargo <small className={'fs-1-25'}>{`v${config.release}`}</small>
        </NavLink>
      </span>
      <div className="LoginForm">
        <Container style={{ display: error.length === 0 ? 'none' : 'block' }}>
          <Message negative>{error}</Message>
        </Container>
        <Card centered raised>
          <Card.Content>
            <Card.Header>Login</Card.Header>
          </Card.Content>
          <Card.Content extra>
            <Form
              onSubmit={e => {
                e.preventDefault();
                onLogin();
              }}
            >
              <Form.Input
                label="Username"
                id="usernameField"
                value={username}
                name="username"
                onChange={e => updateUsername(e.target.value.trim())}
              />
              <Form.Input
                label="Password"
                id="passwordField"
                value={password}
                name="password"
                type="password"
                onChange={e => updatePassword(e.target.value.trim())}
              />
              <LoginButton
                fluid
                id="loginButton"
                icon="sign in"
                type="button"
                onClick={onLogin}
                text="Login"
              />
            </Form>
          </Card.Content>
        </Card>
      </div>
    </div>
  </div>
);

LoginForm.propTypes = {
  error: PropTypes.string.isRequired,
  onLogin: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  updateUsername: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
};

export default LoginForm;
