import React, { Component } from 'react';
import API from './api';
import CargoApi from '../shared-api-adapters/cargo-api';
import TableGovernor from './table-governor';
import TableEditor from './table-editor';
import { Button, Segment, Form, Header, Label, Modal, Icon, Checkbox } from 'semantic-ui-react';
import { NotificationContext } from '../shared-components/notification-context';
import { ADMINISTRATOR } from '../utilities/constants/access-levels';

class FraudTable extends Component {
  constructor(props) {
    super(props);

    this.defaultRecord = {
      id: null,
      description: '',
    };

    this.state = {
      error: false,
      count: 0,
    };
  }

  render() {
    const { error, count } = this.state;

    return (
      <NotificationContext.Consumer>
        {({ addNotifications }) => (
          <API.Crud route={'fraudReasonTypes'} api={new CargoApi()}>
            {({ put, post, destroy }) => (
              <API.Query key={count} queries={[{ route: 'fraudReasonTypes' }]} api={new CargoApi()}>
                {({ data: [fraudReasonTypes] }) => (
                  <TableGovernor data={fraudReasonTypes} defaultRecord={this.defaultRecord}>
                    {({
                      data,
                      currentRecord,
                      editing,
                      editRecord,
                      creating,
                      createRecord,
                      removing,
                      removeRecord,
                      handleInputChange,
                      handleToggle,
                      resetState,
                    }) => {
                      const { id, description } = currentRecord;

                      return (
                        <div className={'m-5'}>
                          <Segment raised>
                            <Label as="a" color="orange" ribbon>
                              Fraud Reasons
                            </Label>
                            {!editing &&
                              !creating && (
                                <div className={'mt-3'}>
                                  <Button
                                    content={'CREATE'}
                                    icon={'plus'}
                                    color={'blue'}
                                    onClick={() => createRecord()}
                                  />
                                </div>
                              )}
                            {(editing || creating) && (
                              <Segment>
                                <Form>
                                  <Header size={'huge'} className={'flex jc-center m-3'}>
                                    {editing ? `Edit ${description}` : 'New Fraud Reason'}
                                  </Header>
                                  <Form.Input
                                    name={'description'}
                                    value={description || ''}
                                    label={'Fraud Reason'}
                                    onChange={handleInputChange}
                                    error={description === '' && error}
                                  />
                                  <div>
                                    <Button
                                      type={'button'}
                                      icon={'minus'}
                                      color={'grey'}
                                      onClick={() => resetState()}
                                    />
                                    <Button
                                      type={'button'}
                                      color={'green'}
                                      onClick={async () => {
                                        if (description === '') {
                                          this.setState({ error: true }, () =>
                                            setTimeout(() => {
                                              this.setState({
                                                error: false,
                                              });
                                            }, 3000)
                                          );
                                        } else {
                                          let result;

                                          if (editing) {
                                            result = await put({
                                              ...{
                                                id,
                                                description,
                                              },
                                            });
                                          } else {
                                            result = await post({
                                              description,
                                            });
                                          }

                                          if (result) {
                                            addNotifications([
                                              {
                                                header: 'SUCCESS',
                                                content: `${
                                                  editing ? 'Updated' : 'Created'
                                                } ${id}`,
                                                timestamp: new Date().toLocaleTimeString(),
                                                color: 'green',
                                              },
                                            ]);

                                            this.setState(prevState => ({
                                              count: prevState.count + 1,
                                            }));
                                          } else {
                                            addNotifications([
                                              {
                                                header: 'ERROR',
                                                content: `Could not ${
                                                  editing ? 'update' : 'create'
                                                } location. Please try again.`,
                                                timestamp: new Date().toLocaleDateString(),
                                                color: 'red',
                                              },
                                            ]);
                                          }
                                        }
                                      }}
                                      content={editing ? 'UPDATE' : 'SAVE'}
                                    />
                                  </div>
                                </Form>
                              </Segment>
                            )}
                            <Modal
                              closeOnDimmerClick={false}
                              closeOnDocumentClick={false}
                              closeIcon
                              open={removing}
                              onClose={resetState}
                              centered={false}
                            >
                              <Header as={'h2'}>
                                <Icon name={'warning sign'} color={'yellow'} size={'large'} />
                                WARNING
                              </Header>
                              <Modal.Content>
                                <p>
                                  Are you sure you want to delete <strong>{id}</strong>?
                                </p>
                              </Modal.Content>
                              <Modal.Actions>
                                <Button
                                  positive
                                  icon={'check'}
                                  content={'YES, DELETE THIS REASON'}
                                  onClick={async () => {
                                    const result = await destroy({ id });

                                    if (result) {
                                      addNotifications([
                                        {
                                          header: 'SUCCESS',
                                          content: `Deleted ${id}`,
                                          timestamp: new Date().toLocaleTimeString(),
                                          color: 'green',
                                        },
                                      ]);

                                      this.setState(prevState => ({
                                        count: prevState.count + 1,
                                      }));
                                    } else {
                                      addNotifications([
                                        {
                                          header: 'ERROR',
                                          content: `Could not delete location ${id}. Please try again.`,
                                          timestamp: new Date().toLocaleDateString(),
                                          color: 'red',
                                        },
                                      ]);
                                    }
                                  }}
                                />
                                <Button
                                  negative
                                  icon={'x'}
                                  content={'NO, DO NOT DELETE THIS REASON'}
                                  onClick={resetState}
                                />
                              </Modal.Actions>
                            </Modal>
                            <TableEditor
                              records={data.map(record => ({
                                ID: record.id,
                                description: record.description,
                                record,
                              }))}
                              edit={rec => {
                                window.scrollTo(0, 0);
                                editRecord(rec);
                              }}
                              remove={record => {
                                if (record.protected) {
                                  addNotifications([
                                    {
                                      header: 'NOTE',
                                      content: `${
                                        record.name
                                      } is a protected location and cannot be deleted.`,
                                      timestamp: new Date().toLocaleDateString(),
                                      color: 'blue',
                                    },
                                  ]);
                                } else removeRecord(record);
                              }}
                            />
                          </Segment>
                        </div>
                      );
                    }}
                  </TableGovernor>
                )}
              </API.Query>
            )}
          </API.Crud>
        )}
      </NotificationContext.Consumer>
    );
  }
}

export default FraudTable;
