import React, { Component } from 'react';
import API from './api';
import TableEditor from './table-editor';
import CargoApi from '../shared-api-adapters/cargo-api';
import TableGovernor from './table-governor';
import { NotificationContext } from '../shared-components/notification-context';
import { Button, Dropdown, Form, Header, Label, Segment } from 'semantic-ui-react';
import { ADMINISTRATOR } from '../utilities/constants/access-levels';
import PropTypes from 'prop-types';

const defaultRecord = {
  id: null,
  name: '',
  accountingCode: '',
};

class ProgramCreator extends Component {
  constructor(props) {
    super(props);

    this.state = { count: 0, error: false };
  }

  render() {
    const { count, error } = this.state;
    const { authUser: { roleId, id: userId } } = this.props;

    return (
      <NotificationContext.Consumer key={count}>
        {({ addNotifications }) => (
          <API.Query
            api={new CargoApi()}
            queries={[{ route: 'programs' }, { route: 'costOfGoodsSold' }]}
          >
            {({ data: [programs, costOfGoods] }) => (
              <API.Crud route={'programs'} api={new CargoApi()}>
                {({ put, post }) => (
                  <API.Crud route={'costOfGoodsSold'} api={new CargoApi()}>
                    {({ post: costPost }) => (
                      <TableGovernor
                        data={programs.map(({ id, name, accountingCode }) => {
                          const costs = costOfGoods.map(({ programId }) => programId);

                          return {
                            id,
                            name,
                            costOfGoodsSold: costs.includes(id)
                              ? costOfGoods.find(({ programId }) => programId === id).cost
                              : null,
                            accountingCode,
                          };
                        })}
                        defaultRecord={defaultRecord}
                      >
                        {({
                          data,
                          currentRecord,
                          handleInputChange,
                          editing,
                          editRecord,
                          creating,
                          createRecord,
                          resetState,
                          filterData,
                        }) => {
                          const { id, name, costOfGoodsSold, accountingCode } = currentRecord;

                          return (
                            <Segment>
                              <Label ribbon color={'orange'} content={'Programs'} />
                              <div className={'mt-3'}>
                                <Dropdown
                                  name={'name'}
                                  placeholder={'Program Name'}
                                  search
                                  selection
                                  clearable
                                  onChange={filterData}
                                  options={programs.map(p => ({
                                    key: p.id,
                                    text: p.name,
                                    value: p.name,
                                  }))}
                                />
                              </div>
                              {!editing &&
                                !creating && (
                                  <div className={'mt-3'}>
                                    <Button
                                      content={'ADD PROGRAM'}
                                      icon={'plus'}
                                      color={'blue'}
                                      onClick={() => createRecord()}
                                    />
                                  </div>
                                )}
                              {(editing || creating) && (
                                <Segment>
                                  <Form
                                    onSubmit={async () => {
                                      if (name === '') {
                                        return this.setState({ error: true }, () =>
                                          setTimeout(() => {
                                            this.setState({
                                              error: false,
                                            });
                                          }, 3000)
                                        );
                                      }

                                      let putProgram = true;
                                      let postProgram = true;
                                      let postCost = true;

                                      if (editing) {
                                        putProgram = await put({
                                          id,
                                          accountingCode: accountingCode || null,
                                        });
                                      } else {
                                        postProgram = await post({
                                          name,
                                          accountingCode: accountingCode || null,
                                        });

                                        if (postProgram) {
                                          const [result] = postProgram;
                                          const { id: programId } = result;

                                          postCost = await costPost({
                                            programId,
                                            cost: costOfGoodsSold,
                                          });
                                        }
                                      }

                                      if (!putProgram) {
                                        return addNotifications([
                                          {
                                            header: 'ERROR',
                                            content: 'Could not update program. Please try again.',
                                            timestamp: new Date().toLocaleDateString(),
                                            color: 'red',
                                          },
                                        ]);
                                      }
                                      if (!postProgram) {
                                        return addNotifications([
                                          {
                                            header: 'ERROR',
                                            content: 'Could not create program. Please try again.',
                                            timestamp: new Date().toLocaleDateString(),
                                            color: 'red',
                                          },
                                        ]);
                                      }
                                      if (!postCost) {
                                        return addNotifications([
                                          {
                                            header: 'ERROR',
                                            content: `Could not add cost of goods sold for ${name}. Please contact the software development team.`,
                                            timestamp: new Date().toLocaleDateString(),
                                            color: 'red',
                                          },
                                        ]);
                                      }

                                      addNotifications([
                                        {
                                          header: 'SUCCESS',
                                          content: `${editing ? 'Updated' : 'Created'} ${name}`,
                                          timestamp: new Date().toLocaleTimeString(),
                                          color: 'green',
                                        },
                                      ]);

                                      return this.setState(prevState => ({
                                        count: prevState.count + 1,
                                      }));
                                    }}
                                  >
                                    <Header size={'huge'} className={'flex jc-center m-3'}>
                                      {editing ? `Edit ${name}` : 'New Program'}
                                    </Header>
                                    <Form.Group>
                                      <Form.Input
                                        name={'name'}
                                        value={name || ''}
                                        label={'Name'}
                                        onChange={handleInputChange}
                                        error={name === '' && error}
                                        readOnly={editing}
                                      />
                                      <Form.Input
                                        name={'costOfGoodsSold'}
                                        value={
                                          costOfGoodsSold || costOfGoodsSold === 0
                                            ? costOfGoodsSold
                                            : ''
                                        }
                                        type={'number'}
                                        label={'Cost Of Goods Sold'}
                                        onChange={handleInputChange}
                                        readOnly={!ADMINISTRATOR.includes(roleId)}
                                      />
                                      <Form.Input
                                        name={'accountingCode'}
                                        value={accountingCode || ''}
                                        label={'Accounting Code'}
                                        onChange={handleInputChange}
                                      />
                                    </Form.Group>
                                    <div>
                                      <Button
                                        type={'button'}
                                        icon={'minus'}
                                        color={'grey'}
                                        onClick={() => resetState()}
                                      />
                                      <Button
                                        color={'green'}
                                        content={editing ? 'UPDATE' : 'SAVE'}
                                      />
                                    </div>
                                  </Form>
                                </Segment>
                              )}
                              <TableEditor
                                records={data.map(program => {
                                  const { id: programId, ...rest } = program;

                                  return {
                                    ...rest,
                                    record: program,
                                  };
                                })}
                                edit={rec => {
                                  window.scrollTo(0, 0);
                                  editRecord(rec);
                                }}
                              />
                            </Segment>
                          );
                        }}
                      </TableGovernor>
                    )}
                  </API.Crud>
                )}
              </API.Crud>
            )}
          </API.Query>
        )}
      </NotificationContext.Consumer>
    );
  }
}

ProgramCreator.propTypes = {
  authUser: PropTypes.object.isRequired,
};

export default ProgramCreator;
