import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'semantic-ui-react';

const Login = ({ onClick, id, icon, text, ...other }) => (
  <div>
    <Button {...other} id={id} type="button" onClick={() => onClick()}>
      <Icon name={icon} />
      {text}
    </Button>
  </div>
);

Login.defaultProps = {
  icons: 'sign in',
  text: 'Login',
};

Login.propTypes = {
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default Login;
