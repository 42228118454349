import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import StatRow from './stat-row';

class StatTable extends React.Component {
  labels = {
    averageSalePrice: 'Average Sale Price',
    assemblyRequired: 'Assembly Required',
    damage: 'Damage',
    functional: 'Functional',
    lightningSubmission: 'Lightning Submission',
    missingParts: 'Missing Parts',
    packaging: 'Packaging',
    refunds: 'Refunds',
    usage: 'Usage',
  };

  label = (main = null, sub, count) => {
    const mainText = main ? `${this.labels[main]} -` : '';

    const subText = main ? sub : this.labels[sub];

    return `${mainText} ${subText} (${count})`;
  };

  percentage = (dividend, divisor) =>
    divisor > 0 ? (dividend / divisor * 100).toFixed(2) : (0).toFixed(2);

  statsTable = (metrics, allMetrics) => {
    const { totalItems } = metrics;
    const allTotalItems = allMetrics ? allMetrics.totalItems : null;

    return (
      <Table basic={'very'} striped celled className={'stats-table'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Items ({totalItems})</Table.HeaderCell>
            <Table.HeaderCell>%</Table.HeaderCell>
            <Table.HeaderCell>{allMetrics && `Company-wide (${allTotalItems})`}</Table.HeaderCell>
            <Table.HeaderCell>{allMetrics && `+ / -`}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.buildRows(metrics, null, totalItems, allTotalItems)}</Table.Body>
      </Table>
    );
  };

  buildRows = (metrics, key = null, totalItems, allTotalItems, prevKey = null) => {
    const { allMetrics } = this.props;

    if (key === 'totalItems') return null;
    if (key === 'totalRetailPrice') return null;
    if (key === 'averageSalePrice') return null;
    if (key === 'averageItemTime') return null;
    if (key === 'totalSalePrice') return null;
    if (key === 'refunds') return null;

    if (!key) {
      return Object.keys(metrics).map(metric =>
        this.buildRows(metrics, metric, totalItems, allTotalItems, prevKey)
      );
    }

    if (typeof metrics[key] === 'object') {
      return this.buildRows(metrics[key], null, totalItems, allTotalItems, key);
    }

    let allStat = prevKey && allMetrics ? allMetrics[prevKey][key] : null;

    if (!allStat && allMetrics) {
      allStat = allMetrics[key];
    }

    const allPct = allMetrics ? this.percentage(allStat, allTotalItems) : null;

    return (
      <StatRow
        key={key}
        label={this.label(prevKey, key, metrics[key])}
        statOne={this.percentage(metrics[key], totalItems)}
        statTwo={allPct}
      />
    );
  };

  render() {
    const { metrics, allMetrics } = this.props;

    if (Object.keys(metrics).length === 0 || metrics.totalItems === 0) {
      return <div>No results found matching the above filters</div>;
    }

    return <div>{this.statsTable(metrics, allMetrics)}</div>;
  }
}

StatTable.propTypes = {
  metrics: PropTypes.object,
  allMetrics: PropTypes.object,
};

export default StatTable;
