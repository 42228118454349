import React, { Component } from 'react';
import { Icon, Pagination } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class Paginater extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 1,
      domain: this.props.domain,
    };
  }

  totalPages = (items, maxItemsPerPage) => {
    const totalPages = Math.ceil(items.length / maxItemsPerPage);

    return totalPages > 0 ? totalPages : 1;
  };

  handlePageChange = (event, { activePage }) => this.setState({ activePage });

  renderPaginater = () => {
    const { props: { items, maxItemsPerPage }, state: { activePage } } = this;

    if (items.length <= maxItemsPerPage) return null;

    return (
      <Pagination
        floated={'right'}
        size={'mini'}
        defaultActivePage={activePage}
        onPageChange={this.handlePageChange}
        firstItem={null}
        lastItem={null}
        prevItem={{ content: <Icon name="angle left" />, icon: true }}
        nextItem={{ content: <Icon name="angle right" />, icon: true }}
        totalPages={this.totalPages(items, maxItemsPerPage)}
      />
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.domain !== prevState.domain) {
      return {
        activePage: 1,
        domain: nextProps.domain,
      };
    }
    return null;
  }

  render() {
    const {
      props: { items, children, maxItemsPerPage },
      state: { activePage },
      renderPaginater,
    } = this;

    const itemsOnThisPage = items.slice(
      (activePage - 1) * maxItemsPerPage,
      maxItemsPerPage * activePage
    );

    return children({ itemsOnThisPage, renderPaginater, activePage });
  }
}

Paginater.defaultProps = {
  domain: null,
};

Paginater.propTypes = {
  children: PropTypes.any.isRequired,
  items: PropTypes.array.isRequired,
  maxItemsPerPage: PropTypes.number.isRequired,
  domain: PropTypes.any,
};

export default Paginater;
