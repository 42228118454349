import React, { Component } from 'react';
import CargoApi from '../shared-api-adapters/cargo-api';
import { Button, Dropdown, Icon, Input, Label, Modal } from 'semantic-ui-react';
import Snackbar from '../shared-components/snackbar';
import REGEX from '../utilities/constants/regex';
import LoadLogsTable from './load-logs-table';
import { DateInput } from 'semantic-ui-calendar-react';

class PurchaseOrders extends Component {
  constructor(props) {
    super(props);

    this.cargoApi = new CargoApi();

    this.initState = {
      rowCount: 1,
      existingInventoryIds: [],
      inputValues: {
        single: {},
        load: {},
      },
      loads: [],
      locations: [],
      locationId: null,
      message: '',
      paymentDate: null,
      paymentMethod: null,
      paymentReference: '',
      poType: 'single',
      poId: null,
      programs: [],
      response: null,
      saving: false,
      showForm: false,
      showSnackbar: false,
      title: '',
      totals: {
        single: 0.0,
        load: 0.0,
      },
      update: false,
      vrids: [],
    };

    this.state = { ...this.initState };
  }

  clearForm = async () => {
    const { poType } = this.state;

    await this.setState({ ...this.initState, poType, showForm: true, rowCount: 0 });
  };

  componentDidMount = () => this.fetchData();

  fetchData = async () => {
    // eslint-disable-next-line react/prop-types
    const { poId } = this.props.computedMatch.params;

    const { inputValues, totals } = this.state;

    const loads = await this.cargoApi.get(this.cargoApi.routes.loads);
    const locations = await this.cargoApi.get(this.cargoApi.routes.locations);
    const programs = await this.cargoApi.get(this.cargoApi.routes.programs);

    let poType = 'single';
    let newInputValues = { single: {}, load: {} };
    let newTotals = {};
    let paymentDate = null;
    let paymentMethod = null;
    let paymentReference = null;
    let title = '';
    let locationId = null;
    let update = false;
    let existingInventoryIds = [];

    if (poId) {
      const [purchaseOrder] = await this.cargoApi.get(this.cargoApi.routes.purchaseOrders, poId);

      if (!purchaseOrder) {
        this.setState({
          loads,
          locations,
        });

        return;
      }

      update = true;

      paymentDate = purchaseOrder.paymentDate ? purchaseOrder.paymentDate.substring(0, 10) : null;
      paymentMethod = purchaseOrder.paymentMethod;
      paymentReference = purchaseOrder.paymentReference;
      title = purchaseOrder.description;
      locationId = purchaseOrder.locationId;

      const purchaseOrderInventory = await this.cargoApi.get(
        `${this.cargoApi.routes.inventory}/purchaseOrder/${poId}`
      );

      existingInventoryIds = purchaseOrderInventory.map(inv => inv.id);

      const isLoad = purchaseOrderInventory.filter(inventory => inventory.vin === null).length;

      poType = isLoad > 0 ? 'load' : 'single';

      newInputValues = { ...inputValues, [poType]: { ...purchaseOrderInventory } };
      newTotals = { ...totals, [poType]: purchaseOrder.amount };
    }

    this.setState({
      existingInventoryIds,
      loads,
      locations,
      poType,
      inputValues: newInputValues,
      showForm: Object.keys(newInputValues[poType]).length > 0,
      rowCount: Object.keys(newInputValues[poType]).length,
      paymentMethod,
      paymentDate,
      paymentReference,
      title,
      locationId,
      totals: newTotals,
      update,
      poId,
      programs,
    });
  };

  renderLoadInput = number => {
    const inputs = [];
    const { inputValues } = this.state;

    for (let i = 0; i < number; i++) {
      inputs.push(
        <div key={`load-${i}`}>
          <Input
            className={'m-2'}
            key={`load-description-${i}`}
            name={`load-description-${i}`}
            label={'Title'}
            type={'text'}
            onChange={this.handleInputChange}
            value={inputValues.load[i] ? inputValues.load[i].description : ''}
          />
          <Input
            className={'m-2'}
            key={`load-vrid-${i}`}
            name={`load-vrid-${i}`}
            label={'VRID'}
            type={'text'}
            onChange={this.handleVridChange}
            value={inputValues.load[i] ? inputValues.load[i].vrid : ''}
          />
          <Modal
            content={
              inputValues.load[i] && (
                <LoadLogsTable loadId={inputValues.load[i].loadId} log={inputValues.load[i].log} />
              )
            }
            size={'huge'}
            className={'p-3 pointer'}
            trigger={
              <Label
                className={'m-2 lh-1-50 pointer'}
                key={`load-loadId-${i}`}
                name={`load-loadId-${i}`}
                size={'large'}
              >
                Load ID: {inputValues.load[i] ? inputValues.load[i].loadId : ''}
              </Label>
            }
          />
          <Label
            className={'m-2 lh-1-50'}
            key={`load-program-${i}`}
            name={`load-program-${i}`}
            size={'large'}
          >
            Program: {inputValues.load[i] ? inputValues.load[i].program : ''}
          </Label>
          <Input
            className={'m-2'}
            key={`load-amount-${i}`}
            name={`load-amount-${i}`}
            label={'$'}
            type={'number'}
            step={0.01}
            onChange={this.handleInputChange}
            value={inputValues.load[i] ? inputValues.load[i].amount : ''}
          />
        </div>
      );
    }

    return inputs;
  };

  renderSingleInput = number => {
    const inputs = [];
    const { inputValues } = this.state;

    for (let i = 0; i < number; i++) {
      inputs.push(
        <div key={`single-${i}`}>
          <Input
            className={'m-2'}
            key={`single-description-${i}`}
            name={`single-description-${i}`}
            label={'Title'}
            type={'text'}
            onChange={this.handleInputChange}
            value={inputValues.single[i] ? inputValues.single[i].description : ''}
          />
          <Input
            className={'m-2'}
            key={`single-vin-${i}`}
            name={`single-vin-${i}`}
            label={'VIN'}
            type={'text'}
            onChange={this.handleInputChange}
            value={inputValues.single[i] ? inputValues.single[i].vin : ''}
          />
          <Label
            className={'m-2 lh-1-50'}
            key={`single-loadId-${i}`}
            name={`single-loadId-${i}`}
            size={'large'}
          >
            Load ID: 33
          </Label>
          <Input
            className={'m-2'}
            key={`single-amount-${i}`}
            name={`single-amount-${i}`}
            label={'$'}
            type={'number'}
            step={0.01}
            onChange={this.handleInputChange}
            value={inputValues.single[i] ? inputValues.single[i].amount : ''}
          />
        </div>
      );
    }

    return inputs;
  };

  handleDateChange = (event, data) =>
    this.setState({ paymentDate: data.value !== '' ? data.value : null });

  handleInputChange = async (event, inputData) => {
    const { inputValues: { single, load } } = this.state;

    const inputName = inputData.name.split('-');

    let currentValues = this.state.inputValues[inputName[0]][inputName[2]];

    if (!currentValues) currentValues = {};

    const entry = {
      [inputName[2]]: {
        ...currentValues,
        [inputName[1]]: inputData.value,
      },
    };

    const singleEntry = inputName[0] === 'single' ? entry : {};
    const loadEntry = inputName[0] === 'load' ? entry : {};

    await this.setState({
      inputValues: {
        single: { ...single, ...singleEntry },
        load: { ...load, ...loadEntry },
      },
    });

    this.totalPurchaseOrder(inputName[0]);
  };

  handleDropdownChange = (event, inputData) => this.setState({ [inputData.name]: inputData.value });

  handleVridChange = async (event, inputData) => {
    const { inputValues: { load }, programs, vrids } = this.state;

    if (inputData.value.length === 9 && !REGEX.VRID.test(inputData.value)) {
      setTimeout(() => {
        this.setState({ showSnackbar: false });
      }, 3000);

      this.setState({
        saving: false,
        showSnackbar: true,
        message: 'VRID must be 9 characters in length and begin with 11',
        response: false,
      });

      return;
    }

    if (vrids.includes(inputData.value)) {
      setTimeout(() => {
        this.setState({ showSnackbar: false });
      }, 3000);

      this.setState({
        saving: false,
        showSnackbar: true,
        message: 'VRID already used in current purchase order',
        response: false,
      });

      return;
    }

    const [poType, inputName, inputNumber] = inputData.name.split('-');

    let currentValues = this.state.inputValues[poType][inputNumber];

    if (!currentValues) currentValues = {};

    const [existingLoad] = await this.cargoApi.get(
      `${this.cargoApi.routes.loads}/vrid`,
      inputData.value
    );

    let error = {};

    if (!existingLoad) {
      setTimeout(() => {
        this.setState({ showSnackbar: false });
      }, 3000);

      error = {
        saving: false,
        showSnackbar: true,
        message: 'No Load ID found for the provided VRID',
        response: false,
      };
    } else if (existingLoad.purchaseOrderId !== null) {
      setTimeout(() => {
        this.setState({ showSnackbar: false });
      }, 3000);

      this.setState({
        saving: false,
        showSnackbar: true,
        message: 'VRID already used in an existing purchase order',
        response: false,
      });

      return;
    }

    const log = existingLoad
      ? await this.cargoApi.get(`${this.cargoApi.routes.loadLogs}/${existingLoad.id}`)
      : [];

    const program = existingLoad ? programs.find(pr => pr.id === existingLoad.programId) : 'None';

    const entry = {
      [inputNumber]: {
        ...currentValues,
        [inputName]: inputData.value,
        loadId: existingLoad ? existingLoad.id : '',
        program: program ? program.name : '',
        log,
      },
    };

    await this.setState({
      inputValues: {
        load: { ...load, ...entry },
      },
      ...error,
      vrids: [...vrids, inputData.value],
    });
  };

  totalPurchaseOrder = type => {
    const { totals } = this.state;

    let total = 0.0;

    Object.keys(this.state.inputValues[type]).map(key => {
      const amount = Number(this.state.inputValues[type][key].amount);

      total += isNaN(amount) ? 0.0 : amount;

      return total;
    });

    return this.setState({ totals: { ...totals, [type]: Number(total).toFixed(2) } });
  };

  incrementRowCount = () => this.setState({ rowCount: this.state.rowCount + 1 });

  /**
   * Save the purchaseOrder
   */
  save = async () => {
    this.setState({ saving: true });

    const {
      existingInventoryIds,
      inputValues,
      poType,
      poId,
      title,
      totals,
      locationId,
      paymentMethod,
      paymentDate,
      update,
    } = this.state;

    let valueChecks = [];

    if (poType === 'load') {
      valueChecks = Object.keys(inputValues[poType]).map(
        item => !(!inputValues[poType][item].loadId || inputValues[poType][item].loadId === '')
      );
    }

    if (valueChecks.includes(false)) {
      setTimeout(() => {
        this.setState({ showSnackbar: false });
      }, 3000);

      this.setState({
        saving: false,
        showSnackbar: true,
        message: 'Failed to save Purchase Order. Load ID required',
        response: false,
      });

      return;
    }

    let savePurchaseOrder = false;

    if (update) {
      [savePurchaseOrder] = await this.cargoApi.put(
        `${this.cargoApi.routes.purchaseOrders}/${poId}`,
        {
          description: title,
          amount: totals[poType],
          locationId,
          paid: paymentMethod ? 1 : 0,
          paymentMethod,
          paymentDate,
        }
      );
    } else {
      [savePurchaseOrder] = await this.cargoApi.post(this.cargoApi.routes.purchaseOrders, {
        description: title,
        amount: totals[poType],
        locationId,
        paid: paymentMethod ? 1 : 0,
        paymentMethod,
        paymentDate,
      });
    }

    if (!savePurchaseOrder) {
      setTimeout(() => {
        this.setState({ showSnackbar: false });
      }, 3000);

      this.setState({
        saving: false,
        showSnackbar: true,
        message: 'Failed to save Purchase Order',
        response: savePurchaseOrder,
      });

      return;
    }

    const { id: purchaseOrderId = poId } = savePurchaseOrder;

    let saveInventory = [];

    const { post, put } = this.cargoApi;

    saveInventory = await Promise.all(
      Object.keys(inputValues[poType]).map(item => {
        const exists = existingInventoryIds.includes(inputValues[poType][item].id);
        const req = exists ? put : post;
        const suffix = exists ? `/${inputValues[poType][item].id}` : '';

        return req(`${this.cargoApi.routes.inventory}${suffix}`, {
          ...inputValues[poType][item],
          purchaseOrderId,
          loadId: poType === 'load' ? inputValues[poType][item].loadId : 33,
        });
      })
    );

    if (poType === 'load') {
      await Promise.all(
        Object.keys(inputValues[poType]).map(item =>
          this.cargoApi.put(`${this.cargoApi.routes.loads}/${inputValues[poType][item].loadId}`, {
            purchaseOrderId,
          })
        )
      );
    }

    const failedCalls = saveInventory.filter(inventoryCall => inventoryCall === false);
    const successfulCalls = saveInventory.filter(inventoryCall => inventoryCall !== false);

    let message = `Successfully ${update ? 'updated' : 'saved'} Purchase Order ID: ${
      update ? poId : purchaseOrderId
    }`;

    if (failedCalls.length > 0) {
      await Promise.all(
        successfulCalls.map(([{ id }]) =>
          this.cargoApi.destroy(this.cargoApi.routes.inventory, { id })
        )
      );

      await this.cargoApi.destroy(this.cargoApi.routes.purchaseOrders, { id: purchaseOrderId });

      message = `Failed to ${update ? 'update' : 'save'} Purchase Order`;
    }

    if (failedCalls.length === 0 && !update) await this.clearForm();

    setTimeout(() => {
      this.setState({ showSnackbar: false });
    }, 3000);

    this.setState({
      saving: false,
      showSnackbar: true,
      message,
      response: savePurchaseOrder,
    });
  };

  toggleForm = poType =>
    this.setState({
      showForm: !this.state.showForm,
      poType,
      rowCount: 1,
      totals: { single: 0.0, load: 0.0 },
    });

  render() {
    const {
      locations,
      locationId,
      message,
      paymentDate,
      paymentMethod,
      paymentReference,
      poType,
      response,
      rowCount,
      saving,
      showForm,
      showSnackbar,
      title,
      totals,
      update,
    } = this.state;

    const inputs =
      poType === 'load' ? this.renderLoadInput(rowCount) : this.renderSingleInput(rowCount);

    return (
      <>
        <h1 className={'Cargo-header'}>Purchase Order</h1>
        <div>
          <Icon
            name={'plus circle'}
            className={'pointer'}
            onClick={() => {
              this.toggleForm('single');
            }}
          />
          Single Item Asset PO
        </div>
        <div>
          <Icon
            name={'plus circle'}
            className={'pointer'}
            onClick={() => {
              this.toggleForm('load');
            }}
          />
          Load PO
        </div>
        <div>
          <Icon name={'plus circle'} className={'pointer'} onClick={this.incrementRowCount} />
          Add line item
        </div>
        {showForm && (
          <>
            <Input
              className={'m-2 mb-3 mt-4'}
              name={'title'}
              type={'text'}
              label={'PO Title'}
              onChange={this.handleDropdownChange}
              value={title}
            />
            <Dropdown
              placeholder={'Select a location'}
              className={'icon'}
              name={'locationId'}
              onChange={this.handleDropdownChange}
              labeled
              floating
              button
              selection
              icon={'home'}
              options={locations.map(({ id, name }) => ({ key: id, value: id, text: name }))}
              value={locationId}
            />
            {inputs.map(input => input)}
            <div className={'mt-2'}>
              <Input
                className={'m-2'}
                type={'number'}
                name={'total'}
                label={'Total $'}
                value={totals[poType]}
                step={0.01}
              />
              <Dropdown
                placeholder={'Select a payment method'}
                className={'icon'}
                name={'paymentMethod'}
                onChange={this.handleDropdownChange}
                labeled
                floating
                button
                selection
                icon={'dollar'}
                options={[
                  { key: 1, value: 'Check', text: 'Check' },
                  { key: 2, value: 'Wire Transfer', text: 'Wire Transfer' },
                ]}
                value={paymentMethod}
              />
              <Input
                className={'m-2'}
                type={'text'}
                name={'paymentReference'}
                label={'Payment Reference'}
                value={paymentReference}
              />
              <DateInput
                dateFormat={'YYYY-MM-DD'}
                name={'endDate'}
                placeholder={'Select a payment date'}
                value={paymentDate || ''}
                popupPosition="bottom left"
                onChange={this.handleDateChange}
                clearable={true}
                closable={true}
                closeOnMouseLeave={false}
                className={'m-2'}
              />
            </div>
            <div className={'mt-2'}>
              <Button className={'m-2'} onClick={this.save} loading={saving}>
                {update ? 'Update' : 'Save'}
              </Button>
            </div>
          </>
        )}
        <Snackbar visible={showSnackbar} message={message} color={response ? 'green' : 'red'} />
      </>
    );
  }
}

export default PurchaseOrders;
