import React, { Fragment } from 'react';
import { Button, Input, Message, Grid, Card, Image, Modal, Label, Icon } from 'semantic-ui-react';
import CargoApi from '../shared-api-adapters/cargo-api';
import DatafinitiService from '../services/datafiniti';
import REGEX from '../utilities/constants/regex';
import Toggle from '../shared-components/toggle';
import FocusInput from './focus-input';

class LotBundler extends React.Component {
  constructor(props) {
    super(props);

    this.api = new CargoApi(null, 'https://dev-api.cargo.helex.io:3000');
    this.sem3 = new DatafinitiService();
    this.inputRef = React.createRef();

    this.state = {
      value: '',
      result: null,
      loading: false,
      searchTerm: '',
      cargoHasRecord: null,
      sem3HasRecord: null,
      data: [],
      inventoryNumber: '',
      emailSentSuccessfully: null,
    };
  }

  /**
   * Determine if data is LPN or UPC and handle accordingly
   */
  handleSearchRequest = (event, { value }) => {
    this.setState({
      result: null,
      cargoHasRecord: null,
      sem3HasRecord: null,
      emailSentSuccessfully: null,
    });

    this.handleSearchType(value);

    return this.setState({ value });
  };

  handleSearchType = (value, searchUPC = false) => {
    if (REGEX.LPN.test(value)) {
      this.setState({ loading: true });
      this.getAsin(value);
    } else if (REGEX.UPC.test(value) || searchUPC) {
      this.setState({ loading: true });
      this.checkSem3(value);
    }
  };

  handleClick = () => {
    const { value } = this.state;

    this.handleSearchType(value, true);
  };

  checkSem3 = async value => {
    this.sem3
      .getItemInfoFromUPC(value)
      .then(result => {
        this.setState({ result: !!result, loading: false, value: '', searchTerm: value });
      })
      .catch(() => this.setState({ result: false, loading: false, value: '', searchTerm: value }));
  };

  getAsin = value => {
    this.api
      .get(this.api.routes.skuToAsin, value)
      .then(([result = {}]) => {
        this.setState({
          cargoHasRecord: !!result.id,
          loading: false,
          value: '',
          searchTerm: value,
        });

        if (!result) return;

        this.setState({ loading: true });

        this.sem3.getItemInfoFromASIN(result.asin).then(res => {
          this.setState({ loading: false, sem3HasRecord: false });

          if (!res) return;

          this.setState(prevState => ({
            sem3HasRecord: true,
            data: [
              {
                description: res.name,
                price: Number(res.price),
                lpn: value,
                image: res.stockImage,
              },
              ...prevState.data,
            ],
          }));
        });
      })
      .catch(() =>
        this.setState({
          result: false,
          loading: false,
          value: '',
          searchTerm: value,
          cargoHasRecord: false,
        })
      );
  };

  reset = () => {
    this.inputRef.current.focus();
    this.setState({
      result: null,
      value: '',
      loading: false,
      sem3HasRecord: null,
      cargoHasRecord: null,
      data: [],
      inventoryNumber: '',
    });
  };

  sendEmail = onSuccessfulSubmit => async () => {
    const result = await this.api.post('/itemConsolidation', {
      subject: this.state.inventoryNumber,
      items: this.state.data,
    });

    if (result) {
      this.setState({ emailSentSuccessfully: true });
      this.reset();
      onSuccessfulSubmit();
    }

    if (!result) {
      this.setState({ emailSentSuccessfully: false });
    }
  };

  setInventoryNumber = (event, { value }) => {
    this.setState({ inventoryNumber: value });
  };

  componentDidMount() {
    this.inputRef.current.focus();
  }

  render() {
    const {
      result,
      value,
      loading,
      searchTerm,
      cargoHasRecord,
      sem3HasRecord,
      data,
      inventoryNumber,
      emailSentSuccessfully,
    } = this.state;

    return (
      <Fragment>
        <Input
          loading={loading}
          ref={this.inputRef}
          fluid
          icon={'barcode'}
          size={'massive'}
          onChange={this.handleSearchRequest}
          value={value}
        />

        <Button.Group fluid className={'mt-2'}>
          <Modal
            size={'tiny'}
            trigger={<Button content={'Reset'} />}
            header={'Are you sure?'}
            content={'Are you sure you want to reset all the work you have done?'}
            actions={[<Button key={0} onClick={this.reset} content={'Reset'} />]}
          />
          <Button onClick={this.handleClick} content={'Search'} />
          <Toggle>
            {({ active, toggle }) => (
              <Modal
                open={active}
                onClose={toggle}
                trigger={<Button onClick={toggle} content={'Bundle Lot'} />}
              >
                <Modal.Header>Bundle Lot</Modal.Header>
                <Modal.Content>
                  <FocusInput
                    placeholder={'Inventory Number'}
                    icon={'barcode'}
                    fluid
                    value={inventoryNumber}
                    onChange={this.setInventoryNumber}
                    className={'mb-2'}
                  />

                  {data.length === 0 && (
                    <Message
                      color={'red'}
                      header={'WAIT!'}
                      content={'No items have been bundled! Please enter some items!'}
                    />
                  )}
                  {data.map((item, key) => (
                    <Label key={key}>
                      {item.lpn}
                      <Icon
                        name={'delete'}
                        onClick={() => {
                          this.setState(prevState => ({
                            data: prevState.data.filter(i => i.lpn !== item.lpn),
                          }));
                        }}
                      />
                    </Label>
                  ))}
                  {emailSentSuccessfully === false && (
                    <Message
                      color={'red'}
                      content={'Email failed to send! Please contact the Mike Pavese!'}
                      header={'Email failed to send!'}
                    />
                  )}
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    content={'Send Email'}
                    onClick={this.sendEmail(toggle)}
                    disabled={data.length === 0}
                  />
                </Modal.Actions>
              </Modal>
            )}
          </Toggle>
        </Button.Group>
        <Label className={'mt-2'} color={'teal'}>
          Count: <Label.Detail>{data.length}</Label.Detail>
        </Label>
        <Grid column={2} divided className={'mt-3'} stackable>
          <Grid.Column width={4}>
            {emailSentSuccessfully && (
              <Message color={'green'} header={'Success!'} content={'Successfully sent email!'} />
            )}
            {cargoHasRecord === true && (
              <Message
                color={'green'}
                content={'We have a record of this LPN in the cargo database!'}
                header={'Successful Manifest Lookup!'}
              />
            )}
            {cargoHasRecord === false && (
              <Message
                color={'red'}
                header={'Failed Manifest Lookup!'}
                content={`We do not have a record of this in the cargo database. This is most likely a result of the manifest not being uploaded!`}
              />
            )}
            {sem3HasRecord === true && (
              <Message
                color={'green'}
                header={'Successful Amazon Lookup!'}
                content={`This item does exist on Amazon.`}
              />
            )}
            {sem3HasRecord === false && (
              <Message
                color={'red'}
                header={'Failed Amazon Lookup!'}
                content={`This item does not exist on Amazon.`}
              />
            )}
          </Grid.Column>
          <Grid.Column width={12}>
            <Card.Group>
              {data.map((item, key) => (
                <Card key={key} raised>
                  <Image size="small" src={item.image} ui={false} wrapped />
                  <Card.Content>
                    <Card.Header>{item.lpn}</Card.Header>
                    <Card.Meta>Price: ${item.price}</Card.Meta>
                    <Card.Description>{item.description}</Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Button
                      content={'Remove'}
                      color={'blue'}
                      onClick={() => {
                        this.setState(prevState => ({
                          data: prevState.data.filter(i => i.lpn !== item.lpn),
                        }));
                      }}
                    />
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          </Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}

export default LotBundler;
