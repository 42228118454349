const auctionMethod = {
  auctions: '/uapi/auctions',
  invoice: '/checkoutapi/invoice2',
  customers: '/checkoutapi/customers',
  item: '/uapi/item',
  items: '/uapi/items',
  images: '/uapi/images',
  itemStatus: '/checkoutapi/itemstatus',
  invoicePayment: '/checkoutapi/invoicepayment',
  relistItem: '/checkoutapi/relistitem',
  sales: '/checkoutapi/sales',
  inventoryNumber: '/uapi/item',
  cards: '/checkoutapi/cards',
  locationItems: '/checkoutapi/location_items',
  emailInvoice: '/checkoutapi/emailInvoice',
  itemPrice: '/checkoutapi/itemPrice',
  refund: '/checkoutapi/refund',
  relistFee: '/checkoutapi/relistfee',
  refundItem: '/checkoutapi/refunditem',
  invoiceRefund: '/checkoutapi/invoicerefund',
  copyItem: '/checkoutapi/copyitem',
  consignors: '/checkoutapi/consignors',
};

const cargo = {
  actions: '/actions',
  auctions: '/auctions',
  auctionMethodAuctions: '/auctionMethod/auctions/',
  auctionMethodAuctionTotals: '/auctionMethod/auctions/location/totals/',
  auctionMethodAuctionIds: '/auctionMethod/auctions/auctionIds',
  auctionMethodAuction900: '/auctionMethod/auction900',
  auctionMethodCreditCardTotals: '/auctionMethod/creditCardTotals',
  auctionMethodVehicles: '/auctionMethod/vehicles',
  auctionMethodWarehouseHistory: '/auctionMethod/warehouseHistory',
  auctionMethodTax: '/auctionMethod/auctions/tax',
  auctionMethodSales: '/auctionMethod/warehouseHistory/pickList/items',
  accountsForReview: '/accountsForReview',
  accountsForReviewByCustomerId: '/accountsForReview/customer',
  appointments: '/appointments',
  auctionMethodRecirculate: '/auctionMethod/recirculated',
  cancellationNotice: '/cancellationNotice',
  completedPickups: '/completedPickups/customer',
  costOfGoodsSold: '/costOfGoodsSold',
  damageTypes: '/damageTypes',
  feeNotifications: '/feeNotifications',
  fraudReasonTypes: '/fraudReasonTypes',
  inventory: '/inventory',
  inventoryVehiclesOnHand: '/inventory/vehicles/onHand',
  items: '/items',
  itemTimers: '/itemTimers',
  itemTimersExtend: '/itemTimers/extend',
  itemTimersByInvoice: '/itemTimers/invoiceId',
  itemByInventoryNumber: '/items/inventoryNumber',
  itemsByStorageLocationId: '/items/storageLocationId',
  itemsHistories: '/itemHistories',
  loads: '/loads',
  loadLogs: '/loadLogs',
  loadOut: '/loadOut',
  locations: '/locations',
  lost: '/lostItems',
  lpnToAsin: '/lpnToAsin',
  manifestUpload: '/upload/manifest',
  metrics: '/metrics',
  metricsAverageElapsedTime: '/metrics/averageElapsedTime',
  metricsByUserId: '/metrics/users',
  metricsSupervisorsAverageItemTime: '/metrics/supervisors/averageItemTime',
  metricsItemTimes: '/metrics/itemTimes',
  metricsLoadTotals: '/metrics/loadTotals',
  metricsProgramAverageElapsedTime: '/metrics/programAverageElapsedTime',
  metricsProgramCostOfGoodsSold: '/metrics/programCostOfGoodsSold',
  metricsProgramLaborTotals: '/metrics/programLaborTotals',
  metricsProgramSalesTotals: '/metrics/programSalesTotals',
  metricsProgramRefundTotals: '/metrics/programRefundTotals',
  metricsRetailPrices: '/metrics/retailPrices',
  metricsRetailVsRaw: '/metrics/retailValueVsRawValue',
  metricsRefunds: '/metrics/refunds',
  metricsReturns: '/metrics/returns',
  metricsCount: '/metrics/count',
  missingPartsTypes: '/missingPartsTypes',
  packageTypes: '/packageTypes',
  programs: '/programs',
  purchaseOrders: '/purchaseOrders',
  quarantined: '/quarantinedItems',
  returns: '/returns',
  refunds: '/refunds',
  returnTypes: '/returnTypes',
  saleTypes: '/saleTypes',
  storageLocations: '/storageLocations',
  storageLocationByName: '/storageLocations/name',
  totes: '/totes',
  toteByName: '/totes/name',
  trash: '/trash',
  trailers: '/trailers',
  trailerHistory: '/trailerHistory',
  trailerLoads: '/trailerLoads',
  trailerLocations: '/trailerLocations',
  trailerStatus: '/trailerStatus',
  trailerSubLocations: '/trailerSubLocations',
  trailerQueue: '/trailerQueue',
  upc: '/search/upc',
  gtins: '/search/gtins',
  skuToAsin: '/skuToAsin',
  usageTypes: '/usageTypes',
  verifyUser: '/verifyUser',
  whiteListedCustomers: '/whiteListedCustomers',
};

const nellisAuction = {
  auctionMethodPayments: '/auctionMethod/payments/userId',
  authenticate: '/nellisAuctionAuthenticate',
  locations: '/locations',
  managers: '/managers',
  separationCodes: '/separationCodes',
  teams: '/teams',
  updateStatus: '/users/updateStatus',
  userExists: '/userExists',
  users: '/users',
  userByEmail: '/users/email',
  userResendEmail: '/users/resendEmail',
  usersToRoles: '/usersToRoles',
  userLastActivity: '/users/lastActivity',
  userLoggedIn: '/users/lastActivity',
  supervisors: '/supervisors',
  supervisorsActive: '/supervisors/active',
  inventorySupervisors: '/inventorySupervisors',
  inventorySupervisorsActive: '/inventorySupervisors/active',
  pettyCash: '/pettyCash',
  pickupTimersActive: '/pickupTimers/active',
  pickupTimersInactive: '/pickupTimers/inactive',
  roles: '/roles',
  applications: '/applications',
  usersToApplications: '/usersToApplications',
  invoiceTimersActive: '/invoiceTimers/active',
  invoiceTimersInactive: '/invoiceTimers/inactive',
  invoiceTimersExtend: '/invoiceTimers/extend',
  invoiceTimersInvoices: '/invoiceTimers/invoices',
  invoiceTimers: '/invoiceTimers',
  invoices: '/invoices',
  returns: '/returns',
  storeCreditByCustomer: '/storeCredit/customerId',
  storeCredit: '/storeCredit',
  storeCreditBatch: '/storeCredit/batch',
};

const auctionConcoction = {
  customers: '/customers',
  firstTimeBuyer: '/invoices/firsttimebuyer',
  payments: '/payments',
  itemsBatch: '/items/batch',
  customersBanned: '/customers/banned',
  customerProfile: '/customerProfile',
  bidding: '/customers/bidding',
};

export { auctionMethod, cargo, nellisAuction, auctionConcoction };
