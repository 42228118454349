import React, { Component } from 'react';
import { Label, Segment } from 'semantic-ui-react';
import API from './api';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CargoApi from '../shared-api-adapters/cargo-api';
import { inRange } from '../utilities/auction-maker-utils';
import AuctionMaker from './auction-maker';

class AuctionMakerPage extends Component {
  state = { cKey: 0 };

  fetch = () => this.setState(prevState => ({ cKey: prevState.cKey + 1 }));

  render() {
    const { cKey } = this.state;

    return (
      <Segment raised>
        <Label as="a" color="orange" ribbon>
          Auction Maker
        </Label>
        <API.Query
          key={cKey}
          api={new CargoApi()}
          queries={[
            {
              route: 'auctionMethodAuction900',
            },
            {
              route: 'loads',
            },
            {
              route: 'locations',
            },
            {
              route: 'trailers',
            },
            {
              route: 'trailerLocations',
            },
          ]}
        >
          {({ data: [auction900, loads, dbLocations, trailers, trailerLocations] }) => {
            const trailersWithLoadoutLocations = trailers.map(trailer => {
              const trailerLocation = trailerLocations.find(
                tl => tl.id === trailer.loadoutLocationId
              );

              return { ...trailer, trailerLocation: trailerLocation || {} };
            });

            const loadDescriptions = loads.reduce(
              (acc, value) => ({
                ...acc,
                [value.id]: value.program ? value.program.name : null,
              }),
              {}
            );

            const { nada, ...locationsWithItems } = auction900.reduce((acc, val) => {
              const loc = dbLocations.find(
                location =>
                  // usually warehouses
                  (val.warehousePosition &&
                    inRange(
                      location.warehousePositionMin,
                      location.warehousePositionMax,
                      val.warehousePosition
                    )) ||
                  // usually in-homes
                  (!location.protected &&
                    location.loadId !== null &&
                    location.loadId === val.loadId)
              );

              return loc
                ? {
                    ...acc,
                    [loc.id]: {
                      ...acc[loc.id],
                      // this grossness makes program assignment dynamic
                      items: {
                        ...(acc[loc.id].items || {}),
                        // dynamic program key
                        [loadDescriptions[val.loadId]]: [
                          ...((acc[loc.id].items || {})[loadDescriptions[val.loadId]] || []),
                          val,
                        ],
                      },
                    },
                  }
                : { ...acc, nada: [...(acc.nada || []), val] };
            }, dbLocations.reduce((acc, val) => ({ ...acc, [val.id]: { ...val, items: {}, addOns: [] } }), {}));

            // we search nada (everything without a physical location WP) for trailer WP's
            const trailersWithItems = trailersWithLoadoutLocations.map(trailer => {
              const items = nada.filter(item => item.warehousePosition === trailer.name);

              return {
                ...trailer,
                items: items.reduce(
                  (acc, val) => ({
                    ...acc,
                    [loadDescriptions[val.loadId]]: [
                      ...(acc[loadDescriptions[val.loadId]] || []),
                      val,
                    ],
                  }),
                  {}
                ),
              };
            });

            const locationsWithAddOns = Object.values(locationsWithItems).map(location => {
              const trailersAtLocation = trailersWithItems.filter(
                trailer => trailer.trailerLocation.locationId === location.id
              );

              // put more addons here
              return { ...location, addOns: [...trailersAtLocation] };
            });

            return <AuctionMaker locations={locationsWithAddOns} fetch={this.fetch} />;
          }}
        </API.Query>
      </Segment>
    );
  }
}

AuctionMakerPage.propTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(({ auth }) => ({ ...auth }), {})(AuctionMakerPage);
