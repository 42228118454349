import React from 'react';
import PropTypes from 'prop-types';

class Toggle extends React.Component {
  state = { active: this.props.active || false };

  toggle = () => this.setState(prevState => ({ active: !prevState.active }));

  setInactive = () => this.setState({ active: false });

  setActive = () => this.setState({ active: true });

  set = value => this.setState({ active: value });

  render() {
    const { toggle, setInactive, setActive, set, state: { active } } = this;

    return this.props.children({
      active,
      setInactive,
      setActive,
      set,
      toggle,
    });
  }
}

Toggle.propTypes = {
  children: PropTypes.func.isRequired,
  active: PropTypes.any,
};

export default Toggle;
