import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popup, Progress } from 'semantic-ui-react';

import './upload-status.css';

const UploadStatus = ({ uploads }) => {
  const uploadCount = Object.keys(uploads).length;

  if (uploadCount === 0) {
    return null;
  }

  return (
    <div className="UploadStatus">
      <Popup
        basic
        trigger={
          <div>
            <Button
              floated="right"
              color="blue"
              icon="cloud upload"
              content={`${uploadCount} file(s) uploading`}
            />
          </div>
        }
        content={
          <div className="UploadStatus-progress">
            {Object.keys(uploads).map(name => (
              <Progress indicating size="tiny" percent={uploads[name].progress} key={name}>
                {uploads[name].label}
              </Progress>
            ))}
          </div>
        }
        on="click"
        position="top right"
      />
    </div>
  );
};

UploadStatus.propTypes = {
  uploads: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
    })
  ),
};

export default UploadStatus;
