import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import { asMoney } from '../shared-components/helpers';
import PropTypes from 'prop-types';

class PurchaseOrderReportTable extends Component {
  location = affiliateId =>
    this.props.locations.find(({ affiliateId: locationId }) => locationId === Number(affiliateId));

  render() {
    const { reportTotals, totals } = this.props;

    return (
      <Table basic={'very'} striped celled className={'stats-table'} textAlign={'center'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Location</Table.HeaderCell>
            <Table.HeaderCell>Accounting Program</Table.HeaderCell>
            <Table.HeaderCell>Payments</Table.HeaderCell>
            <Table.HeaderCell>Refunds</Table.HeaderCell>
            <Table.HeaderCell>Net Payments</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.keys(reportTotals).map(affiliateId => {
            const location = this.location(affiliateId);

            return Object.keys(reportTotals[affiliateId]).map((accountingCode, key) => {
              if (/^\d+\.1$/.test(accountingCode) || accountingCode === 'tax') return null;

              const net =
                Number(reportTotals[affiliateId][accountingCode]) +
                Number(reportTotals[affiliateId][`${accountingCode}.1`]);

              const rowSpan = (Object.keys(reportTotals[affiliateId]).length - 1) / 2.0;

              return (
                <Table.Row key={`location-row-header-${affiliateId}-${accountingCode}`}>
                  {key === 0 && (
                    <Table.Cell rowSpan={rowSpan} className={'t-left'}>
                      {location ? location.name : affiliateId}
                    </Table.Cell>
                  )}

                  <Table.Cell>{accountingCode}</Table.Cell>
                  <Table.Cell>{asMoney(reportTotals[affiliateId][accountingCode])}</Table.Cell>
                  <Table.Cell>
                    {asMoney(reportTotals[affiliateId][`${accountingCode}.1`])}
                  </Table.Cell>
                  <Table.Cell>{asMoney(net)}</Table.Cell>
                </Table.Row>
              );
            });
          })}
          <Table.Row>
            <Table.Cell className={'t-left'}>Totals</Table.Cell>
            <Table.Cell />
            <Table.Cell>{asMoney(totals.payments)}</Table.Cell>
            <Table.Cell>{asMoney(totals.refunds)}</Table.Cell>
            <Table.Cell>{asMoney(totals.payments + totals.refunds)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

PurchaseOrderReportTable.propTypes = {
  locations: PropTypes.array.isRequired,
  reportTotals: PropTypes.object.isRequired,
  totals: PropTypes.object.isRequired,
};

export default PurchaseOrderReportTable;
