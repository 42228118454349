import React from 'react';
import PropTypes from 'prop-types';
import { Image, Message } from 'semantic-ui-react';
import { getFeaturedImage, getImages } from '../utilities/helpers/item-identification';

const ItemImageList = ({ photos, featuredOnly, exceptFeatured, basic }) => {
  const sortedPhotos = featuredOnly ? [getFeaturedImage(photos)] : getImages(photos);

  const basicImageProps = {
    inline: true,
    spaced: true,
    size: 'small',
  };

  const featuredImageProps = {
    size: 'large',
    centered: true,
  };

  if (sortedPhotos.length === 0) {
    return <Message>No photos to display</Message>;
  }

  return (
    <div className="ItemImageList">
      {exceptFeatured ? null : (
        <div className="featured">
          <Image
            src={sortedPhotos.shift().url}
            {...(basic ? basicImageProps : featuredImageProps)}
          />
        </div>
      )}
      {featuredOnly ? null : (
        <div className="other">
          {sortedPhotos.map((photo, i) => (
            <Image src={photo.url} key={`photo-${i}`} {...basicImageProps} />
          ))}
        </div>
      )}
    </div>
  );
};

ItemImageList.defaultProps = {
  basic: false,
  exceptFeatured: false,
  featuredOnly: false,
};

ItemImageList.propTypes = {
  basic: PropTypes.bool,
  exceptFeatured: PropTypes.bool,
  featuredOnly: PropTypes.bool,
  photos: PropTypes.array.isRequired,
};

export default ItemImageList;
