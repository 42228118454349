import ApiService from './api-service';
import { auctionMethod } from './routes';
import config from '../config';

class AuctionMethodApi extends ApiService {
  constructor(hostnameOverride = null, overrideApiKey = null) {
    super();
    this.hostname = hostnameOverride || config.auctionMethodApi.hostname;
    this.routes = auctionMethod;
    this.xApiKey = overrideApiKey || config.auctionMethodApi.xApiKey;
  }

  auth = async (username, password) => {
    this.response = await this.axios({
      method: 'GET',
      url: `${this.hostname}/uapi/auth`,
      headers: { username, password },
    });

    return this._validateStatus();
  };

  /**
   * Wrapper for axios
   *
   * @param method
   * @param route
   * @param data
   * @return {Promise<*|boolean>}
   * @private
   */
  _request = async (method, route, data) => {
    this.response = await this.axios({
      method,
      url: `${this.hostname}${route}`,
      data,
      headers: { 'x-api-key': this.xApiKey },
    }).catch(error => error.response);

    return this._validateStatus();
  };

  _validateStatus() {
    const { data } = this.response;

    switch (data.status) {
      case 'error':
        this.message = data.message;

        return false;
      case 'success':
        this.message = data.message;
        return data.data;
      default:
        return this.response;
    }
  }
}

export default AuctionMethodApi;
