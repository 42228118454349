import React from 'react';
import PropTypes from 'prop-types';
import REGEX from '../utilities/constants/regex';

class UrlWithAsinField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      http: '',
    };
  }

  handleUniversalIdChange = event => {
    this.setState({ urlToParse: event.target.value });
  };

  isValid = () => REGEX.HTTP.test(this.state.urlToParse);

  componentDidUpdate(prevProps, prevState) {
    if (prevState.urlToParse !== this.state.urlToParse && this.isValid()) {
      this.props.onValidScan(this.state.urlToParse);
      this.setState({ urlToParse: '' });
    }
  }

  render() {
    const { handleUniversalIdChange, state: { urlToParse }, props: { children } } = this;

    return children({ handleUniversalIdChange, urlToParse });
  }
}

UrlWithAsinField.defaultProps = {
  onValidScan: () => {},
};

UrlWithAsinField.propTypes = {
  children: PropTypes.func.isRequired,
  onValidScan: PropTypes.func.isRequired,
};

export default UrlWithAsinField;
