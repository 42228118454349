import React, { Component, Fragment } from 'react';
import { Checkbox, Dropdown, Table } from 'semantic-ui-react';
import { asMoney } from '../shared-components/helpers';
import PropTypes from 'prop-types';

class PurchaseOrderVehicleTable extends Component {
  render() {
    const { data, onHand, onChange } = this.props;

    return (
      <Table basic={'very'} striped celled className={'stats-table'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>VIN</Table.HeaderCell>
            <Table.HeaderCell>Bid</Table.HeaderCell>
            <Table.HeaderCell>Premium</Table.HeaderCell>
            <Table.HeaderCell>Tax</Table.HeaderCell>
            <Table.HeaderCell>On Hand</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map(
            (
              {
                vin,
                vehicle_info: vehicleInfo,
                bidAmount,
                itemId,
                premiumAmount,
                taxAmount,
                title_copy: titleCopy,
                auctionVehicleId = '',
              },
              key
            ) => (
              <Table.Row key={`vehicle-data-${key}`}>
                <Table.Cell>
                  <Checkbox checked={!!auctionVehicleId} />
                </Table.Cell>
                <Table.Cell>
                  {vehicleInfo !== '' && vin !== '' ? vehicleInfo : titleCopy}
                </Table.Cell>
                <Table.Cell>{vin}</Table.Cell>
                <Table.Cell>{asMoney(bidAmount)}</Table.Cell>
                <Table.Cell>{asMoney(premiumAmount)}</Table.Cell>
                <Table.Cell>{asMoney(taxAmount)}</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    options={onHand.map(
                      ({ id: invId, description, vin: invVin, amount: invAmount }) => ({
                        key: invId,
                        value: invId,
                        text: `${description} - ${invVin} - $${invAmount}`,
                      })
                    )}
                    clearable={true}
                    className={'m-2'}
                    placeholder={'Select a vehicle on hand'}
                    value={auctionVehicleId}
                    name={`${itemId}-auctionVehicle`}
                    onChange={onChange}
                    fluid
                  />
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    );
  }
}

PurchaseOrderVehicleTable.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onHand: PropTypes.array.isRequired,
};

export default PurchaseOrderVehicleTable;
