import { Header, Input, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isEmpty } from 'ramda';

class AuctionMakerForm extends Component {
  render() {
    const {
      total,
      programTotals,
      title,
      onChange,
      adjustments,
      totalSelected,
      locationId,
    } = this.props;

    return (
      <div className={'mr-2 mb-1'}>
        <Header as={'h3'} content={title} subheader={`${total} items total`} />
        {Object.keys(programTotals).map((key, index) => {
          const value = isEmpty(adjustments) ? programTotals[key] : adjustments[key];

          return (
            <div key={index} className={'mb-2'}>
              <div>
                {key} ({programTotals[key]})
              </div>
              <Input
                type={'number'}
                value={value}
                size={'small'}
                name={key}
                max={programTotals[key]}
                min={0}
                onChange={onChange}
                location={locationId || title}
                fluid
              />
            </div>
          );
        })}
        <Label>
          Selected:
          <Label.Detail>{totalSelected}</Label.Detail>
        </Label>
      </div>
    );
  }
}

AuctionMakerForm.defaultProps = {
  onChange: () => {},
};

AuctionMakerForm.propTypes = {
  programTotals: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  adjustments: PropTypes.object.isRequired,
  totalSelected: PropTypes.number.isRequired,
  locationId: PropTypes.any.isRequired,
};

export default AuctionMakerForm;
