import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class PNLstatement extends React.Component {
  totalRefundCount = programId => {
    const program = this.props.data.refundTotals.find(p => p.id === programId);

    return program ? program.totalRefundCount : 0;
  };

  totalUnits = programId => {
    const program = this.props.data.salesTotals.find(p => p.id === programId);

    return program ? program.totalUnits : 0;
  };

  renderCostOfGoodsSold = data =>
    data.map(({ id, name, costOfGoodsSold }) => (
      <Table.Row key={id}>
        <Table.Cell>{`${name} (${this.totalUnits(id)})`}</Table.Cell>
        <Table.Cell>
          {costOfGoodsSold
            ? `$${Number(costOfGoodsSold).toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })}`
            : '--'}
        </Table.Cell>
      </Table.Row>
    ));

  renderCostOfLabor = data =>
    data.map(({ id, name, costOfLabor, processingTimeInHours }) => (
      <Table.Row key={id}>
        <Table.Cell>{`${name} - (${Number(processingTimeInHours).toLocaleString('en-US', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })} HR)`}</Table.Cell>
        <Table.Cell>
          {costOfLabor
            ? `$${Number(costOfLabor).toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })}`
            : '--'}
        </Table.Cell>
      </Table.Row>
    ));

  renderRefundTotals = data =>
    data.map(({ id, name, totalRefunds }) => (
      <Table.Row key={id}>
        <Table.Cell>{`${name} (${this.totalRefundCount(id)})`}</Table.Cell>
        <Table.Cell style={{ color: 'red' }}>
          {totalRefunds
            ? `($${Number(totalRefunds).toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })})`
            : '--'}
        </Table.Cell>
      </Table.Row>
    ));

  renderSalesTotals = data =>
    data.map(({ id, name, totalSalePrice }) => (
      <Table.Row key={id}>
        <Table.Cell>{`${name} (${this.totalUnits(id)})`}</Table.Cell>
        <Table.Cell>
          {totalSalePrice
            ? `$${Number(totalSalePrice).toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })}`
            : '--'}
        </Table.Cell>
      </Table.Row>
    ));

  totalExpenses = (costOfGoods, laborTotals) => {
    const grossCostOfGoods = costOfGoods.reduce(
      (acc, { costOfGoodsSold }) => (costOfGoodsSold ? acc + parseFloat(costOfGoodsSold) : acc),
      0.0
    );

    const grossLaborCosts = laborTotals.reduce(
      (acc, { costOfLabor }) => (costOfLabor ? acc + parseFloat(costOfLabor) : acc),
      0.0
    );

    return grossCostOfGoods + grossLaborCosts;
  };

  totalSales = (sales, refunds) => {
    const grossSales = sales.reduce(
      (acc, { totalSalePrice }) => (totalSalePrice ? acc + parseFloat(totalSalePrice) : acc),
      0.0
    );

    const grossRefunds = refunds.reduce(
      (acc, { totalRefunds }) => (totalRefunds ? acc + parseFloat(totalRefunds) : acc),
      0.0
    );

    return grossSales + grossRefunds;
  };

  render() {
    const { data } = this.props;

    if (Object.keys(data).length <= 0) {
      return (
        <div className={'missing-stats'}>No program averages found using the provided filters</div>
      );
    }

    const { costOfGoodsSold, laborTotals, refundTotals, salesTotals } = data;

    if (!costOfGoodsSold || !refundTotals || !salesTotals || !laborTotals) return null;

    const grossExpenses = this.totalExpenses(costOfGoodsSold, laborTotals);

    const grossSales = this.totalSales(salesTotals, refundTotals);

    const netProfitLoss = grossSales - grossExpenses;

    return (
      <Table basic={'very'} striped celled className={'stats-table'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={2}>Revenue</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell colSpan={2}>Sales</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderSalesTotals(salesTotals)}</Table.Body>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={2}>Refunds</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.renderRefundTotals(refundTotals)}
          <Table.Row>
            <Table.Cell>
              <strong>Gross Sales (Sales + Refunds)</strong>
            </Table.Cell>
            <Table.Cell>
              <strong>
                ${grossSales.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={2}>Expenses</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell colSpan={2}>Cost of Goods Sold</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderCostOfGoodsSold(costOfGoodsSold)}</Table.Body>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={2}>Cost of Labor</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.renderCostOfLabor(laborTotals)}
          <Table.Row>
            <Table.Cell>
              <strong>Gross Expenses (Cost of Goods + Cost of Labor)</strong>
            </Table.Cell>
            <Table.Cell>
              <strong>
                ${grossExpenses.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <strong>Net Profit / Loss (Gross Sales - Gross Expenses)</strong>
            </Table.Cell>
            <Table.Cell>
              <strong>
                ${netProfitLoss.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

PNLstatement.propTypes = {
  data: PropTypes.object,
};

export default PNLstatement;
