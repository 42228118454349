const appConfig = JSON.parse(process.env.REACT_APP_CONFIG || '{}');

export default {
  environment: process.env.NODE_ENV || 'production',
  firebase: appConfig.firebase || {},
  nellisAuctionApi: appConfig.nellisAuctionApi || {},
  auctionMethodApi: appConfig.auctionMethod || {},
  cargoApi: appConfig.cargoApi || {},
  auctionID: appConfig.auctionID,
  release: '3.16.3',
};
