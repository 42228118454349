import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class RetailVsRawTable extends React.Component {
  render() {
    const { data } = this.props;

    const { totalRetailPrice, totalRawRetailPrice, retailVsRawPercentage } = data[0];

    return (
      <Table basic={'very'} striped celled className={'stats-table'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Total Retail Price</Table.HeaderCell>
            <Table.HeaderCell>Total Raw Price</Table.HeaderCell>
            <Table.HeaderCell>Retail vs. Raw %</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              ${totalRetailPrice ? parseFloat(totalRetailPrice).toFixed(2) : 0.0}
            </Table.Cell>
            <Table.Cell>
              ${totalRawRetailPrice ? parseFloat(totalRawRetailPrice).toFixed(2) : 0.0}
            </Table.Cell>
            <Table.Cell>
              {retailVsRawPercentage ? parseFloat(retailVsRawPercentage).toFixed(2) : 0.0}%
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

RetailVsRawTable.propTypes = {
  data: PropTypes.array,
};

export default RetailVsRawTable;
