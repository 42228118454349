import React from 'react';
import dependencies from '../dependencies';
import PropTypes from 'prop-types';

class FirebaseItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };

    this.itemsRef = dependencies.firebaseDatabase.ref('items');
  }

  removeItem = inventoryNumber => {
    this.itemsRef.child(`${inventoryNumber}`).remove();
  };

  componentDidMount() {
    this.itemsRef.on('value', snapshot => {
      const items = snapshot.val() || [];

      this.setState({
        items: Object.keys(items).map(inventoryNumber => ({
          ...items[inventoryNumber],
          inventoryNumber,
        })),
      });
    });
  }

  componentWillUnmount() {
    this.itemsRef.off();
  }

  render() {
    const { removeItem, state: { items }, props: { children, filterBy, bundleBy } } = this;

    let mutatedItems;

    if (filterBy) {
      mutatedItems = items.filter(item => item[filterBy.key] === filterBy.value);
    }

    if (bundleBy) {
      mutatedItems = items.reduce(
        (acc, val) => ({
          ...acc,
          [val[bundleBy]]: acc[val[bundleBy]] ? [...acc[val[bundleBy]], val] : [val],
        }),
        {}
      );
    }

    return children({
      removeItem,
      items: mutatedItems || items,
    });
  }
}

FirebaseItems.propTypes = {
  children: PropTypes.any.isRequired,
  filterBy: PropTypes.object,
  bundleBy: PropTypes.string,
};

export default FirebaseItems;
