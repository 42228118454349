import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableEditor from './table-editor';
import Toggle from '../shared-components/toggle';
import API from '../shared-components/api';
import CargoApi from '../shared-api-adapters/cargo-api';
import { Button, Dropdown, Form, Header, Icon, Input, Label, Segment } from 'semantic-ui-react';
import { asDateTime } from '../shared-components/helpers';
import { DateInput } from 'semantic-ui-calendar-react';

class AuctionIdentificationForm extends Component {
  render() {
    const { auctions, locations, refreshPage, post, put } = this.props;

    return (
      <>
        <AuctionIdentificationGovernor
          initialState={{ date: '', auctionId: '', locationId: null, id: null }}
        >
          {({ state, handleChange, reset, setState }) => (
            <Segment>
              <Label ribbon color={'orange'}>
                Auctions
              </Label>

              <Toggle>
                {({ active, toggle, setActive }) => (
                  <>
                    <div className={'mt-2'}>
                      {active ? (
                        <>
                          <Button icon color={'grey'} onClick={toggle}>
                            <Icon name={'minus'} />
                          </Button>
                        </>
                      ) : (
                        <Button color={'blue'} content={'CREATE'} icon={'plus'} onClick={toggle} />
                      )}
                    </div>
                    <Form
                      onSubmit={async () => {
                        const result = state.id
                          ? await put(
                              {
                                id: state.id,
                                auctionId: state.auctionId,
                                date: state.date,
                                locationId: state.locationId,
                              },
                              { content: 'Successfully updated record.' },
                              { content: 'Failed to udpate record' }
                            )
                          : await post(
                              {
                                auctionId: state.auctionId,
                                date: state.date,
                                locationId: state.locationId,
                              },
                              { content: 'Successfully added record.' },
                              { content: 'Failed to add record.' }
                            );

                        if (result) {
                          refreshPage();
                        }
                      }}
                    >
                      {active && (
                        <div className={'mt-2 flex fd-column ai-start'}>
                          <Input
                            name={'auctionId'}
                            value={state.auctionId}
                            onChange={handleChange}
                            placeholder={'Auction Id'}
                            className={'mb-2'}
                          />
                          <Dropdown
                            name={'locationId'}
                            className={'mb-2'}
                            placeholder={'Select location'}
                            selection
                            options={locations.map(loc => ({
                              key: loc.name,
                              text: loc.name,
                              value: loc.id,
                            }))}
                            value={state.locationId}
                            onChange={handleChange}
                          />
                          <DateInput
                            name={'date'}
                            dateFormat={'MM-DD-YYYY'}
                            placeholder={'Date'}
                            value={state.date}
                            iconPosition={'left'}
                            onChange={handleChange}
                          />
                          <div>
                            <Button
                              content={'Clear'}
                              color={'blue'}
                              type={'button'}
                              onClick={reset}
                            />
                            <Button type={'submit'} content={'Save'} color={'green'} />
                          </div>
                        </div>
                      )}
                    </Form>
                    <TableEditor
                      records={auctions.map(auction => {
                        const { id, ...rest } = auction;

                        return {
                          ...rest,
                          date: asDateTime(auction.date),
                          record: auction,
                        };
                      })}
                      edit={rec => {
                        setActive();
                        setState({
                          ...rec,
                        });
                      }}
                    />
                  </>
                )}
              </Toggle>
            </Segment>
          )}
        </AuctionIdentificationGovernor>
      </>
    );
  }
}

AuctionIdentificationForm.propTypes = {
  auctions: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  refreshPage: PropTypes.func.isRequired,
  put: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
};

class AuctionIdentificationGovernor extends Component {
  constructor(props) {
    super(props);

    this.state = props.initialState;
  }

  reset = () => {
    this.setState(this.props.initialState);
  };

  wrapperState = obj => this.setState(obj);

  handleChange = (event, { name, value }) => this.setState({ [name]: value });

  render() {
    const { props: { children }, state } = this;

    return children({
      state,
      handleChange: this.handleChange,
      reset: this.reset,
      setState: this.wrapperState,
    });
  }
}

AuctionIdentificationGovernor.defaultProps = {
  initialState: {},
};

AuctionIdentificationGovernor.propTypes = {
  initialState: PropTypes.object.isRequired,
};

class AuctionIdentification extends Component {
  state = {
    key: 0,
  };

  refreshPage = () => {
    this.setState(prevState => ({ key: prevState.key + 1 }));
  };

  render() {
    return (
      <API.Crud route={'auctions'} api={new CargoApi()}>
        {({ ...crud }) => (
          <API.Query
            key={this.state.key}
            queries={[{ route: 'auctions' }, { route: 'locations' }]}
            api={new CargoApi()}
          >
            {({ data: [auctions, locations] }) => (
              <AuctionIdentificationForm
                auctions={auctions || []}
                locations={locations}
                {...crud}
                refreshPage={this.refreshPage}
              />
            )}
          </API.Query>
        )}
      </API.Crud>
    );
  }
}

AuctionIdentificationGovernor.propTypes = {
  children: PropTypes.func.isRequired,
};

export default AuctionIdentification;
