import REGEX from '../constants/regex';

export default {
  assemblyTypeId: {
    required: false,
  },
  conditionTypeId: {
    required: false,
  },
  functionalTypeId: {
    required: false,
  },
  missingPartsTypeId: {
    required: false,
  },
  assemblyRequired: {
    required: false,
    choices: /^[01]$/,
    pattern: /^[01]$/,
    hint: 'Must be a 0 or 1',
  },
  semanticSuccess: {
    required: false,
  },
  lpnSuccess: {
    required: false,
  },
  itemCategory: {
    required: false,
  },
  uidType: {
    required: false,
  },
  damageTypeId: {
    required: false,
    choices: /^[01]$/,
  },
  functional: {
    required: false,
    choices: /^[01]$/,
    pattern: /^[01]$/,
    hint: 'Must be a 0 or 1',
  },
  leadDescription: {
    required: true,
  },
  lightningSubmission: {
    required: true,
    pattern: /^[10]$/,
    hint: 'Must be a single digit',
  },
  loadId: {
    required: true,
    pattern: /^\d{4,5}$/,
    hint: 'Must be 4 or 5 digits',
  },
  missingParts: {
    required: false,
  },
  packageTypeId: {
    required: false,
    choices: /^[01]$/,
  },
  photos: {
    required: false,
    default: [],
  },
  universalId: {
    required: false,
    pattern: /^((LPN(\D|\d){10,11})|(B0(\D|\d){8})|(\d{12}))|(X00(\D|\d){7})$/i,
    hint: 'Must be valid UID ',
  },
  urlToParse: {
    required: false,
    pattern: REGEX.HTTP,
    hint: 'Must be in the form of http...',
  },
  isSubmitting: {
    required: false,
  },
  returnNotes: {
    required: false,
  },
  returnType: {
    required: false,
  },
  sessionUserId: {
    required: false,
  },
  sessionStartTime: {
    required: false,
  },
  rawRetailPrice: {
    required: false,
    pattern: /^\d+(\.\d{1,2})?$/,
    hint: 'Must be a valid dollar amount.',
  },
  retailPrice: {
    required: true,
    pattern: /^\d+(\.\d{1,2})?$/,
    hint: 'Must be a valid dollar amount.',
  },
  usageTypeId: {
    required: false,
    choices: /^[01]$/,
  },
  stockImage: {
    required: false,
  },
  merchantUrls: {
    required: false,
  },
  notes: {
    required: false,
  },
  brand: {
    required: false,
  },
};
