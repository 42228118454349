import React from 'react';
import PropTypes from 'prop-types';
import ItemImageList from './item-image-list';
import { getAuctionMethodData } from '../utilities/item-report';

/**
 * Displays the item in a way that will be presented during the auction
 */
class ItemReport extends React.Component {
  render() {
    const { inventoryNumber, data, containers } = this.props;
    const auctionMethodData = getAuctionMethodData(containers, data);

    return (
      <div className="ItemReport mt-2">
        <h3>{`Item #${inventoryNumber || '[No inventory number assigned]'}`}</h3>
        <ItemImageList basic={true} photos={data.photos} />
        <div
          className={'mt-2'}
          dangerouslySetInnerHTML={{
            __html: auctionMethodData.description,
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: auctionMethodData.extraInfo,
          }}
        />
      </div>
    );
  }
}

ItemReport.propTypes = {
  data: PropTypes.object.isRequired,
  containers: PropTypes.array.isRequired,
  inventoryNumber: PropTypes.string,
};

export default ItemReport;
