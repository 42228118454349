import React, { Component } from 'react';
import PropTypes from 'prop-types';

const NotificationContext = React.createContext({});

class NotificationsProvider extends Component {
  state = {
    notifications: [],
  };

  addNotifications = (messages = []) =>
    this.setState(prevState => ({ notifications: [...prevState.notifications, ...messages] }));

  render() {
    const { addNotifications } = this;

    return (
      <NotificationContext.Provider value={{ ...this.state, addNotifications }}>
        {this.props.children}
      </NotificationContext.Provider>
    );
  }
}

NotificationsProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default NotificationsProvider;

export { NotificationContext, NotificationsProvider };
