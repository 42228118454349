import Validator from '../utilities/validator';
import itemRules from '../utilities/validation-rules/item-rules';

class ItemValidationService extends Validator {
  constructor() {
    super(itemRules);
  }
}

export default ItemValidationService;
