import React from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const YesNoButtons = buttonProps => (
  <>
    {['No', 'Yes']
      .map((content, key) => (
        <Button
          as={Link}
          key={key}
          {...buttonProps}
          content={content}
          value={key}
          fluid
          className={'my-2'}
        />
      ))
      .reverse()}
  </>
);

export default YesNoButtons;
