import { compose, mapObjIndexed, omit, values } from 'ramda';
import { startCase } from 'lodash/fp';
import { createActions, handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import Raven from 'raven-js';
import {
  resolveLightningSubmission,
  resolveLeadDescription,
} from '../../utilities/helpers/submission';
import {
  resolvePhotosDataStructure,
  resolveRelisting,
  resolveReturnDefaultValue,
} from '../../utilities/helpers/connect';
import depends from '../../dependencies';

const itemDataStructure = {
  id: null,
  assemblyRequired: 0, // erroneous
  itemCategory: '',
  functional: 0, // erroneous
  lightningSubmission: 0,
  leadDescription: '',
  loadId: null,
  merchantUrls: [],
  missingParts: null,
  nellisAuctionId: null,
  photos: [],
  universalId: null,
  rawRetailPrice: 0.0,
  retailPrice: 0.0,
  return: {},
  stockImage: '',
  notes: null,
  uidType: null,
  lpnSuccess: null,
  semanticSuccess: null,
  asIs: 0,
  weights: {},
  usageTypeId: 1, // erroneous
  urlToParse: '',
};

const initialState = {
  data: { ...itemDataStructure },
  loading: false,
  requiredFields: {},
  updating: false,
  submitting: false,
  uploads: {},
  messages: [],
  messageType: 'error',
  types: {},
  validationErrors: {},
  itemSearchStatus: 'inactive',
  showUrlInput: false,
};

const inventoryEditor = ({
  cargoApiService,
  itemManagementService,
  itemValidationService,
  semantics3Service,
}) => {
  const simpleActions = createActions({
    setType: null,
    setLoadId: null,
    cutCurrentItemConnection: null,
    updateItemData: null,
    updateWeighted: null,
    addUploadTask: (name, label, task) => ({ name, label, task }),
    updateUploadProgress: (name, progress) => ({ name, progress }),
    removeUploadTask: null,
    setValidationErrors: null,
    setRequiredFields: null,
    setMessages: (messages, type = 'error') => ({ messages, type }),
    setLoading: null,
    setUpdating: null,
    setSubmitting: null,
    setItemSearchStatus: null,
    clearData: null,
    setExistingReturn: null,
    setUrlInput: null,
  });

  const complexActions = {
    connect: (inventoryNumber, dataFrom = null) => (dispatch, getState) => {
      const { user } = getState().auth;

      dispatch(simpleActions.setLoading(true));

      return cargoApiService
        .get(cargoApiService.routes.itemByInventoryNumber, dataFrom || inventoryNumber)
        .then(async ([cargoApiData = {}]) => {
          // if doing a return and the item is not found in cargo
          if (!cargoApiData && dataFrom) {
            dispatch(
              simpleActions.setMessages([
                `Unable to locate item ${dataFrom}`,
                'This is usually the result of an unsuccessful submission to Cargo at the original creation time',
                'You will have to completely re-log this item through Cargo',
                `Please contact Zmoney if you believe this to be a mistake`,
              ])
            );
            return false;
          }

          const preloadedData = {
            ...(compose(
              resolvePhotosDataStructure,
              resolveRelisting(!!dataFrom),
              resolveReturnDefaultValue
            )(cargoApiData) || itemDataStructure),
          };

          /**
           * store session for return
           * @TODO
           * at some point, the querystring (prevInventoryNumber)
           * for returns should change to submissions to firebase
           * and management via sessions
           */
          if (dataFrom) {
            await itemManagementService.updateItem(inventoryNumber, {
              ...preloadedData,
              sessionUserId: user.id,
              sessionStartTime: new Date().toISOString(),
            });
          }

          return itemManagementService
            .getItem(inventoryNumber, result => {
              // temporary fix for issue where data.photos is
              // being populated in firebase on an existing item
              const data = {
                ...itemDataStructure,
                ...(result &&
                !(
                  Object.keys(result).length === 1 &&
                  result.hasOwnProperty('photos') &&
                  preloadedData.id
                )
                  ? result
                  : preloadedData),
              };

              /**
               * This allows the cutting of item feeds across multiple sessions
               * running on multiple clients
               */
              if (result && result.submitted) {
                dispatch(push('/'));
                itemManagementService.deleteItem(inventoryNumber);

                itemManagementService.cutItemFeed(inventoryNumber);
              } else {
                dispatch(simpleActions.updateItemData(data));
                dispatch(
                  simpleActions.setRequiredFields(
                    itemValidationService.setData(data).getRequiredFields()
                  )
                );
              }
            })
            .then(() => true)
            .catch(error => {
              dispatch(simpleActions.setMessages(['An internal error occurred', error.toString()]));
              Raven.captureException(error);
              return false;
            })
            .then(successful => {
              dispatch(simpleActions.setLoading(false));
              return successful;
            });
        });
    },

    disconnect: inventoryNumber => () => {
      itemManagementService.cutItemFeed(inventoryNumber);
    },

    update: (inventoryNumber, changes, type = 'submit') => (dispatch, getState) => {
      const { data } = getState().inventoryEditor;
      const { user } = getState().auth;

      if (inventoryNumber === '') {
        dispatch(
          simpleActions.setMessages([
            'An internal error occurred',
            'Failed to update item. No item has been connected. Inventory number is unset.',
          ])
        );
        Raven.captureException(new Error('Inventory number is unset.'));
        return Promise.resolve(false);
      }

      const newData = {
        ...data,
        ...changes,
        sessionUserId: user.id,
        sessionStartTime: !data.sessionStartTime ? new Date().toISOString() : data.sessionStartTime,
      };

      const result = itemValidationService.setData(newData).validate(type, Object.keys(changes));

      dispatch(simpleActions.setValidationErrors(result));

      dispatch(simpleActions.setUpdating(true));

      return itemManagementService
        .updateItem(inventoryNumber, newData)
        .then(() => true)
        .catch(error => {
          dispatch(simpleActions.setMessages(['An internal error occurred', error.toString()]));
          Raven.captureException(error, { extra: newData });
          return false;
        })
        .then(successful => {
          dispatch(simpleActions.setUpdating(false));
          return successful;
        });
    },

    submitReturnData: (
      inventoryNumber,
      prevInventoryNumber,
      userId,
      returningUserId,
      returnNotes,
      returnType
    ) => dispatch =>
      cargoApiService
        .post(cargoApiService.routes.returns, {
          inventoryNumber,
          prevInventoryNumber,
          userId,
          returningUserId,
          notes: returnNotes,
          returnType,
        })
        .then(() => {
          dispatch(simpleActions.setMessages([`Successfully updated return!`], 'success'));
          return true;
        })
        .catch(error => {
          dispatch(simpleActions.setMessages([`Failed to update return table!`], error.toString()));
          return false;
        }),

    submit: (inventoryNumber, dataForAuctionMethod) => (dispatch, getState) => {
      const inventoryData = compose(resolveLightningSubmission, resolveLeadDescription)(
        getState().inventoryEditor.data
      );

      const { user } = getState().auth;

      if (inventoryNumber === '') {
        dispatch(
          simpleActions.setMessages([
            'An internal error occurred',
            'Failed to submit item. No item has been connected. Inventory number is unset.',
          ])
        );
        Raven.captureException(new Error('Inventory number is unset.'));
        return Promise.resolve(false);
      }

      const result = itemValidationService.setData(getState().inventoryEditor.data).validate();

      dispatch(simpleActions.setValidationErrors(result));

      if (Object.keys(result).length > 0) {
        dispatch(
          simpleActions.setMessages(
            ['One or more fields failed validation.'].concat(
              compose(values, mapObjIndexed((v, f) => `${startCase(f)}: ${v}`))(result)
            )
          )
        );
        return Promise.resolve(false);
      }

      dispatch(simpleActions.setSubmitting(true));

      return itemManagementService
        .saveItem(inventoryNumber, dataForAuctionMethod, inventoryData, user)
        .then(newItem => {
          /**
           * Place here because:
           *  - it means no errors on item submission
           *  - check for nellisAuctionId because we only add to putaway if it's a new item
           *
           */
          if (!inventoryData.nellisAuctionId)
            depends.firestore
              .collection('putaway')
              .doc(String(user.affiliateId))
              .collection('items')
              .doc(String(inventoryNumber))
              .set({
                userId: null,
                lastSeenBy: user.id,
                timeLastSeen: new Date(),
                tote: null,
              });

          inventoryData.nellisAuctionId = newItem.nellisAuctionId;

          const defaultSubmission = {
            ...inventoryData,
            inventoryNumber,
            locationId: user.locationId,
          };

          // fixes issues with stock image only submissions breaking photos parsing
          const { stockImage = '' } = defaultSubmission;
          let { photos } = defaultSubmission;

          photos = stockImage !== '' ? [{ id: 'stockImage', url: stockImage }, ...photos] : photos;

          const dataWithoutId = { ...defaultSubmission, photos };

          delete dataWithoutId.id;
          delete dataWithoutId.userId;

          const action =
            inventoryData.id === null
              ? () =>
                  cargoApiService.post(cargoApiService.routes.items, {
                    ...dataWithoutId,
                    userId: user.id,
                  })
              : () =>
                  cargoApiService.put(
                    `${cargoApiService.routes.itemByInventoryNumber}/${inventoryNumber}`,
                    {
                      ...dataWithoutId,
                      lastUpdatedBy: user.id,
                    }
                  );

          const message = inventoryData.id === null ? 'submitted' : 'updated';

          action()
            .then(() => {
              dispatch(simpleActions.setMessages([`Successfully ${message}!`], 'success'));
              setTimeout(() => dispatch(simpleActions.setMessages([])), 3000);
              return true;
            })
            .then(itemManagementService.updateItem(inventoryNumber, { submitted: true }));

          return true;
        })
        .catch(error => {
          dispatch(simpleActions.setMessages(['An internal error occurred', error.toString()]));
          Raven.captureException(error, {
            extra: getState().inventoryEditor.data,
          });
          return false;
        })
        .then(successful => {
          dispatch(simpleActions.setSubmitting(false));
          return successful;
        });
    },
    lookupItemByLPN: (inventoryNumber, lpn, type) => async dispatch => {
      dispatch(simpleActions.setItemSearchStatus('pending'));

      return cargoApiService
        .get(cargoApiService.routes.skuToAsin, lpn)
        .then(([result]) => {
          semantics3Service
            .getItemInfoFromASIN(result.asin)
            .then(data => {
              if (!data) {
                dispatch(
                  simpleActions.setMessages([
                    `Failed to locate ASIN for ${lpn}.`,
                    `${lpn} was not found in Amazons database.`,
                  ])
                );
                dispatch(simpleActions.setItemSearchStatus('no-result'));
                dispatch(complexActions.update(inventoryNumber, { uidType: type, lpnSuccess: 0 }));
                return false;
              }

              dispatch(
                complexActions.update(inventoryNumber, {
                  rawRetailPrice: data.price,
                  retailPrice: data.price,
                  leadDescription: data.name.trim(),
                  itemCategory: data.itemCategory,
                  merchantUrls: data.merchantUrls,
                  stockImage: data.stockImage,
                  loadId: result.loadId ? result.loadId : null,
                  uidType: type,
                  brand: data.brand,
                  lpnSuccess: 1,
                })
              );

              dispatch(simpleActions.setItemSearchStatus('success'));

              return true;
            })
            .catch(error => {
              dispatch(
                simpleActions.setMessages([
                  `Failed to lookup item using code ${lpn}. ${error.toString()}`,
                  `Semantics API has crashed. Please let your manager know.`,
                ])
              );
              dispatch(simpleActions.setItemSearchStatus('failure'));
              return false;
            })
            .then(status => {
              setTimeout(() => dispatch(simpleActions.setItemSearchStatus('inactive')), 2000);
              return status;
            });
        })
        .catch(() => {
          dispatch(
            simpleActions.setMessages([
              `Failed to locate ASIN for ${lpn}.`,
              'This usually means that the manifests were not uploaded.',
              'Please contact your manager about manifest uploading to correct this issue.',
            ])
          );
          dispatch(simpleActions.setItemSearchStatus('failure'));
          return false;
        })
        .then(status => {
          setTimeout(() => dispatch(simpleActions.setItemSearchStatus('inactive')), 2000);
          return status;
        });
    },

    lookupItem: (inventoryNumber, universalId, type) => dispatch => {
      dispatch(simpleActions.setItemSearchStatus('pending'));

      return semantics3Service
        .getItemInfoFromUPC(universalId)
        .then(data => {
          if (!data) {
            dispatch(simpleActions.setItemSearchStatus('no-result'));
            dispatch(complexActions.update(inventoryNumber, { uidType: type, semanticSuccess: 0 }));
            return false;
          }

          dispatch(
            complexActions.update(inventoryNumber, {
              rawRetailPrice: data.price,
              retailPrice: data.price,
              leadDescription: data.name,
              stockImage: data.stockImage,
              itemCategory: data.itemCategory,
              merchantUrls: data.merchantUrls,
              uidType: type,
              brand: data.brand,
              semanticSuccess: 1,
            })
          );

          dispatch(simpleActions.setItemSearchStatus('success'));

          return true;
        })
        .catch(error => {
          dispatch(
            simpleActions.setMessages([
              `Failed to lookup item using code ${universalId}. ${error.toString()}`,
            ])
          );
          dispatch(simpleActions.setItemSearchStatus('failure'));
          return false;
        })
        .then(status => {
          setTimeout(() => dispatch(simpleActions.setItemSearchStatus('inactive')), 2000);
          return status;
        });
    },

    lookupItemByGtins: (inventoryNumber, universalId, type) => dispatch => {
      dispatch(simpleActions.setItemSearchStatus('pending'));

      return semantics3Service
        .getItemInfoFromGtins(universalId)
        .then(data => {
          if (!data) {
            dispatch(simpleActions.setItemSearchStatus('no-result'));
            dispatch(complexActions.update(inventoryNumber, { uidType: type, semanticSuccess: 0 }));
            return false;
          }

          dispatch(
            complexActions.update(inventoryNumber, {
              rawRetailPrice: data.price,
              retailPrice: data.price,
              leadDescription: data.name,
              stockImage: data.stockImage,
              itemCategory: data.itemCategory,
              merchantUrls: data.merchantUrls,
              uidType: type,
              brand: data.brand,
              semanticSuccess: 1,
            })
          );

          dispatch(simpleActions.setItemSearchStatus('success'));

          return true;
        })
        .catch(error => {
          dispatch(
            simpleActions.setMessages([
              `Failed to lookup item using code ${universalId}. ${error.toString()}`,
            ])
          );
          dispatch(simpleActions.setItemSearchStatus('failure'));
          return false;
        })
        .then(status => {
          setTimeout(() => dispatch(simpleActions.setItemSearchStatus('inactive')), 2000);
          return status;
        });
    },

    lookupItemByASIN: (inventoryNumber, asin) => dispatch => {
      dispatch(simpleActions.setItemSearchStatus('pending'));

      return semantics3Service
        .getItemInfoFromASIN(asin)
        .then(data => {
          if (!data) {
            dispatch(simpleActions.setItemSearchStatus('no-result'));
            dispatch(
              complexActions.update(inventoryNumber, { uidType: 'ASIN', semanticSuccess: 0 })
            );
            return false;
          }

          dispatch(
            complexActions.update(inventoryNumber, {
              rawRetailPrice: data.price,
              retailPrice: data.price,
              leadDescription: data.name,
              stockImage: data.stockImage,
              itemCategory: data.itemCategory,
              merchantUrls: data.merchantUrls,
              uidType: 'ASIN',
              brand: data.brand,
              semanticSuccess: 1,
            })
          );

          dispatch(simpleActions.setItemSearchStatus('success'));

          return true;
        })
        .catch(error => {
          dispatch(
            simpleActions.setMessages([
              `Failed to lookup item using code ${asin}. ${error.toString()}`,
            ])
          );
          dispatch(simpleActions.setItemSearchStatus('failure'));
          return false;
        })
        .then(status => {
          setTimeout(() => dispatch(simpleActions.setItemSearchStatus('inactive')), 2000);
          return status;
        });
    },

    displayAsinNotFound: () => dispatch => {
      dispatch(simpleActions.setMessages(['Unable to parse an ASIN from provided URL']));
    },

    displayX00notification: () => dispatch => {
      dispatch(
        simpleActions.setMessages(
          ['X00 Found. Please enter the URL containing the ASIN below'],
          'success'
        )
      );
      dispatch(simpleActions.setUrlInput(true));
    },

    hideUrlInput: () => dispatch => {
      dispatch(simpleActions.setUrlInput(false));
    },
  };

  return {
    actions: { ...simpleActions, ...complexActions },
    reducer: handleActions(
      {
        setType: (state, action) => ({
          ...state,
          inventoryType: action.payload,
        }),
        setAuctionId: (state, action) => ({
          ...state,
          auctionId: action.payload,
        }),
        setLoadId: (state, action) => ({
          ...state,
          loadId: action.payload,
        }),
        updateItemData: (state, action) => ({
          ...state,
          data: {
            ...state.data,
            ...(action.payload || {}),
          },
        }),
        updateWeighted: (state, action) => ({
          ...state,
          data: {
            ...state.data,
            weights: {
              ...(state.data.weights || {}),
              [action.payload.name]: action.payload.value,
            },
          },
        }),
        addUploadTask: (state, action) => ({
          ...state,
          uploads: {
            ...state.uploads,
            [action.payload.name]: {
              progress: 0,
              label: action.payload.label,
            },
          },
        }),
        updateUploadProgress: (state, action) => ({
          ...state,
          uploads: {
            ...state.uploads,
            [action.payload.name]: {
              ...state.uploads[action.payload.name],
              progress: action.payload.progress,
            },
          },
        }),
        removeUploadTask: (state, action) => ({
          ...state,
          uploads: omit([action.payload], state.uploads),
        }),
        setItemSearchStatus: (state, action) => ({
          ...state,
          itemSearchStatus: action.payload,
        }),
        setRequiredFields: (state, action) => ({
          ...state,
          requiredFields: action.payload,
        }),
        setValidationErrors: (state, action) => ({
          ...state,
          validationErrors: action.payload,
        }),
        setLoading: (state, action) => ({
          ...state,
          loading: !!action.payload,
        }),
        setUpdating: (state, action) => ({
          ...state,
          updating: !!action.payload,
        }),
        setSubmitting: (state, action) => ({
          ...state,
          submitting: !!action.payload,
        }),
        setMessages: (state, action) => ({
          ...state,
          messages: action.payload.messages,
          messageType: action.payload.type,
        }),
        clearData: state => ({
          ...state,
          data: {
            ...itemDataStructure,
          },
        }),
        setUrlInput: (state, action) => ({
          ...state,
          showUrlInput: action.payload,
        }),
      },
      initialState
    ),
  };
};

export default inventoryEditor;
export { initialState, itemDataStructure };
