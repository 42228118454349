import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';

/**
 * SessionTimer - Keeps track of Cargo user's session
 *
 * NOTE: Default is set to log user out after 15 minutes
 */
class SessionTimer extends Component {
  constructor(props) {
    super(props);
    this.sessionTimerRef = React.createRef();
  }

  render() {
    return (
      <IdleTimer
        ref={this.sessionTimerRef}
        element={document}
        onIdle={this.onIdle}
        // one hour
        timeout={3600000}
      />
    );
  }

  onIdle = () => this.props.logout();
}

SessionTimer.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default SessionTimer;
