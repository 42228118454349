import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class LoadTotalsTable extends React.Component {
  renderRows = data => {
    const filteredData = data.filter(datum => datum.name !== null);

    return filteredData.map(({ loadId, name, count }) => (
      <Table.Row key={loadId}>
        <Table.Cell>{loadId}</Table.Cell>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>{count}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { data } = this.props;

    return (
      <Table basic={'very'} striped celled className={'stats-table'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Load ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Count</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderRows(data)}</Table.Body>
      </Table>
    );
  }
}

LoadTotalsTable.propTypes = {
  data: PropTypes.array,
};

export default LoadTotalsTable;
