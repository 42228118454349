import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Image, Progress, Segment } from 'semantic-ui-react';
import PhotoControls from '../photo-controls';
import noPhoto from '../../assets/images/no-photo.png';
import { isPhone } from '../../utilities/device-detection';

import './photo-list.css';

class PhotoList extends React.Component {
  getListItem = (photo, photoId, attrs) => {
    const { onPhotoClick, onPhotoControlClick, photoControls, photoControlsProps } = this.props;

    const isButtonDisabled = (action, id) => {
      if (attrs.isLoaded && action === 'edit') return true;
      return photoControlsProps.isButtonDisabled(action, id);
    };

    return (
      <div className="PhotoList-item" key={photoId}>
        <PhotoControls
          attached="top"
          controls={photoControls}
          imageId={photoId}
          onSelect={onPhotoControlClick}
          {...{ ...photoControlsProps, isButtonDisabled }}
        />
        <Dimmer.Dimmable
          as={Segment}
          attached
          className="imageContainer"
          dimmed={photo.status !== 'ok'}
        >
          <Dimmer inverted active={photo.status !== 'ok'}>
            {photo.status !== 'uploading' ? null : (
              <Progress percent={photo.uploadProgress} indicating>
                Upload Progress
              </Progress>
            )}
          </Dimmer>
          <Image
            onClick={() => onPhotoClick(photoId)}
            size={isPhone() ? 'medium' : 'large'}
            src={photo.url || noPhoto}
          />
        </Dimmer.Dimmable>
      </div>
    );
  };

  render() {
    const { photos, isLoaded } = this.props;

    return (
      <div className="PhotoList">
        {photos.length === 0 ? (
          <p className="noItems">No photos added.</p>
        ) : (
          photos.map((photo, key) =>
            this.getListItem(photo, key, {
              isLoaded: isLoaded(key),
            })
          )
        )}
      </div>
    );
  }
}

PhotoList.propTypes = {
  isLoaded: PropTypes.func.isRequired,
  onPhotoClick: PropTypes.func.isRequired,
  onPhotoControlClick: PropTypes.func.isRequired,
  photoControls: PropTypes.object.isRequired,
  photoControlsProps: PropTypes.shape({
    isButtonDisabled: PropTypes.func.isRequired,
  }),
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      sortOrder: PropTypes.number,
      status: PropTypes.string.isRequired,
      uploadProgress: PropTypes.number,
      url: PropTypes.string,
    })
  ).isRequired,
};

export default PhotoList;
