import React, { Component } from 'react';
import CHANGELOG from '../../src/changelog.md';
import ReactMarkdown from 'react-markdown';
import dependencies from '../dependencies';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class ReleaseNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
    };

    this.reloadedRef = dependencies.firebaseDatabase.ref('reloaded');
  }

  fetchData = () => {
    fetch(CHANGELOG)
      .then(response => response.text())
      .then(text => {
        this.setState({ text });
      });
  };

  componentDidMount() {
    if (this.props.location.state) {
      const id = this.props.location.state.id;

      this.reloadedRef.once('value', snapshot => {
        const reloaded = snapshot.val() || [];

        if (!reloaded.includes(id)) {
          this.reloadedRef.set([...reloaded, id]);

          window.location.reload(true);
        }
      });
    }

    this.fetchData();
  }

  render() {
    const { text } = this.state;

    return <ReactMarkdown source={text} />;
  }
}

ReleaseNotes.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default withRouter(ReleaseNotes);
