import React from 'react';
import Auth from '../auth';
import Main from '../main';
import './app.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NotificationContext } from '../../shared-components/notification-context';
import NotificationsCenter from '../../shared-components/notification-center';

class App extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <div className="App">
        <Auth authRequired={true}>
          <div className="App-body">
            <Main />
            <NotificationContext.Consumer>
              {({ notifications }) => <NotificationsCenter notifications={notifications} />}
            </NotificationContext.Consumer>
          </div>
        </Auth>
      </div>
    );
  }
}

App.propTypes = {
  user: PropTypes.object,
};

export default connect(({ auth, router }) => ({ ...auth, ...router }), {})(App);
