import { sortObject } from './object';
import noPhoto from '../../assets/images/no-photo.png';

const getItemTypeInfo = number => {
  const info = {
    auction: '',
    lot: '',
    id: number,
  };

  if (!/^\d+$/.test(number) || number.length < 7 || number.length > 8) {
    return info;
  }

  info.lot = number.toString().slice(-4);
  info.auction = number.toString().slice(0, -4);

  return info;
};

const getImages = photos => {
  if (!photos || Object.keys(photos).length === 0) {
    return [];
  }

  const sortedPhotos = sortObject(photos, 'sortOrder', 'asc');

  return sortedPhotos.map(photo => ({
    ...photo,
    missing: !photo.url,
    url: !photo.url ? noPhoto : photo.url,
  }));
};

const getFeaturedImage = photos => {
  const images = getImages(photos);

  if (images.length === 0) {
    return { url: noPhoto };
  }

  return images[0];
};

const getFeaturedImagesFromNellisAuctionImages = images => {
  if (images.length === 0) {
    return { image_url: noPhoto, thumb_url: noPhoto };
  }

  return {
    image_url: images[0].image_url || noPhoto,
    thumb_url: images[0].thumb_url || noPhoto,
  };
};

export { getFeaturedImage, getFeaturedImagesFromNellisAuctionImages, getImages, getItemTypeInfo };
