import dependencies from '../../dependencies';
import Dropzone from 'react-dropzone';
import ImageCompressor from 'image-compressor.js';
import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'fileapi';
import shortid from 'shortid';
import { Form, Message } from 'semantic-ui-react';
import { omit, without } from 'ramda';
import Base, { extractBaseProps } from '../base';
import LoadedFileList from '../loaded-file-list';
import PhotoEditor from '../photo-editor';
import PhotoList from '../photo-list';
import PhotoViewer from '../photo-viewer';

import './photo-manager.css';

const compressImage = file => {
  const imageCompressor = new ImageCompressor();

  return imageCompressor.compress(file, {
    maxHeight: 800,
    maxWidth: 800,
    quality: 0.8,
  });
};

const shouldBeCompressed = file => file && file.size && file.size > 1024 * 1024;

class PhotoManager extends React.Component {
  constructor(props) {
    super(props);

    this.storageRef = dependencies.firebaseStorage.ref();
    this.state = {
      editorSubject: null,
      files: {},
      sortOrder: [],
      uploads: {},
      viewerSubject: null,
      rejectedFiles: [],
    };

    this.storageLocations = {
      database: `items/${this.props.inventoryNumber}/photos`,
      storage: `inventory-photos/${this.props.inventoryNumber}`,
    };
  }

  openImageForEditing = id => {
    const imageInfo = this.state.files[id];

    if (imageInfo) {
      return this.setState({
        editorSubject: id,
      });
    }

    this.addOrUpdateLocalFile(id, null, 'downloading');

    if (this.state.editorSubject === null) {
      this.setState({ editorSubject: id });
    }

    const { path } = this.props.value[id];

    return this.storageRef
      .child(path)
      .getDownloadURL()
      .then(url => fetch(url))
      .then(res => res.blob())
      .then(photoBlob => this.addOrUpdateLocalFile(id, photoBlob, 'adding'))
      .catch(e => {
        this.removeLocalFile(id);

        if (this.state.editorSubject === id) {
          this.setState({ editorSubject: null });
        }

        throw new Error(`Failed to download the image. ${e.toString()}`);
      });
  };

  move = (array, oldIndex, newIndex) => {
    const arr = [...array];

    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  reorderImage = (id, direction) => {
    const { data: { photos } } = this.props;
    const destination = id + direction;

    if (destination < 0) {
      // send to end of array
      return this.move(photos, id, photos.length - 1);
    } else if (destination > photos.length - 1) {
      // send to beginning of array
      return this.move(photos, id, 0);
    }
    // send to destination
    return this.move(photos, id, destination);
  };

  photoControlDispatcher = (action, imageId) => {
    const { update, inventoryNumber } = this.props;

    if (action === 'edit') {
      return this.openImageForEditing(imageId);
    }

    if (['moveUp', 'moveDown'].includes(action)) {
      return update(inventoryNumber, {
        photos: this.reorderImage(imageId, action === 'moveUp' ? -1 : 1),
      });
    }

    if (action === 'remove') {
      return this.removePhoto(imageId);
    }

    throw new Error(
      `Unknown photo control action has been dispatched. The action "${action}" is unknown.`
    );
  };

  getUploadProgress = (id = null) => {
    const { uploads } = this.state;
    let progress,
      total = 100;

    if (id) {
      if (!uploads[id]) {
        return null;
      }

      progress = uploads[id].progress;
    } else {
      total = Object.keys(uploads).length * 100;
      progress = Object.keys(uploads).reduce((sum, key) => sum + uploads[key].progress, 0);
    }

    return progress / total * 100;
  };

  removePhoto = async id => {
    const { storageLocations, props: { value, data: { photos }, update, inventoryNumber } } = this;

    update(inventoryNumber, {
      photos: photos.filter((photo, key) => key !== id),
    });

    // TODO: Add error handling for failed delete
    await dependencies.firebaseStorage
      .ref(storageLocations.storage)
      .child(value[id].filename)
      .delete();

    this.removeLocalFile(id);
  };

  addOrUpdateLocalFile = async (id, file, status, callback) => {
    const { editorSubject, files } = this.state;
    let effectiveStatus = status;

    if (status === 'adding') {
      effectiveStatus = 'ok';

      if (shouldBeCompressed(file)) {
        this.addOrUpdateLocalFile(id, file, 'compressing');

        try {
          const compressedFile = await compressImage(file);

          this.addOrUpdateLocalFile(id, compressedFile, 'ok', callback);
        } catch (err) {
          console.error('Image compression error:', err);
          this.addOrUpdateLocalFile(id, file, 'ok', callback);
        }

        return;
      }
    }

    if (files[id] && files[id].file) {
      URL.revokeObjectURL(files[id].file);
    }

    const thisFileState = {
      file,
      rotation: 0,
      status: effectiveStatus || 'ok',
      url: file ? URL.createObjectURL(file) : null,
    };

    this.setState(
      prevState => ({
        editorSubject: editorSubject ? id : editorSubject,
        files: { ...prevState.files, [id]: thisFileState },
      }),
      callback
    );
  };

  addNewPhotos = accepted => {
    accepted.forEach(photo => {
      // https://www.npmjs.com/package/react-dropzone#word-of-caution-when-working-with-previews
      window.URL.revokeObjectURL(photo.preview);

      this.addOrUpdateLocalFile(this.generatePhotoId(), photo, 'adding', () => {
        if (!this.state.editorSubject) {
          this.showFileInEditor('first');
        }
      });
    });
  };

  addUpload = (id, path, photo) => {
    const { inventoryNumber } = this.props;
    const { addUploadTask } = this.props;

    const storageRef = dependencies.firebaseStorage.ref();
    const label = `Item: ${inventoryNumber}, ID: ${id}`;
    const task = storageRef.child(path).put(photo);

    addUploadTask(id, label, task);

    this.setState({
      uploads: {
        ...this.state.uploads,
        [id]: {
          progress: 0,
        },
      },
    });

    return task;
  };

  removeUpload = id => {
    const { removeUploadTask } = this.props;

    removeUploadTask(id);

    const remainingUploads = { ...this.state.uploads };

    delete remainingUploads[id];

    this.setState({
      uploads: remainingUploads,
    });
  };

  generatePhotoId = () => shortid.generate();

  getFileInfo = ({ name }, id) => {
    const { storage } = this.storageLocations;
    const filename = `${id}.${/(?:\.([^.]+))?$/.exec(name)[1] || 'jpg'}`;

    return { filename, id, path: `${storage}/${filename}` };
  };

  updateUploadProgress = (id, progress) => {
    const { updateUploadProgress } = this.props;

    updateUploadProgress(id, progress);

    this.setState({
      uploads: {
        ...this.state.uploads,
        [id]: {
          ...this.state.uploads[id],
          progress,
        },
      },
    });
  };

  startUpload = ({ id, path }, photo) => {
    const task = this.addUpload(id, path, photo);

    task.on('state_changed', snapshot =>
      this.updateUploadProgress(
        id,
        Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100)
      )
    );

    task.then(
      () => this.removeUpload(id),
      err => {
        this.removeUpload(id);
        throw new Error(err);
      }
    );

    return task;
  };

  getSortOrder(id) {
    const { sortOrder } = this.state;
    const index = sortOrder.indexOf(id);

    if (index < 0) {
      this.setState({
        sortOrder: [...sortOrder, id],
      });

      return sortOrder.length;
    }

    return index;
  }

  photoWillBeRotated(id) {
    const { files } = this.state;

    this.setState({
      files: {
        ...files,
        [id]: {
          ...files[id],
          status: 'rotating',
        },
      },
    });
  }

  photoHasBeenRotated(id, angle, newFile) {
    const { files } = this.state;
    const { rotation } = files[id];
    const newRotation = (rotation + angle) % 360;

    URL.revokeObjectURL(files[id].file);

    this.setState({
      files: {
        ...files,
        [id]: {
          ...files[id],
          file: newFile,
          status: 'ok',
          rotation: newRotation,
          url: URL.createObjectURL(newFile),
        },
      },
    });
  }

  rotateImagePreview = (id, angle) => {
    const image = new Image(this.state.files[id].file);

    this.photoWillBeRotated(id);

    image.rotate(angle).get((error, img) => {
      if (error) {
        throw new Error(error);
      }

      img.toBlob(file => this.photoHasBeenRotated(id, angle, file));
    });
  };

  showRejectedFiles = files => {
    const filenames = files.map(f => f.name);

    this.setState({ rejectedFiles: [...this.state.rejectedFiles, ...filenames] }, () =>
      setTimeout(() => {
        this.setState({
          rejectedFiles: without(filenames, this.state.rejectedFiles),
        });
      }, 3000)
    );
  };

  removeLocalFile = id => {
    const tmp = omit([id], this.state.files);

    this.setState({ editorSubject: Object.keys(tmp)[0] || null, files: tmp });
  };

  savePhoto = id => {
    const { files } = this.state;

    if (!files[id]) {
      return Promise.reject(new Error(`Photo ${id} doesn't exist.`));
    }

    const { file } = files[id];
    const info = this.getFileInfo(file, id);
    const task = this.startUpload(info, file)
      .then(snapshot => {
        snapshot.ref.getDownloadURL().then(url => {
          this.props.update(this.props.inventoryNumber, {
            photos: [...this.props.data.photos, { ...info, url }],
          });
        });
      })
      .then(() => this.removeLocalFile(id));

    this.setState({
      files: { ...files, [id]: { ...files[id], status: 'uploading' } },
    });

    return task;
  };

  showImagePreviewLoader() {
    const { editorSubject, files } = this.state;

    if (!editorSubject) {
      return false;
    }

    if (!files[editorSubject]) {
      return false;
    }

    const { status } = files[editorSubject];

    return status !== 'ok';
  }

  getPhotoList = () => {
    const { files } = this.state;
    const remoteImages = this.props.value;

    const getFileAttr = (id, attr, def = null) => (files[id] || {})[attr] || def;

    return Object.keys(remoteImages)
      .reduce((result, id) => [...result, { ...remoteImages[id], id }], [])
      .map(info => ({
        ...info,
        url: getFileAttr(info.id, 'url', info.url),
        status: getFileAttr(info.id, 'status', 'ok'),
        uploadProgress:
          getFileAttr(info.id, 'status') === 'uploading' ? this.getUploadProgress(info.id) : null,
      }));
  };

  componentWillUpdate(newProps) {
    if (newProps.inventoryNumber !== this.props.inventoryNumber) {
      this.clearLocalFiles();
    }
  }

  clearLocalFiles = () => {
    const { files } = this.state;

    Object.keys(files).forEach(id => {
      if (files[id].file) {
        URL.revokeObjectURL(files[id]);
        delete files[id].file;
      }
    });

    this.setState({ files: {} });
  };

  isButtonDisabled = (event, id) => {
    const { sortOrder } = this.state;

    if (event === 'moveUp' && sortOrder.indexOf(id) === 0) return true;
    return event === 'moveDown' && sortOrder.indexOf(id) === sortOrder.length - 1;
  };

  getLocalFilesOrdered = () =>
    Object.keys(this.state.files)
      .sort()
      .reduce((result, f) => [...result, f], []);

  showFileInEditor(term) {
    const { editorSubject } = this.state;
    const orderedFiles = this.getLocalFilesOrdered();

    if (orderedFiles.length === 0) {
      this.setState({ editorSubject: null });
      return;
    }

    const editorSubjectIndex = orderedFiles.indexOf(editorSubject);
    let newSubjectIndex = null;

    if (editorSubjectIndex === -1 || term === 'first') {
      newSubjectIndex = 0;
    } else if (term === 'prev') {
      newSubjectIndex = editorSubject === 0 ? 0 : editorSubjectIndex - 1;
    } else if (term === 'next') {
      newSubjectIndex =
        editorSubject === orderedFiles.length - 1
          ? orderedFiles.length - 1
          : editorSubjectIndex + 1;
    }

    this.setState({
      editorSubject: newSubjectIndex !== null ? orderedFiles[newSubjectIndex] : term,
    });
  }

  render() {
    const { baseProps, ...other } = extractBaseProps(this.props, {
      className: 'PhotoManager',
    });
    const { value } = other;
    const { files, editorSubject, rejectedFiles, viewerSubject } = this.state;

    const buttons = {
      edit: {
        name: 'pencil',
        color: 'blue',
      },
      moveUp: {
        name: 'up arrow',
      },
      moveDown: {
        name: 'down arrow',
      },
      remove: {
        name: 'trash',
        color: 'red',
      },
    };

    const orderedFiles = this.getLocalFilesOrdered();

    return (
      <Base {...baseProps}>
        <Message negative hidden={rejectedFiles.length === 0}>
          <h4>Some photos failed to be added. Only JPG images are allowed.</h4>
          <ul>{rejectedFiles.map((f, i) => <li key={i}>{f}</li>)}</ul>
        </Message>
        <PhotoViewer
          onClose={() => this.setState({ viewerSubject: null })}
          subject={{ id: viewerSubject, ...value[viewerSubject] }}
        />
        <LoadedFileList files={files} onClick={() => this.showFileInEditor('first')} />
        <PhotoEditor
          isFirst={orderedFiles.indexOf(editorSubject) === 0}
          isLast={orderedFiles.indexOf(editorSubject) === orderedFiles.length - 1}
          isNew={editorSubject && !this.props.value[editorSubject]}
          onCancel={id => this.removeLocalFile(id)}
          onClose={() => this.setState({ editorSubject: null })}
          onNextClick={() => this.showFileInEditor('next')}
          onPrevClick={() => this.showFileInEditor('prev')}
          onRotateLeft={id => this.rotateImagePreview(id, -90)}
          onRotateRight={id => this.rotateImagePreview(id, 90)}
          onUpload={id => this.savePhoto(id)}
          subject={{ id: editorSubject, ...files[editorSubject] }}
        />
        <Dropzone
          accept={'image/jpeg'}
          multiple
          onDrop={this.addNewPhotos}
          onDropRejected={this.showRejectedFiles}
          ref={ref => (this.dropzone = ref)}
          style={{ display: 'none' }}
        />
        <PhotoList
          isLoaded={id => !!this.state.files[id]}
          onPhotoClick={id => this.setState({ viewerSubject: id })}
          onPhotoControlClick={this.photoControlDispatcher}
          photoControls={buttons}
          photoControlsProps={{ isButtonDisabled: () => false }}
          photos={this.getPhotoList()}
        />
        <Form.Button
          color="blue"
          fluid
          onClick={() => this.dropzone.open()}
          size="small"
          type="button"
        >
          Add Photos
        </Form.Button>
      </Base>
    );
  }
}

PhotoManager.propTypes = {
  data: PropTypes.object,
  addUploadTask: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  inventoryNumber: PropTypes.string.isRequired,
  updateUploadProgress: PropTypes.func.isRequired,
  removeUploadTask: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};

export default PhotoManager;
