import React, { Component } from 'react';
import CargoApi from '../shared-api-adapters/cargo-api';
import {
  Dropdown,
  Icon,
  Label,
  Button,
  Loader,
  Divider,
  Modal,
  Header,
  Grid,
  Segment,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
import { Link } from 'react-router-dom';
import { DRIVER } from '../utilities/constants/access-levels';
import { NotificationContext } from '../shared-components/notification-context';

class TrailerQueue extends Component {
  constructor(props) {
    super(props);
    const { authUser: { token } } = this.props;

    this.cargoApi = new CargoApi();
    this.nellisAuctionApi = new NellisAuctionApi(token);

    this.state = {
      activeLocationId: 1,
      allTrailers: [],
      myTrailers: [],
      otherTrailers: [],
      locations: [],
      trailerStatus: [],
      trailerSubLocations: [],
      trailerSubLocation: null,
      trailerSubLocationId: null,
      trailerNotes: null,
      buttonLoader: null,
      grabbing: false,
      starting: false,
      ending: false,
      returning: false,
      loading: true,
      user: null,
    };
  }

  beginTrip = async (trailerId, physicalLocationId, loads, addNotifications) => {
    const { id: driverId } = this.props.authUser;
    const { locations, user } = this.state;

    await this.cargoApi.put(`${this.cargoApi.routes.trailerQueue}/${trailerId}`, {
      enRoute: 1,
    });

    await this.cargoApi.post(this.cargoApi.routes.trailerHistory, {
      trailerId,
      locationId: physicalLocationId,
      driverId,
      loadIds: loads.length !== 0 ? loads.map(({ loadId }) => loadId).join(', ') : null,
    });

    const { name: locationName } = locations.find(({ id }) => id === physicalLocationId);

    if (loads.length !== 0) {
      await Promise.all(
        loads.map(async ({ loadId }) => {
          const loadResponse = await this.cargoApi.put(`${this.cargoApi.routes.loads}/${loadId}`, {
            pickupDate: new Date(),
          });

          if (!loadResponse) throw `Load ${loadId} could not be timestamped.`;

          const logResponse = await this.cargoApi.post(this.cargoApi.routes.loadLogs, {
            userId: driverId,
            action: `Departed ${locationName} with driver ${user.firstName} ${user.lastName}`,
            loadId,
          });

          if (!logResponse) throw `The log for ${loadId} could not be updated.`;
        })
      ).catch(err =>
        addNotifications([
          {
            header: 'ERROR',
            content: err,
            timestamp: new Date().toLocaleTimeString(),
            color: 'red',
          },
        ])
      );
    }

    this.fetchData();
  };

  endTrip = async (
    trailerId,
    physicalLocationId,
    id,
    destinationLocationId,
    loads,
    addNotifications
  ) => {
    const { id: driverId } = this.props.authUser;
    const { trailerSubLocationId, trailerNotes, locations } = this.state;

    const { destroy, put, post } = this.cargoApi;

    await destroy(`${this.cargoApi.routes.trailerQueue}/${id}`);

    await post(this.cargoApi.routes.trailerHistory, {
      trailerId,
      locationId: destinationLocationId,
      driverId,
      loadIds: loads.length !== 0 ? loads.map(({ loadId }) => loadId).join(', ') : null,
    });

    // indicate the trip has arrived at new location and no more destination is set
    await put(`${this.cargoApi.routes.trailers}/${trailerId}`, {
      destinationLocationId: null,
      physicalLocationId: destinationLocationId,
      trailerSubLocationId: trailerSubLocationId ? trailerSubLocationId : null,
      notes: trailerNotes && Object.keys(trailerNotes).length !== 0 ? trailerNotes : null,
    });

    if (loads.length !== 0) {
      const { name: locationName } = locations.find(
        ({ id: locationId }) => locationId === destinationLocationId
      );

      await Promise.all(
        loads.map(async ({ loadId }) => {
          const loadResponse = await put(`${this.cargoApi.routes.loads}/${loadId}`, {
            dropoffDate: new Date(),
          });

          if (!loadResponse) throw `Load ${loadId} could not be timestamped.`;

          const logResponse = await post(this.cargoApi.routes.loadLogs, {
            userId: driverId,
            action: `Arrived at ${locationName}`,
            loadId,
          });

          if (!logResponse) throw `The log for load ${loadId} could not be updated`;
        })
      ).catch(err =>
        addNotifications([
          {
            header: 'ERROR',
            content: err,
            timestamp: new Date().toLocaleTimeString(),
            color: 'red',
          },
        ])
      );
    }

    this.fetchData();
  };

  changeLocation = async physicalLocationId => {
    const { activeLocationId } = this.state;

    if (activeLocationId === physicalLocationId) return;

    await this.setState({ activeLocationId: physicalLocationId });
    this.fetchData();
  };

  handleSubLocationChange = (event, inputData) =>
    this.setState({ trailerSubLocationId: inputData.value });

  handleNotesChange = (event, inputData) => this.setState({ trailerNotes: { ...inputData.value } });

  componentDidMount = async () => {
    const { authUser: { locationId: userLocation } } = this.props;
    const locations = await this.cargoApi.get(this.cargoApi.routes.trailerLocations);
    const defaultLocation = locations.find(({ locationId }) => locationId === userLocation);

    this.setState({
      activeLocationId: defaultLocation ? defaultLocation.id : 1,
      locations,
    });

    this.fetchData();
  };

  displayMyTrailers = addNotifications => {
    const {
      allTrailers,
      myTrailers,
      locations,
      trailerSubLocations,
      trailerStatus,
      buttonLoader,
      starting,
      ending,
      returning,
    } = this.state;

    return myTrailers.map(
      ({ id: queueId, trailerId, trailer, physicalLocationId, destinationLocationId, enRoute }) => {
        const { trailerLoads: loads, name = '' } = allTrailers.find(({ id }) => id === trailerId);
        const { name: locationName = '' } = locations.find(({ id }) => id === physicalLocationId);
        const { name: destinationLocationName = '' } = locations.find(
          ({ id }) => id === destinationLocationId
        );
        const { trailerStatusId } = trailer;
        const trailerStatusName = trailerStatus.find(({ id }) => id === trailerStatusId).name;

        return (
          <div key={trailerId} className={'flex jc-center p-4'}>
            <Segment className={'w-100 bg-light-grey'} raised>
              <Label
                content={
                  <div className={'mt-1'}>
                    <Icon name={'truck'} /> {name}
                  </div>
                }
                color={'blue'}
                ribbon
                size={'huge'}
              />
              <div className={'m-3'}>
                <Label
                  className={'w-100 bg-white'}
                  size={'huge'}
                  content={
                    <div className={'flex jc-center'}>
                      {locationName} <Icon className={'ml-1'} name={'arrow right'} />{' '}
                      {destinationLocationName}
                    </div>
                  }
                />
              </div>
              <div className={'m-3'}>
                <Label
                  size={'huge'}
                  className={'w-100 bg-white'}
                  content={<div className={'flex jc-center'}>Status: {trailerStatusName}</div>}
                />
              </div>
              <div className={'m-3'}>
                <Label
                  size={'huge'}
                  className={'w-100 bg-white'}
                  content={
                    loads.length === 0 ? (
                      <div className={'flex jc-center'}>
                        <div>Load ID: None</div>
                        <div className={'ml-4'}>VRID: None</div>
                      </div>
                    ) : (
                      <div className={'flex jc-center'}>
                        <div className={'flex'}>
                          <>Load ID:</>
                          <div className={'ml-2'}>
                            {loads.map(({ loadId }, loadKey) => (
                              <div
                                key={loadKey}
                                className={loadKey !== loads.length - 1 ? 'mb-1' : null}
                              >
                                {loadId}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className={'flex ml-4'}>
                          <>VRID:</>
                          <div className={'ml-2'}>
                            {loads.map(({ load: { vrid } }, vridKey) => (
                              <div
                                key={vridKey}
                                className={vridKey !== loads.length - 1 ? 'mb-1' : null}
                              >
                                {vrid || 'None'}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  }
                />
              </div>
              {enRoute !== 1 && (
                <div className={'flex jc-center mt-3'}>
                  <Button
                    positive
                    size={'large'}
                    loading={buttonLoader === trailerId && starting}
                    icon={'play'}
                    content={'BEGIN TRIP'}
                    onClick={async () => {
                      await this.setState({ buttonLoader: trailerId, starting: true });
                      await this.beginTrip(trailerId, physicalLocationId, loads, addNotifications);
                      await this.setState({ buttonLoader: null, starting: false });
                    }}
                  />
                  <Button
                    negative
                    size={'large'}
                    loading={buttonLoader === trailerId && returning}
                    icon={'undo'}
                    content={'RETURN TRAILER'}
                    onClick={async () => {
                      await this.setState({ buttonLoader: trailerId, returning: true });
                      await this.handleClick(trailerId, null, loads, addNotifications);
                      await this.setState({ buttonLoader: null, returning: false });
                    }}
                  />
                </div>
              )}
              {enRoute === 1 && (
                <div className={'flex jc-center mt-3'}>
                  <Modal
                    size={'large'}
                    closeIcon
                    trigger={<Button negative size={'large'} icon={'stop'} content={'END TRIP'} />}
                    onClose={() =>
                      this.setState({ trailerSubLocationId: null, trailerNotes: null })
                    }
                  >
                    <Header icon={'shipping fast'} content={`Trailer ${name} Trip Summary`} />
                    <Modal.Content>
                      <Dropdown
                        className={'border-basic'}
                        fluid
                        placeholder="Select parking location"
                        name={'trailerSubLocation'}
                        selection
                        options={trailerSubLocations
                          .filter(tsl => tsl.trailerLocationId === destinationLocationId)
                          .map(t => ({
                            key: t.id,
                            text: t.name,
                            value: t.id,
                          }))}
                        onChange={this.handleSubLocationChange}
                      />
                      <Dropdown
                        className={'border-basic mt-3'}
                        fluid
                        placeholder={'Notes'}
                        multiple
                        selection
                        options={[
                          { key: 0, text: 'Filled Gas', value: 'Filled Gas' },
                          { key: 1, text: 'Flat/Blowout Tire ', value: 'Flat/Blowout Tire' },
                          { key: 2, text: 'Broke Down', value: 'Broke Down' },
                          { key: 3, text: 'Red-Tagged', value: 'Red-Tagged' },
                        ]}
                        onChange={this.handleNotesChange}
                      />
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        className={'border-black'}
                        icon={'map marker alternate'}
                        content={'COMPLETE'}
                        color={'blue'}
                        loading={buttonLoader === trailerId && ending}
                        onClick={async () => {
                          await this.setState({ buttonLoader: trailerId, ending: true });
                          await this.endTrip(
                            trailerId,
                            physicalLocationId,
                            queueId,
                            destinationLocationId,
                            loads,
                            addNotifications
                          );
                          await this.setState({ buttonLoader: null, ending: false });
                        }}
                      />
                    </Modal.Actions>
                  </Modal>
                </div>
              )}
            </Segment>
          </div>
        );
      }
    );
  };

  displayOtherTrailers = addNotifications => {
    const { id: userId } = this.props.authUser;
    const {
      allTrailers,
      myTrailers,
      otherTrailers,
      locations,
      buttonLoader,
      grabbing,
    } = this.state;

    return otherTrailers.map(
      ({ trailerId, destinationLocationId, physicalLocationId, trailerStatusId }) => {
        const { trailerLoads: loads, name = '' } = allTrailers.find(({ id }) => id === trailerId);
        const { name: locationName = '' } = locations.find(
          ({ id }) => id === destinationLocationId
        );
        const { name: physicalLocationName = '' } = locations.find(
          ({ id }) => id === physicalLocationId
        );

        return (
          <div key={trailerId} className={'flex jc-center p-4'}>
            <Segment className={'w-100 bg-light-grey'} raised>
              <Label
                content={
                  <div className={'mt-1'}>
                    <Icon name={'truck'} /> {name}
                  </div>
                }
                color={'blue'}
                ribbon
                size={'huge'}
              />
              <div className={'m-3'}>
                <Label
                  className={'w-100 bg-white'}
                  size={'huge'}
                  content={
                    <div className={'flex jc-center'}>
                      {physicalLocationName} <Icon className={'ml-1'} name={'arrow right'} />{' '}
                      {locationName}
                    </div>
                  }
                />
              </div>
              <div className={'m-3'}>
                <Label
                  size={'huge'}
                  className={'w-100 bg-white'}
                  content={<div className={'flex jc-center'}>Status: {trailerStatusId}</div>}
                />
              </div>
              <div className={'m-3'}>
                <Label
                  size={'huge'}
                  className={'w-100 bg-white'}
                  content={
                    loads.length === 0 ? (
                      <div className={'flex jc-center'}>
                        <div>Load ID: None</div>
                        <div className={'ml-4'}>VRID: None</div>
                      </div>
                    ) : (
                      <div className={'flex jc-center'}>
                        <div className={'flex'}>
                          <>Load ID:</>
                          <div className={'ml-2'}>
                            {loads.map(({ loadId }, loadKey) => (
                              <div
                                key={loadKey}
                                className={loadKey !== loads.length - 1 ? 'mb-1' : null}
                              >
                                {loadId}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className={'flex ml-4'}>
                          <>VRID:</>
                          <div className={'ml-2'}>
                            {loads.map(({ load: { vrid } }, vridKey) => (
                              <div
                                key={vridKey}
                                className={vridKey !== loads.length - 1 ? 'mb-1' : null}
                              >
                                {vrid || 'None'}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  }
                />
              </div>
              {myTrailers.length === 0 && (
                <div className={'flex jc-center mt-3'}>
                  <Button
                    loading={buttonLoader === trailerId && grabbing}
                    compact
                    size={'large'}
                    color={'blue'}
                    content={
                      <div>
                        <Icon name={'plus'} /> CLAIM
                      </div>
                    }
                    onClick={async () => {
                      await this.setState({ buttonLoader: trailerId, grabbing: true });
                      await this.handleClick(trailerId, userId, loads, addNotifications);
                      await this.setState({ buttonLoader: null, grabbing: false });
                    }}
                  />
                </div>
              )}
            </Segment>
          </div>
        );
      }
    );
  };

  handleClick = async (trailerId, driverId, loads, addNotifications) => {
    const { user } = this.state;

    await this.cargoApi.put(`${this.cargoApi.routes.trailerQueue}/${trailerId}`, { driverId });

    const message = driverId ? 'Claimed' : 'Unclaimed';

    if (loads.length !== 0) {
      await Promise.all(
        loads.map(async ({ loadId }) => {
          const loadResponse = await this.cargoApi.post(this.cargoApi.routes.loadLogs, {
            userId: driverId ? driverId : user.id,
            action: `${message} by ${user.firstName} ${user.lastName}`,
            loadId,
          });

          if (!loadResponse) throw loadId;
        })
      ).catch(err =>
        addNotifications([
          {
            header: 'ERROR',
            content: `The log for load ${err} could not be updated.`,
            timestamp: new Date().toLocaleTimeString(),
            color: 'red',
          },
        ])
      );
    }

    this.fetchData();
  };

  fetchData = async () => {
    const { buttonLoader } = this.state;

    if (!buttonLoader) this.setState({ loading: true });

    const { activeLocationId } = this.state;
    const { id: userId } = this.props.authUser;

    const [user] = await this.nellisAuctionApi.get(
      `${this.nellisAuctionApi.routes.users}/${userId}`
    );

    const queue = await this.cargoApi.get(this.cargoApi.routes.trailerQueue);
    const allTrailers = await this.cargoApi.get(this.cargoApi.routes.trailers);
    const trailerStatus = await this.cargoApi.get(this.cargoApi.routes.trailerStatus);
    const trailerSubLocations = await this.cargoApi.get(this.cargoApi.routes.trailerSubLocations);

    const filteredQueue = queue.filter(entry => entry.physicalLocationId === activeLocationId);

    const myTrailers = queue.filter(({ driverId }) => driverId === userId);
    let otherTrailers = filteredQueue.filter(({ driverId }) => driverId === null);

    otherTrailers = otherTrailers.map(t => {
      const { priority } = trailerStatus.find(({ id }) => id === t.trailer.trailerStatusId);

      return {
        ...t,
        priority,
        trailerStatusId: trailerStatus.find(({ id }) => id === t.trailer.trailerStatusId).name,
      };
    });

    const fullInDock = otherTrailers
      .filter(t => t.trailer.trailerStatusId === 1)
      .sort((a, b) => a.trailer.loadId - b.trailer.loadId);

    otherTrailers = otherTrailers
      .filter(t => t.trailer.trailerStatusId !== 1)
      .sort((a, b) => b.priority - a.priority);

    otherTrailers = [...fullInDock, ...otherTrailers];

    this.setState({
      allTrailers,
      myTrailers,
      otherTrailers,
      trailerSubLocations,
      trailerStatus,
      user,
    });

    if (!buttonLoader) this.setState({ loading: false });
  };

  render() {
    const { myTrailers, otherTrailers, locations, activeLocationId, loading } = this.state;
    const { authUser: { roleId } } = this.props;

    return (
      <NotificationContext.Consumer>
        {({ addNotifications }) => (
          <>
            {!DRIVER.includes(roleId) && (
              <div className={'flex jc-center'}>
                <Button.Group>
                  <Button
                    className={'border-black'}
                    content={'ALL TRAILERS'}
                    as={Link}
                    to={'/trailerEditor'}
                  />
                  <Button
                    className={'border-black'}
                    content={'TRAILER CITY'}
                    as={Link}
                    to={'/trailerCity'}
                  />
                  <Button className={'border-black'} color={'blue'} content={'TRAILER QUEUE'} />
                </Button.Group>
              </div>
            )}

            <Loader size={'huge'} active={loading} content={'Loading'} />
            <h1 className={'Cargo-header'}>Trailer Queue</h1>

            <Grid columns={2}>
              <Grid.Column width={2}>
                <Button.Group widths={locations.length || 1} vertical>
                  {locations.map(location => (
                    <Button
                      style={{ border: '1px solid', borderColor: 'black' }}
                      key={location.id}
                      content={<div className={'flex jc-center'}>{location.name}</div>}
                      color={location.id === activeLocationId ? 'blue' : null}
                      onClick={() => this.changeLocation(location.id)}
                      disabled={loading}
                    />
                  ))}
                </Button.Group>
              </Grid.Column>

              <Grid.Column width={14}>
                {!loading && (
                  <>
                    <h1 className={'flex jc-center ff-jura'}>My Trailer</h1>
                    <Divider />

                    {myTrailers.length !== 0 && (
                      <div className={'m-auto'}>
                        {myTrailers.length > 0 && this.displayMyTrailers(addNotifications)}
                      </div>
                    )}
                    {myTrailers.length === 0 && (
                      <div className={'flex jc-center m-auto'}>
                        <Icon name={'info circle'} color={'blue'} />
                        You have no trailers assigned to you
                      </div>
                    )}

                    <Divider />
                    <h1 className={'flex jc-center ff-jura'}>Available Trailers</h1>
                    <Divider />

                    {otherTrailers.length !== 0 && (
                      <div className={'m-auto'}>
                        {otherTrailers.length > 0 && this.displayOtherTrailers(addNotifications)}
                      </div>
                    )}
                    {otherTrailers.length === 0 && (
                      <div className={'flex jc-center m-2'}>
                        <Icon name={'info circle'} color={'blue'} />
                        No trailers available at this location
                      </div>
                    )}
                  </>
                )}
              </Grid.Column>
            </Grid>
          </>
        )}
      </NotificationContext.Consumer>
    );
  }
}

TrailerQueue.propTypes = {
  authUser: PropTypes.object.isRequired,
};

export default TrailerQueue;
