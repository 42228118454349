import moment from 'moment';
import API from './api';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  getFrontPageText,
  getHours,
  getInvoiceText,
  getPaymentInfo,
  getPickUpDateString,
  getPickUpDay,
  getRemovalInfo,
  getTitle,
} from '../utilities/auction-maker-utils';

class AuctionMakerGovernor extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState(props.location);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.endTime !== this.state.endTime) {
      const { location } = this.props;
      const pickUpDateString = getPickUpDateString(this.state.endTime);
      const hours = getHours(getPickUpDay(this.state.endTime).day(), location);

      this.setState({
        frontPageText: getFrontPageText(7, pickUpDateString, hours, location.address),
        invoiceText: getInvoiceText(pickUpDateString, hours, location.address),
        removalInfo: getRemovalInfo(pickUpDateString, hours, location.address),
      });
    }

    if (prevProps.location.id !== this.props.location.id) {
      this.resetState();
    }
  }

  getInitialState = location => {
    const endTime = moment()
      .endOf('day')
      .add(1, 'day')
      .format('dddd, MMMM Do, YYYY');
    const hours = getHours(getPickUpDay(endTime).day(), location);
    const pickUpDateString = getPickUpDateString(endTime);

    return {
      affiliateId: location.affiliateId,
      title: getTitle('Online Returns', location.name),
      type: '',
      frontPageText: getFrontPageText(7, pickUpDateString, hours, location.address),
      invoiceText: getInvoiceText(pickUpDateString, hours, location.address),
      paymentInfo: getPaymentInfo(),
      removalInfo: getRemovalInfo(pickUpDateString, hours, location.address),
      address: location.address || '',
      city: location.city || '',
      state: location.state || '',
      zip: location.zipCode || '',
      taxRate: location.taxRate,
      category: 1,
      startTime: moment()
        .startOf('day')
        .subtract(1, 'day')
        .format('dddd, MMMM Do, YYYY'),
      endTime,
      timezone: location.timezone,
      buyersPremium: 15,
    };
  };

  resetState = () => {
    this.setState(this.getInitialState(this.props.location));
  };

  handleUpdate = (event, { location, name, value }) => {
    this.setState(prevState => ({
      [location]: {
        ...prevState[location],
        [name]: value,
      },
    }));
  };

  handleLeadItemClick = item => {
    const { data } = this.props;

    const leadItem = data.find(i => i.internalId === item.inventoryNumber);

    this.setState({ leadItem: { ...leadItem, ...item } });
  };

  // find better place for this
  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleChange = (event, { name, value }) => this.setState({ [name]: value });

  render() {
    const {
      props: { children },
      handleLeadItemClick,
      handleChange,
      handleUpdate,
      handleAccordionClick,
    } = this;

    return (
      <API.Crud route={'auctions'} api={new AuctionMethodApi()}>
        {({ post }) =>
          children({
            submit: post,
            governorState: this.state,
            handleLeadItemClick,
            handleChange,
            handleUpdate,
            handleAccordionClick,
          })
        }
      </API.Crud>
    );
  }
}

AuctionMakerGovernor.propTypes = {
  data: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

export default AuctionMakerGovernor;
