import React from 'react';
import PropTypes from 'prop-types';
import { Form, Message } from 'semantic-ui-react';
import { startCase } from 'lodash/fp';
import { omit } from 'ramda';

import './base.css';

class Base extends React.Component {
  render() {
    const { field, label, hint, invalid, visible, ...other } = this.props;
    const hiddenStyles = {
      ...(typeof visible === 'undefined' || visible ? {} : { display: 'none' }),
    };

    const children = React.Children.map(this.props.children, child =>
      React.cloneElement(
        child,
        {
          ...child.props,
          style: { ...(child.props.style || {}), ...hiddenStyles },
        },
        child.props.children
      )
    );

    return (
      <Form.Field {...other}>
        <span className="Base-label" style={hiddenStyles}>
          {label || startCase(field)}
        </span>
        <Message hidden={!invalid || !visible} size="tiny" negative floating>
          {invalid}
        </Message>
        {children}
        {hint && (
          <p className="hint">
            <small>
              <i>{hint}</i>
            </small>
          </p>
        )}
      </Form.Field>
    );
  }
}

const extractBaseProps = (props, { className } = {}) => {
  const baseProps = [
    'as',
    'className',
    'control',
    'disabled',
    'error',
    'field',
    'hint',
    'inline',
    'invalid',
    'label',
    'required',
    'visible',
    'width',
  ];

  return baseProps.reduce(
    (newProps, baseProp) => ({
      ...omit([baseProp], newProps),
      baseProps: {
        ...newProps.baseProps,
        [baseProp]: newProps[baseProp],
      },
      className:
        baseProp === 'className' && className ? `${newProps.className} ${className}` : className,
    }),
    { ...props, baseProps: {} }
  );
};

Base.defaultProps = {
  label: '',
  name: '',
  hint: '',
  invalid: null,
  visible: true,
};

Base.propTypes = {
  children: PropTypes.node.isRequired,
  field: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  hint: PropTypes.string,
  invalid: PropTypes.string,
  visible: PropTypes.bool,
};

export default Base;
export { extractBaseProps };
