import { isEmpty } from 'ramda';

/**
 * Photos data structure has changed. Object => Array. This takes that object,
 * and uses its sortOrder key to order them by index in a new array
 * @param obj
 * @returns {*}
 */
const resolvePhotosDataStructure = obj => {
  if (!obj || isEmpty(obj) || isEmpty(obj.photos) || Array.isArray(obj.photos)) return obj;

  return {
    ...obj,
    photos: Object.values(obj.photos).reduce((acc, value) => {
      const { sortOrder, ...rest } = value;

      // eslint-disable-next-line no-param-reassign
      acc[sortOrder] = rest;

      return acc;
    }, new Array(Object.values(obj.photos).length)),
  };
};

/**
 * When relisting an item, we do not want conflicts cause by certain
 * properties. We parse them out here.
 *
 * @param isRelisting
 * @returns {Object}
 */
const resolveRelisting = isRelisting => data => {
  if (!isRelisting) return data;

  // eslint-disable-next-line no-unused-vars
  const { nellisAuctionId, id, inventoryNumber, salePrice, ...rest } = data;

  return { ...rest, prevInventoryNumber: inventoryNumber };
};

const resolveReturnDefaultValue = data => {
  if (isEmpty(data) || data.return) return data;

  return { ...data, return: {} };
};

export { resolvePhotosDataStructure, resolveRelisting, resolveReturnDefaultValue };
