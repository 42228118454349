import React, { Component } from 'react';
import { Dropdown, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class PoAuction extends Component {
  getLocation = affiliateId =>
    this.props.locations.find(({ affiliateId: locationId }) => locationId === Number(affiliateId));

  render() {
    const { auctions, locations, programs, updateLocation, updateProgram } = this.props;

    return (
      <Table
        basic={'very'}
        striped
        celled
        className={'stats-table p-2'}
        sortable
        textAlign={'center'}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Location</Table.HeaderCell>
            <Table.HeaderCell>Program</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {auctions.map(a => {
            const auctionLocation = this.getLocation(a.affiliateId);

            return (
              <Table.Row key={`${a.auctionId}-location-div`}>
                <Table.Cell>{a.auctionId}</Table.Cell>
                <Table.Cell>{a.title}</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    key={`${a.auctionId}-location`}
                    name={`location-${a.auctionId}`}
                    labeled
                    selection
                    options={locations.map(loc => ({ value: loc.id, key: loc.id, text: loc.name }))}
                    value={auctionLocation ? auctionLocation.id : ''}
                    placeholder={'Please select a location'}
                    onChange={updateLocation}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                    options={programs.map(pr => ({
                      key: pr.id,
                      value: pr.id,
                      text: pr.name,
                    }))}
                    value={a.programId}
                    placeholder={'Select a program'}
                    name={`program-${a.auctionId}`}
                    onChange={updateProgram}
                    className={'m-2'}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}

PoAuction.propTypes = {
  locations: PropTypes.array.isRequired,
  auctions: PropTypes.array.isRequired,
  programs: PropTypes.array.isRequired,
  updateLocation: PropTypes.func.isRequired,
  updateProgram: PropTypes.func.isRequired,
};

export default PoAuction;
