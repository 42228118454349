import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';

const Logout = ({ label, onLogout, ...other }) => {
  const trigger = (
    <Button icon="log out" label={label} labelPosition="left" type="button" {...other} />
  );
  const content = <Button color="red" content="Logout?" onClick={() => onLogout()} type="button" />;

  return (
    <div>
      <Popup content={content} on="click" position="bottom right" trigger={trigger} />
    </div>
  );
};

Logout.defaultProps = {
  label: 'Logout',
};

Logout.propTypes = {
  label: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
};

export default Logout;
