import { connect } from 'react-redux';
import actions from '../../store/actions';
import InventoryEditor from './inventory-editor';

export default connect(
  ({ inventoryEditor, auth, router }) => ({
    ...inventoryEditor,
    ...auth,
    ...router,
  }),
  { ...actions.inventoryEditor, ...actions.router }
)(InventoryEditor);
