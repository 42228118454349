import ApiService from './api-service';
import { cargo } from './routes';
import config from '../config';

class CargoApi extends ApiService {
  constructor(token = null, hostnameOverride = null) {
    super(token);
    this.hostname = hostnameOverride || config.cargoApi.hostname;
    this.routes = cargo;
  }

  uploadCsv = async formData => {
    this.response = await this.axios
      .post(`${this.hostname}${this.routes.manifestUpload}`, formData)
      .catch(error => error.response);

    return this._validateStatus();
  };
}

export default CargoApi;
