const REGEX = {
  ASIN: /^B0(\D|\d){8}$/,
  LPN: /^LPN(\D|\d){10,11}$/i,
  UPC: /^\d{12}$/,
  ISBN: /^(\d{10|\d{13})$/,
  IN: /^\d{10}$/,
  VRID: /^11(\d|\D){7}$/,
  WP: /^\d{4}\.\d{4}$/,
  WP3: /^\d{4}\.\d{3}$/,
  TOTE: /^BIN\s\d{2}-\D{2}-\d{2}$/,
  X00: /^X00(\D|\d){7}$/i,
  HTTP: /^https?:\/\/(\w|.)*$/i,
};

// https://www.amazon.com/FIGROL-Clippers-Professional-Cordless-Rechargeable/dp/B085VSMTGN?th=1

export default REGEX;
