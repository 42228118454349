import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import { asMoney } from '../shared-components/helpers';
import PropTypes from 'prop-types';

class PurchaseOrderReportExpenseTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creditCardTotals: this.props.creditCardTotals,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.fees !== prevProps.fees) this.calculateFees();
  }

  calculateFees = () => {
    const { affiliates, creditCardTotals } = this.props;
    const { fees: { creditCardFees, swipedCreditCardFees } } = this.props;

    const totals = creditCardTotals.reduce(
      (acc, { affiliateId, creditCard, swipedCreditCard }) => {
        if (!Object.keys(affiliates).includes(String(affiliateId))) return acc;

        return {
          ccPayments: acc.ccPayments + creditCard,
          swipedCCPayments: acc.swipedCCPayments + swipedCreditCard,
        };
      },
      { ccPayments: 0.0, swipedCCPayments: 0.0 }
    );

    const updatedTotals = creditCardTotals.map(cct => {
      const ccFeePercent = cct.creditCard / totals.ccPayments;
      const swipedCCFeePercent = cct.swipedCreditCard / totals.swipedCCPayments;

      return {
        ...cct,
        ccFees: creditCardFees * ccFeePercent,
        swipedCCFees: swipedCreditCardFees * swipedCCFeePercent,
      };
    });

    this.setState({ creditCardTotals: updatedTotals });
  };

  location = affiliateId =>
    this.props.locations.find(({ affiliateId: locationId }) => locationId === Number(affiliateId));

  render() {
    const { affiliates, vehicleCost } = this.props;
    const { creditCardTotals } = this.state;
    const expenseTotals = { tax: 0.0, cc: 0.0, swipedCC: 0.0, ccFees: 0.0, swipedCCFees: 0.0 };

    return (
      <Table basic={'very'} striped celled className={'stats-table'} textAlign={'center'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Tax</Table.HeaderCell>
            <Table.HeaderCell>CC Payments</Table.HeaderCell>
            <Table.HeaderCell>Swiped CC Payments</Table.HeaderCell>
            <Table.HeaderCell>CC Fees</Table.HeaderCell>
            <Table.HeaderCell>Swiped CC Fees</Table.HeaderCell>
            <Table.HeaderCell>Vehicles</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.keys(affiliates).map(affiliateId => {
            const location = this.location(affiliateId);

            const totals = creditCardTotals.find(
              total => total.affiliateId === Number(affiliateId)
            );

            expenseTotals.tax += affiliates[affiliateId].tax;

            if (totals) {
              expenseTotals.cc += totals.creditCard;
              expenseTotals.swipedCC += totals.swipedCreditCard;
              expenseTotals.ccFees += totals.ccFees;
              expenseTotals.swipedCCFees += totals.swipedCCFees;
            }

            return (
              <Table.Row key={`credit-card-fees-${affiliateId}`}>
                <Table.Cell className={'t-left'}>
                  {location ? location.name : affiliateId}
                </Table.Cell>
                <Table.Cell>{asMoney(affiliates[affiliateId].tax)}</Table.Cell>
                <Table.Cell>{totals ? asMoney(totals.creditCard) : ''}</Table.Cell>
                <Table.Cell>{totals ? asMoney(totals.swipedCreditCard) : ''}</Table.Cell>
                <Table.Cell>{totals ? asMoney(totals.ccFees) : ''}</Table.Cell>
                <Table.Cell>{totals ? asMoney(totals.swipedCCFees) : ''}</Table.Cell>
                <Table.Cell>{affiliateId === '9' ? asMoney(vehicleCost) : ''}</Table.Cell>
              </Table.Row>
            );
          })}
          <Table.Row>
            <Table.Cell className={'t-left'}>Totals</Table.Cell>
            <Table.Cell>{asMoney(expenseTotals.tax)}</Table.Cell>
            <Table.Cell>{asMoney(expenseTotals.cc)}</Table.Cell>
            <Table.Cell>{asMoney(expenseTotals.swipedCC)}</Table.Cell>
            <Table.Cell>{asMoney(expenseTotals.ccFees)}</Table.Cell>
            <Table.Cell>{asMoney(expenseTotals.swipedCCFees)}</Table.Cell>
            <Table.Cell>{asMoney(vehicleCost)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

PurchaseOrderReportExpenseTable.propTypes = {
  affiliates: PropTypes.object.isRequired,
  creditCardTotals: PropTypes.array.isRequired,
  fees: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  vehicleCost: PropTypes.number,
};

export default PurchaseOrderReportExpenseTable;
