import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class ProgramAveragesTable extends React.Component {
  renderRows = data =>
    data.map(({ id, name, averageItemTime }) => (
      <Table.Row key={id}>
        <Table.Cell>{`${name} (${id})`}</Table.Cell>
        <Table.Cell>{Number(averageItemTime).toFixed(3)}</Table.Cell>
      </Table.Row>
    ));

  render() {
    const { data } = this.props;

    if (data.length <= 0) {
      return (
        <div className={'missing-stats'}>No program averages found using the provided filters</div>
      );
    }

    return (
      <Table basic={'very'} striped celled className={'stats-table'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Program (ID)</Table.HeaderCell>
            <Table.HeaderCell>Average Item Time (Minutes)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderRows(data)}</Table.Body>
      </Table>
    );
  }
}

ProgramAveragesTable.propTypes = {
  data: PropTypes.array,
};

export default ProgramAveragesTable;
