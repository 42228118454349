import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Label } from 'semantic-ui-react';
import AuctionMakerGovernor from './auction-maker-governor';
import { getProgramTotals, getSelected } from '../utilities/auction-maker-utils';
import AuctionMakerLayout from './auction-maker-layout';
import AuctionMakerForm from './auction-maker-form';

class AuctionMaker extends Component {
  state = {
    index: 0,
  };

  render() {
    const { locations, fetch } = this.props;
    const { index } = this.state;

    const { items, addOns, ...location } = locations[index];

    return (
      <div className={'mt-3'}>
        {locations.map(({ name }, key) => (
          <div key={key} className={'inline-flex fd-row flex-wr'}>
            <Button
              active={index === key}
              content={name}
              onClick={() => this.setState({ index: key })}
            />
          </div>
        ))}
        <Divider />
        <>
          <AuctionMakerGovernor
            // this is just a list of items for finding lead item
            data={Object.values(items).flat()}
            // standard location object
            location={location}
          >
            {({ governorState, handleUpdate, handleChange, ...rest }) => {
              const { [location.id]: adjustments = {} } = governorState;
              const selectedItems = getSelected(items, adjustments);
              const addOnsSelectedItems = addOns
                .map(addOn => getSelected(addOn.items, governorState[addOn.name] || {}))
                .flat();

              const totalSelected = selectedItems.length + addOnsSelectedItems.length;

              return (
                <>
                  <AuctionMakerLayout.Header
                    handleChange={handleChange}
                    startTime={governorState.startTime}
                    endTime={governorState.endTime}
                  />
                  <div className={'flex fd-row jc-between ai-baseline'}>
                    <AuctionMakerForm
                      onChange={handleUpdate}
                      // item list length
                      total={Object.values(items).flat().length}
                      programTotals={getProgramTotals(items)}
                      title={location.name}
                      locationId={location.id}
                      // governor state updates
                      adjustments={adjustments}
                      totalSelected={selectedItems.length}
                    />
                    {addOns.map((addOn, key) => {
                      const { [addOn.name]: addOnAdjustments = {} } = governorState;

                      return (
                        <AuctionMakerForm
                          key={key}
                          onChange={handleUpdate}
                          // item list length
                          total={Object.values(addOn.items).flat().length}
                          programTotals={getProgramTotals(addOn.items)}
                          title={addOn.name}
                          locationId={addOn.name}
                          // governor state updates
                          adjustments={addOnAdjustments}
                          totalSelected={getSelected(addOn.items, addOnAdjustments).length}
                        />
                      );
                    })}
                    <Label>
                      Total for Auction:
                      <Label.Detail>{totalSelected}</Label.Detail>
                    </Label>
                  </div>
                  <AuctionMakerLayout.Footer
                    {...rest}
                    handleUpdate={handleUpdate}
                    handleChange={handleChange}
                    governorState={governorState}
                    fetch={fetch}
                    selected={[...selectedItems, ...addOnsSelectedItems]}
                  />
                </>
              );
            }}
          </AuctionMakerGovernor>
        </>
      </div>
    );
  }
}

AuctionMaker.propTypes = {
  locations: PropTypes.array.isRequired,
  fetch: PropTypes.func.isRequired,
};

export default AuctionMaker;
