import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
import config from '../config';
import { CARGO } from '../utilities/constants/access-levels';

class AuthService {
  constructor({ auctionMethodApi, firebaseAuth }) {
    this.nellisAuctionApi = new NellisAuctionApi();
    this.auctionMethodApi = auctionMethodApi;
    this.auth = firebaseAuth;

    this.apiKey = config.auctionMethodApi.xApiKey;
  }

  /**
   * Creates user object containing the fields we require
   *
   * Get token from firebase, use token for our api and retrieve user info
   * @param email
   * @return Object
   */
  createUserObject = async email => {
    const token = await this.auth.currentUser.getIdToken(true);

    this.nellisAuctionApi.setToken(token);

    const userInfo = await this.nellisAuctionApi.get(this.nellisAuctionApi.routes.authenticate);

    if (!userInfo[0].applicationIds.includes(CARGO)) {
      throw new Error(
        'User does not have access to this application. Please contact your system administrator'
      );
    }

    await this.setToken();

    await this.nellisAuctionApi.put(`${this.nellisAuctionApi.routes.userLastActivity}/${email}`, {
      email,
    });

    return {
      api_key: this.apiKey,
      email,
      id: userInfo[0].userId,
      affiliateId: userInfo[0].location.affiliateId,
      locationId: userInfo[0].locationId,
      token,
      applicationIds: userInfo[0].applicationIds,
      roleId: userInfo[0].roleId,
    };
  };

  getIdToken = () => this.auth.currentUser.getIdToken(true);

  setToken = async () => {
    const token = await this.auth.currentUser.getIdToken(true);

    this.nellisAuctionApi.setToken(token);
  };

  login = async (email, password) => {
    const user = await this.auth.signInWithEmailAndPassword(email, password);

    return this.createUserObject(user.email);
  };

  logout = () => this.auth.signOut();
}

export default AuthService;
