import React, { Component } from 'react';
import { Message, Transition } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';

class Snackbar extends Component {
  render() {
    const { visible, duration, message, color } = this.props;

    return ReactDom.createPortal(
      <Transition visible={visible} animation="fade up" duration={duration || 500}>
        <Message color={color || 'black'} compact size={'huge'}>
          {message}
        </Message>
      </Transition>,
      document.querySelector('#snackbar')
    );
  }
}

Snackbar.propTypes = {
  visible: PropTypes.bool,
  duration: PropTypes.number,
  message: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default Snackbar;
