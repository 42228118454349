import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const PreviewItemButton = ({ onClick, active }) => (
  <Button
    basic
    content={active ? 'Back' : 'Preview'}
    icon={active ? 'left chevron' : 'eye'}
    primary
    onClick={onClick}
    size={'small'}
  />
);

PreviewItemButton.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PreviewItemButton;
