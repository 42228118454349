import axios from 'axios';
import config from '../config';
const hostname = config.cargoApi.hostname;

/**
 * Ensure merchants on our list make it through only once
 * @param {Array} merchantUrls
 * @return {Array} objects containing merchant info
 */
const filterMerchantUrls = (merchantUrls = []) => {
  const merchantsUsed = [];

  const acceptedMerchants = [
    { name: 'Amazon', url: 'amazon.com' },
    { name: 'Best Buy', url: 'bestbuy.com' },
    { name: "Fry's", url: 'frys.com' },
    { name: 'Office Depot', url: 'officedepot.com' },
    { name: 'Target', url: 'target.com' },
    { name: 'Wal-Mart', url: 'walmart.com' },
  ];

  return merchantUrls
    .filter(merchant => {
      const accepted = acceptedMerchants.filter(m => new RegExp(m.url).test(merchant));

      if (accepted.length > 0) {
        if (merchantsUsed.includes(accepted[0].url)) return false;
        merchantsUsed.push(accepted[0].url);
        return true;
      }

      return false;
    })
    .map(merchant => {
      const [{ name } = ''] = acceptedMerchants.filter(m => new RegExp(m.url).test(merchant));

      return { url: merchant, name };
    });
};

const extractMostRecentPrice = prices => {
  let amount = 0;
  let startDate = new Date(1970, 1, 1);

  prices.forEach(({ dateSeen, amountMax }) => {
    dateSeen.forEach(date => {
      const tempDate = new Date(date);

      if (tempDate.getTime() > startDate.getTime()) {
        startDate = tempDate;
        amount = amountMax;
      }
    });
  });

  return Number(amount).toFixed(2);
};

const buildItemResult = ({
  brand = '',
  imageURLs = [],
  name = '',
  primaryCategories = [],
  prices = [],
  sourceURLs = [],
}) => ({
  name,
  price: extractMostRecentPrice(prices),
  stockImage: imageURLs.length > 0 ? imageURLs[0] : null,
  itemCategory: primaryCategories.length > 0 ? primaryCategories[0] : '',
  merchantUrls: filterMerchantUrls(sourceURLs),
  brand,
});

class DatafinitiService {
  constructor(dependencies = {}) {
    this.axios = dependencies.axios || axios;
  }

  getItemInfoFromUPC(upc) {
    return this.axios
      .get(`${hostname}/search/upc/${upc}`)
      .then(response => response.data)
      .then(({ data: [{ records } = []] }) => {
        if (records.length === 0) return false;

        return buildItemResult(records[0]);
      });
  }

  getItemInfoFromGtins(gtins) {
    return this.axios
      .get(`${hostname}/search/gtins/${gtins}`)
      .then(response => response.data)
      .then(({ data: [{ records } = []] }) => {
        if (records.length === 0) return false;

        return buildItemResult(records[0]);
      });
  }

  getItemInfoFromASIN(asin) {
    return this.axios
      .get(`${hostname}/search/asin/${asin}`)
      .then(response => response.data)
      .then(({ data: [{ records } = []] }) => {
        if (records.length === 0) return false;

        return buildItemResult(records[0]);
      });
  }
}

export default DatafinitiService;
export { buildItemResult };
