import React, { Component } from 'react';
import CargoApi from '../shared-api-adapters/cargo-api';
import { Button, Icon, Popup, Progress, Table } from 'semantic-ui-react';
import Paginater from '../shared-components/paginater';
import { asDateTime, asMoney } from '../shared-components/helpers';
import { DateInput } from 'semantic-ui-calendar-react';

class PurchaseOrderManagement extends Component {
  constructor(props) {
    super(props);
    this.cargoApi = new CargoApi();
    this.state = {
      endDate: null,
      filtering: false,
      loads: [],
      locations: [],
      purchaseOrders: [],
      startDate: null,
    };
  }

  componentDidMount = () => this.fetchData();

  fetchData = async () => {
    this.setState({
      locations: await this.cargoApi.get(this.cargoApi.routes.locations),
      purchaseOrders: await this.cargoApi.get(this.cargoApi.routes.purchaseOrders),
    });
  };

  handleDropdownChange = (event, data) => this.setState({ [data.name]: data.value });

  handleFilter = async () => {
    this.setState({ filtering: true });

    const { endDate, startDate } = this.state;

    const purchaseOrders = await this.cargoApi.get(this.cargoApi.routes.purchaseOrders, null, {
      startDate,
      endDate,
    });

    this.setState({ purchaseOrders, filtering: false });
  };

  render() {
    const { endDate, filtering, loads, locations, purchaseOrders, startDate } = this.state;

    return (
      <>
        <h1 className={'Cargo-header'}>Purchase Order Management</h1>
        <div className={'float-right mb-4'}>
          <DateInput
            dateFormat={'YYYYMMDD'}
            name={'startDate'}
            placeholder={'Select a start date'}
            value={startDate || ''}
            popupPosition="bottom left"
            onChange={this.handleDropdownChange}
            clearable={true}
            closable={true}
            closeOnMouseLeave={false}
            className={'m-2'}
          />
          <DateInput
            dateFormat={'YYYYMMDD'}
            name={'endDate'}
            placeholder={'Select an end date'}
            value={endDate || ''}
            popupPosition="bottom left"
            onChange={this.handleDropdownChange}
            clearable={true}
            closable={true}
            closeOnMouseLeave={false}
            className={'m-2'}
          />
          <Button
            icon={'filter'}
            className={'float-right m-2'}
            content={'Filter'}
            onClick={this.handleFilter}
            loading={filtering}
          />
        </div>
        {purchaseOrders.length > 0 && (
          <Paginater items={purchaseOrders} maxItemsPerPage={20} domain={purchaseOrders}>
            {({ itemsOnThisPage, renderPaginater }) => (
              <>
                <Table basic={'very'} textAlign={'center'} striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>PO Number</Table.HeaderCell>
                      <Table.HeaderCell>Date</Table.HeaderCell>
                      <Table.HeaderCell>Location</Table.HeaderCell>
                      <Table.HeaderCell>Amount</Table.HeaderCell>
                      <Table.HeaderCell>Percent Complete</Table.HeaderCell>
                      <Table.HeaderCell>Paid</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {itemsOnThisPage.map((po, key) => {
                      const { inventories } = po;

                      const loadsInfo = inventories.map(
                        ({ load }) =>
                          load
                            ? { id: load.id, percent: load.percentComplete }
                            : { id: null, percent: 0.0 }
                      );

                      const actualLoadPercentageTotal = loadsInfo.reduce(
                        (acc, value) => acc + value.percent,
                        0.0
                      );

                      const colorPercentage =
                        inventories.length !== 0
                          ? parseFloat(actualLoadPercentageTotal) /
                            (parseFloat(inventories.length) * 100.0)
                          : 0;

                      let color = 'green';

                      if (colorPercentage < 1) color = 'yellow';

                      if (colorPercentage < 0.25) color = 'red';

                      return (
                        <Table.Row key={key}>
                          <Table.Cell>
                            <a href={`purchaseOrders/${po.id}`}>{po.description}</a>
                          </Table.Cell>
                          <Table.Cell>{asDateTime(po.createdAt)}</Table.Cell>
                          <Table.Cell>
                            {po.locationId && locations.find(loc => loc.id === po.locationId).name}
                          </Table.Cell>
                          <Table.Cell>{asMoney(po.amount)}</Table.Cell>
                          <Table.Cell>
                            <Popup
                              trigger={
                                <Progress
                                  value={actualLoadPercentageTotal}
                                  total={inventories.length * 100}
                                  progress={'percent'}
                                  color={color}
                                  precision={2}
                                />
                              }
                              on={'hover'}
                              content={
                                <>
                                  {loadsInfo.map(({ id, percent }) => (
                                    <div key={`load-${id}`}>
                                      Load ID: {id}: {percent}%
                                    </div>
                                  ))}
                                </>
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>
                            {po.paid === 1 && <Icon name={'checkmark'} color={'green'} />}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
                <div className={'float-right m-2'}>{renderPaginater()}</div>
              </>
            )}
          </Paginater>
        )}
      </>
    );
  }
}

export default PurchaseOrderManagement;
