import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Header, Label, Message } from 'semantic-ui-react';
import API from './api';
import ItemReport from './item-report';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';
import { startCase, isEmpty } from 'lodash/fp';
import Home from './home';
import { Link } from 'react-router-dom';
import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
import { connect } from 'react-redux';

const DetailLabel = ({ label, detail }) => (
  <div className={'mb-1'}>
    <Label>
      {label}:
      <Label.Detail>{detail || 'N/A'}</Label.Detail>
    </Label>
  </div>
);

DetailLabel.propTypes = {
  label: PropTypes.string.isRequired,
  detail: PropTypes.any,
};

class ItemDetail extends React.Component {
  render() {
    const { computedMatch: { params: { itemId } }, location: { state: item }, user } = this.props;

    return (
      <API.Query
        queries={[{ route: 'users', id: item.userId }]}
        api={new NellisAuctionApi(user.token)}
      >
        {({ data: [[itemCreationUser]] }) => (
          <Grid columns={2} celled={'internally'} stackable>
            <Grid.Column>
              <API.InventoryEditorData>
                {containers => (
                  <Grid columns={2}>
                    <Grid.Column width={10}>
                      <ItemReport inventoryNumber={itemId} containers={containers} data={item} />
                    </Grid.Column>
                    <Grid.Column width={6} verticalAlign={'bottom'} />
                  </Grid>
                )}
              </API.InventoryEditorData>
              <Divider />
              <DetailLabel
                label={'Creator'}
                detail={`${itemCreationUser.firstName} ${itemCreationUser.lastName}`}
              />
              <DetailLabel label={'At'} detail={new Date(item.createdAt).toLocaleString()} />
            </Grid.Column>
            <Grid.Column>
              <Header as={'h3'}>Return Detail</Header>
              {isEmpty(item.return) ? (
                <Message content={`No return data available for ${item.inventoryNumber}`} />
              ) : (
                <>
                  <Link
                    to={{
                      pathname: Home.getRoute(),
                      state: { barcode: item.return.inventoryNumber },
                    }}
                  >
                    <DetailLabel label={'New Number'} detail={item.return.inventoryNumber} />
                  </Link>
                  <DetailLabel label={'Reason'} detail={item.return.notes} />
                  <DetailLabel
                    label={'Returned On'}
                    detail={new Date(item.return.createdAt).toLocaleString()}
                  />
                </>
              )}
              <Divider />
              <API.Query
                queries={[{ route: 'sales', filter: { internal_id: item.inventoryNumber } }]}
                api={new AuctionMethodApi()}
                control={true}
              >
                {({ data: [{ images, ...sale } = {}] }) => (
                  <>
                    <Header as={'h3'}>Sale Detail</Header>
                    {isEmpty(sale) && (
                      <Message content={`No sale data available for ${item.inventoryNumber}`} />
                    )}
                    {Object.entries(sale).map(([key, value]) => (
                      <DetailLabel key={key} label={startCase(key)} detail={value} />
                    ))}
                  </>
                )}
              </API.Query>
            </Grid.Column>
          </Grid>
        )}
      </API.Query>
    );
  }
}

ItemDetail.defaultProps = {
  computedMatch: {},
  location: {},
};

ItemDetail.propTypes = {
  computedMatch: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(({ auth }) => ({
  ...auth,
}))(ItemDetail);
