const ADMINISTRATOR = [1];
const SUPERVISOR = [2];
const AUTH_USER = [3];
const TEMP_LABOR = [4];
const INVENTORY_SUPERVISOR = [5];
const DRIVER = [6];
const OFFSITE_USER = [7];

const CARGO = 1;
const HR = 3;
const CHECKOUT = 2;

export {
  ADMINISTRATOR,
  SUPERVISOR,
  AUTH_USER,
  TEMP_LABOR,
  INVENTORY_SUPERVISOR,
  DRIVER,
  OFFSITE_USER,
  CARGO,
  CHECKOUT,
  HR,
};
