import React, { Fragment } from 'react';
import { Button, Input, Grid, Card, Label, Loader, Modal } from 'semantic-ui-react';
import CargoApi from '../shared-api-adapters/cargo-api';
import REGEX from '../utilities/constants/regex';
import { NotificationContext } from '../shared-components/notification-context';
import { FAILED, SUCCESS } from '../shared-components/notifications';
import PropTypes from 'prop-types';

class InventoryAudit extends React.Component {
  constructor(props) {
    super(props);

    this.api = new CargoApi(null, 'https://dev-api.cargo.helex.io:3000');
    this.inputRef = React.createRef();

    this.state = {
      value: '',
      result: null,
      loading: false,
      isLocation: false,
      scannedItems: [],
    };
  }

  handleSearchRequest = async (event, { value }) => {
    this.setState({ value });

    if (REGEX.IN.test(value)) {
      this.setState(prevState => ({ scannedItems: [...prevState.scannedItems, value] }));

      this.setState({ value: '' });
    }
  };

  handleChange = (event, { name, value }) => this.setState({ [name]: value });

  handleSubmission = async () => {
    this.setState({ loading: true });

    const data = this.state.scannedItems.map(item => ({
      inventoryNumber: item,
      location: this.state.location,
    }));

    const results = await this.api.post('/inventoryAudit', { data });

    if (results) {
      this.setState({ loading: false, value: '', scannedItems: [] });

      // eslint-disable-next-line react/prop-types
      this.props.addNotifications([
        { ...SUCCESS, content: 'Successfully added item to audit list' },
      ]);

      return;
    }

    this.props.addNotifications([{ ...FAILED, content: 'Failed to add item to audit list' }]);
  };

  componentDidMount() {
    this.inputRef.current.focus();
  }

  render() {
    const { value, loading, scannedItems } = this.state;

    if (loading) return <Loader active>Auditing {scannedItems.length} items</Loader>;

    return (
      <Fragment>
        <Modal
          trigger={<Button content={'Set Location'} className={'mb-2'} />}
          size={'mini'}
          centered={false}
        >
          <Modal.Content>
            <Input placeholder={'Location'} fluid onChange={this.handleChange} name={'location'} />
          </Modal.Content>
          <Modal.Actions>
            <Button content={'Send'} onClick={this.handleSubmission} />
          </Modal.Actions>
        </Modal>
        <Input
          ref={this.inputRef}
          fluid
          icon={'barcode'}
          size={'massive'}
          onChange={this.handleSearchRequest}
          value={value}
        />

        <Label className={'mt-2'} color={'teal'}>
          Count: <Label.Detail>{scannedItems.length}</Label.Detail>
        </Label>
        <Grid column={2} divided className={'mt-3'} stackable>
          <Grid.Column width={16}>
            <Card.Group>
              {scannedItems.map((item, key) => (
                <Card key={key} raised size={'tiny'}>
                  <Card.Content>
                    <Card.Header>{item}</Card.Header>
                  </Card.Content>
                  <Card.Content extra>
                    <Button
                      content={'Remove'}
                      color={'blue'}
                      onClick={() => {
                        this.setState(prevState => ({
                          scannedItems: prevState.scannedItems.filter(i => i !== item),
                        }));
                      }}
                    />
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          </Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}

const WithNotifications = () => (
  <NotificationContext.Consumer>
    {({ addNotifications }) => <InventoryAudit addNotifications={addNotifications} />}
  </NotificationContext.Consumer>
);

InventoryAudit.propTypes = {
  addNotifications: PropTypes.func.isRequired,
};

export default WithNotifications;
