import React, { PureComponent } from 'react';
import { Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * Breadcrumb component
 */
class Crumbs extends PureComponent {
  renderCrumb = (crumb, key = null) => (
    <Label
      key={key}
      content={`${crumb.label}: ${crumb.content}`}
      style={{ ...(crumb.style || {}) }}
      color={crumb.color || null}
      className={'mr-1 mb-1 fw-100'}
    />
  );

  withLink = (crumb, key) => (
    <Link key={key} to={`${crumb.path}${this.props.search}`}>
      {this.renderCrumb(crumb)}
    </Link>
  );

  render() {
    const { crumbs, noLink } = this.props;

    return (
      <div className={'mt-4 mb-2'}>
        {crumbs.map((crumb, key) => {
          if (!crumb.content) return null;
          if (noLink) return this.renderCrumb(crumb, key);
          return this.withLink(crumb, key);
        })}
      </div>
    );
  }
}

Crumbs.defaultProps = {
  search: '',
};

Crumbs.propTypes = {
  crumbs: PropTypes.array.isRequired,
  search: PropTypes.string,
  noLink: PropTypes.bool,
};

export default Crumbs;
