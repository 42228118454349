import ApiService from './api-service';
import { nellisAuction } from './routes';
import config from '../config';

class NellisAuctionApi extends ApiService {
  constructor(token = null) {
    super(token);
    this.hostname = config.nellisAuctionApi.hostname;
    this.routes = nellisAuction;
  }

  setToken = token => (this.token = token);
}

export default NellisAuctionApi;
