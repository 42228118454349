import React from 'react';
import PropTypes from 'prop-types';
import { Image, Modal } from 'semantic-ui-react';

import './photo-viewer.css';

class PhotoViewer extends React.Component {
  render() {
    const { onClose, subject } = this.props;

    if (!subject.id) {
      return null;
    }

    const { url } = subject;

    return (
      <div className="PhotoViewer">
        <Modal open size="fullscreen" onClose={onClose} closeIcon>
          <Modal.Content image className="PhotoViewer-content">
            <Image wrapped centered size="large" src={url} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

PhotoViewer.defaultProps = {
  subject: {},
};

PhotoViewer.propTypes = {
  onClose: PropTypes.func.isRequired,
  subject: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default PhotoViewer;
