import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { CARGO } from '../utilities/constants/access-levels';

class Access extends React.Component {
  componentDidMount() {
    const { accessLevel, authUser, history, setMessages } = this.props;

    if (
      !authUser ||
      !accessLevel.includes(authUser.roleId) ||
      !authUser.applicationIds.includes(CARGO)
    ) {
      history.push(
        '/',
        setMessages([
          'You do not have to access that page.',
          'Contact an administrator if this was a mistake.',
        ])
      );
    }
  }

  render() {
    const { render } = this.props;

    return render(this.props);
  }
}

Access.propTypes = {
  render: PropTypes.any.isRequired,
  accessLevel: PropTypes.array.isRequired,
  authUser: PropTypes.object,
  setMessages: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Access);
