import moment from 'moment';
import { compose, sum } from 'ramda';

const TRAILER_REGEX = /^TR\W\d{6,}$/;
const HENDO_TOTE_REGEX = /^TSP\W\d{2}-[aA-zZ]{2}-\d{2}$/;
const CHE_TOTE_REGEX = /^BIN\W\d{2}-[aA-zZ]{2}-\d{2}$/;

const inRange = (min, max, value) => value >= min && value <= max;

const assignHen = (warehousePosition, location) => {
  if (location || !warehousePosition) return location;

  if (inRange(1, 199, warehousePosition) || warehousePosition.match(HENDO_TOTE_REGEX)) return 'hen';

  return location;
};

const assignChe = (warehousePosition, location) => {
  if (location || !warehousePosition) return location;

  if (inRange(200, 2999, warehousePosition)) {
    return 'che';
  }

  return location;
};

const assignDm = (warehousePosition, location) => {
  if (location || !warehousePosition) return location;

  if (inRange(7000, 7999, warehousePosition) || warehousePosition.match(CHE_TOTE_REGEX)) {
    return 'dm';
  }

  return location;
};

const assignPhx = (warehousePosition, location) => {
  if (location || !warehousePosition) return location;

  if (inRange(3000, 4999, warehousePosition)) {
    return 'phx';
  }

  return location;
};

const assignBonnieSprings = (warehousePosition, location) => {
  if (location || !warehousePosition) return location;

  if (inRange(6000, 6999, warehousePosition)) {
    return 'bs';
  }

  return location;
};

const assignTrailer = (warehousePosition, location) => {
  if (location || !warehousePosition) return location;

  if (warehousePosition.match(TRAILER_REGEX)) {
    return 'tr';
  }

  return location;
};

const getTitle = (type, location) => `${type} Liquidation - ${location} Location`;

const getAffiliate = affiliate => `Nellis Auction - ${affiliate}`;

const getFrontPageText = (days, date, hours, address) =>
  `Removal: All items must be picked up within ${days} days of the auction removal date to avoid relisting. Auction removal will be available on ${date} from ${hours}. All bidders must have their purchases paid for and removed from ${address}`;

const getInvoiceText = (date, hours, location) =>
  `<p>Auction removal will start on ${date} from ${hours}. All bidders must have their purchases paid for and removed from ${location}. <em>Please be prepared to show identification on location to pick-up your items! Nellis Auction offers returns on items that are misdescribed within 7 days of the date you picked up your item(s).</em></p><p style=“color: #000000; font-family: Verdana,Arial,Helvetica,sans-serif; font-size: 11px; font-style: normal; font-variant: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: left; text-decoration: none; text-indent: 0px; text-transform: none; -webkit-text-stroke-width: 0px; white-space: normal; word-spacing: 0px;“><strong><span class=“wysiwyg-font-size-large wysiwyg-color-black” style=“background-color: #FFFD7F; font-size: 12pt;“>Curbside Scheduling App</span><br /></strong></p>
<ol style=“color: #000000; font-family: Verdana,Arial,Helvetica,sans-serif; font-size: 11px; font-style: normal; font-variant: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: left; text-decoration: none; text-indent: 0px; text-transform: none; -webkit-text-stroke-width: 0px; white-space: normal; word-spacing: 0px;“>
<li>Whenever you’re ready to come pick up your items simply log on to NellisAuction.com inside your <a href=“https://www.nellisauction.com/myaccount” target=“_blank”>My Account</a> section and click the red tab labeled “<strong>Schedule Curbside Pickup</strong>“.</li>
</ol>
<p style=“color: #000000; font-family: Verdana,Arial,Helvetica,sans-serif; font-size: 11px; font-style: normal; font-variant: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: left; text-decoration: none; text-indent: 0px; text-transform: none; -webkit-text-stroke-width: 0px; white-space: normal; word-spacing: 0px;“><span class=“wysiwyg-underline” style=“font-size: 12pt;“><strong>Curbside Check-In</strong></span></p>
<ol style=“color: #000000; font-family: Verdana,Arial,Helvetica,sans-serif; font-size: 11px; font-style: normal; font-variant: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: left; text-decoration: none; text-indent: 0px; text-transform: none; -webkit-text-stroke-width: 0px; white-space: normal; word-spacing: 0px;“>
<li>Please have your pickup confirmation number ready at the time of arrival.</li>
<li>Please have your payment ready to expedite the pickup process.</li>
<li>On arrival enter designated drive-thru lane for check-in.</li>
<li>When prompted by Nellis Auction staff please pull into the assigned parking spot.</li>
<li>Our team will bring items to your vehicle.
<p>(<strong>PLEASE NOTE*</strong> due to recent health mandates our team is unable to load items into the vehicle, please bring the appropriate manpower to load your items.)</p>
</li>
</ol><p style="text-align: center;"><a href="https://birdeye.com/nellis-auction-148882768408783/mobile-deeplink/1?rid=2&amp;rtype=review" target="_blank"><img alt="Feedback" src="https://i.imgur.com/Ym7HfXN.png" /></a></p><p>The Nellis Auction Team thanks you for your business!</p>`;

const getPaymentInfo = () =>
  `<p>TERMS OF SALE: Payment for purchases must be made by debit card, cash, certified check, wire transfer or credit card (Visa/MasterCard/Discover) Credit / Debit card on file will be automatically charged by 4pm the next day. Payments can be made within the account tab in the bidder's account center under the invoice tab.</p>`;

const getRemovalInfo = (
  date,
  hours,
  location
) => `<p>${date} from ${hours}. All bidders must have their purchases paid for and removed from ${location}</p>
<p>1) Credit / Debit card on file will be automatically charged by 4pm the next day.<br />2) All items must be picked up within 7 days of the auction removal date or a relisting fee of 35% of invoice subtotal + premium will be applied to your account.<br />3) Nellis Auction offers returns on items that are misdescribed within 7 days of the date you picked up your item(s).</p>`;

// Pickups are next day, unless it's Sunday. Then it's Monday
const resolvePickUpDay = pickUpDay => pickUpDay;

const formatDescriptionDate = date => date.format('dddd, MMMM Do, YYYY');

const parseDateString = parser => dateString => moment(dateString, parser);

const endOf = type => date => date.endOf(type);

const addDays = num => date => date.add(num, 'days');

const getPickUpDay = compose(addDays(1), parseDateString('dddd, MMMM Do, YYYY'));

const getPickUpDateString = compose(
  formatDescriptionDate,
  resolvePickUpDay,
  getPickUpDay,
  parseDateString('dddd, MMMM Do, YYYY')
);

const getProgramTotals = programs =>
  Object.keys(programs).reduce((acc, key) => ({ ...acc, [key]: programs[key].length }), {});

const getAdjustedTotal = (data, adjustedData) =>
  sum(Object.values({ ...getProgramTotals(data), ...adjustedData }));

const getLeadImagePossibles = data =>
  data.sort((a, b) => Number(b.retailPrice) - Number(a.retailPrice)).slice(0, 10);

const getSelected = (data, adjustments = {}) =>
  Object.keys(data)
    .map(key =>
      data[key].slice(
        0,
        isNaN(Number(adjustments[key])) ? data[key].length : Number(adjustments[key])
      )
    )
    .flat();

const getHours = (day, { weeklyHours, saturdayHours }) => (day === 6 ? saturdayHours : weeklyHours);

export {
  getTitle,
  getRemovalInfo,
  getPaymentInfo,
  getPickUpDay,
  getInvoiceText,
  getFrontPageText,
  getAffiliate,
  getAdjustedTotal,
  getLeadImagePossibles,
  getProgramTotals,
  getSelected,
  getHours,
  getPickUpDateString,
  assignBonnieSprings,
  assignChe,
  assignHen,
  assignPhx,
  assignDm,
  assignTrailer,
  inRange,
  CHE_TOTE_REGEX,
  HENDO_TOTE_REGEX,
  TRAILER_REGEX,
};
