import React from 'react';
import { Button, Icon, Transition } from 'semantic-ui-react';
import PropTypes from 'prop-types';

/**
 * Animates a show hide button
 *
 * @param visible - used to toggle button
 * @param text - button text
 * @returns {*}
 * @constructor
 */

class TransitionButton extends React.PureComponent {
  render() {
    const { visible, text, ...buttonProps } = this.props;

    return (
      <>
        <Transition visible={!!visible} animation="scale" duration={200}>
          <Button {...buttonProps} icon labelPosition="right">
            {text}
            <Icon name="right arrow" />
          </Button>
        </Transition>
      </>
    );
  }
}

TransitionButton.propTypes = {
  visible: PropTypes.bool,
  text: PropTypes.string,
};

export default TransitionButton;
