/**
 * Format provided dateString into date and time string
 *
 * @param dateString
 * @param milliseconds
 * @return {string} in format: MM/DD/YYYY H:M:S AM|PM
 */
const asDateTime = (dateString, milliseconds = null) => {
  const date = milliseconds ? new Date(milliseconds) : new Date(dateString);

  return `${date.getMonth() +
    1}/${date.getDate()}/${date.getFullYear()} ${date.toLocaleTimeString()}`;
};

/**
 * Format provided numberString with dollar sign, commas and two decimals
 *
 * @param numberString {string}
 * @return {string} in format: $xxx,xxx.xx
 */
const asMoney = numberString => {
  const number = Number(numberString);

  return isNaN(number)
    ? ''
    : `$${number.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

/**
 * Gets difference between provided epoch milliseconds and the current time
 * Formats time with hours, minutes, and seconds
 *
 * @param epochMs {number}
 * @returns {string} in format: hh:mm:ss
 */
const asTimer = epochMs => {
  const milliseconds = new Date().getTime() - epochMs;

  const s = Math.floor((milliseconds / 1000) % 60);
  const m = Math.floor((milliseconds / (1000 * 60)) % 60);
  const h = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

  return `${h > 10 ? h : `0${h}`}:${m > 9 ? m : `0${m}`}:${s > 9 ? s : `0${s}`}`;
};

/**
 * Formats provided 10-digit phone number as (xxx) xxx-xxxx
 *
 * @param phoneNumber {number|string}
 * @returns {string|null}
 */
const asPhoneNumber = phoneNumber => {
  const phoneNumberString = String(phoneNumber);
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

// sort arrivalTimeAscending
const sortByArrivalTime = docs =>
  docs.sort(({ arrivalTime }, { arrivalTime: arrivalTime2 }) => {
    if (!arrivalTime || !arrivalTime2) return 0;

    return arrivalTime.toMillis() > arrivalTime2.toMillis() ? 1 : -1;
  });

const sortByLastName = docs =>
  docs.sort(
    ({ customer: { lastName } }, { customer: { lastName: lastNameB } }) =>
      lastName > lastNameB ? 1 : -1
  );

const sortByApproxPickupTime = docs =>
  docs.sort(({ approxPickupTime }, { approxPickupTime: approxPickupTime2 }) => {
    if (!approxPickupTime || !approxPickupTime2) return 0;
    return approxPickupTime.toMillis() > approxPickupTime2.toMillis() ? 1 : -1;
  });

const sortAwaitingConsolidation = docs => [
  ...sortByArrivalTime(docs.filter(doc => doc.isHere)),
  ...sortByApproxPickupTime(docs.filter(doc => !doc.isHere)),
];

export {
  asDateTime,
  asMoney,
  asTimer,
  asPhoneNumber,
  sortByApproxPickupTime,
  sortByArrivalTime,
  sortByLastName,
  sortAwaitingConsolidation,
};
