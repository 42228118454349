import { isArray } from 'lodash/fp';
import {
  always,
  compose,
  filter,
  fromPairs,
  identity,
  ifElse,
  intersection,
  isNil,
  length,
  lensProp,
  map,
  mapObjIndexed,
  over,
  T,
} from 'ramda';
import { isEmpty } from './helpers/object';

/**
 * All choices in value (if an array) must be present in choices
 * @param {Array} choices An array of choices
 * @param {String} value The value to check
 * @return {Boolean} True if value is included in choices otherwise false
 */
const choicesInclude = (choices, value) => {
  if (isArray(value)) {
    return intersection(choices, value).length === value.length;
  }

  return choices.includes(value);
};

/**
 * For each key, check if "default" is defined. If it's not use empty string.
 * @param {Object} rules The rules object that will be filled (it's actually copied and then filled)
 * @return {Object} The rules where all "default" properties have been filled
 */
const fillDefaults = map(over(lensProp('default'), ifElse(isNil, always(''), identity)));

/**
 * All event types besides "submit" allow required fields to be empty in
 * attempt to not be annoying
 * @param {Boolean} valueIsRequired A boolean indicating if this field is required (see isRequired below)
 * @param {String} eventType A string indicating the event type
 * @return {Boolean} True if the field can be empty in this scenario otherwise false
 */
const isAllowedToBeEmpty = (valueIsRequired, eventType = 'submit') =>
  eventType !== 'submit' || !valueIsRequired;

/**
 * The required function needs all field data because sometimes a
 * certain field is required only if another field has certain value
 * @param {mixed} required A function or Boolean indicating whether or not the field is required
 * @param {Object} allFieldData The data for all fields in the form
 * @return {Boolean} True if required otherwise false
 */
const isRequired = (required, allFieldData) =>
  (typeof required === 'function' ? required : always(required))(allFieldData);

/**
 * Simple RegExp pattern matching or substring searching
 * @param {String} patternOrStr A RegExp object or a string
 * @param {String} value The actual value to check
 * @return {Boolean} True if value matches otherwise false
 */
const matches = (patternOrStr, value) =>
  patternOrStr instanceof RegExp ? patternOrStr.test(value) : value.indexOf(patternOrStr) > -1;

/**
 * Validator factory
 * Given a rules object it will return a new function that expects the current form data
 * That function will expose a number of functions to get information about the data (see below)
 * @param {Object} rawRules The javascript object representing the validation rules
 * @return {function} The validator for the given rules
 */
class Validator {
  constructor(rules) {
    this.rules = fillDefaults(rules);
    this.data = {};
  }

  /**
   * Sets the data object to be validated
   * @param {Object} The new data object
   * @return {Object} this; for chaining purposes
   */
  setData(data) {
    this.data = data;
    return this;
  }

  /**
   * Checks a single field for validity
   * @param {String} field The field name to validate
   * @param {String} eventType Standard web event name (submit, blur, or change)
   * @return {mixed} error Null (if no error) or a string indicating the error message
   */
  check(field, eventType = 'submit') {
    if (!['submit', 'blur', 'change'].includes(eventType)) {
      return null;
    }

    const fieldRules = this.rules[field];

    if (!fieldRules) {
      throw new Error(
        `Cannot validate ${field}. This field is not defined in the validation rules.`
      );
    }

    const value = this.data[field];
    const empty = fieldRules.empty || isEmpty;
    const hint = fieldRules.hint || '';
    const is = fieldRules.is || T;
    const numberSelected = fieldRules.numberSelected || length;
    const minSelected = fieldRules.minSelected || null;
    const pattern = fieldRules.pattern || /.*/;
    const required = fieldRules.required || false;
    let error = null;

    if (fieldRules.ignore) {
      return null;
    }

    if (!hint && (fieldRules.is || fieldRules.pattern)) {
      throw new Error(
        `Field rules are invalid for ${field}. When 'is' or 'pattern' is defined then a 'hint' must be defined.`
      );
    }

    const req = isRequired(required, this.data);

    if (isAllowedToBeEmpty(req, eventType) && empty(value)) {
      return null;
    } else if (empty(value)) {
      error = hint || `Cannot be empty.`;
    } else if (!is(value)) {
      error = hint;
    } else if (!matches(pattern, value)) {
      error = hint;
    } else if (minSelected && minSelected > numberSelected(value)) {
      error = hint || `At least ${minSelected} options must be selected.`;
    }

    if (req && error) {
      error = `Required field; ${error}`;
    }

    return error;
  }

  /**
   * Given the current data, checks which fields are currently required
   * @return {Object} An object of Boolean values indicating whether or not each field is required
   */
  getRequiredFields() {
    return mapObjIndexed(
      (value, field) =>
        Boolean(!this.rules[field].ignore && isRequired(this.rules[field].required, this.data)),
      this.rules
    );
  }

  /**
   * Checks several fields (all by default) and returns the result in object form
   * @param {String} eventType The type of event to validation against (submit is default)
   * @param {Array} fields An optional array of fields to check
   * @return {Object} An object with each field that returned a non-null value from `check`
   */
  validate(eventType = 'submit', fields = Object.keys(this.rules)) {
    return compose(filter(v => !isNil(v)), fromPairs, map(f => [f, this.check(f, eventType)]))(
      fields
    );
  }
}

export default Validator;
export { choicesInclude, fillDefaults, isAllowedToBeEmpty, isRequired, matches };
