import React, { Component, Fragment } from 'react';
import { Button, Form, Input, Label, Segment, Table } from 'semantic-ui-react';
import CargoApi from '../../shared-api-adapters/cargo-api';
import './tools.css';
import * as PATHS from '../../utilities/constants/routes';
import PropTypes from 'prop-types';
import MessageBlock from '../../shared-components/message-block';

class Tools extends Component {
  constructor(props) {
    super(props);

    this.cargoApiService = new CargoApi();

    this.state = {
      status: 'success',
      messages: [],
      createProgramLoading: false,
      programName: '',
      programId: '',
      warehouseLocationHistory: null,
      warehouseLocationLoading: false,
      warehouseTimeFrame: 30,
      path: '',
    };
  }

  handleChange = (event, { value, name }) => {
    this.setState({ [name]: value });
  };

  fetchPrograms = async () => {
    const programs = await this.cargoApiService.get(this.cargoApiService.routes.programs);

    this.setState({ programs });
  };

  fetchWarehouseHistory = async () => {
    const { inventoryNumber, warehouseTimeFrame } = this.state;

    this.setState({ warehouseLocationLoading: true });

    let warehouseLocationHistory = await this.cargoApiService.get(
      this.cargoApiService.routes.auctionMethodWarehouseHistory,
      inventoryNumber,
      { days: warehouseTimeFrame }
    );

    let messages = [];
    let status = 'error';

    if (warehouseLocationHistory.length === 0 || !inventoryNumber) {
      warehouseLocationHistory = '';
      messages = ['Item not found. Please try again.'];
      status = 'error';
    } else {
      // eslint-disable-next-line camelcase
      const { warehouse_history } = warehouseLocationHistory[0];

      // eslint-disable-next-line camelcase
      if (!warehouse_history) {
        warehouseLocationHistory = '';
        messages = ['Item exists, but does not have any previous warehouse locations.'];
        status = 'success';
        // eslint-disable-next-line camelcase
      } else warehouseLocationHistory = warehouse_history;
    }

    this.setState({
      status,
      messages,
      warehouseLocationHistory,
      warehouseLocationLoading: false,
    });
  };

  formatTerribleString = string => {
    if (string.length === 0 || !string) return '';

    return string.split('\n').map((line, key) => {
      const text = line.split(' _ ');
      const timeLocation = text[2].split(': ');

      return (
        <Table.Row key={key}>
          <Table.Cell>{timeLocation[1]}</Table.Cell>
          <Table.Cell>
            {text[0]} - {timeLocation[0]}
          </Table.Cell>
          <Table.Cell> {text[1]}</Table.Cell>
        </Table.Row>
      );
    });
  };

  setTimeFrame = (event, { value }) => this.setState({ warehouseTimeFrame: value });

  componentDidMount() {
    this.fetchPrograms();

    this.setState({ path: this.props.location.pathname });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname)
      this.setState({
        path: this.props.location.pathname,
        messages: [],
        programId: '',
        inventoryNumber: '',
        programName: '',
      });
  }

  createProgram = async () => {
    const { costOfGoodsSold, programName } = this.state;
    let response = false;
    let messages = [`You have successfully created program: ${programName}`];
    let status = 'success';

    if (programName !== '') {
      response = await this.cargoApiService.post(this.cargoApiService.routes.programs, {
        name: programName,
      });

      await this.cargoApiService.post(this.cargoApiService.routes.costOfGoodsSold, {
        programId: response.id,
        cost: costOfGoodsSold,
      });
    }

    if (!response) {
      messages = [`An error occurred while creating a new program. Please try again.`];
      status = 'error';
    }

    await this.fetchPrograms();

    this.setState({ messages, status });
  };

  render() {
    const {
      createProgramLoading,
      messages,
      status,
      warehouseLocationHistory,
      warehouseLocationLoading,
      warehouseTimeFrame,
      path,
    } = this.state;

    return (
      <Fragment>
        <MessageBlock
          onDismiss={this.handleMessageDismiss}
          timeout={5000}
          messages={messages}
          type={status}
        />

        {path === PATHS.WAREHOUSE_HISTORY && (
          <Segment raised>
            <Label as="a" color="orange" ribbon>
              Warehouse Location History
            </Label>

            <div className={'mt-2'}>
              <Button
                active={warehouseTimeFrame === 30}
                content={'Past 30 days'}
                compact
                size={'small'}
                value={30}
                onClick={this.setTimeFrame}
              />
              <Button
                active={warehouseTimeFrame === 90}
                content={'Past 90 days'}
                compact
                size={'small'}
                value={90}
                onClick={this.setTimeFrame}
              />
              <Button
                active={warehouseTimeFrame === 1500}
                content={'All-Time (Slow)'}
                compact
                size={'small'}
                value={1500}
                onClick={this.setTimeFrame}
              />
            </div>
            <Form onSubmit={this.fetchWarehouseHistory}>
              <Input
                type="number"
                fluid
                onChange={this.handleChange}
                name={'inventoryNumber'}
                placeholder={'Inventory Number'}
                className={'mt-2'}
              />
              <Button
                className="Tools-button"
                type="submit"
                color={'grey'}
                content={'Look up'}
                loading={warehouseLocationLoading}
                icon={'search'}
              />
            </Form>
            {warehouseLocationHistory && (
              <Table basic={'very'} striped celled className={'stats-table'}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Location</Table.HeaderCell>
                    <Table.HeaderCell>Date / Time</Table.HeaderCell>
                    <Table.HeaderCell>User ID</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.formatTerribleString(warehouseLocationHistory)}</Table.Body>
              </Table>
            )}
          </Segment>
        )}

        {path === PATHS.PROGRAM_CREATOR && (
          <Segment raised>
            <Label as="a" color="orange" ribbon>
              Create a Program
            </Label>

            <Form onSubmit={this.createProgram}>
              <div>
                <Input
                  type="text"
                  fluid
                  onChange={this.handleChange}
                  name={'programName'}
                  placeholder={'Enter a program name (REQUIRED)'}
                  className={'mt-2'}
                />
                <Input
                  type="number"
                  fluid
                  step={0.01}
                  onChange={this.handleChange}
                  name={'costOfGoodsSold'}
                  placeholder={'Enter Costs of Goods Sold (REQUIRED)'}
                  className={'mt-2'}
                />
              </div>
              <Button
                className="Tools-button"
                type="submit"
                color={'grey'}
                content={'Create'}
                loading={createProgramLoading}
                icon={'save outline'}
              />
            </Form>
          </Segment>
        )}
      </Fragment>
    );
  }
}

Tools.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default Tools;
