import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const SavingIndicator = ({ pending }) => (
  <Label active basic color={pending ? 'yellow' : 'green'} size="small">
    <Icon name={pending ? 'pencil' : 'checkmark'} />
    {pending ? 'Saving' : 'Saved'}
  </Label>
);

SavingIndicator.propTypes = {
  pending: PropTypes.bool.isRequired,
};

export default SavingIndicator;
