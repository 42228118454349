import React, { Component } from 'react';
import { Button, Icon, Label, Message, Popup, Transition } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';

class AnimatedMessage extends Component {
  componentDidMount() {
    const { node } = this.props;

    setTimeout(() => {
      node.remove();
    }, 10000);
  }

  render() {
    const { duration, message, header, color, animation } = this.props;

    return (
      <Transition
        visible={true}
        animation={animation || 'fade up'}
        duration={duration || 500}
        unmountOnHide
      >
        <Message color={color || 'black'} compact size={'huge'} className={'mb-1'}>
          <Message.Header>{header}</Message.Header>
          <Message.Content>{message}</Message.Content>
        </Message>
      </Transition>
    );
  }
}

AnimatedMessage.propTypes = {
  message: PropTypes.string.isRequired,
  animation: PropTypes.string,
  duration: PropTypes.number,
  color: PropTypes.string,
  node: PropTypes.any.isRequired,
  header: PropTypes.string.isRequired,
};

const snackbarPortal = (message, header, color, animation = 'fade up') => {
  const snackbarRoot = document.querySelector('#snackbar');

  const div = document.createElement('div');

  snackbarRoot.append(div);

  return render(
    <AnimatedMessage
      message={message}
      header={header}
      color={color}
      animation={animation}
      node={div}
    />,
    div
  );
};

export { snackbarPortal };

class NotificationsCenter extends Component {
  state = {
    loggedNotifications: [],
    transition: false,
    open: false,
  };

  handleNotificationOpen = () => {
    this.setState({
      open: true,
      loggedNotifications: [...this.props.notifications],
    });
  };

  handleNotificationClose = () => {
    this.setState({ open: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.notifications.length !== this.props.notifications.length) {
      this.setState(prevState => ({ transition: !prevState.transition }));

      this.props.notifications
        .slice(prevProps.notifications.length)
        .forEach(notification =>
          snackbarPortal(notification.content, notification.header, notification.color)
        );
    }
  }

  renderNotifications = () => {
    const { props: { notifications } } = this;

    return (
      <>
        {notifications.length > 0 ? (
          notifications.map(
            (
              {
                color = null,
                header = 'Notification',
                timestamp = new Date().toLocaleTimeString(),
                content = 'No message available',
              },
              key
            ) => (
              <Message key={key} color={color}>
                <Message.Header>{header} </Message.Header>
                <Message.Content>{timestamp}</Message.Content>
                <Message.Content>{content}</Message.Content>
              </Message>
            )
          )
        ) : (
          <div>No notifications</div>
        )}
      </>
    );
  };

  render() {
    const {
      props: { notifications },
      state: { transition, open, loggedNotifications },
      handleNotificationOpen,
      handleNotificationClose,
      renderNotifications,
    } = this;

    const diff = notifications.slice(loggedNotifications.length);
    const isErrorNotification = notifications
      .filter((val, key) => !loggedNotifications[key])
      .some(notification => notification.color === 'red');

    return (
      <>
        <Popup
          open={open}
          basic
          trigger={
            <Transition animation={'shake'} duration={500} visible={transition} mountOnShow={false}>
              <Button
                size={'huge'}
                className={'fixed r-1r b-10r shadow-pop pr-3 pl-3'}
                onClick={handleNotificationOpen}
                circular
              >
                <Icon name={'bell'} className={'m-0 mr-1'} />
                {diff.length > 0 && (
                  <Label color={isErrorNotification ? 'red' : 'green'} floating>
                    {diff.length}
                  </Label>
                )}
              </Button>
            </Transition>
          }
          content={renderNotifications()}
          position={'top left'}
          className={'mh-30r ofy-auto'}
          onClose={handleNotificationClose}
        />
      </>
    );
  }
}

NotificationsCenter.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default NotificationsCenter;
