import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { asDateTime } from '../shared-components/helpers';

class LoadLogsTable extends Component {
  render() {
    const { loadId, log } = this.props;

    return (
      <>
        <h1 className={'Cargo-header'}>Load Logs: #{loadId}</h1>
        <Table basic={'very'} striped celled className={'stats-table'} textAlign={'center'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>UserId</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.HeaderCell>Datetime</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {log.map(({ id, userId, action, createdAt }) => (
              <Table.Row key={id}>
                <Table.Cell>{userId}</Table.Cell>
                <Table.Cell className={'t-left'}>{action}</Table.Cell>
                <Table.Cell>{asDateTime(createdAt)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    );
  }
}

LoadLogsTable.propTypes = {
  loadId: PropTypes.number.isRequired,
  log: PropTypes.array.isRequired,
};

export default LoadLogsTable;
