import React from 'react';
import { Button, Container, Divider, List, Sidebar, Transition } from 'semantic-ui-react';
import { Link, NavLink, Switch, withRouter } from 'react-router-dom';
import InventoryEditor from './inventory-editor';
import Tools from './tools/tools';
import UploadStatus from './upload-status';
import StatCity from './stat-city';
import Home from './home';
import { Provider } from 'unstated';
import QuickSort from './quick-sort';
import SessionWatcher from './session-watcher';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AuctionMakerPage from './auction-maker-page';
import ItemDetail from './item-detail';
import Firewall from './firewall';
import config from '../config';
import UserAuthStatus from './user-auth-status';
import SessionTimer from './session-timer';
import {
  ADMINISTRATOR,
  SUPERVISOR,
  AUTH_USER,
  TEMP_LABOR,
  INVENTORY_SUPERVISOR,
  DRIVER,
  OFFSITE_USER,
} from '../utilities/constants/access-levels';
import TrailerTable from './trailer-table';
import TrailerHistory from './trailer-history';
import TrailerQueue from './trailer-queue';
import TrailerCity from './trailer-city';
import PurchaseOrders from './purchase-orders';
import PurchaseOrderManagement from './purchase-order-management';
import PurchaseOrderReportGenerator from './po-report-generator';
import LoadCompletion from './load-completion';
import ReleaseNotes from './release-notes';
import LotBundler from './lot-bundler';
import AuctionIdentification from './auction-identification';
import LocationTable from './location-table';
import FraudTable from './fraud-table';
import LoadEditor from './load-editor';
import LoadLogs from './load-logs';
import ProgramCreator from './program-creator';
import InventoryAudit from './inventory-audit';

const ALL_ACCESS = [
  ...ADMINISTRATOR,
  ...SUPERVISOR,
  ...AUTH_USER,
  ...TEMP_LABOR,
  ...INVENTORY_SUPERVISOR,
  ...OFFSITE_USER,
];
const LOW_ACCESS = [...ADMINISTRATOR, ...SUPERVISOR, ...INVENTORY_SUPERVISOR];

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.sidebarButtons = [
      <Button
        fluid
        key={0}
        accesslevel={ALL_ACCESS}
        content={'HOME'}
        icon={'home'}
        as={Link}
        to={'/'}
      />,
      <Button
        fluid
        key={1}
        accesslevel={[...ADMINISTRATOR, ...INVENTORY_SUPERVISOR]}
        content={'AUCTION MAKER'}
        icon={'dollar sign'}
        as={Link}
        to={'/auctionMaker'}
      />,
      <Button
        fluid
        key={2}
        accesslevel={LOW_ACCESS}
        content={'STAT CITY'}
        icon={'pie graph'}
        as={Link}
        to={'/statCity'}
      />,
      <Button
        fluid
        key={3}
        accesslevel={ALL_ACCESS}
        content={'SESSIONS'}
        icon={'spinner'}
        as={Link}
        to={'/sessions'}
      />,
    ].filter(({ props: { accesslevel } }) => accesslevel.includes(props.user.roleId));

    this.purchaseOrderButtons = [
      <Button
        fluid
        key={0}
        accesslevel={ADMINISTRATOR}
        content={'NEW PURCHASE ORDER'}
        icon={'clipboard outline'}
        as={Link}
        to={'/purchaseOrders'}
      />,
      <Button
        fluid
        key={1}
        accesslevel={ADMINISTRATOR}
        content={'ALL PURCHASE ORDERS'}
        icon={'clipboard'}
        as={Link}
        to={'/purchaseOrderManagement'}
      />,
    ];

    this.tableButtons = [
      <Button
        fluid
        key={0}
        accesslevel={ADMINISTRATOR}
        content={'LOCATIONS'}
        icon={'building'}
        as={Link}
        to={'/locationEditor'}
      />,
      <Button
        fluid
        key={0}
        accesslevel={ADMINISTRATOR}
        content={'AUCTIONS'}
        icon={'th'}
        as={Link}
        to={'/auctions'}
      />,
      <Button
        fluid
        key={0}
        accesslevel={ADMINISTRATOR}
        content={'FRAUD'}
        icon={'exclamation triangle'}
        as={Link}
        to={'/fraud'}
      />,
    ].filter(({ props: { accesslevel } }) => accesslevel.includes(props.user.roleId));

    this.toolButtons = [
      <Button
        fluid
        key={0}
        accesslevel={LOW_ACCESS}
        content={'LOAD EDITOR'}
        icon={'clipboard'}
        as={Link}
        to={'/loadEditor'}
      />,
      <Button
        fluid
        key={1}
        accesslevel={LOW_ACCESS}
        content={'CREATE PROGRAMS'}
        icon={'save outline'}
        as={Link}
        to={'/programCreator'}
      />,
      <Button
        fluid
        key={2}
        accesslevel={LOW_ACCESS}
        content={'WAREHOUSE LOCATION HISTORY'}
        icon={'history'}
        as={Link}
        to={'/warehouseHistory'}
      />,
      <Button
        fluid
        key={3}
        accesslevel={ALL_ACCESS}
        content={'QUICK SORT'}
        icon={'sort amount down'}
        as={Link}
        to={'/quickSort'}
      />,
    ].filter(({ props: { accesslevel } }) => accesslevel.includes(props.user.roleId));

    this.trailerButtons = [
      <Button
        fluid
        key={0}
        accesslevel={LOW_ACCESS}
        content={'ALL TRAILERS'}
        icon={'truck'}
        as={Link}
        to={'/trailerEditor'}
      />,
      <Button
        fluid
        key={1}
        accesslevel={LOW_ACCESS}
        content={'TRAILER CITY'}
        icon={'map signs'}
        as={Link}
        to={'/trailerCity'}
      />,
      <Button
        fluid
        key={2}
        accesslevel={[...LOW_ACCESS, ...DRIVER]}
        content={'TRAILER QUEUE'}
        icon={'shipping fast'}
        as={Link}
        to={'/trailerQueue'}
      />,
      <Button
        fluid
        key={3}
        accesslevel={LOW_ACCESS}
        content={'LOAD COMPLETION'}
        icon={'dolly'}
        as={Link}
        to={'/loadCompletion'}
      />,
    ].filter(({ props: { accesslevel } }) => accesslevel.includes(props.user.roleId));

    this.state = {
      visible: false,
      showPurchaseOrder: false,
      showTools: false,
      showTables: false,
      showTrailers: false,
    };
  }

  componentDidMount() {
    const { user: { roleId } } = this.props;

    if (DRIVER.includes(roleId)) this.props.history.push('/trailerQueue');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname)
      this.setState({ visible: false });
  }

  render() {
    const { visible, showTools, showTables, showTrailers, showPurchaseOrder } = this.state;
    const { user: { roleId } } = this.props;

    return (
      <Provider>
        <Sidebar
          onHide={() =>
            this.setState({
              visible: false,
              showTools: false,
              showTables: false,
              showPurchaseOrder: false,
              showTrailers: false,
            })
          }
          direction={'left'}
          visible={visible}
          animation={'overlay'}
          className={'bg-cargo-grey'}
        >
          <List className={'p-3'}>
            <div className="AppHeaderLogoContainer">
              <NavLink to={DRIVER.includes(roleId) ? '/trailerQueue' : '/'} className={'AppName'}>
                Cargo <small className={'fs-1-25'}>{`v${config.release}`}</small>
              </NavLink>
            </div>

            <Divider className={'w-100'} />

            <div>
              {DRIVER.includes(roleId) && (
                <Button
                  fluid
                  content={'TRAILER QUEUE'}
                  icon={'shipping fast'}
                  as={Link}
                  to={'/trailerQueue'}
                />
              )}
            </div>

            <div>
              {this.sidebarButtons.map((button, key) => (
                <div key={key}>
                  {button}
                  {key !== this.sidebarButtons.length - 1 && <Divider className={'w-100'} />}
                </div>
              ))}
            </div>

            {LOW_ACCESS.includes(roleId) && (
              <div>
                <Divider className={'w-100'} />
                <Button
                  fluid
                  active={false}
                  content={'TOOLS'}
                  icon={showTools ? 'caret up' : 'caret down'}
                  onClick={() => {
                    this.setState({ showTools: !showTools });
                  }}
                />
                <Transition.Group animation={'slide down'} duration={500}>
                  {showTools && (
                    <div className={'bg-white round p-3'}>
                      {this.toolButtons.map((button, key) => (
                        <div key={key}>
                          {button}
                          {key !== this.toolButtons.length - 1 && <Divider className={'w-100'} />}
                        </div>
                      ))}
                    </div>
                  )}
                </Transition.Group>
              </div>
            )}

            {LOW_ACCESS.includes(roleId) && (
              <div>
                <Divider className={'w-100'} />
                <Button
                  fluid
                  active={false}
                  content={'TRAILERS'}
                  icon={showTrailers ? 'caret up' : 'caret down'}
                  onClick={() => {
                    this.setState({ showTrailers: !showTrailers });
                  }}
                />
                <Transition.Group animation={'slide down'} duration={500}>
                  {showTrailers && (
                    <div className={'bg-white round p-3'}>
                      {this.trailerButtons.map((button, key) => (
                        <div key={key}>
                          {button}
                          {key !== this.trailerButtons.length - 1 && (
                            <Divider className={'w-100'} />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </Transition.Group>
              </div>
            )}

            {ADMINISTRATOR.includes(roleId) && (
              <div>
                <Divider className={'w-100'} />
                <Button
                  fluid
                  active={false}
                  content={'PURCHASE ORDERS'}
                  icon={showTables ? 'caret up' : 'caret down'}
                  onClick={() => {
                    this.setState({ showPurchaseOrder: !showPurchaseOrder });
                  }}
                />
                <Transition.Group animation={'slide down'} duration={500}>
                  {showPurchaseOrder && (
                    <div className={'bg-white round p-3'}>
                      {this.purchaseOrderButtons.map((button, key) => (
                        <div key={key}>
                          {button}
                          {key !== this.purchaseOrderButtons.length - 1 ? (
                            <Divider className={'w-100'} />
                          ) : null}
                        </div>
                      ))}
                    </div>
                  )}
                </Transition.Group>
                <Divider className={'w-100'} />
              </div>
            )}
            {[...ADMINISTRATOR, ...INVENTORY_SUPERVISOR].includes(this.props.user.roleId) && (
              <div>
                <Button
                  fluid
                  active={false}
                  content={'TABLES'}
                  icon={showTables ? 'caret up' : 'caret down'}
                  onClick={() => {
                    this.setState({ showTables: !showTables });
                  }}
                />
                <Transition.Group animation={'slide down'} duration={500}>
                  {showTables && (
                    <div className={'bg-white round p-3'}>
                      {this.tableButtons.map((button, key) => (
                        <div key={key}>
                          {button}
                          {key !== this.tableButtons.length - 1 && <Divider className={'w-100'} />}
                        </div>
                      ))}
                    </div>
                  )}
                </Transition.Group>
              </div>
            )}
          </List>
        </Sidebar>

        <Sidebar.Pusher>
          <div className="AppHeaderTopBanner">
            <div className="AppHeaderLogoContainer ml-1">
              <span>
                <Button
                  icon={'bars'}
                  onClick={() => {
                    this.setState({ visible: true });
                  }}
                />
                <NavLink
                  to={DRIVER.includes(roleId) ? '/trailerQueue' : '/'}
                  className="AppName ml-2 mr-2"
                >
                  Cargo <small className={'fs-1-25'}>{`v${config.release}`}</small>
                </NavLink>
              </span>
              <UserAuthStatus />
            </div>
          </div>
          <SessionTimer />
        </Sidebar.Pusher>

        <div className="Main">
          <Switch>
            <Home path="/" exact />
            <Firewall
              path={'/statCity'}
              render={props => <StatCity {...props} />}
              accessLevel={LOW_ACCESS}
            />
            <SessionWatcher path={'/sessions'} exact />
          </Switch>
          <Container className="pageBody">
            <Switch>
              <InventoryAudit path={'/inventoryAudit'} />
              <ItemDetail path={'/itemDetail/:itemId'} />
              <InventoryEditor path="/item/:itemId" />
              <Firewall
                path={'/auctionMaker'}
                render={props => <AuctionMakerPage {...props} />}
                accessLevel={[...ADMINISTRATOR, ...INVENTORY_SUPERVISOR]}
              />
              <Firewall
                path={'/auctions'}
                render={() => <AuctionIdentification />}
                accessLevel={[...ADMINISTRATOR, ...INVENTORY_SUPERVISOR]}
              />
              <Firewall
                path={'/purchaseOrders/:poId?'}
                render={props => <PurchaseOrders {...props} />}
                accessLevel={ADMINISTRATOR}
              />
              <Firewall
                path={'/purchaseOrderManagement'}
                render={props => <PurchaseOrderManagement {...props} />}
                accessLevel={ADMINISTRATOR}
              />
              <Firewall
                path={'/purchaseOrderReportGenerator'}
                render={props => <PurchaseOrderReportGenerator {...props} />}
                accessLevel={ADMINISTRATOR}
              />
              <Firewall
                path={'/quickSort'}
                render={props => <QuickSort {...props} />}
                accessLevel={LOW_ACCESS}
              />
              <Firewall
                path={'/warehouseHistory'}
                render={props => <Tools {...props} />}
                accessLevel={LOW_ACCESS}
              />
              <Firewall
                path={'/programCreator'}
                render={props => <ProgramCreator {...props} />}
                accessLevel={LOW_ACCESS}
              />
              <Firewall
                path={'/locationEditor'}
                render={props => <LocationTable {...props} />}
                accessLevel={ADMINISTRATOR}
              />
              <Firewall
                path={'/fraud'}
                render={props => <FraudTable {...props} />}
                accessLevel={ADMINISTRATOR}
              />
              <Firewall
                path={'/trailerEditor'}
                render={props => <TrailerTable {...props} />}
                accessLevel={LOW_ACCESS}
              />
              <Firewall
                path={'/loadEditor'}
                render={props => <LoadEditor {...props} />}
                accessLevel={LOW_ACCESS}
              />
              <Firewall
                path={'/trailerCity'}
                render={props => <TrailerCity {...props} />}
                accessLevel={LOW_ACCESS}
              />
              <Firewall
                path={'/trailerQueue'}
                render={props => <TrailerQueue {...props} />}
                accessLevel={[...LOW_ACCESS, ...DRIVER]}
              />
              <Firewall
                path={'/trailerHistory/:trailerId'}
                render={props => <TrailerHistory {...props} />}
                accessLevel={LOW_ACCESS}
              />
              <Firewall
                path={'/loadLogs/:loadId'}
                render={props => <LoadLogs {...props} />}
                accessLevel={LOW_ACCESS}
              />
              <Firewall
                path={'/loadCompletion'}
                render={props => <LoadCompletion {...props} />}
                accessLevel={LOW_ACCESS}
              />
              <ReleaseNotes path={'/releaseNotes'} />
              <Firewall
                path={'/lotBundler'}
                render={props => <LotBundler {...props} />}
                accessLevel={[
                  ...ADMINISTRATOR,
                  ...SUPERVISOR,
                  ...AUTH_USER,
                  ...INVENTORY_SUPERVISOR,
                ]}
              />
            </Switch>
          </Container>
          <UploadStatus />
        </div>
      </Provider>
    );
  }
}

Main.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default connect(({ auth, router }) => ({ ...auth, ...router }), {})(withRouter(Main));
