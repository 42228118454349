import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';

class FocusInput extends Component {
  inputRef = React.createRef();

  componentDidMount() {
    this.inputRef.current.focus();
  }

  render() {
    return <Input {...this.props} ref={this.inputRef} />;
  }
}

export default FocusInput;
