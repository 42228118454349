import { createActions, handleActions } from 'redux-actions';

const initialState = {
  loading: false,
  user: null,
  username: '',
  password: '',
  error: '',
};

const auth = ({ authService }) => {
  const simpleActions = createActions({
    setPending: null,
    setUser: null,
    setError: null,
    clearLoginForm: null,
    updateUsername: null,
    updatePassword: null,
  });

  const complexActions = {
    checkAuthStatus: () => dispatch => {
      // use firebase listener to determine whether or not user logged in
      authService.auth.onAuthStateChanged(user => {
        if (user) {
          authService
            .createUserObject(user.email)
            .then(ourUser => dispatch(simpleActions.setUser(ourUser)))
            .catch(error => {
              dispatch(simpleActions.setError(error));
              dispatch(simpleActions.setUser({}));
            });
        } else {
          dispatch(simpleActions.setUser({}));
        }
      });
    },
    login: () => (dispatch, getState) => {
      const { username, password } = getState().auth;

      dispatch(simpleActions.setPending(true));
      dispatch(simpleActions.clearLoginForm());

      return authService
        .login(username, password)
        .then(result => {
          dispatch(simpleActions.setUser(result));
          dispatch(simpleActions.setPending(false));
        })
        .catch(error => {
          console.error(error);
          dispatch(simpleActions.setError(error));
          dispatch(simpleActions.setPending(false));
        });
    },
    logout: () => dispatch => {
      authService.logout();
      dispatch(simpleActions.setUser(null));
    },
  };

  return {
    actions: { ...simpleActions, ...complexActions },
    reducer: handleActions(
      {
        clearLoginForm: state => ({
          ...state,
          username: '',
          password: '',
        }),
        updatePassword: (state, action) => ({
          ...state,
          password: action.payload,
        }),
        updateUsername: (state, action) => ({
          ...state,
          username: action.payload,
        }),
        setUser: (state, action) => ({
          ...state,
          user: action.payload,
        }),
        setError: (state, action) => ({
          ...state,
          error: action.payload.toString(),
        }),
        setPending: (state, action) => ({
          ...state,
          loading: !!action.payload,
        }),
      },
      initialState
    ),
  };
};

export default auth;
export { initialState };
