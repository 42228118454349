import React, { Component } from 'react';
import CargoApi from '../shared-api-adapters/cargo-api';
import { Table, Input, Button, Icon, Loader, Grid, Label, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { NotificationContext } from '../shared-components/notification-context';

class LoadCompletion extends Component {
  constructor(props) {
    super(props);

    this.cargoApiService = new CargoApi();

    this.state = {
      data: [],
      trailerLocations: [],
      currentLocation: null,
      initialProgress: [],
      loading: true,
    };
  }

  fetchData = async () => {
    const { currentLocation } = this.state;

    this.setState({ loading: true });

    const trailers = await this.cargoApiService.get(this.cargoApiService.routes.trailers);

    // trailerStatusId 1 === Full
    // trailerStatusId 3 === Full Same Day Unload
    await this.setState({
      data: trailers
        .filter(({ trailerSubLocation }) => trailerSubLocation !== null)
        .filter(
          ({
            trailerLoads: loadIds,
            trailerStatusId,
            trailerSubLocation: { trailerLocationId, isLoadingDock },
          }) =>
            trailerLocationId === currentLocation &&
            loadIds.length !== 0 &&
            (trailerStatusId === 1 || trailerStatusId === 3) &&
            isLoadingDock
        )
        .map(({ name, trailerLoads: loadIds, id: trailerId }) => ({
          trailerName: name,
          loadIds: loadIds.map(({ load: { id: loadId, percentComplete } }) => ({
            loadId,
            percentComplete,
          })),
          trailerId,
        })),
      loading: false,
    });

    this.setState({ initialProgress: this.state.data });
  };

  labelColor = percent => {
    if (Number(percent) === 0) return 'red';
    if (Number(percent) === 100) return 'green';
    return 'yellow';
  };

  componentDidMount = async () => {
    const { authUser: { locationId: userLocation } } = this.props;
    const trailerLocations = await this.cargoApiService.get(
      this.cargoApiService.routes.trailerLocations
    );
    const defaultLocation = trailerLocations.find(({ locationId }) => locationId === userLocation);

    this.setState({
      currentLocation: defaultLocation ? defaultLocation.id : 1,
      trailerLocations,
    });

    this.fetchData();
  };

  render() {
    const { data, trailerLocations, currentLocation, initialProgress, loading } = this.state;
    const { authUser: { id: userId } } = this.props;

    return (
      <NotificationContext.Consumer>
        {({ addNotifications }) => (
          <>
            <Loader active={loading} size={'big'} content={'Loading'} />
            <h1 className={'Cargo-header'}>Load Completion</h1>

            <Grid columns={2}>
              <Grid.Column width={2}>
                <Button.Group widths={trailerLocations.length || 1} vertical>
                  {trailerLocations.map(location => (
                    <Button
                      key={location.id}
                      className={'border-black'}
                      content={<div className={'flex jc-center'}>{location.name}</div>}
                      color={location.id === currentLocation ? 'blue' : null}
                      onClick={async () => {
                        await this.setState({ currentLocation: location.id });
                        this.fetchData();
                      }}
                      disabled={loading}
                    />
                  ))}
                </Button.Group>
              </Grid.Column>
              <Grid.Column width={14}>
                {!loading && (
                  <>
                    {data.length === 0 && (
                      <div className={'flex jc-center'}>
                        <Icon name={'exclamation'} color={'red'} />
                        There are currently no full trailers that are docked at this location
                      </div>
                    )}

                    {data.length !== 0 && (
                      <>
                        <Table celled textAlign={'center'}>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Trailer</Table.HeaderCell>
                              <Table.HeaderCell>Load ID</Table.HeaderCell>
                              <Table.HeaderCell>Progress</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {data.map(({ trailerName, loadIds }, key) => (
                              <Table.Row key={key}>
                                <Table.Cell>
                                  <strong>{trailerName}</strong>
                                </Table.Cell>
                                <Table.Cell verticalAlign={'middle'} width={2}>
                                  {loadIds.map(({ loadId }, loadKey) => (
                                    <div key={loadKey}>
                                      <strong>{loadId}</strong>
                                      {loadKey !== loadIds.length - 1 && <Divider />}
                                    </div>
                                  ))}
                                </Table.Cell>
                                <Table.Cell>
                                  <>
                                    {loadIds.map(
                                      ({ loadId, percentComplete: percentage }, percentKey) => (
                                        <div
                                          key={percentKey}
                                          className={
                                            percentKey !== loadIds.length - 1 ? 'mb-3' : null
                                          }
                                        >
                                          <Input
                                            fluid
                                            value={percentage}
                                            type={'range'}
                                            min={0}
                                            max={100}
                                            step={20}
                                            label={
                                              <Label
                                                color={this.labelColor(percentage)}
                                                size={'small'}
                                                content={`${percentage}%`}
                                              />
                                            }
                                            labelPosition={'right'}
                                            onChange={async (event, { value }) => {
                                              await this.setState({
                                                data: data.map(d => {
                                                  const newLoadList = d.loadIds.map(l => ({
                                                    ...l,
                                                    percentComplete:
                                                      l.loadId === loadId
                                                        ? value
                                                        : l.percentComplete,
                                                  }));

                                                  return newLoadList
                                                    ? { ...d, loadIds: newLoadList }
                                                    : { ...d };
                                                }),
                                              });
                                            }}
                                          />
                                        </div>
                                      )
                                    )}
                                  </>
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                        <div className={'flex jc-center mt-5'}>
                          <Button
                            disabled={loading}
                            content={'UPDATE PROGRESS'}
                            icon={'clipboard check'}
                            color={'green'}
                            size={'large'}
                            onClick={async () => {
                              await Promise.all(
                                data.map(async ({ loadIds, trailerId, trailerName }) => {
                                  await Promise.all(
                                    loadIds.map(async ({ loadId, percentComplete }) => {
                                      const loadResponse = await this.cargoApiService.put(
                                        `${this.cargoApiService.routes.loads}/${loadId}`,
                                        {
                                          percentComplete: Number(percentComplete),
                                        }
                                      );

                                      if (!loadResponse) {
                                        throw `Load ${loadId} in trailer ${trailerName} could not be updated. Please try again.`;
                                      }

                                      let loadLogResponse = true;
                                      let loadDestroyResponse = true;

                                      if (Number(percentComplete) === 100) {
                                        loadLogResponse = await this.cargoApiService.post(
                                          this.cargoApiService.routes.loadLogs,
                                          {
                                            loadId,
                                            userId,
                                            action: 'Load completed',
                                          }
                                        );
                                        if (!loadLogResponse) {
                                          throw `The log for load ${loadId} could not be updated. Please try again.`;
                                        }

                                        loadDestroyResponse = await this.cargoApiService.destroy(
                                          `${
                                            this.cargoApiService.routes.trailerLoads
                                          }/${trailerId}/loadId/${loadId}`
                                        );
                                        if (!loadLogResponse) {
                                          throw `Load ${loadId} could not be removed from trailer ${trailerName} upon completion. Please try again.`;
                                        }
                                      }

                                      // check if load has been modified to avoid redundant notifications
                                      const { loadIds: currentLoads } = initialProgress.find(
                                        ({ trailerId: modifiedTrailer }) =>
                                          trailerId === modifiedTrailer
                                      );
                                      const modified =
                                        currentLoads.find(
                                          ({ loadId: currentLoad }) => currentLoad === loadId
                                        ).percentComplete === Number(percentComplete);

                                      if (!modified) {
                                        addNotifications([
                                          {
                                            header: 'SUCCESS',
                                            content: `Load ${loadId} in trailer ${trailerName} updated`,
                                            timestamp: new Date().toLocaleTimeString(),
                                            color: 'green',
                                          },
                                        ]);
                                      }

                                      return percentComplete;
                                    })
                                  )
                                    .then(async values => {
                                      // trailerStatusId 4 === Empty
                                      // set trailer to empty if all loads are at 100%
                                      if (values.every(percentage => Number(percentage) === 100)) {
                                        const trailerStatusResponse = await this.cargoApiService.put(
                                          `${this.cargoApiService.routes.trailers}/${trailerId}`,
                                          {
                                            trailerStatusId: 4,
                                          }
                                        );

                                        if (trailerStatusResponse) {
                                          addNotifications([
                                            {
                                              header: 'SUCCESS',
                                              content: `Trailer ${trailerName} is now empty`,
                                              timestamp: new Date().toLocaleTimeString(),
                                              color: 'green',
                                            },
                                          ]);
                                        } else {
                                          addNotifications([
                                            {
                                              header: 'ERROR',
                                              content: `Trailer ${trailerName} could not be set to empty. Please try again.`,
                                              timestamp: new Date().toLocaleTimeString(),
                                              color: 'red',
                                            },
                                          ]);
                                        }
                                      }
                                    })
                                    .catch(err => {
                                      addNotifications([
                                        {
                                          header: 'ERROR',
                                          content: err,
                                          timestamp: new Date().toLocaleTimeString(),
                                          color: 'red',
                                        },
                                      ]);
                                      throw err;
                                    });
                                })
                              );

                              this.fetchData();
                            }}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </Grid.Column>
            </Grid>
          </>
        )}
      </NotificationContext.Consumer>
    );
  }
}

LoadCompletion.propTypes = {
  authUser: PropTypes.object.isRequired,
};

export default LoadCompletion;
