import React from 'react';
import PropTypes from 'prop-types';
import TextInput from './text-input';

const RetailPriceField = ({ value, ...rest }) => (
  <TextInput value={value && !isNaN(Number(value)) ? value : ''} {...rest} />
);

RetailPriceField.defaultProps = {
  field: 'retailPrice',
  icon: 'dollar',
  iconPosition: 'left',
  inline: true,
  invalid: null,
  label: 'Retail Price on Amazon.com',
  placeholder: '0.00',
  step: '.01',
  type: 'number',
};

RetailPriceField.propTypes = {
  field: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  inline: PropTypes.bool,
  invalid: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool.isRequired,
  step: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RetailPriceField;
