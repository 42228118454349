import React, { Component } from 'react';
import API from './api';
import CargoApi from '../shared-api-adapters/cargo-api';
import TableGovernor from './table-governor';
import TableEditor from './table-editor';
import { Button, Segment, Form, Header, Label, Modal, Icon, Checkbox } from 'semantic-ui-react';
import { NotificationContext } from '../shared-components/notification-context';
import { ADMINISTRATOR } from '../utilities/constants/access-levels';
import PropTypes from 'prop-types';

class LocationTable extends Component {
  constructor(props) {
    super(props);

    this.defaultRecord = {
      id: null,
      protected: 0,
      name: '',
      affiliateId: '',
      warehousePositionMin: '',
      warehousePositionMax: '',
      address: '',
      state: '',
      city: '',
      zipCode: '',
      loadId: '',
      curbsideAvailable: false,
    };

    this.state = {
      error: false,
      count: 0,
    };
  }

  render() {
    const { error, count } = this.state;
    const { authUser: { roleId } } = this.props;

    return (
      <NotificationContext.Consumer>
        {({ addNotifications }) => (
          <API.Crud route={'locations'} api={new CargoApi()}>
            {({ put, post, destroy }) => (
              <API.Query key={count} queries={[{ route: 'locations' }]} api={new CargoApi()}>
                {({ data: [locations] }) => (
                  <TableGovernor data={locations} defaultRecord={this.defaultRecord}>
                    {({
                      data,
                      currentRecord,
                      editing,
                      editRecord,
                      creating,
                      createRecord,
                      removing,
                      removeRecord,
                      handleInputChange,
                      handleToggle,
                      resetState,
                    }) => {
                      const {
                        id,
                        name,
                        affiliateId,
                        address,
                        city,
                        state,
                        zipCode,
                        warehousePositionMin,
                        warehousePositionMax,
                        curbsideAvailable,
                        loadId,
                        protected: protect,
                      } = currentRecord;

                      return (
                        <div className={'m-5'}>
                          <Segment raised>
                            <Label as="a" color="orange" ribbon>
                              Locations
                            </Label>
                            {!editing &&
                              !creating && (
                                <div className={'mt-3'}>
                                  <Button
                                    content={'CREATE'}
                                    icon={'plus'}
                                    color={'blue'}
                                    onClick={() => createRecord()}
                                  />
                                </div>
                              )}
                            {(editing || creating) && (
                              <Segment>
                                <Form>
                                  <Header size={'huge'} className={'flex jc-center m-3'}>
                                    {editing ? `Edit ${name}` : 'New Location'}
                                  </Header>
                                  <Form.Group>
                                    <Form.Input
                                      name={'name'}
                                      value={name || ''}
                                      label={'Name'}
                                      onChange={handleInputChange}
                                      error={name === '' && error}
                                      readOnly={protect}
                                    />
                                    <Form.Input
                                      name={'affiliateId'}
                                      value={affiliateId || ''}
                                      label={'Affiliate ID'}
                                      onChange={handleInputChange}
                                      error={affiliateId === '' && error}
                                      readOnly={protect}
                                    />
                                    <div className={'ml-5 mr-5 inline-flex fd-column'}>
                                      <strong className={'mb-2'}>Curbside Available</strong>
                                      <Checkbox
                                        name={'curbsideAvailable'}
                                        value={Number(curbsideAvailable)}
                                        checked={Boolean(curbsideAvailable)}
                                        onChange={handleToggle}
                                        readOnly={!ADMINISTRATOR.includes(roleId)}
                                      />
                                    </div>
                                  </Form.Group>
                                  <Form.Group>
                                    <Form.Input
                                      name={'address'}
                                      value={address || ''}
                                      label={'Address'}
                                      onChange={handleInputChange}
                                      readOnly={protect}
                                    />
                                    <Form.Input
                                      name={'city'}
                                      value={city || ''}
                                      label={'City'}
                                      onChange={handleInputChange}
                                      error={city === '' && error}
                                      readOnly={protect}
                                    />
                                    <Form.Input
                                      name={'state'}
                                      value={state || ''}
                                      label={'State'}
                                      onChange={handleInputChange}
                                      error={state === '' && error}
                                      readOnly={protect}
                                    />
                                    <Form.Input
                                      name={'zipCode'}
                                      value={zipCode || ''}
                                      label={'Zip Code'}
                                      onChange={handleInputChange}
                                      readOnly={protect}
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <Form.Input
                                      name={'warehousePositionMin'}
                                      value={
                                        warehousePositionMin || warehousePositionMin === 0
                                          ? warehousePositionMin
                                          : ''
                                      }
                                      label={'Warehouse Position Minimum'}
                                      onChange={handleInputChange}
                                      type={'number'}
                                    />
                                    <Form.Input
                                      name={'warehousePositionMax'}
                                      value={
                                        warehousePositionMax || warehousePositionMax === 0
                                          ? warehousePositionMax
                                          : ''
                                      }
                                      label={'Warehouse Position Maximum'}
                                      onChange={handleInputChange}
                                      type={'number'}
                                    />
                                  </Form.Group>
                                  <div>
                                    <Button
                                      type={'button'}
                                      icon={'minus'}
                                      color={'grey'}
                                      onClick={() => resetState()}
                                    />
                                    <Button
                                      type={'button'}
                                      color={'green'}
                                      onClick={async () => {
                                        if (
                                          name === '' ||
                                          affiliateId === '' ||
                                          city === '' ||
                                          state === ''
                                        ) {
                                          this.setState({ error: true }, () =>
                                            setTimeout(() => {
                                              this.setState({
                                                error: false,
                                              });
                                            }, 3000)
                                          );
                                        } else {
                                          let result;

                                          if (editing) {
                                            result = await put({
                                              id,
                                              warehousePositionMin:
                                                warehousePositionMin === ''
                                                  ? null
                                                  : Number(warehousePositionMin),
                                              warehousePositionMax:
                                                warehousePositionMax === ''
                                                  ? null
                                                  : Number(warehousePositionMax),
                                              curbsideAvailable: curbsideAvailable ? 1 : 0,
                                            });
                                          } else {
                                            result = await post({
                                              name,
                                              affiliateId: Number(affiliateId),
                                              warehousePositionMin:
                                                warehousePositionMin === ''
                                                  ? null
                                                  : Number(warehousePositionMin),
                                              warehousePositionMax:
                                                warehousePositionMax === ''
                                                  ? null
                                                  : Number(warehousePositionMax),
                                              curbsideAvailable: curbsideAvailable ? 1 : 0,
                                              loadId: loadId === '' ? null : Number(loadId),
                                              address: address === '' ? null : address,
                                              city,
                                              state,
                                              zipCode: zipCode === '' ? null : Number(zipCode),
                                            });
                                          }

                                          if (result) {
                                            addNotifications([
                                              {
                                                header: 'SUCCESS',
                                                content: `${
                                                  editing ? 'Updated' : 'Created'
                                                } ${name}`,
                                                timestamp: new Date().toLocaleTimeString(),
                                                color: 'green',
                                              },
                                            ]);

                                            this.setState(prevState => ({
                                              count: prevState.count + 1,
                                            }));
                                          } else {
                                            addNotifications([
                                              {
                                                header: 'ERROR',
                                                content: `Could not ${
                                                  editing ? 'update' : 'create'
                                                } location. Please try again.`,
                                                timestamp: new Date().toLocaleDateString(),
                                                color: 'red',
                                              },
                                            ]);
                                          }
                                        }
                                      }}
                                      content={editing ? 'UPDATE' : 'SAVE'}
                                    />
                                  </div>
                                </Form>
                              </Segment>
                            )}
                            <Modal
                              closeOnDimmerClick={false}
                              closeOnDocumentClick={false}
                              closeIcon
                              open={removing && !protect}
                              onClose={resetState}
                              centered={false}
                            >
                              <Header as={'h2'}>
                                <Icon name={'warning sign'} color={'yellow'} size={'large'} />
                                WARNING
                              </Header>
                              <Modal.Content>
                                <p>
                                  Are you sure you want to delete <strong>{name}</strong>?
                                </p>
                              </Modal.Content>
                              <Modal.Actions>
                                <Button
                                  positive
                                  icon={'check'}
                                  content={'YES, DELETE THIS LOCATION'}
                                  onClick={async () => {
                                    const result = await destroy({ id });

                                    if (result) {
                                      addNotifications([
                                        {
                                          header: 'SUCCESS',
                                          content: `Deleted ${name}`,
                                          timestamp: new Date().toLocaleTimeString(),
                                          color: 'green',
                                        },
                                      ]);

                                      this.setState(prevState => ({
                                        count: prevState.count + 1,
                                      }));
                                    } else {
                                      addNotifications([
                                        {
                                          header: 'ERROR',
                                          content: `Could not delete location ${name}. Please try again.`,
                                          timestamp: new Date().toLocaleDateString(),
                                          color: 'red',
                                        },
                                      ]);
                                    }
                                  }}
                                />
                                <Button
                                  negative
                                  icon={'x'}
                                  content={'NO, DO NOT DELETE THIS LOCATION'}
                                  onClick={resetState}
                                />
                              </Modal.Actions>
                            </Modal>
                            <TableEditor
                              records={data.map(location => ({
                                name: location.name,
                                affiliateID: location.affiliateId,
                                address: `${(location.address ? `${location.address} ` : '') +
                                  location.city}, ${location.state}${
                                  location.zipCode ? ` ${location.zipCode}` : ''
                                }`,
                                warehousePositions:
                                  (location.warehousePositionMin ||
                                    location.warehousePositionMin === 0) &&
                                  (location.warehousePositionMax ||
                                    location.warehousePositionMax === 0)
                                    ? `${location.warehousePositionMin} - ${
                                        location.warehousePositionMax
                                      }`
                                    : '',
                                curbsideAvailable: location.curbsideAvailable ? (
                                  <Icon name={'check'} />
                                ) : null,
                                record: location,
                              }))}
                              edit={location => {
                                window.scrollTo(0, 0);
                                editRecord(location);
                              }}
                              remove={location => {
                                if (location.protected) {
                                  addNotifications([
                                    {
                                      header: 'NOTE',
                                      content: `${
                                        location.name
                                      } is a protected location and cannot be deleted.`,
                                      timestamp: new Date().toLocaleDateString(),
                                      color: 'blue',
                                    },
                                  ]);
                                } else {
                                  removeRecord(location);
                                }
                              }}
                            />
                          </Segment>
                        </div>
                      );
                    }}
                  </TableGovernor>
                )}
              </API.Query>
            )}
          </API.Crud>
        )}
      </NotificationContext.Consumer>
    );
  }
}

LocationTable.propTypes = {
  authUser: PropTypes.object,
};

export default LocationTable;
