import React from 'react';
import PropTypes from 'prop-types';
import REGEX from '../utilities/constants/regex';

class UniversalIdField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uid: '',
      validate: false,
    };
  }

  handleUniversalIdChange = event => {
    const value = event.target.value;

    if (!value) return;

    clearTimeout(this.timeout);

    this.setState({ uid: value });
    this.timeout = setTimeout(() => {
      this.setState({ uid: value, validate: true });
    }, 1000);
  };

  isValid = () =>
    REGEX.LPN.test(this.state.uid) ||
    REGEX.UPC.test(this.state.uid) ||
    REGEX.ISBN.test(this.state.uid) ||
    REGEX.ASIN.test(this.state.uid) ||
    REGEX.X00.test(this.state.uid);

  componentDidUpdate(prevProps, prevState) {
    if (this.state.validate) {
      this.props.onValidScan(this.state.uid);
      this.setState({ uid: '', validate: false });
    }
  }

  render() {
    const { handleUniversalIdChange, state: { uid }, props: { children } } = this;

    return children({ handleUniversalIdChange, uid });
  }
}

UniversalIdField.defaultProps = {
  onValidScan: () => {},
};

UniversalIdField.propTypes = {
  children: PropTypes.func.isRequired,
  onValidScan: PropTypes.func.isRequired,
};

export default UniversalIdField;
