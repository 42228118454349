import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dimmer, Icon, Image, Loader, Modal, Segment } from 'semantic-ui-react';
import { startCase } from 'lodash/fp';

import './photo-editor.css';

const PhotoEditor = ({
  isFirst,
  isLast,
  isNew,
  onCancel,
  onClose,
  onNextClick,
  onPrevClick,
  onRotateLeft,
  onRotateRight,
  onUpload,
  subject,
}) => {
  if (!subject.id) {
    return null;
  }

  const { id, url, rotation, status } = subject;
  const dimmed = status !== 'ok';

  return (
    <Modal className="PhotoEditor t-2r" size="small" onClose={onClose} open>
      <Modal.Content>
        <Dimmer.Dimmable
          as={Segment}
          basic
          blurring
          className="PhotoEditor-loader"
          dimmed={dimmed}
          padded={false}
        >
          <Dimmer active={dimmed} inverted>
            <Loader inverted>{startCase(status)}</Loader>
          </Dimmer>
          <Image centered size="large" src={url} />
        </Dimmer.Dimmable>
        <Button
          circular
          className="PhotoEditor-closeButton"
          icon="remove"
          negative
          onClick={onClose}
          type="button"
        />
        {isFirst ? null : (
          <Button
            className="PhotoEditor-prevButton"
            floated="left"
            onClick={() => onPrevClick()}
            type="button"
          >
            Previous
          </Button>
        )}
        {isLast ? null : (
          <Button
            className="PhotoEditor-nextButton"
            floated="right"
            onClick={() => onNextClick()}
            type="button"
          >
            Next
          </Button>
        )}
      </Modal.Content>
      <Modal.Actions className="PhotoEditor-buttons">
        <Button.Group basic widths={4}>
          <Button
            circular
            color="green"
            disabled={(!rotation && !isNew) || status !== 'ok'}
            icon="cloud upload"
            onClick={() => onUpload(id)}
          />
          <Button circular disabled={status !== 'ok'} icon onClick={() => onRotateLeft(id)}>
            <Icon name="repeat" fitted flipped="horizontally" />
          </Button>
          <Button
            circular
            disabled={status !== 'ok'}
            icon="repeat"
            onClick={() => onRotateRight(id)}
          />
          <Button
            circular
            disabled={status !== 'ok'}
            icon="trash"
            negative
            onClick={() => onCancel(id)}
          />
        </Button.Group>
      </Modal.Actions>
    </Modal>
  );
};

PhotoEditor.defaultProps = {
  isFirst: false,
  isLast: false,
  isNew: false,
  subject: {},
};

PhotoEditor.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  isNew: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onRotateLeft: PropTypes.func.isRequired,
  onRotateRight: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  subject: PropTypes.shape({
    id: PropTypes.any,
    url: PropTypes.string,
    rotation: PropTypes.number,
    status: PropTypes.string,
  }),
};

export default PhotoEditor;
