import { isArray, isPlainObject } from 'lodash/fp';

const isEmpty = data =>
  (!data && data !== 0) ||
  (isArray(data) && data.length === 0) ||
  (isPlainObject(data) && Object.keys(data).length === 0);

const insertInto = (array, index, value) => {
  if (!isArray(array)) {
    return null;
  }

  const newArray = [...array];

  newArray[index] = value;

  return newArray;
};

const updateExistingProps = (base, objs, { baseField, srcField }) =>
  objs.reduce(
    (obj, newObj) =>
      Object.keys(obj).reduce((result, key) => {
        if (typeof newObj[key] === 'undefined') return result;
        const newValue = srcField ? newObj[key][srcField] : newObj[key];

        return {
          ...result,
          [key]: baseField ? { ...result[key], [baseField]: newValue } : newValue,
        };
      }, obj),
    base
  );

const sortObject = (
  obj,
  sortProp,
  direction = 'desc',
  convertToNumberFunction = null,
  keyStorageProp = null
) => {
  const keys = Object.keys(obj);

  keys.sort((a, b) => {
    /* Detect which value to use in comparison for object "a" and convert to number */
    const aV = sortProp === null ? a : obj[a][sortProp];
    const aN = convertToNumberFunction ? convertToNumberFunction(aV) : aV;

    /* Detect which value to use in comparison for object "b" and convert to number */
    const bV = sortProp === null ? b : obj[b][sortProp];
    const bN = convertToNumberFunction ? convertToNumberFunction(bV) : bV;

    return direction === 'desc' ? bN - aN : aN - bN;
  });

  return keys.reduce(
    (result, key) => [
      ...result,
      keyStorageProp ? { ...obj[key], [keyStorageProp]: key } : obj[key],
    ],
    []
  );
};

export { isEmpty, insertInto, sortObject, updateExistingProps };
