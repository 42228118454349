import React, { Component } from 'react';
import PropTypes, { bool } from 'prop-types';

class TableGovernor extends Component {
  constructor(props) {
    super(props);

    this.defaultRecord = props.defaultRecord;

    this.state = {
      data: props.data || [],
      currentRecord: {},
      editing: false,
      creating: false,
      removing: false,
      filters: {},
      search: false,
    };
  }

  resetState = () => {
    this.setState({
      editing: false,
      creating: false,
      removing: false,
      currentRecord: this.defaultRecord,
    });
  };

  editRecord = currentRecord => {
    this.setState({
      currentRecord,
      editing: true,
      creating: false,
      removing: false,
    });
  };

  createRecord = () => {
    this.setState({
      currentRecord: this.defaultRecord,
      creating: true,
      editing: false,
      removing: false,
    });
  };

  removeRecord = currentRecord => {
    this.setState({
      currentRecord,
      creating: false,
      editing: false,
      removing: true,
    });
  };

  handleInputChange = async (event, { name, value }) => {
    await this.setState(prevState => ({
      currentRecord: {
        ...prevState.currentRecord,
        [name]: value,
      },
    }));
  };

  handleToggle = async (event, { name, value }) => {
    await this.setState(prevState => ({
      currentRecord: {
        ...prevState.currentRecord,
        [name]: !value,
      },
    }));
  };

  filterData = async (event, { name, value: v, option = '' }) => {
    const { data } = this.state;

    let value = v;

    if (typeof v === 'boolean') {
      value = v ? 1 : 0;
    }

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: {
          value,
          option,
        },
      },
    });

    let currentFilters = this.state.filters;

    if (value === '') {
      const { [name]: removed, ...newFilters } = this.state.filters;

      currentFilters = newFilters;
      this.setState({ filters: newFilters });
    }

    let matched = 0;

    await this.setState({
      search: Object.keys(currentFilters).length !== 0,
      matches: data.filter(record => {
        matched = 0;
        Object.keys(currentFilters).forEach(filter => {
          switch (currentFilters[filter].option) {
            case 'includes':
              if (String(record[filter]).includes(String(currentFilters[filter].value))) matched++;
              break;
            case 'startsWith':
              if (String(record[filter]).startsWith(String(currentFilters[filter].value)))
                matched++;
              break;
            case 'falsyValue':
              if (currentFilters[filter].value) {
                if (record[filter]) matched++;
              } else {
                if (!record[filter]) matched++;
              }
              break;
            default:
              if (String(record[filter]) === String(currentFilters[filter].value)) matched++;
          }
        });
        return matched === Object.keys(currentFilters).length;
      }),
    });
  };

  createLoad = ({ id }) => {
    this.setState(prevState => ({
      currentRecord: {
        ...prevState.currentRecord,
        id,
      },
    }));
  };

  updateData = record => {
    this.setState(previousState => ({
      data: [...previousState.data.filter(({ id }) => id !== record.id), record].sort(
        ({ id: a }, { id: b }) => a - b
      ),
      editing: false,
    }));
    if (this.state.search) {
      this.filterData({}, {});
    }
  };

  render() {
    const { children } = this.props;

    return children({
      data: this.state.search ? this.state.matches : this.state.data,
      currentRecord: this.state.currentRecord,
      handleInputChange: this.handleInputChange,
      handleToggle: this.handleToggle,
      editing: this.state.editing,
      editRecord: this.editRecord,
      creating: this.state.creating,
      createRecord: this.createRecord,
      removing: this.state.removing,
      removeRecord: this.removeRecord,
      resetState: this.resetState,
      filterData: this.filterData,
      createLoad: this.createLoad,
      updateData: this.updateData,
    });
  }
}

TableGovernor.propTypes = {
  children: PropTypes.func,
  data: PropTypes.array.isRequired,
  defaultRecord: PropTypes.object.isRequired,
};

export default TableGovernor;
