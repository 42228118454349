import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class ItemTimesTable extends React.Component {
  renderRows = data =>
    data.map(({ inventoryNumber, loadId, programName, createdAt, processingTime, userId }) => (
      <Table.Row key={`${inventoryNumber}-${processingTime}`}>
        <Table.Cell>
          <a href={`/item/${inventoryNumber}/form/main`} target={'_blank'} rel="noreferrer">
            {inventoryNumber}
          </a>
        </Table.Cell>
        <Table.Cell>{`${this.userName(userId)}`}</Table.Cell>
        <Table.Cell>{programName}</Table.Cell>
        <Table.Cell>{loadId}</Table.Cell>
        <Table.Cell>{createdAt}</Table.Cell>
        <Table.Cell>{processingTime ? processingTime.toFixed(2) : '--'}</Table.Cell>
      </Table.Row>
    ));

  userName = userId => {
    const user = this.props.data.users.find(u => u.id === userId);

    return user ? `${user.lastName}, ${user.firstName}` : userId;
  };

  render() {
    const { data } = this.props;

    return (
      <Table basic={'very'} striped celled className={'stats-table'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Inventory Number</Table.HeaderCell>
            <Table.HeaderCell>User Id</Table.HeaderCell>
            <Table.HeaderCell>Program</Table.HeaderCell>
            <Table.HeaderCell>Load ID</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Item Time</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderRows(data.itemTimes)}</Table.Body>
      </Table>
    );
  }
}

ItemTimesTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ItemTimesTable;
