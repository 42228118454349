import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DescriptionHeader from './description-header';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

/**
 * Resolves ui based on usageType table
 * contents
 */
class ButtonCity extends Component {
  resolveData = data =>
    data.map((button, key) => (
      <Button
        as={Link}
        key={key}
        color={this.props.color}
        to={this.props.to}
        onClick={(event, inputData) => this.props.onClick(event, inputData, button.ratingWeight)}
        name={this.props.name}
        value={button.id}
        content={button.description}
        style={{
          opacity: [1, 0.5, 0.66, 0.82][key],
        }}
        className={'my-2'}
        fluid
      />
    ));

  render() {
    const { resolveData } = this;

    return (
      <>
        <DescriptionHeader header={this.props.header} />
        {this.props.children({ resolveData })}
      </>
    );
  }
}

ButtonCity.propTypes = {
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ButtonCity;
