import React from 'react';
import { Container, Subscribe } from 'unstated';
import PropTypes from 'prop-types';

import CargoApi from '../shared-api-adapters/cargo-api';

const apiService = new CargoApi();

class DataLoader extends Container {
  constructor(api = () => {}, id, route) {
    super();
    this.state = {
      response: null,
    };
    this.id = id;
    this.api = api;
    this.route = route;
  }

  fetch = async () => {
    const response = await this.api.get(this.route);

    await this.setState({
      response,
    });
  };
}

const conditionTypes = new DataLoader(apiService, 'conditionTypes', '/conditionTypes');
const assemblyTypes = new DataLoader(apiService, 'assemblyTypes', '/assemblyTypes');
const damageTypes = new DataLoader(apiService, 'damageTypes', '/damageTypes');
const missingPartsTypes = new DataLoader(apiService, 'missingPartsTypes', '/missingPartsTypes');
const functionalTypes = new DataLoader(apiService, 'functionalTypes', '/functionalTypes');
const packageTypes = new DataLoader(apiService, 'packageTypes', '/packageTypes');

const ConditionTypes = ({ children }) => (
  <Subscribe to={[conditionTypes]}>
    {container => {
      const { response } = container.state;

      if (!response) {
        container.fetch();
      }

      return children(container);
    }}
  </Subscribe>
);

ConditionTypes.propTypes = {
  children: PropTypes.func.isRequired,
};

const AssemblyTypes = ({ children }) => (
  <Subscribe to={[assemblyTypes]}>
    {container => {
      const { response } = container.state;

      if (!response) {
        container.fetch();
      }

      return children(container);
    }}
  </Subscribe>
);

AssemblyTypes.propTypes = {
  children: PropTypes.func.isRequired,
};

const DamageTypes = ({ children }) => (
  <Subscribe to={[damageTypes]}>
    {container => {
      const { response } = container.state;

      if (!response) {
        container.fetch();
      }

      return children(container);
    }}
  </Subscribe>
);

DamageTypes.propTypes = {
  children: PropTypes.func.isRequired,
};

const MissingPartsTypes = ({ children }) => (
  <Subscribe to={[missingPartsTypes]}>
    {container => {
      const { response } = container.state;

      if (!response) {
        container.fetch();
      }

      return children(container);
    }}
  </Subscribe>
);

MissingPartsTypes.propTypes = {
  children: PropTypes.func.isRequired,
};

const FunctionalTypes = ({ children }) => (
  <Subscribe to={[functionalTypes]}>
    {container => {
      const { response } = container.state;

      if (!response) {
        container.fetch();
      }

      return children(container);
    }}
  </Subscribe>
);

FunctionalTypes.propTypes = {
  children: PropTypes.func.isRequired,
};

const PackageTypes = ({ children }) => (
  <Subscribe to={[packageTypes]}>
    {container => {
      const { response } = container.state;

      if (!response) {
        container.fetch();
      }

      return children(container);
    }}
  </Subscribe>
);

PackageTypes.propTypes = {
  children: PropTypes.func.isRequired,
};

const InventoryEditorData = ({ children }) => (
  <PackageTypes>
    {pTypes =>
      pTypes.state.response && (
        <ConditionTypes>
          {cTypes =>
            cTypes.state.response && (
              <DamageTypes>
                {dTypes =>
                  dTypes.state.response && (
                    <FunctionalTypes>
                      {fTypes =>
                        fTypes.state.response && (
                          <AssemblyTypes>
                            {aTypes =>
                              aTypes.state.response && (
                                <MissingPartsTypes>
                                  {mPartsTypes =>
                                    mPartsTypes.state.response &&
                                    children([dTypes, cTypes, pTypes, mPartsTypes, aTypes, fTypes])
                                  }
                                </MissingPartsTypes>
                              )
                            }
                          </AssemblyTypes>
                        )
                      }
                    </FunctionalTypes>
                  )
                }
              </DamageTypes>
            )
          }
        </ConditionTypes>
      )
    }
  </PackageTypes>
);

InventoryEditorData.propTypes = {
  children: PropTypes.func.isRequired,
};

class Crud extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: null,
      // have to get results from AM to decide on money handling
      results: null,
      loading: false,
    };

    this._isMounted = true;
  }

  post = async data => {
    const { api, route } = this.props;

    this.setState({ loading: true });

    try {
      const results = await api.post(`${api.routes[route]}`, data);

      this.setState({
        success: true,
        loading: false,
        results,
      });

      this.resetSuccess(3000);

      return results;
    } catch (error) {
      this.setState({
        success: false,
      });

      this.resetSuccess(3000);

      return false;
    }
  };

  put = async data => {
    const { api, route, ignoreId } = this.props;

    try {
      const result = await api.put(
        `${api.routes[route]}${data.id && !ignoreId ? `/${data.id}` : ''}`,
        data
      );

      this.setState({
        success: true,
      });

      this.resetSuccess(3000);

      return result;
    } catch (error) {
      this.setState({
        success: false,
      });
      this.resetSuccess(3000);

      return false;
    }
  };

  destroy = async data => {
    const { api, route, ignoreId } = this.props;

    try {
      const result = await api.destroy(
        `${api.routes[route]}${data.id && !ignoreId ? `/${data.id}` : ''}`,
        data
      );

      this.setState({
        success: true,
      });

      this.resetSuccess(3000);

      return result;
    } catch (error) {
      this.setState({
        success: false,
      });
      this.resetSuccess(3000);

      return false;
    }
  };

  resetSuccess = time => {
    setTimeout(() => {
      if (this._isMounted) this.setState({ success: null });
    }, time);
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { post, put, destroy, state: { success, results, loading }, props: { children } } = this;

    return children({ post, put, destroy, success, results, loading });
  }
}

Crud.defaultProps = {
  ignoreId: false,
};

Crud.propTypes = {
  api: PropTypes.object,
  route: PropTypes.string.isRequired,
  children: PropTypes.func,
  ignoreId: PropTypes.bool,
};

class Query extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };

    this._isMounted = true;
  }

  withLocations = locations => user => ({
    ...user,
    location: locations.find(location => location.id === user.locationId),
  });

  withRole = ([role]) => user => ({ ...user, ...role });

  withApplications = applications => user => ({
    ...user,
    applicationIds: applications.map(app => app.applicationId),
  });

  getData = async () => {
    clearTimeout(this.timeout);
    this.setState({ loading: true, data: [] });

    this.timeout = setTimeout(async () => {
      const data = await Promise.all(
        this.props.queries.map(({ route, id, filter }) =>
          this.props.api.get(this.props.api.routes[route], id || this.props.id, filter)
        )
      );

      if (this._isMounted)
        this.setState({
          data: this.props.descending ? data.map(d => d.reverse()) : data,
          loading: false,
        });
    }, this.props.wait);
  };

  componentDidMount() {
    return this.getData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.queries) !== JSON.stringify(this.props.queries)) {
      return this.getData();
    }

    return null;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      withLocations,
      withRole,
      withApplications,
      state: { data, loading },
      props: { children, queries, control },
    } = this;

    if (!control) {
      if (loading) return 'Loading...';
      if (data.length === 0) return 'Unable to retrieve data';
      if (data.length !== queries.length) return 'Did not locate all required data';
    }

    return children({ withLocations, withRole, withApplications, data, loading, queries });
  }
}

Query.defaultProps = {
  id: null,
  wait: 0,
  control: false,
  descending: false,
};

Query.propTypes = {
  children: PropTypes.func.isRequired,
  queries: PropTypes.array.isRequired,
  api: PropTypes.object,
  id: PropTypes.string,
  wait: PropTypes.number,
  control: PropTypes.bool,
};

/**
 * This is to force a naming convention
 * when importing
 */
const API = {
  DamageTypes,
  PackageTypes,
  InventoryEditorData,
  Query,
  Crud,
};

export default API;
