import React from 'react';
import PropTypes from 'prop-types';
import { FAILED, SUCCESS } from './notifications';
import { NotificationContext } from './notification-context';

class Crud extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: null,
      results: null,
      loading: false,
    };

    this._isMounted = true;
  }

  post = async (
    data,
    onSuccessMessage = { ...SUCCESS, content: 'Successful request.' },
    onFailureMessage = { ...FAILED, content: 'Failed Request' }
  ) => {
    const { api, route, addNotifications } = this.props;

    this.setState({ loading: true, success: null });

    const results = await api.post(`${api.routes[route]}`, data);

    if (this._isMounted)
      this.setState({
        success: true,
        loading: false,
        results,
      });

    if (results) addNotifications([{ ...SUCCESS, onSuccessMessage }]);
    if (!results) {
      addNotifications([{ ...FAILED, ...{ content: api.message }, ...onFailureMessage }]);
    }

    this.resetSuccess(3000);

    return results;
  };

  batch = async requests => {
    const { api } = this.props;

    this.setState({ loading: true, success: null });

    try {
      const results = await Promise.all(
        requests.map(({ method, route, data }) => api[method](api.routes[route], data))
      );

      if (this._isMounted)
        this.setState({
          success: true,
          loading: false,
          results,
        });

      this.resetSuccess(3000);

      return results;
    } catch (error) {
      if (this._isMounted)
        this.setState({
          success: false,
          loading: false,
        });

      this.resetSuccess(3000);

      return error;
    }
  };

  put = async (
    data,
    onSuccessMessage = { ...SUCCESS, content: 'Successful update.' },
    onFailureMessage = { ...FAILED, content: 'Failed update.' }
  ) => {
    const { api, route, ignoreId, addNotifications } = this.props;

    const results = await api.put(
      `${api.routes[route]}${data.id && !ignoreId ? `/${data.id}` : ''}`,
      data
    );

    if (results) addNotifications([onSuccessMessage]);
    if (!results) addNotifications([onFailureMessage]);

    this.setState({
      success: true,
    });

    this.resetSuccess(3000);

    return results;
  };

  destroy = async data => {
    const { api, route, ignoreId } = this.props;

    try {
      await api.destroy(`${api.routes[route]}${data.id && !ignoreId ? `/${data.id}` : ''}`, data);

      this.setState({
        success: true,
      });

      this.resetSuccess(3000);
    } catch (error) {
      this.setState({
        success: false,
      });

      this.resetSuccess(3000);
    }
  };

  resetSuccess = time => {
    setTimeout(() => {
      if (this._isMounted) this.setState({ success: null });
    }, time);
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      post,
      put,
      destroy,
      batch,
      state: { success, results, loading },
      props: { children },
    } = this;

    return children({ post, put, destroy, success, results, loading, batch });
  }
}

Crud.defaultProps = {
  ignoreId: false,
};

Crud.propTypes = {
  api: PropTypes.object,
  route: PropTypes.string,
  children: PropTypes.func,
  ignoreId: PropTypes.bool,
};

class Query extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: new Array(props.queries.length).fill([]),
      loading: false,
      success: null,
    };

    this._isMounted = true;
  }

  withLocations = locations => user => ({
    ...user,
    location: locations.find(location => location.id === user.locationId),
  });

  withRole = ([role]) => user => ({ ...user, ...role });

  withApplications = applications => user => ({
    ...user,
    applicationIds: applications.map(app => app.applicationId),
  });

  getData = async () => {
    const { queries, api, id, descending, wait } = this.props;

    clearTimeout(this.timeout);
    this.setState({ loading: true });

    this.timeout = setTimeout(async () => {
      try {
        const data = await Promise.all(
          queries.map(({ route, id: queryId, filter }) =>
            api.get(api.routes[route], queryId || id, filter)
          )
        );

        if (this._isMounted)
          this.setState({
            data: descending ? data.map(d => d.reverse()) : data,
            loading: false,
          });
      } catch (error) {
        if (this._isMounted) this.setState({ loading: false });
      }
    }, wait);
  };

  componentDidMount() {
    const { queryOnMount } = this.props;

    if (queryOnMount) this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { externalLoadWatcher } = this.props;

    if (JSON.stringify(prevProps.queries) !== JSON.stringify(this.props.queries)) {
      return this.getData();
    }

    if (externalLoadWatcher && prevState.loading !== this.state.loading) {
      externalLoadWatcher(this.state.loading);
    }

    return null;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      withLocations,
      withRole,
      withApplications,
      getData,
      state: { data, loading },
      props: { children, queries, control },
    } = this;

    if (!control) {
      if (loading) return 'Loading...';
      if (data.length === 0) return 'Unable to retrieve data';
      if (data.length !== queries.length) return 'Did not locate all required data';
    }

    return children({ withLocations, withRole, withApplications, data, loading, queries, getData });
  }
}

Query.defaultProps = {
  id: null,
  wait: 0,
  control: false,
  descending: false,
  queryOnMount: true,
};

Query.propTypes = {
  children: PropTypes.func.isRequired,
  queries: PropTypes.array.isRequired,
  api: PropTypes.object,
  id: PropTypes.string,
  wait: PropTypes.number,
  control: PropTypes.bool,
  descending: PropTypes.bool,
  externalLoadWatcher: PropTypes.func,
  queryOnMount: PropTypes.bool,
};

// eslint-disable-next-line react/prop-types
const CrudNotifications = ({ children, ...rest }) => (
  <NotificationContext.Consumer>
    {({ addNotifications }) => (
      <Crud addNotifications={addNotifications} {...rest}>
        {crud => children({ ...crud })}
      </Crud>
    )}
  </NotificationContext.Consumer>
);

const API = {
  Crud: CrudNotifications,
  Query,
};

export default API;
