import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import LoginForm from '../login-form';

class Auth extends React.Component {
  componentDidMount() {
    this.props.checkAuthStatus();
  }

  render() {
    const {
      children,
      error,
      user,
      username,
      password,
      updateUsername,
      updatePassword,
      loading,
      login,
      authRequired,
    } = this.props;

    if (user === null) return null;

    let body = (
      <LoginForm
        onLogin={login}
        error={error}
        username={username}
        password={password}
        updatePassword={updatePassword}
        updateUsername={updateUsername}
      />
    );

    if (loading) {
      body = <Loader active={true} />;
    } else if (authRequired === false || Object.keys(user).length > 0) {
      body = children;
    }

    return <div className="Auth">{body}</div>;
  }
}

Auth.defaultProps = {
  authRequired: true,
  user: null,
};

Auth.propTypes = {
  authRequired: PropTypes.bool,
  checkAuthStatus: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  user: PropTypes.object,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  updateUsername: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
};

export default Auth;
