import { startCase } from 'lodash/fp';

const htmlReport = (data, appData) => {
  const find = field => data.find(d => d.label === field);
  const getAssembly = assembly =>
    assembly.content === 'Yes' ? `Assembly: <strong>requires assembly</strong>` : '';

  const getDamaged = damage =>
    damage.content === 'No' ? `Damage: <strong>No</strong>` : `Damage: <strong>Yes</strong>`;

  const getMissingParts = missingParts =>
    `Missing Parts: <strong>${missingParts.content} </strong>`;

  const getFunctional = functional => `Functional: <strong>${functional.content}</strong>`;

  const getPackaging = packaging => `Packaging: <strong>${packaging.content}</strong>`;

  const getLeadDescription = () =>
    appData.leadDescription ? `<h5>${appData.leadDescription}</h5>` : '';

  const getClerkNotes = () => (appData.notes ? `Clerk Comments: ${appData.notes}` : '');

  const getCondition = usage => `Condition: <strong>${usage.content}</strong>`;

  const getListItem = content => (content ? `<li>${content.toUpperCase()}</li>` : '');

  const getDescriptionList = () =>
    `<ul>\n${[
      getCondition(find('Condition') || {}),
      getDamaged(find('Damage') || {}),
      getFunctional(find('Functional') || {}),
      getMissingParts(find('Missing Parts') || {}),
      getAssembly(find('Assembly') || {}),
      getPackaging(find('Packaging') || {}),
    ]
      .map(x => getListItem(x))
      .filter(s => !!s)
      .join('\n')}\n</ul>`;

  const getInternalNotes = () => {
    const fields = {
      CLID: appData.userId || appData.sessionUserId,
      LOCID: appData.locationId,
      INV: appData.inventoryNumber,
    };

    const setKeys = Object.keys(fields).filter(f => !!fields[f]);

    if (setKeys.length === 0) {
      return '';
    }

    const text = `<strong>INTERNAL USE ONLY: </strong>${setKeys.reduce(
      (result, k) => `${result} ${k}: ${fields[k]}`,
      ''
    )}`;

    return `<p>\n<small>${text}</small>\n</p>`;
  };

  const getHTML = (options = {}) => {
    let html = '';

    if (!options.noTitle) {
      html += `${getLeadDescription()}\n`;
    }

    html += `${getClerkNotes()}\n`;

    html += `${getDescriptionList()}\n`;

    html += getInternalNotes();

    return html;
  };

  return {
    getHTML,
  };
};

const itemNotes = data => {
  const find = field => data.find(d => d.label === field);

  const getAssembly = assembly => (assembly.content === 'Yes' ? 'ASSEMBLY REQUIRED; ' : '');

  const getDamaged = damage => (damage.content === 'No' ? '' : `DAMAGE: Yes; `);

  const getMissingParts = missingParts =>
    missingParts.content === 'No' ? '' : `MISSING PARTS: ${startCase(missingParts.content)}; `;

  const getFunctional = functional => (functional.content === 'No' ? 'NOT FUNCTIONAL; ' : '');

  const getCondition = condition => `Condition: ${condition.content}; `;

  const getNotes = () =>
    [
      getCondition(find('Condition') || {}),
      getDamaged(find('Damage') || {}),
      getFunctional(find('Functional') || {}),
      getMissingParts(find('Missing Parts') || {}),
      getAssembly(find('Assembly') || {}),
    ]
      .filter(n => !!n)
      .join('')
      .toUpperCase();

  return { getNotes };
};

const getDescriptionHTML = (data, appData, options) => htmlReport(data, appData).getHTML(options);

const getItemNotes = data => itemNotes(data).getNotes();

const getExtraInfo = (data, contentData) => {
  const numericPrice = parseFloat(data.retailPrice);
  const result = [];

  if (!isNaN(numericPrice) && numericPrice > 0) {
    result.push(`<strong>Suggested Retail Price:</strong> $${data.retailPrice}`);
  }

  result.push(`<strong>Notes:</strong> ${getItemNotes(contentData)}`);

  if (data.asIs) {
    result.push(
      `<div style="border-style: solid; border-color: red; border-width: 2px; display: inline; padding-left: 10px; padding-right: 10px"><strong>**** SOLD AS IS ****</strong></div> <br />`
    );
  }

  return result.join('<br />');
};

const getReportData = data => ({
  'Lot Number': data.lotNumber || 'Not set',
  Title: data.leadDescription ? data.leadDescription.toUpperCase() : 'Not set',
  Category: '',
  Featured: '',
  'Quantity Available': 1,
  'Starting Bid': 5,
  'New Lot Number': '',
  Description: getDescriptionHTML(data),
  Seller: data.loadId || 'Not set',
  Taxable: '',
  'Tax Rate': '',
  'Tax Code': '',
  Premium: '',
  'Video Link': '',
  'Tax Premium Only': '',
  'Reserve Price': '',
  'Deposit Required': '',
  'Buy Now': '',
  'Display Consignor Name': '',
  'Internal ID': '',
  'Sequence Number': '',
  'No CC Payment': '',
  Notes: getItemNotes(data),
});

/**
 * The main item data comes back with numeric ids to describe
 * item status. That is not helpful for ui presentation.
 * So we relate these ids to their tables and get their descriptions
 *
 * @param apiData
 * @param appData
 * @returns {*[]}
 */
const buildContentBasedOnTableDescriptions = (apiData, appData) => {
  const relations = [
    { label: 'Condition', field: 'conditionTypeId', tableName: 'conditionTypes' },
    { label: 'Damage', field: 'damageTypeId', tableName: 'damageTypes' },
    { label: 'Missing Parts', field: 'missingPartsTypeId', tableName: 'missingPartsTypes' },
    { label: 'Functional', field: 'functionalTypeId', tableName: 'functionalTypes' },
    { label: 'Packaging', field: 'packageTypeId', tableName: 'packageTypes' },
    { label: 'Assembly', field: 'assemblyTypeId', tableName: 'assemblyTypes' },
  ];

  const getTableRecords = tableName => apiData.find(d => d.id === tableName).state.response;

  /**
   * generates content based on descriptions from api tables
   * @param records
   * @param field
   * @returns {*}
   */
  const contentGenerator1 = (records, field) =>
    records.find(record => record.id === appData[field]).description;

  const weightGenerator = (records, field) =>
    records.find(record => record.id === appData[field]).ratingWeight;

  /**
   * @param tableName - reference to
   * @param field - reference to redux store key
   * @returns {*}
   */
  const getContent = ({ tableName, field }) => contentGenerator1(getTableRecords(tableName), field);

  const getWeight = ({ tableName, field }) => weightGenerator(getTableRecords(tableName), field);

  return relations.reduce(
    (acc, relation) =>
      typeof appData[relation.field] !== 'undefined' && appData[relation.field] !== null
        ? [
            ...acc,
            {
              label: relation.label,
              content: getContent(relation),
              ratingWeight: getWeight(relation),
              field: relation.field,
            },
          ]
        : acc,
    []
  );
};

const getAuctionMethodData = (apiData, appData) => {
  const data = buildContentBasedOnTableDescriptions(apiData, appData);

  return (
    data.length === 6 && {
      description: getDescriptionHTML(data, appData),
      title: appData.leadDescription.toUpperCase(),
      extraInfo: getExtraInfo(appData, data),
    }
  );
};

export {
  getDescriptionHTML,
  getExtraInfo,
  getItemNotes,
  getReportData,
  buildContentBasedOnTableDescriptions,
  getAuctionMethodData,
};
