import React, { Component } from 'react';
import TableGovernor from './table-governor';
import API from './api';
import CargoApi from '../shared-api-adapters/cargo-api';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';
import {
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  Message,
  Popup,
  Segment,
} from 'semantic-ui-react';
import TableEditor from './table-editor';
import Paginater from '../shared-components/paginater';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import { NotificationContext } from '../shared-components/notification-context';
import PropTypes from 'prop-types';
import { ADMINISTRATOR, SUPERVISOR } from '../utilities/constants/access-levels';
import { DateInput } from 'semantic-ui-calendar-react';

class LoadEditor extends Component {
  constructor(props) {
    super(props);

    this.cargoApiService = new CargoApi();
    this.uploadInputRef = React.createRef();

    this.defaultRecord = {
      id: '',
      programId: '',
      isReportable: false,
      verified: false,
      vrid: '',
      taskId: '',
      manifestItemCount: '',
      cost: '',
      shippingCost: '',
      revShare: '',
      paymentDate: '',
      paymentRef: '',
      comments: '',
      vendorRef: '',
      isReceived: false,
      dateReceived: '',
      vendor: '',
    };

    this.consignor = {
      firstname: 'Cargo',
      lastname: 'Cargo',
      country_id: '226',
      address: 'Cargo',
      city: 'Las Vegas',
      state_id: '135',
      zip: 'Cargo',
      mailing_country_id: '226',
      mailing_address: 'Cargo',
      mailing_city: 'Las Vegas',
      mailing_state_id: '135',
      mailing_zip: 'Cargo',
      phone: 'Cargo',
      password: 'Cargo',
      affiliate_id: '1',
      commission_method: 'item_level',
      notification_options: 'email',
    };

    this.state = {
      error: false,
      manifestError: null,
      message: '',
      loadCounts: [],
      programs: [],
      creatingLoad: false,
      checked: false,
      numberOfRecords: 25,
      fetchingCosts: null,
      costs: { loadId: null, totalItems: null, totalSales: null, totalReceivables: null },
      count: 0,
    };
  }

  fetchData = async () => {
    const loadCounts = await this.cargoApiService.get(
      `${this.cargoApiService.routes.loads}/counts/itemCounts`
    );
    const programs = await this.cargoApiService.get(this.cargoApiService.routes.programs);

    this.setState({
      loadCounts,
      programs,
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  /**
   * Ensure file provided is a CSV
   * @return {boolean}
   */
  validateFileExtension = async currentRecord => {
    const { id, programId } = currentRecord;

    let message = '';
    let manifestError = null;

    // need load and program for file
    if (id !== '' && programId !== '') {
      const fileName = this.uploadInputRef.current.files[0].name;

      if (fileName.split('.').pop() === 'csv') {
        return true;
      }

      message = 'File provided must be a CSV. Please try again.';
      manifestError = true;
    }

    await this.setState({
      manifestError,
      message,
    });

    return false;
  };

  /**
   * Submit provided file to our API to be uploaded and parsed into database
   * @param event
   * @param currentRecord
   */
  handleUpload = async (event, currentRecord) => {
    const { id, programId } = currentRecord;

    event.persist();

    const data = new FormData();

    data.append('file', this.uploadInputRef.current.files[0]);
    data.append('filename', this.createFilename());
    data.append('loadId', id);
    data.append('programId', programId);

    return await this.cargoApiService
      .uploadCsv(data)
      .then(async response => {
        await this.setState({
          manifestError: false,
          message: this.cargoApiService.getMessage(),
        });
      })
      .catch(async error => {
        await this.setState({
          manifestError: true,
          message: 'Error uploading manifest. Please try again.',
        });
      });
  };

  createFilename = () => {
    const date = new Date();

    return `${date.toISOString().substring(0, 10)}-${date
      .toISOString()
      .substring(11, 19)}-manifest`;
  };

  render() {
    const {
      loadCounts,
      programs,
      error,
      creatingLoad,
      numberOfRecords,
      fetchingCosts,
      costs,
      count,
    } = this.state;
    const { authUser: { roleId, id: userId } } = this.props;

    return (
      <NotificationContext.Consumer>
        {({ addNotifications }) => (
          <API.Crud route={'consignors'} api={new AuctionMethodApi()}>
            {({ post: amPost }) => (
              <API.Crud route={'loads'} api={new CargoApi()}>
                {({ put, post }) => (
                  <API.Crud route={'loadLogs'} api={new CargoApi()}>
                    {({ post: logPost }) => (
                      <API.Query key={count} queries={[{ route: 'loads' }]} api={new CargoApi()}>
                        {({ data: loads }) => (
                          <TableGovernor data={loads[0] || []} defaultRecord={this.defaultRecord}>
                            {({
                              data,
                              currentRecord,
                              editing,
                              editRecord,
                              creating,
                              createRecord,
                              handleInputChange,
                              handleToggle,
                              resetState,
                              filterData,
                              createLoad,
                              updateData,
                            }) => {
                              const {
                                id,
                                programId,
                                isReportable,
                                vrid,
                                taskId,
                                verified,
                                manifest,
                                cost,
                                shippingCost,
                                revShare,
                                paymentDate,
                                paymentRef,
                                comments,
                                vendorRef,
                                isReceived,
                                dateReceived,
                                vendor,
                              } = currentRecord;

                              return (
                                <Paginater
                                  maxItemsPerPage={numberOfRecords}
                                  items={data}
                                  domain={data.length}
                                >
                                  {({ renderPaginater, itemsOnThisPage }) => (
                                    <>
                                      <div>{renderPaginater()}</div>
                                      <Header
                                        content={'LOADS'}
                                        size={'large'}
                                        icon={'clipboard list'}
                                      />
                                      <Form className={'mt-4'}>
                                        <Form.Group>
                                          <Form.Input
                                            name={'id'}
                                            placeholder={'Load ID'}
                                            type={'number'}
                                            option={'startsWith'}
                                            onChange={filterData}
                                          />
                                          <Form.Dropdown
                                            name={'programId'}
                                            placeholder={'Program'}
                                            search
                                            selection
                                            clearable
                                            onChange={filterData}
                                            options={programs.map(p => ({
                                              key: p.id,
                                              text: p.name,
                                              value: p.id,
                                            }))}
                                          />
                                          <Form.Input
                                            name={'vendor'}
                                            placeholder={'Vendor'}
                                            option={'startsWith'}
                                            onChange={filterData}
                                          />
                                          <Form.Input
                                            name={'vrid'}
                                            placeholder={'VRID'}
                                            option={'startsWith'}
                                            onChange={filterData}
                                          />
                                          <Form.Input
                                            name={'taskId'}
                                            placeholder={'Task ID'}
                                            option={'startsWith'}
                                            onChange={filterData}
                                          />
                                        </Form.Group>
                                        <Form.Group>
                                          <Form.Dropdown
                                            name={'verified'}
                                            placeholder={'Verified'}
                                            selection
                                            clearable
                                            onChange={filterData}
                                            options={[
                                              { key: 0, text: 'Verified', value: true },
                                              {
                                                key: 1,
                                                text: 'Not Verified',
                                                value: false,
                                              },
                                            ]}
                                          />
                                          <Form.Dropdown
                                            name={'isReportable'}
                                            placeholder={'Reportable'}
                                            selection
                                            clearable
                                            onChange={filterData}
                                            options={[
                                              { key: 0, text: 'Reportable', value: true },
                                              {
                                                key: 1,
                                                text: 'Not Reportable',
                                                value: false,
                                              },
                                            ]}
                                          />
                                          <Form.Dropdown
                                            name={'isReceived'}
                                            placeholder={'Received'}
                                            selection
                                            clearable
                                            onChange={filterData}
                                            option={'falsyValue'}
                                            options={[
                                              { key: 0, text: 'Received', value: true },
                                              {
                                                key: 1,
                                                text: 'Not Received',
                                                value: false,
                                              },
                                            ]}
                                          />
                                          <Form.Dropdown
                                            name={'paymentDate'}
                                            placeholder={'Payment Date'}
                                            selection
                                            clearable
                                            onChange={filterData}
                                            option={'startsWith'}
                                            options={[
                                              {
                                                key: 0,
                                                text: 'Payment Date',
                                                value: '20',
                                              },
                                              {
                                                key: 1,
                                                text: 'No Payment Date',
                                                value: 'null',
                                              },
                                            ]}
                                          />
                                        </Form.Group>
                                      </Form>
                                      <div className={'flex jc-between mt-3'}>
                                        <Button
                                          content={'NEW LOAD'}
                                          icon={'plus'}
                                          color={'blue'}
                                          onClick={() => createRecord()}
                                          disabled={editing || creating}
                                        />
                                        <Dropdown
                                          labeled
                                          value={numberOfRecords}
                                          selection
                                          onChange={(event, { value }) =>
                                            this.setState({ numberOfRecords: value })
                                          }
                                          options={[10, 25, 50].map((records, key) => ({
                                            key,
                                            text: records,
                                            value: records,
                                          }))}
                                        />
                                      </div>
                                      {(editing || creating) && (
                                        <Segment>
                                          <Form>
                                            <div className={'relative flex fd-row jc-center'}>
                                              <div className={'flex mt-3 mb-4'}>
                                                <Header size={'huge'}>
                                                  {editing ? `Load ${id}` : 'New Load'}
                                                </Header>
                                              </div>
                                              <div
                                                className={'absolute'}
                                                style={{ top: 0, right: 0 }}
                                              >
                                                <Button
                                                  icon={'x'}
                                                  color={'red'}
                                                  onClick={event => {
                                                    event.preventDefault();
                                                    if (this.uploadInputRef.current)
                                                      this.uploadInputRef.current.value = '';
                                                    resetState();
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <Form.Group>
                                              <Form.Input
                                                readOnly={editing}
                                                name={'id'}
                                                value={id}
                                                label={'Load ID'}
                                                onChange={handleInputChange}
                                                error={!Number(id) && error}
                                                type={'number'}
                                              />
                                              <Form.Dropdown
                                                label={'Program'}
                                                name={'programId'}
                                                value={programId}
                                                selection
                                                search
                                                searchInput={{ type: 'string' }}
                                                error={!Number(programId) && error}
                                                onChange={handleInputChange}
                                                options={programs.map(p => ({
                                                  key: p.id,
                                                  text: p.name,
                                                  value: p.id,
                                                }))}
                                                disabled={
                                                  Boolean(verified) &&
                                                  !ADMINISTRATOR.includes(roleId)
                                                }
                                              />
                                              <Grid columns={3} className={'ml-5'}>
                                                <Grid.Column>
                                                  <div className={'inline-flex fd-column'}>
                                                    <strong className={'mb-2'}>Verified</strong>
                                                    <Checkbox
                                                      name={'verified'}
                                                      value={Number(verified)}
                                                      checked={Boolean(verified)}
                                                      onChange={handleToggle}
                                                      readOnly={!ADMINISTRATOR.includes(roleId)}
                                                    />
                                                  </div>
                                                </Grid.Column>
                                                <Grid.Column>
                                                  <div className={'inline-flex fd-column'}>
                                                    <strong className={'mb-2'}>Reportable</strong>
                                                    <Checkbox
                                                      name={'isReportable'}
                                                      value={Number(isReportable)}
                                                      checked={Boolean(isReportable)}
                                                      onChange={handleToggle}
                                                      readOnly={!ADMINISTRATOR.includes(roleId)}
                                                    />
                                                  </div>
                                                </Grid.Column>
                                                <Grid.Column>
                                                  <div className={'inline-flex fd-column'}>
                                                    <strong className={'mb-2'}>Received</strong>
                                                    <Checkbox
                                                      name={'isReceived'}
                                                      value={Number(isReceived)}
                                                      checked={Boolean(isReceived)}
                                                      onChange={handleToggle}
                                                      readOnly={!ADMINISTRATOR.includes(roleId)}
                                                    />
                                                  </div>
                                                </Grid.Column>
                                              </Grid>
                                            </Form.Group>
                                            {[...SUPERVISOR, ...ADMINISTRATOR].includes(roleId) &&
                                              creating && (
                                                <Button
                                                  className={'mb-3'}
                                                  loading={creatingLoad}
                                                  compact
                                                  color={'blue'}
                                                  onClick={async () => {
                                                    await this.setState({ creatingLoad: true });

                                                    const amData = await amPost({
                                                      ...this.consignor,
                                                      ...{
                                                        email: `${String(
                                                          new Date().getTime()
                                                        )}@cargo.com`,
                                                      },
                                                    });

                                                    createLoad(amData);

                                                    await this.setState({
                                                      creatingLoad: false,
                                                    });
                                                  }}
                                                >
                                                  <Icon name={'plus'} />
                                                  Generate New Load ID
                                                </Button>
                                              )}
                                            <Form.Group>
                                              <Form.Input
                                                label={'VRID'}
                                                name={'vrid'}
                                                value={vrid || ''}
                                                onChange={handleInputChange}
                                              />
                                              <Form.Input
                                                label={'Task ID'}
                                                name={'taskId'}
                                                value={taskId || ''}
                                                onChange={handleInputChange}
                                              />
                                              <Form.Input
                                                label={'Vendor'}
                                                name={'vendor'}
                                                value={vendor || ''}
                                                onChange={handleInputChange}
                                              />
                                              <Form.Input
                                                label={'Vendor Reference'}
                                                name={'vendorRef'}
                                                value={vendorRef || ''}
                                                onChange={handleInputChange}
                                              />
                                            </Form.Group>
                                            <Form.Group>
                                              <Form.Input
                                                name={'cost'}
                                                value={cost || cost === 0 ? String(cost) : ''}
                                                label={'Cost'}
                                                onChange={handleInputChange}
                                                type={'number'}
                                              />
                                              <Form.Input
                                                name={'shippingCost'}
                                                value={
                                                  shippingCost || shippingCost === 0
                                                    ? String(shippingCost)
                                                    : ''
                                                }
                                                label={'Shipping Cost'}
                                                onChange={handleInputChange}
                                                type={'number'}
                                              />
                                              <Form.Input
                                                name={'revShare'}
                                                value={
                                                  revShare || revShare === 0 ? String(revShare) : ''
                                                }
                                                label={'Revenue Share'}
                                                onChange={handleInputChange}
                                                type={'number'}
                                              />
                                            </Form.Group>
                                            <Form.Group>
                                              <DateInput
                                                label={'Payment Date'}
                                                name={'paymentDate'}
                                                value={paymentDate ? paymentDate.slice(0, 10) : ''}
                                                clearable
                                                closable
                                                closeOnMouseLeave={false}
                                                onChange={handleInputChange}
                                                dateFormat={'YYYY-MM-DD'}
                                              />
                                              <Form.Input
                                                label={'Payment Reference'}
                                                name={'paymentRef'}
                                                value={paymentRef || ''}
                                                onChange={handleInputChange}
                                              />
                                              <DateInput
                                                label={'Date Received'}
                                                name={'dateReceived'}
                                                value={
                                                  dateReceived ? dateReceived.slice(0, 10) : ''
                                                }
                                                clearable
                                                closable
                                                closeOnMouseLeave={false}
                                                onChange={handleInputChange}
                                                dateFormat={'YYYY-MM-DD'}
                                              />
                                            </Form.Group>
                                            <Form.Group>
                                              <Form.Input
                                                label={'Manifest Items'}
                                                value={manifest}
                                                readOnly={true}
                                              />
                                              <div className={'ml-2 mr-2 inline-flex fd-column'}>
                                                <strong className={'mb-1'}>Manifest CSV</strong>
                                                <input ref={this.uploadInputRef} type={'file'} />
                                              </div>
                                              <Form.Input
                                                label={'Comments'}
                                                name={'comments'}
                                                value={comments || ''}
                                                onChange={handleInputChange}
                                              />
                                            </Form.Group>
                                            <Divider />
                                            <div className={'flex jc-end'}>
                                              <Button
                                                fluid
                                                color={'green'}
                                                icon={editing ? 'edit' : 'save'}
                                                content={editing ? 'UPDATE' : 'SAVE'}
                                                onClick={async event => {
                                                  let validated = true;
                                                  let result = null;
                                                  let content = '';

                                                  // Check file extension of manifest
                                                  if (
                                                    this.uploadInputRef.current.files.length > 0
                                                  ) {
                                                    validated = await this.validateFileExtension(
                                                      currentRecord
                                                    );
                                                  }

                                                  // Verify required fields
                                                  if (id === '' || programId === '' || !validated) {
                                                    if (!validated) {
                                                      addNotifications([
                                                        {
                                                          header: 'ERROR',
                                                          content: this.state.message,
                                                          timestamp: new Date().toLocaleTimeString(),
                                                          color: 'red',
                                                        },
                                                      ]);
                                                    }

                                                    this.setState(
                                                      {
                                                        error: true,
                                                      },
                                                      () => {
                                                        setTimeout(() => {
                                                          this.setState({
                                                            error: false,
                                                          });
                                                        }, 3000);
                                                      }
                                                    );

                                                    return;
                                                  }
                                                  const loadData = {
                                                    id: Number(id),
                                                    verified: Number(verified),
                                                    isReportable: Number(isReportable),
                                                    programId,
                                                    vrid: vrid || null,
                                                    taskId: taskId || null,
                                                    cost: cost || cost === 0 ? Number(cost) : null,
                                                    shippingCost:
                                                      shippingCost || shippingCost === 0
                                                        ? Number(shippingCost)
                                                        : null,
                                                    revShare:
                                                      revShare || revShare === 0
                                                        ? Number(revShare)
                                                        : null,
                                                    paymentDate: paymentDate || null,
                                                    paymentRef: paymentRef || null,
                                                    comments: comments || null,
                                                    vendorRef: vendorRef || null,
                                                    isReceived: Number(isReceived),
                                                    dateReceived: dateReceived || null,
                                                    vendor: vendor || null,
                                                  };

                                                  // Update load
                                                  if (editing) {
                                                    result = await put(loadData);
                                                    if (!result) {
                                                      addNotifications([
                                                        {
                                                          header: 'ERROR',
                                                          content: `Could not update load ${id}! Please try again.`,
                                                          timestamp: new Date().toLocaleTimeString(),
                                                          color: 'red',
                                                        },
                                                      ]);
                                                      return;
                                                    }
                                                    // Create load
                                                  } else {
                                                    if (
                                                      loads
                                                        .map(load => load.id)
                                                        .includes(Number(id))
                                                    ) {
                                                      addNotifications([
                                                        {
                                                          header: 'ERROR',
                                                          content: `Load ${id} already exists!`,
                                                          timestamp: new Date().toLocaleTimeString(),
                                                          color: 'red',
                                                        },
                                                      ]);
                                                      return;
                                                    }
                                                    result = await post(loadData);
                                                    if (!result) {
                                                      addNotifications([
                                                        {
                                                          header: 'ERROR',
                                                          content: `Could not create load ${id}! Please try again.`,
                                                          timestamp: new Date().toLocaleTimeString(),
                                                          color: 'red',
                                                        },
                                                      ]);
                                                      return;
                                                    }

                                                    await logPost({
                                                      userId,
                                                      loadId: Number(id),
                                                      action: 'Load created',
                                                    });
                                                  }

                                                  content = `${
                                                    editing ? 'Updated' : 'Created'
                                                  } load ${id}!`;

                                                  // Upload manifest CSV
                                                  if (
                                                    this.uploadInputRef.current.files.length > 0 &&
                                                    validated
                                                  ) {
                                                    await this.handleUpload(event, currentRecord);

                                                    addNotifications([
                                                      {
                                                        header: this.state.manifestError
                                                          ? 'ERROR'
                                                          : 'SUCCESS',
                                                        content: this.state.message,
                                                        timestamp: new Date().toLocaleTimeString(),
                                                        color: this.state.manifestError
                                                          ? 'red'
                                                          : 'green',
                                                      },
                                                    ]);
                                                  }

                                                  addNotifications([
                                                    {
                                                      header: 'SUCCESS',
                                                      content,
                                                      timestamp: new Date().toLocaleTimeString(),
                                                      color: 'green',
                                                    },
                                                  ]);

                                                  if (editing) {
                                                    updateData({
                                                      ...loadData,
                                                      program: programs.find(
                                                        ({ id: pId }) => pId === loadData.programId
                                                      ),
                                                    });
                                                  } else {
                                                    this.setState(prevState => ({
                                                      count: prevState.count + 1,
                                                    }));

                                                    await this.fetchData();
                                                  }
                                                }}
                                              />
                                            </div>
                                          </Form>
                                        </Segment>
                                      )}
                                      {data.length === 0 ? (
                                        <Message className={'flex jc-center'} negative>
                                          <Icon name={'exclamation circle'} />
                                          No results found!
                                        </Message>
                                      ) : (
                                        <TableEditor
                                          records={itemsOnThisPage.map(record => {
                                            const [{ itemCount } = {}] = loadCounts.filter(
                                              ({ loadId }) => loadId === record.id
                                            );

                                            const totalCost =
                                              !record.cost &&
                                              record.cost !== 0 &&
                                              !record.shippingCost &&
                                              record.shippingCost !== 0 &&
                                              !record.revShare &&
                                              record.revShare !== 0
                                                ? null
                                                : record.cost +
                                                  record.shippingCost +
                                                  record.revShare;

                                            return {
                                              loadID: (
                                                <a href={`/loadLogs/${record.id}`}>{record.id}</a>
                                              ),
                                              received: record.isReceived ? (
                                                <Icon name={'check'} />
                                              ) : null,
                                              program: record.program ? record.program.name : '',
                                              manifest:
                                                itemCount || itemCount === 0 ? itemCount : null,
                                              comments: record.comments,
                                              paymentReference: record.paymentRef,
                                              cost:
                                                totalCost || totalCost === 0
                                                  ? `$${totalCost}`
                                                  : null,
                                              costBreakdown: (
                                                <Popup
                                                  trigger={
                                                    fetchingCosts === record.id ? (
                                                      <Icon loading name={'spinner'} />
                                                    ) : (
                                                      <Icon
                                                        name={'info circle'}
                                                        className={'pointer'}
                                                      />
                                                    )
                                                  }
                                                  position={'bottom center'}
                                                  size={'small'}
                                                  wide={'very'}
                                                  on={'click'}
                                                  open={costs.loadId === record.id}
                                                  onClose={() =>
                                                    this.setState({
                                                      fetchingCosts: null,
                                                      costs: {
                                                        loadId: null,
                                                        totalItems: null,
                                                        totalSales: null,
                                                        totalReceivables: null,
                                                      },
                                                    })
                                                  }
                                                  onOpen={async () => {
                                                    this.setState({ fetchingCosts: record.id });

                                                    const results = await this.cargoApiService.get(
                                                      '/auctionMethod/loadCosts',
                                                      record.id
                                                    );

                                                    const [
                                                      {
                                                        totalItems,
                                                        totalSales,
                                                        totalReceivables,
                                                      } = {},
                                                    ] =
                                                      results || [];

                                                    this.setState({
                                                      fetchingCosts: null,
                                                      costs: {
                                                        loadId: record.id,
                                                        totalItems,
                                                        totalSales,
                                                        totalReceivables,
                                                      },
                                                    });
                                                  }}
                                                >
                                                  <Header
                                                    content={`Costs for Load ${record.id}:`}
                                                  />
                                                  <Divider />
                                                  <Label.Group tag className={'flex fd-column'}>
                                                    <Label className={'flex jc-between'}>
                                                      Cost:{' '}
                                                      <Label.Detail>
                                                        {record.cost || record.cost === 0
                                                          ? `$${record.cost}`
                                                          : 'N/A'}
                                                      </Label.Detail>
                                                    </Label>
                                                    <Label className={'flex jc-between'}>
                                                      Shipping Cost:{' '}
                                                      <Label.Detail>
                                                        {record.shippingCost ||
                                                        record.shippingCost === 0
                                                          ? `$${record.shippingCost}`
                                                          : 'N/A'}
                                                      </Label.Detail>
                                                    </Label>
                                                    <Label className={'flex jc-between'}>
                                                      Revenue Share:{' '}
                                                      <Label.Detail>
                                                        {record.revShare || record.revShare === 0
                                                          ? `$${record.revShare}`
                                                          : 'N/A'}
                                                      </Label.Detail>
                                                    </Label>
                                                  </Label.Group>
                                                  <Divider />
                                                  <Label.Group tag className={'flex fd-column'}>
                                                    <Label className={'flex jc-between'}>
                                                      Total Items:{' '}
                                                      <Label.Detail>
                                                        {costs.totalItems || costs.totalItems === 0
                                                          ? costs.totalItems
                                                          : 'Not found'}
                                                      </Label.Detail>
                                                    </Label>
                                                    <Label className={'flex jc-between'}>
                                                      Total Sales:{' '}
                                                      <Label.Detail>
                                                        {costs.totalSales || costs.totalSales === 0
                                                          ? `$${costs.totalSales}`
                                                          : 'Not found'}
                                                      </Label.Detail>
                                                    </Label>
                                                    <Label className={'flex jc-between'}>
                                                      Total Receivable:{' '}
                                                      <Label.Detail>
                                                        {costs.totalReceivables ||
                                                        costs.totalReceivables === 0
                                                          ? `$${costs.totalReceivables}`
                                                          : 'Not found'}
                                                      </Label.Detail>
                                                    </Label>
                                                  </Label.Group>
                                                </Popup>
                                              ),
                                              logistics: (
                                                <Popup
                                                  style={{
                                                    padding: 0,
                                                  }}
                                                  trigger={
                                                    <Icon
                                                      name={'clipboard list'}
                                                      className={'pointer'}
                                                    />
                                                  }
                                                  position={'bottom center'}
                                                  size={'small'}
                                                  wide={'very'}
                                                  on={'click'}
                                                >
                                                  <TableEditor
                                                    records={[
                                                      {
                                                        VRID: record.vrid,
                                                        taskID: record.taskId,
                                                        vendor: record.vendor,
                                                        vendorReference: record.vendorRef,
                                                      },
                                                    ]}
                                                  />
                                                </Popup>
                                              ),
                                              dates: (
                                                <Popup
                                                  style={{
                                                    padding: 0,
                                                  }}
                                                  trigger={
                                                    <Icon name={'calendar'} className={'pointer'} />
                                                  }
                                                  position={'bottom center'}
                                                  size={'small'}
                                                  wide={'very'}
                                                  on={'click'}
                                                >
                                                  <TableEditor
                                                    records={[
                                                      {
                                                        paymentDate: record.paymentDate
                                                          ? record.paymentDate.slice(0, 10)
                                                          : '',
                                                        dateReceived: record.dateReceived
                                                          ? record.dateReceived.slice(0, 10)
                                                          : '',
                                                      },
                                                    ]}
                                                  />
                                                </Popup>
                                              ),
                                              record,
                                            };
                                          })}
                                          edit={rec => {
                                            window.scrollTo(0, 0);
                                            if (this.uploadInputRef.current)
                                              this.uploadInputRef.current.value = '';
                                            editRecord(rec);
                                          }}
                                          compact={true}
                                        />
                                      )}
                                    </>
                                  )}
                                </Paginater>
                              );
                            }}
                          </TableGovernor>
                        )}
                      </API.Query>
                    )}
                  </API.Crud>
                )}
              </API.Crud>
            )}
          </API.Crud>
        )}
      </NotificationContext.Consumer>
    );
  }
}

LoadEditor.propTypes = {
  authUser: PropTypes.object,
};

export default LoadEditor;
