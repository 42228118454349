import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FocusChain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refs: [...new Array(props.total)].map(React.createRef),
      currentIndex: 0,
    };
  }

  focusRef = index => {
    this.state.refs[index].current.focus();
  };

  next = () => this.setState(prevState => ({ currentIndex: prevState.currentIndex + 1 }));

  render() {
    const { props: { children }, state: { refs }, next, focusRef } = this;

    return children({ refs, next, focusRef });
  }
}

FocusChain.propTypes = {
  total: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired,
};

export default FocusChain;
