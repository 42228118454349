import { isEqual } from 'lodash/fp';

/**
 * Lightning submission acts as our way of knowing when an item is in 'perfect'
 * condition. Do to changes in clerk workflows its purpose has been distorted.
 * These functions will help resolve perfect item status
 */

const getPerfectItemData = ({
  usageTypeId,
  damageTypeId,
  functional,
  missingParts,
  packageTypeId,
}) => ({ usageTypeId, damageTypeId, functional, missingParts, packageTypeId });

const perfectItem = {
  usageTypeId: 1,
  damageTypeId: 1,
  functional: 1,
  missingParts: { none: 1 },
  packageTypeId: 1,
};

const resolveLightningSubmission = data => {
  if (data.lightningSubmission === 0) return data;

  if (isEqual(getPerfectItemData(data), perfectItem)) {
    return { ...data, lightningSubmission: 1 };
  }

  return { ...data, lightningSubmission: 0 };
};

const resolveLeadDescription = data => ({
  ...data,
  leadDescription: data.leadDescription.replace(/[^\w\s]/gi, '').trim(),
});

export { resolveLightningSubmission, resolveLeadDescription };
