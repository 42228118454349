import React from 'react';
import PropTypes from 'prop-types';

class MultiToggle extends React.Component {
  state = {
    indexes: this.props.indexes || [],
  };

  toggle = index =>
    this.setState(
      prevState =>
        prevState.indexes.includes(index)
          ? { indexes: prevState.indexes.filter(ix => ix !== index) }
          : { indexes: [...prevState.indexes, index] }
    );

  addAll = indexes => {
    this.setState({ indexes });
  };

  removeAll = () => {
    this.setState({ indexes: [] });
  };

  componentDidUpdate(prevProps, prevState) {
    const { didUpdate } = this.props;

    if (prevState.indexes.length !== this.state.indexes.length) {
      didUpdate(this.state.indexes);
    }
  }

  render() {
    const { toggle, addAll, removeAll, state: { indexes }, props: { className } } = this;

    return (
      <div className={className}>{this.props.children({ addAll, removeAll, indexes, toggle })}</div>
    );
  }
}

MultiToggle.defaultProps = {
  didUpdate: () => {},
};

MultiToggle.propTypes = {
  children: PropTypes.func,
  didUpdate: PropTypes.func,
  className: PropTypes.string,
  indexes: PropTypes.array,
};

export default MultiToggle;
