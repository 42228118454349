import Raven from 'raven-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import getStore from './store';
import history from './history';
import App from './components/app';

import { NotificationsProvider } from './shared-components/notification-context';

import 'semantic-ui-css/semantic.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import './shared-css/main.css';


/*
  This is a hack to help fix an issue where extensions that manipulate the dom,
  complete break react. This code supposedly causes performance problems. So at some
  point in the future it should be reviewed.
  https://github.com/facebook/react/issues/11538
 */
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;

  Node.prototype.removeChild = function(child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;

  Node.prototype.insertBefore = function(newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this
        );
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  };
}

Raven.context(() => {
  ReactDOM.render(
    <Provider store={getStore()}>
      <ConnectedRouter history={history}>
        <NotificationsProvider>
          <App />
        </NotificationsProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );
});

if (navigator.serviceWorker) {
  // Uninstall all previously installed service workers
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration
        .unregister()
        .then(() => {
          console.log('Service worker removal succeeded:', registration.scope);
        })
        .catch((error) => {
          console.error('Service worker removal failed:', registration.scope);
          console.error(error);
        });
    });
  });
}

