import React from 'react';
import { Form } from 'semantic-ui-react';
import Base, { extractBaseProps } from './base';

const TextInput = props => {
  const { baseProps, ...other } = extractBaseProps(props, {
    className: 'TextInput',
  });

  return (
    <Base {...baseProps}>
      <Form.Input name={baseProps.field} {...other} />
    </Base>
  );
};

TextInput.propTypes = { field: Base.propTypes.field };

export default TextInput;
