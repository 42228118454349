import React, { Component } from 'react';
import Access from './access';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import actions from '../store/actions';

class Firewall extends Component {
  render() {
    const { auth, ...rest } = this.props;

    if (!auth) {
      return null;
    }

    return <Access {...rest} authUser={auth.user} />;
  }
}

Firewall.propTypes = {
  auth: PropTypes.object,
};

export default connect(
  ({ inventoryEditor, auth }) => ({
    ...inventoryEditor,
    auth,
  }),
  { ...actions.inventoryEditor }
)(Firewall);
