import React from 'react';
import PropTypes from 'prop-types';

class FocusableInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.focusWhen !== this.props.focusWhen && prevState.touched === false) {
      this.props.focus();
      this.setState({ touched: true });
    }
  }

  componentDidMount() {
    if (this.props.focusOnMount) this.props.focus();
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { inputRef, focus, focusOnMount, focusWhen, ...rest } = this.props;

    return <input ref={inputRef} {...rest} />;
  }
}

FocusableInput.propTypes = {
  focusOnMount: PropTypes.bool,
  focusWhen: PropTypes.bool,
  focus: PropTypes.func.isRequired,
  inputRef: PropTypes.any.isRequired,
};

export default FocusableInput;
