import React, { Component } from 'react';
import { NotificationContext } from '../shared-components/notification-context';
import API from './api';
import CargoApi from '../shared-api-adapters/cargo-api';
import TableEditor from './table-editor';
import {
  Button,
  Checkbox,
  Form,
  Header,
  Icon,
  Label,
  Message,
  // Modal,
  Popup,
  Segment,
} from 'semantic-ui-react';
import { asMoney } from '../shared-components/helpers';
import TableGovernor from './table-governor';
import { DateInput } from 'semantic-ui-calendar-react';
import { Link } from 'react-router-dom';

class TrailerTable extends Component {
  constructor(props) {
    super(props);

    this.defaultRecord = {
      id: null,
      name: '',
      trailerStatusId: '',
      physicalLocationId: '',
      owner: '',
      notes: [],
      loadList: '',
      trailerSubLocationId: '',
      monthlyCost: '',
      leaseExpiration: '',
      active: 1,
      ownerReturnDate: '',
      ownerPickupDate: '',
      inspectionDate: '',
      enRoute: 0,
      inQueue: 0,
    };

    this.state = {
      error: false,
      count: 0,
      currLocation: '',
      subLocation: '',
      showPopup: false,
    };
  }

  render() {
    const { error, count, currLocation, subLocation, showPopup } = this.state;

    return (
      <NotificationContext.Consumer key={count}>
        {({ addNotifications }) => (
          <API.Crud route={'trailerLoads'} api={new CargoApi()}>
            {({ post: postTrailerLoads, destroy: destroyTrailerLoads }) => (
              <API.Crud route={'trailerQueue'} api={new CargoApi()}>
                {({ destroy: destroyTrailerQueue }) => (
                  <API.Crud route={'trailers'} api={new CargoApi()}>
                    {({ put: putTrailer, post: postTrailer /* , destroy: destroyTrailer*/ }) => (
                      <API.Query
                        queries={[
                          { route: 'trailers' },
                          { route: 'trailerLocations' },
                          { route: 'trailerQueue' },
                          { route: 'trailerSubLocations' },
                          { route: 'trailerStatus' },
                          { route: 'loads' },
                        ]}
                        api={new CargoApi()}
                      >
                        {({
                          data: [
                            trailers,
                            trailerLocations,
                            trailerQueue,
                            trailerSubLocations,
                            trailerStatuses,
                            loads,
                          ],
                        }) => (
                          <>
                            <TableGovernor
                              data={trailers.map(t => {
                                const { trailerLoads } = t;
                                const trailer = trailerQueue.find(({ id: qId }) => t.id === qId);
                                const enRoute = trailer && trailer.enRoute ? 1 : 0;
                                const inQueue = trailerQueue.find(({ id: qId }) => t.id === qId)
                                  ? 1
                                  : 0;
                                const loadList = trailerLoads
                                  .map(({ loadId: load }) => load)
                                  .join(', ');

                                return {
                                  ...t,
                                  enRoute,
                                  inQueue,
                                  loadList,
                                };
                              })}
                              defaultRecord={this.defaultRecord}
                            >
                              {({
                                data,
                                currentRecord,
                                editing,
                                editRecord,
                                creating,
                                createRecord,
                                // removing,
                                // removeRecord,
                                handleInputChange,
                                handleToggle,
                                resetState,
                                filterData,
                              }) => {
                                const {
                                  id: trailerId,
                                  name,
                                  trailerStatusId,
                                  physicalLocationId,
                                  owner,
                                  notes,
                                  trailerLoads,
                                  loadList,
                                  trailerSubLocationId,
                                  monthlyCost,
                                  leaseExpiration,
                                  active,
                                  ownerReturnDate,
                                  ownerPickupDate,
                                  inspectionDate,
                                  enRoute,
                                  inQueue,
                                } = currentRecord;

                                return (
                                  <>
                                    <div className={'flex jc-center'}>
                                      <Button.Group>
                                        <Button
                                          className={'border-black'}
                                          color={'blue'}
                                          content={'ALL TRAILERS'}
                                        />
                                        <Button
                                          className={'border-black'}
                                          content={'TRAILER CITY'}
                                          as={Link}
                                          to={'/trailerCity'}
                                        />
                                        <Button
                                          className={'border-black'}
                                          content={'TRAILER QUEUE'}
                                          as={Link}
                                          to={'/trailerQueue'}
                                        />
                                      </Button.Group>
                                    </div>

                                    <h1 className={'Cargo-header'}>Trailers</h1>

                                    <Form className={'mt-4'}>
                                      <Form.Group>
                                        <Form.Input
                                          name={'name'}
                                          placeholder={'Trailer Name'}
                                          type={'string'}
                                          option={'startsWith'}
                                          onChange={filterData}
                                        />
                                        <Form.Input
                                          name={'loadList'}
                                          placeholder={'Load ID'}
                                          type={'string'}
                                          option={'includes'}
                                          onChange={filterData}
                                        />
                                        <Form.Dropdown
                                          name={'trailerStatusId'}
                                          placeholder={'Status'}
                                          selection
                                          clearable
                                          onChange={filterData}
                                          options={trailerStatuses.map(s => ({
                                            key: s.id,
                                            text: s.name,
                                            value: s.id,
                                          }))}
                                        />
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Dropdown
                                          name={'physicalLocationId'}
                                          value={currLocation}
                                          placeholder={'Physical Location'}
                                          selection
                                          clearable
                                          onChange={async (event, { name: n, value: v }) => {
                                            await this.setState({
                                              currLocation: v,
                                              subLocation: '',
                                            });
                                            filterData(event, {
                                              name: n,
                                              value: v,
                                            });
                                            filterData(event, {
                                              name: 'trailerSubLocationId',
                                              value: '',
                                            });
                                          }}
                                          options={trailerLocations.map(t => ({
                                            key: t.id,
                                            text: t.name,
                                            value: t.id,
                                          }))}
                                        />
                                        <Form.Dropdown
                                          name={'trailerSubLocationId'}
                                          value={subLocation}
                                          placeholder={'Sub-Location'}
                                          selection
                                          clearable
                                          onChange={(event, { name: n, value: v }) => {
                                            this.setState({ subLocation: v });
                                            filterData(event, {
                                              name: n,
                                              value: v,
                                            });
                                          }}
                                          options={trailerSubLocations
                                            .filter(
                                              ({ trailerLocationId }) =>
                                                trailerLocationId === currLocation
                                            )
                                            .map(sub => ({
                                              key: sub.id,
                                              text: sub.name,
                                              value: sub.id,
                                            }))}
                                        />
                                        <Form.Dropdown
                                          name={'destinationLocationId'}
                                          placeholder={'Destination'}
                                          selection
                                          clearable
                                          onChange={filterData}
                                          options={trailerLocations.map(t => ({
                                            key: t.id,
                                            text: t.name,
                                            value: t.id,
                                          }))}
                                        />
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Dropdown
                                          name={'enRoute'}
                                          placeholder={'En Route Status'}
                                          selection
                                          clearable
                                          onChange={filterData}
                                          options={[
                                            { key: 0, text: 'En route', value: 1 },
                                            { key: 1, text: 'Parked', value: 0 },
                                          ]}
                                        />
                                        <Form.Dropdown
                                          name={'active'}
                                          placeholder={'Active Status'}
                                          selection
                                          clearable
                                          onChange={filterData}
                                          options={[
                                            { key: 0, text: 'Active', value: 1 },
                                            { key: 1, text: 'Inactive', value: 0 },
                                          ]}
                                        />
                                      </Form.Group>
                                    </Form>
                                    {!editing &&
                                      !creating && (
                                        <div className={'mt-3'}>
                                          <Button
                                            content={'NEW TRAILER'}
                                            icon={'plus'}
                                            color={'blue'}
                                            onClick={() => createRecord()}
                                          />
                                        </div>
                                      )}
                                    {(editing || creating) && (
                                      <Segment>
                                        <Form
                                          onSubmit={async () => {
                                            if (
                                              name === '' ||
                                              physicalLocationId === '' ||
                                              trailerStatusId === ''
                                            ) {
                                              this.setState({ error: true }, () =>
                                                setTimeout(() => {
                                                  this.setState({
                                                    error: false,
                                                  });
                                                }, 3000)
                                              );
                                            } else {
                                              let valid = true;
                                              let newLoads = [];

                                              if (loadList !== '') {
                                                // spread operator on set removes duplicates
                                                newLoads = [
                                                  ...new Set(
                                                    loadList
                                                      .split(/,\s*/)
                                                      .filter(curr => curr !== '')
                                                  ),
                                                ];

                                                newLoads.forEach(curr => {
                                                  if (
                                                    !loads.find(load => String(load.id) === curr)
                                                  ) {
                                                    valid = false;
                                                    addNotifications([
                                                      {
                                                        header: 'ERROR',
                                                        content: `Load ID ${curr} does not exist.`,
                                                        timestamp: new Date().toLocaleTimeString(),
                                                        color: 'red',
                                                      },
                                                    ]);
                                                  }
                                                });
                                              }

                                              if (valid) {
                                                let result;

                                                if (editing) {
                                                  result = await putTrailer({
                                                    id: trailerId,
                                                    name,
                                                    trailerStatusId,
                                                    physicalLocationId,
                                                    owner: owner || null,
                                                    notes:
                                                      notes && notes.length !== 0
                                                        ? { ...notes }
                                                        : null,
                                                    trailerSubLocationId:
                                                      trailerSubLocationId !== null &&
                                                      trailerSubLocationId !== '' &&
                                                      trailerSubLocations.find(
                                                        sub => sub.id === trailerSubLocationId
                                                      ).trailerLocationId === physicalLocationId
                                                        ? trailerSubLocationId
                                                        : null,
                                                    monthlyCost: monthlyCost || null,
                                                    leaseExpiration: leaseExpiration || null,
                                                    ownerReturnDate: ownerReturnDate || null,
                                                    ownerPickupDate: ownerPickupDate || null,
                                                    inspectionDate: inspectionDate || null,
                                                    active: active ? 1 : 0,
                                                  });

                                                  if (
                                                    !inQueue &&
                                                    trailerQueue.find(({ id }) => id === trailerId)
                                                  ) {
                                                    await destroyTrailerQueue({ id: trailerId });
                                                  }
                                                } else {
                                                  result = await postTrailer({
                                                    name,
                                                    trailerStatusId,
                                                    physicalLocationId,
                                                    owner: owner || null,
                                                    notes:
                                                      notes && notes.length !== 0
                                                        ? { ...notes }
                                                        : null,
                                                    trailerSubLocationId:
                                                      trailerSubLocationId !== null &&
                                                      trailerSubLocationId !== '' &&
                                                      trailerSubLocations.find(
                                                        sub => sub.id === trailerSubLocationId
                                                      ).trailerLocationId === physicalLocationId
                                                        ? trailerSubLocationId
                                                        : null,
                                                    monthlyCost: monthlyCost || null,
                                                    leaseExpiration: leaseExpiration || null,
                                                    ownerReturnDate: ownerReturnDate || null,
                                                    ownerPickupDate: ownerPickupDate || null,
                                                    inspectionDate: inspectionDate || null,
                                                    active: active ? 1 : 0,
                                                  });
                                                }

                                                if (result) {
                                                  // replacing current loads
                                                  if (newLoads.length !== 0) {
                                                    if (editing)
                                                      await destroyTrailerLoads({ id: trailerId });
                                                    await Promise.all(
                                                      newLoads.map(async curr => {
                                                        valid = await postTrailerLoads({
                                                          trailerId: editing
                                                            ? trailerId
                                                            : result[0].id,
                                                          loadId: Number(curr),
                                                        });

                                                        if (!valid) throw curr;
                                                      })
                                                    ).catch(err => {
                                                      addNotifications([
                                                        {
                                                          header: 'ERROR',
                                                          content: `Load ${err} could not be added to Trailer ${name}.`,
                                                          timestamp: new Date().toLocaleTimeString(),
                                                          color: 'red',
                                                        },
                                                      ]);
                                                    });
                                                  } else {
                                                    // clearing existing loads
                                                    if (trailerLoads.length !== 0) {
                                                      await destroyTrailerLoads({ id: trailerId });
                                                    }
                                                  }

                                                  addNotifications([
                                                    {
                                                      header: 'SUCCESS',
                                                      content: `${
                                                        editing ? 'Updated' : 'Created'
                                                      } ${name}`,
                                                      timestamp: new Date().toLocaleTimeString(),
                                                      color: 'green',
                                                    },
                                                  ]);

                                                  this.setState(prevState => ({
                                                    count: prevState.count + 1,
                                                  }));
                                                } else {
                                                  addNotifications([
                                                    {
                                                      header: 'ERROR',
                                                      content: `Could not ${
                                                        editing ? 'update' : 'create'
                                                      } trailer. Please try again.`,
                                                      timestamp: new Date().toLocaleTimeString(),
                                                      color: 'red',
                                                    },
                                                  ]);
                                                }
                                              }
                                            }
                                          }}
                                        >
                                          <Header size={'huge'} className={'flex jc-center m-3'}>
                                            {editing ? `Edit ${name}` : 'New Trailer'}
                                          </Header>
                                          <Form.Group>
                                            <Form.Input
                                              label={'Name'}
                                              name={'name'}
                                              value={name}
                                              type={'string'}
                                              onChange={handleInputChange}
                                              error={name === '' && error}
                                              readOnly={trailerId !== null}
                                              disabled={Boolean(enRoute)}
                                            />
                                            <Form.Dropdown
                                              label={'Physical Location'}
                                              name={'physicalLocationId'}
                                              value={physicalLocationId}
                                              selection
                                              options={trailerLocations.map(location => ({
                                                key: location.id,
                                                text: location.name,
                                                value: location.id,
                                              }))}
                                              onChange={handleInputChange}
                                              error={physicalLocationId === '' && error}
                                              disabled={Boolean(enRoute)}
                                            />
                                            <Form.Dropdown
                                              label={'Sub-Location'}
                                              name={'trailerSubLocationId'}
                                              value={trailerSubLocationId}
                                              selection
                                              clearable
                                              options={trailerSubLocations
                                                .filter(
                                                  ({ trailerLocationId }) =>
                                                    trailerLocationId === physicalLocationId
                                                )
                                                .map(sub => ({
                                                  key: sub.id,
                                                  text: sub.name,
                                                  value: sub.id,
                                                }))}
                                              onChange={handleInputChange}
                                              disabled={Boolean(enRoute)}
                                            />
                                            <Form.Dropdown
                                              label={'Status'}
                                              name={'trailerStatusId'}
                                              value={trailerStatusId}
                                              selection
                                              options={trailerStatuses.map(status => ({
                                                key: status.id,
                                                text: status.name,
                                                value: status.id,
                                              }))}
                                              onChange={handleInputChange}
                                              error={trailerStatusId === '' && error}
                                              disabled={Boolean(enRoute)}
                                            />
                                          </Form.Group>
                                          <Form.Group>
                                            <Form.Input
                                              onFocus={() => this.setState({ showPopup: true })}
                                              onBlur={() => this.setState({ showPopup: false })}
                                              label={
                                                <span
                                                  style={{
                                                    display: 'block',
                                                    margin: '0 0 .28571429rem 0',
                                                    color: 'rgba(0,0,0,.87)',
                                                    fontSize: '.92857143em',
                                                    fontWeight: '700',
                                                    textTransform: 'none',
                                                    opacity: enRoute ? '.45' : '',
                                                  }}
                                                >
                                                  Load ID
                                                  <Popup
                                                    open={showPopup}
                                                    position={'top center'}
                                                    content={
                                                      <div className={'mb-3'}>
                                                        <div className={'flex jc-end pointer'}>
                                                          <Icon
                                                            name={'x'}
                                                            onClick={() =>
                                                              this.setState({ showPopup: false })
                                                            }
                                                          />
                                                        </div>
                                                        For consolidated loads, enter a
                                                        comma-separated list of load IDs (e.g. 1, 2,
                                                        3)
                                                      </div>
                                                    }
                                                    trigger={
                                                      <Icon
                                                        onClick={() =>
                                                          this.setState({ showPopup: true })
                                                        }
                                                        className={'ml-2 pointer'}
                                                        name={'question circle'}
                                                        color={'blue'}
                                                      />
                                                    }
                                                  />
                                                </span>
                                              }
                                              name={'loadList'}
                                              value={loadList}
                                              type={'string'}
                                              onChange={handleInputChange}
                                              disabled={Boolean(enRoute)}
                                            />
                                            <Form.Dropdown
                                              label={'Vendor'}
                                              name={'owner'}
                                              value={owner || ''}
                                              selection
                                              clearable
                                              options={trailerLocations
                                                .filter(({ vendor }) => vendor)
                                                .map(({ id: vendorId, name: vendorName }) => ({
                                                  key: vendorId,
                                                  text: vendorName,
                                                  value: String(vendorId),
                                                }))}
                                              onChange={handleInputChange}
                                              disabled={Boolean(enRoute)}
                                            />
                                            <Form.Input
                                              label={'Monthly Cost'}
                                              name={'monthlyCost'}
                                              value={monthlyCost || ''}
                                              type={'number'}
                                              onChange={handleInputChange}
                                              disabled={Boolean(enRoute)}
                                            />
                                          </Form.Group>
                                          <Form.Group>
                                            <DateInput
                                              label={'Vendor Pickup Date'}
                                              name={'ownerPickupDate'}
                                              value={
                                                ownerPickupDate ? ownerPickupDate.slice(0, 10) : ''
                                              }
                                              clearable
                                              closable
                                              closeOnMouseLeave={false}
                                              onChange={handleInputChange}
                                              dateFormat={'YYYY-MM-DD'}
                                              disabled={Boolean(enRoute)}
                                            />
                                            <DateInput
                                              label={'Vendor Return Date'}
                                              name={'ownerReturnDate'}
                                              value={
                                                ownerReturnDate ? ownerReturnDate.slice(0, 10) : ''
                                              }
                                              clearable
                                              closable
                                              closeOnMouseLeave={false}
                                              onChange={handleInputChange}
                                              dateFormat={'YYYY-MM-DD'}
                                              disabled={Boolean(enRoute)}
                                            />
                                            <DateInput
                                              label={'Annual Inspection'}
                                              name={'inspectionDate'}
                                              value={
                                                inspectionDate ? inspectionDate.slice(0, 10) : ''
                                              }
                                              clearable
                                              closable
                                              closeOnMouseLeave={false}
                                              onChange={handleInputChange}
                                              dateFormat={'YYYY-MM-DD'}
                                              disabled={Boolean(enRoute)}
                                            />
                                            <DateInput
                                              label={'Lease Expiration'}
                                              name={'leaseExpiration'}
                                              value={
                                                leaseExpiration ? leaseExpiration.slice(0, 10) : ''
                                              }
                                              clearable
                                              closable
                                              closeOnMouseLeave={false}
                                              onChange={handleInputChange}
                                              dateFormat={'YYYY-MM-DD'}
                                              disabled={Boolean(enRoute)}
                                            />
                                          </Form.Group>
                                          <div>
                                            <Form.Dropdown
                                              label={'Notes'}
                                              placeholder={'Notes about previous trip'}
                                              name={'notes'}
                                              value={
                                                notes ? Object.keys(notes).map(n => notes[n]) : []
                                              }
                                              fluid
                                              multiple
                                              selection
                                              clearable
                                              options={[
                                                { key: 0, text: 'Filled Gas', value: 'Filled Gas' },
                                                {
                                                  key: 1,
                                                  text: 'Flat/Blowout Tire ',
                                                  value: 'Flat/Blowout Tire',
                                                },
                                                { key: 2, text: 'Broke Down', value: 'Broke Down' },
                                                { key: 3, text: 'Red-Tagged', value: 'Red-Tagged' },
                                              ]}
                                              onChange={handleInputChange}
                                              disabled={Boolean(enRoute)}
                                            />
                                          </div>
                                          <div className={'mt-4 ml-3'}>
                                            <Checkbox
                                              label={'Active'}
                                              name={'active'}
                                              value={Number(active)}
                                              checked={Boolean(active)}
                                              onChange={handleToggle}
                                              disabled={Boolean(enRoute)}
                                            />
                                          </div>
                                          <div className={'mt-3 ml-3'}>
                                            <Checkbox
                                              label={'En Route'}
                                              name={'enRoute'}
                                              value={Number(enRoute)}
                                              checked={Boolean(enRoute)}
                                              readOnly
                                            />
                                          </div>
                                          <div className={'mt-3 ml-3'}>
                                            <Checkbox
                                              label={'In Queue'}
                                              name={'inQueue'}
                                              value={Number(inQueue)}
                                              checked={Boolean(inQueue)}
                                              onChange={handleToggle}
                                              disabled={Boolean(enRoute)}
                                              readOnly={!inQueue}
                                            />
                                          </div>
                                          <div className={'mt-4'}>
                                            <Button
                                              type={'button'}
                                              icon={'minus'}
                                              color={'grey'}
                                              onClick={resetState}
                                            />
                                            <Button
                                              type={'submit'}
                                              color={'green'}
                                              content={editing ? 'UPDATE' : 'SAVE'}
                                              disabled={Boolean(enRoute)}
                                            />
                                          </div>
                                        </Form>
                                      </Segment>
                                    )}

                                    {/*
                                    <Modal
                                      closeOnDimmerClick={false}
                                      closeOnDocumentClick={false}
                                      closeIcon
                                      open={removing}
                                      onClose={resetState}
                                      centered={false}
                                    >
                                      <Header as={'h2'}>
                                        <Icon
                                          name={'warning sign'}
                                          color={'yellow'}
                                          size={'large'}
                                        />
                                        WARNING
                                      </Header>
                                      <Modal.Content>
                                        <p>
                                          You are about to delete a trailer! Are you sure you want
                                          to delete <strong>{name}</strong>?
                                        </p>
                                      </Modal.Content>
                                      <Modal.Actions>
                                        <Button
                                          positive
                                          icon={'check'}
                                          content={'YES, DELETE THIS TRAILER'}
                                          onClick={async () => {
                                            const result = await destroyTrailer({ id: trailerId });

                                            if (result) {
                                              addNotifications([
                                                {
                                                  header: 'SUCCESS',
                                                  content: `Deleted ${name}`,
                                                  timestamp: new Date().toLocaleTimeString(),
                                                  color: 'green',
                                                },
                                              ]);

                                              this.setState(prevState => ({
                                                count: prevState.count + 1,
                                              }));
                                            } else {
                                              addNotifications([
                                                {
                                                  header: 'ERROR',
                                                  content: `Could not delete trailer ${name}. Please try again.`,
                                                  timestamp: new Date().toLocaleTimeString(),
                                                  color: 'red',
                                                },
                                              ]);
                                            }
                                            resetState();
                                          }}
                                        />
                                        <Button
                                          negative
                                          icon={'x'}
                                          content={'NO, DO NOT DELETE THIS TRAILER'}
                                          onClick={resetState}
                                        />
                                      </Modal.Actions>
                                    </Modal>
                                    */}

                                    {data.length === 0 ? (
                                      <Message className={'flex jc-center'} negative>
                                        <Icon name={'exclamation circle'} />
                                        No results found!
                                      </Message>
                                    ) : (
                                      <TableEditor
                                        headers={[
                                          'Name',
                                          'Active',
                                          'In Queue',
                                          'En Route',
                                          'Physical Location',
                                          'Sub-Location',
                                          'Destination',
                                          'Status',
                                          'Load ID',
                                          'Notes',
                                          'Additional Information',
                                          'Edit',
                                        ]}
                                        records={data.map(trailer => {
                                          const { enRoute: trailerEnRoute } =
                                            trailerQueue.find(
                                              ({ id: queueId }) => queueId === trailer.id
                                            ) || {};

                                          const { name: trailerDestinationLocation } =
                                            trailerLocations.find(
                                              ({ id: trailerLocationId }) =>
                                                trailerLocationId ===
                                                trailer.trailerDestinationLocationId
                                            ) || {};

                                          return {
                                            name: (
                                              <a href={`/trailerHistory/${trailer.id}`}>
                                                {trailer.name}
                                              </a>
                                            ),
                                            active: trailer.active ? <Icon name={'check'} /> : '',
                                            inQueue: trailerQueue.find(
                                              ({ id: queueId }) => trailer.id === queueId
                                            ) ? (
                                              <Icon name={'check'} />
                                            ) : (
                                              ''
                                            ),
                                            enRoute: trailerEnRoute ? <Icon name={'check'} /> : '',
                                            physicalLocation: trailerLocations.find(
                                              ({ id }) => id === trailer.physicalLocationId
                                            ).name,
                                            subLocation: trailer.trailerSubLocation
                                              ? trailer.trailerSubLocation.name
                                              : '',
                                            destinationLocation: trailerDestinationLocation || '',
                                            status: trailer.trailerStatus
                                              ? trailer.trailerStatus.name
                                              : '',
                                            loadID:
                                              trailer.trailerLoads.length > 0 ? (
                                                <>
                                                  {trailer.trailerLoads.map(
                                                    ({ loadId: load }, key) => (
                                                      <div
                                                        key={key}
                                                        className={
                                                          key !== trailer.trailerLoads.length - 1
                                                            ? 'mb-1'
                                                            : null
                                                        }
                                                      >
                                                        <Label content={load} color={'orange'} />
                                                      </div>
                                                    )
                                                  )}
                                                </>
                                              ) : (
                                                ''
                                              ),
                                            notes: trailer.notes ? (
                                              <>
                                                {Object.keys(trailer.notes).map((note, key) => (
                                                  <div
                                                    key={key}
                                                    className={
                                                      key !== Object.keys(trailer.notes).length - 1
                                                        ? 'mb-1'
                                                        : null
                                                    }
                                                  >
                                                    <Label color={'yellow'}>
                                                      {trailer.notes[note]}
                                                    </Label>
                                                  </div>
                                                ))}
                                              </>
                                            ) : (
                                              ''
                                            ),
                                            misc: (
                                              <Popup
                                                trigger={
                                                  <Icon name={'list'} className={'pointer'} />
                                                }
                                                position={'left center'}
                                                size={'small'}
                                                wide={'very'}
                                              >
                                                <TableEditor
                                                  records={[
                                                    {
                                                      vendor: trailer.owner
                                                        ? trailerLocations.find(
                                                            ({ id, name: vendorName }) =>
                                                              String(id) === trailer.owner ||
                                                              vendorName === trailer.owner
                                                          ).name
                                                        : '',
                                                      monthlyCost: trailer.monthlyCost
                                                        ? asMoney(trailer.monthlyCost)
                                                        : '',
                                                      vendorPickupDate: trailer.ownerPickupDate
                                                        ? trailer.ownerPickupDate.slice(0, 10)
                                                        : '',
                                                      vendorReturnDate: trailer.ownerReturnDate
                                                        ? trailer.ownerReturnDate.slice(0, 10)
                                                        : '',
                                                      annualInspection: trailer.inspectionDate
                                                        ? trailer.inspectionDate.slice(0, 10)
                                                        : '',
                                                      leaseExpiration: trailer.leaseExpiration
                                                        ? trailer.leaseExpiration.slice(0, 10)
                                                        : '',
                                                    },
                                                  ]}
                                                />
                                              </Popup>
                                            ),
                                            record: trailer,
                                          };
                                        })}
                                        edit={rec => {
                                          window.scrollTo(0, 0);
                                          editRecord(rec);
                                        }}
                                        // remove={rec => removeRecord(rec)}
                                      />
                                    )}
                                  </>
                                );
                              }}
                            </TableGovernor>
                          </>
                        )}
                      </API.Query>
                    )}
                  </API.Crud>
                )}
              </API.Crud>
            )}
          </API.Crud>
        )}
      </NotificationContext.Consumer>
    );
  }
}

export default TrailerTable;
