import React, { Fragment } from 'react';
import { Button, Input, Message } from 'semantic-ui-react';
import CargoApi from '../shared-api-adapters/cargo-api';
import DatafinitiService from '../services/datafiniti';
import REGEX from '../utilities/constants/regex';

class QuickSort extends React.Component {
  constructor(props) {
    super(props);

    this.api = new CargoApi();
    this.datafiniti = new DatafinitiService();
    this.inputRef = React.createRef();

    this.state = {
      value: '',
      result: null,
      loading: false,
      searchTerm: '',
    };
  }

  handleSearch = async (event, { value }) => {
    this.setState({ loading: true, value });

    if (this.isLPN(value) || this.isX00(value)) {
      this.checkNellisDatabase(value)
        .then(result =>
          this.setState({ result: !!result, loading: false, value: '', searchTerm: value })
        )
        .catch(() =>
          this.setState({ result: false, loading: false, value: '', searchTerm: value })
        );
    } else if (this.isASIN(value)) {
      this.datafiniti
        .getItemInfoFromASIN(value)
        .then(result =>
          this.setState({ result: !!result, loading: false, value: '', searchTerm: value })
        )
        .catch(() =>
          this.setState({ result: false, loading: false, value: '', searchTerm: value })
        );
    } else if (this.isISBN(value)) {
      this.datafiniti
        .getItemInfoFromGtins(value)
        .then(result =>
          this.setState({ result: !!result, loading: false, value: '', searchTerm: value })
        )
        .catch(() =>
          this.setState({ result: false, loading: false, value: '', searchTerm: value })
        );
    } else if (this.isUPC(value)) {
      this.datafiniti
        .getItemInfoFromUPC(value)
        .then(result =>
          this.setState({ result: !!result, loading: false, value: '', searchTerm: value })
        )
        .catch(() =>
          this.setState({ result: false, loading: false, value: '', searchTerm: value })
        );
    } else {
      this.setState({ result: false, loading: false, searchTerm: '' });
    }
  };

  isASIN = id => (!id ? false : REGEX.ASIN.test(id.toString()));

  isISBN = id => (!id ? false : REGEX.ISBN.test(id.toString()));

  isUPC = id => (!id ? false : REGEX.UPC.test(id.toString()));

  isLPN = id => (!id ? false : REGEX.LPN.test(id.toString()));

  isX00 = id => (!id ? false : REGEX.X00.test(id.toString()));

  checkNellisDatabase = async value =>
    await this.api.get(this.api.routes.skuToAsin, value).then(([result]) => result);

  reset = () => {
    this.inputRef.current.focus();
    this.setState({
      result: null,
      value: '',
      loading: false,
    });
  };

  componentDidMount() {
    this.inputRef.current.focus();
  }

  render() {
    const { result, value, loading, searchTerm } = this.state;

    return (
      <Fragment>
        <Input
          loading={loading}
          ref={this.inputRef}
          fluid
          icon={'barcode'}
          size={'massive'}
          onChange={this.handleSearch}
          value={value}
        />
        <Button fluid className={'mt-2'} onClick={this.reset} content={'Reset'} />
        {result !== null &&
          loading === false && (
            <Message success={result} error={!result}>
              <Message.Header>{`Scanned: ${searchTerm}`}</Message.Header>
              <Message.Content>{result ? 'Success!' : 'Failed!'}</Message.Content>
            </Message>
          )}
      </Fragment>
    );
  }
}

export default QuickSort;
