import React, { Component, Fragment } from 'react';
import FirebaseItems from './firebase-items';
import SessionNav from './session-nav';
import { Button, Container, Divider, Header, Icon, Segment } from 'semantic-ui-react';
import API from './api';
import { buildContentBasedOnTableDescriptions } from '../utilities/item-report';
import Crumbs from './crumbs';
import ItemImageList from './item-image-list';
import MultiToggle from '../shared-components/multi-toggle';
import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class SessionWatcher extends Component {
  resolveIconDisplay = (l1, l2) => {
    if (l1 === 0) {
      return 'square outline';
    }
    if (l1 === l2) {
      return 'check square outline';
    }
    if (l1 !== l2) {
      return 'minus square outline';
    }
    return 'square outline';
  };

  /**
   * @param fieldContents - array of field contents
   */
  withColors = fieldContents => {
    const colors = {
      Usage: 'teal',
      Assembly: 'brown',
      Damage: 'violet',
      'Missing Parts': 'orange',
      Functional: 'pink',
      Packaging: 'red',
    };

    return fieldContents.map(content => ({ ...content, color: colors[content.label] }));
  };

  render() {
    const { user } = this.props;

    return (
      <Container className={'my-3'}>
        <API.Query queries={[{ route: 'users' }]} api={new NellisAuctionApi(user.token)}>
          {({ data: [users] }) => {
            const searchableUsers = users.reduce(
              (acc, value) => ({ ...acc, [value.id]: value }),
              {}
            );

            return (
              <API.InventoryEditorData>
                {containers => (
                  <FirebaseItems bundleBy={'sessionUserId'}>
                    {({ items }) => (
                      <MultiToggle>
                        {({ addAll, removeAll, indexes, toggle }) => (
                          <Fragment>
                            <Button
                              basic
                              icon
                              onClick={
                                indexes.length > 0
                                  ? removeAll
                                  : () =>
                                      addAll(
                                        Object.keys(items)
                                          .map(key => items[key].map((val, k) => key + k))
                                          .flat()
                                      )
                              }
                            >
                              <Icon
                                name={this.resolveIconDisplay(
                                  indexes.length,
                                  Object.values(items).flat().length
                                )}
                              />
                              <Icon name={'caret down'} />
                            </Button>
                            {Object.keys(items).map(key => (
                              <Fragment key={key}>
                                <Header>
                                  <Icon name={'user'} />
                                  {searchableUsers[key]
                                    ? `${searchableUsers[key].firstName} ${
                                        searchableUsers[key].lastName
                                      }`
                                    : 'Unassigned'}
                                </Header>
                                <Divider />
                                <div className={'flex fd-row ai-baseline jc-start flex-wr'}>
                                  {items[key].map((item, k) => {
                                    const crumbInfo = buildContentBasedOnTableDescriptions(
                                      containers,
                                      item
                                    );

                                    return indexes.includes(key + k) ? (
                                      <Segment key={k} className={'w-20r mr-2'}>
                                        <Icon
                                          className={'float-right pointer'}
                                          name={'close'}
                                          onClick={() => toggle(key + k)}
                                        />
                                        <h3>{`Item #${item.inventoryNumber}`}</h3>
                                        <ItemImageList basic={true} photos={item.photos} />
                                        <p className={'mt-2'}>
                                          <strong>{item.leadDescription}</strong>
                                        </p>
                                        <p>
                                          <strong>Retail Price:</strong> ${item.retailPrice}
                                        </p>
                                        {item.notes && (
                                          <p>
                                            <strong>Clerk Notes: </strong>
                                            {item.notes}
                                          </p>
                                        )}
                                        <Crumbs crumbs={this.withColors(crumbInfo)} noLink={true} />
                                      </Segment>
                                    ) : (
                                      <SessionNav
                                        key={k}
                                        date={new Date(item.lastUpdated)}
                                        inventoryNumber={item.inventoryNumber}
                                        description={item.leadDescription || ''}
                                        image={
                                          item.photos ? Object.values(item.photos)[0] : { url: '' }
                                        }
                                        nav={false}
                                        onClick={() => toggle(key + k)}
                                        className={'mr-2'}
                                      />
                                    );
                                  })}
                                </div>
                              </Fragment>
                            ))}
                          </Fragment>
                        )}
                      </MultiToggle>
                    )}
                  </FirebaseItems>
                )}
              </API.InventoryEditorData>
            );
          }}
        </API.Query>
      </Container>
    );
  }
}

SessionWatcher.propTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(({ auth }) => ({ ...auth }))(SessionWatcher);
