import React, { Component } from 'react';
import CargoApi from '../shared-api-adapters/cargo-api';
import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
import {
  Button,
  Checkbox,
  Dropdown,
  Segment,
  Dimmer,
  Icon,
  Loader,
  Menu,
  Table,
  Input,
  Grid,
  Label,
  Popup,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NotificationContext } from '../shared-components/notification-context';

class TrailerCity extends Component {
  constructor(props) {
    super(props);

    const { authUser: { id, token, locationId } } = this.props;

    this.nellisAuctionApi = new NellisAuctionApi(token);
    this.cargoApi = new CargoApi();

    this.state = {
      activeLocationId: null,
      activeTab: 'available',

      locations: [],
      trailers: [],
      trailersAtLocation: [],
      trailerStatus: [],
      trailersForQueue: [],
      trailerLoads: [],
      queueForLocation: [],
      loads: [],
      loadCounts: [],
      drivers: [],

      showPopup: false,
      loading: true,
      saving: false,

      message: '',
      userId: id,
    };
  }

  changeLocation = async locationId => {
    const { activeLocationId } = this.state;

    if (activeLocationId === locationId) return;

    await this.setState({ activeLocationId: locationId, trailersForQueue: [] });
    this.fetchData();
  };

  componentDidMount = async () => {
    const { authUser: { locationId: userLocation } } = this.props;
    const locations = await this.cargoApi.get(this.cargoApi.routes.trailerLocations);
    const defaultLocation = locations.find(({ locationId }) => locationId === userLocation);

    this.setState({
      activeLocationId: defaultLocation ? defaultLocation.id : 1,
      locations,
    });

    this.fetchData();
  };

  handleCheckboxClick = (event, { value }) => {
    const { trailersForQueue } = this.state;

    let tempTrailersForQueue = [];

    if (trailersForQueue.includes(value)) {
      tempTrailersForQueue = trailersForQueue.filter(t => t !== value);
    } else {
      tempTrailersForQueue = [...trailersForQueue, value];
    }

    this.setState({ trailersForQueue: tempTrailersForQueue });
  };

  handleDestinationChange = (event, { name, value }) => {
    const trailerId = Number(name.split('-')[1]);

    const modifiedTrailers = this.state.trailersAtLocation.map(trailer => {
      let destinationLocationId = trailer.destinationLocationId;

      if (trailer.id === trailerId) destinationLocationId = value;

      return {
        ...trailer,
        destinationLocationId,
      };
    });

    this.setState({ trailersAtLocation: modifiedTrailers });
  };

  handleStatusChange = (event, { name, value }) => {
    const trailerId = Number(name.split('-')[1]);

    const modifiedTrailers = this.state.trailersAtLocation.map(trailer => ({
      ...trailer,
      trailerStatusId: trailer.id === trailerId ? value : trailer.trailerStatusId,
    }));

    this.setState({ trailersAtLocation: modifiedTrailers });
  };

  handleLoadChange = (event, { name, value }) => {
    const trailerId = Number(name.split('-')[1]);

    const modifiedTrailers = this.state.trailersAtLocation.map(trailer => ({
      ...trailer,
      loadList: trailer.id === trailerId ? value : trailer.loadList,
      programList:
        trailer.id === trailerId
          ? value
              .split(/,\s*/)
              .filter(load => load !== '')
              .map(load => this.findProgramName(load))
              .join(', ')
          : trailer.programList,
      manifestCounts:
        trailer.id === trailerId
          ? value
              .split(/,\s*/)
              .filter(load => load !== '')
              .map(load => this.getManifestCount(load))
              .join(', ')
          : trailer.manifestCounts,
    }));

    this.setState({ trailersAtLocation: modifiedTrailers });
  };

  handleDriverChange = (event, { name, value }) => {
    const trailerId = Number(name.split('-')[1]);

    const modifiedTrailers = this.state.trailersAtLocation.map(trailer => ({
      ...trailer,
      driverId: trailer.id === trailerId ? value : trailer.driverId,
    }));

    this.setState({ trailersAtLocation: modifiedTrailers });
  };

  findProgramName = match => {
    if (isNaN(Number(match))) return 'None';

    const { loads } = this.state;
    const result = loads.find(({ id }) => id === Number(match));

    if (result) return result.program.name;

    return 'None';
  };

  getManifestCount = match => {
    if (isNaN(Number(match))) return '0';

    const { loadCounts } = this.state;
    const result = loadCounts.find(({ loadId }) => loadId === Number(match));

    if (result) return String(result.itemCount);

    return '0';
  };

  fetchData = async () => {
    this.setState({ loading: true });

    const { activeLocationId, locations } = this.state;
    const trailers = await this.cargoApi.get(this.cargoApi.routes.trailers);
    const trailerStatus = await this.cargoApi.get(this.cargoApi.routes.trailerStatus);
    const trailerQueue = await this.cargoApi.get(this.cargoApi.routes.trailerQueue);
    const trailerLoads = await this.cargoApi.get(this.cargoApi.routes.trailerLoads);
    const loads = await this.cargoApi.get(this.cargoApi.routes.loads);
    const loadCounts = await this.cargoApi.get(`${this.cargoApi.routes.loads}/counts/itemCounts`);
    const users = await this.nellisAuctionApi.get(this.nellisAuctionApi.routes.users);

    this.setState({
      loadCounts,
    });

    const trailerQueueIds = trailerQueue.map(({ trailerId }) => trailerId);
    const trailersAtLocation = trailers
      .filter(({ active }) => active)
      .filter(({ physicalLocationId }) => physicalLocationId === activeLocationId)
      .filter(({ id }) => !trailerQueueIds.includes(id))
      .map(trailer => ({
        ...trailer,
        loadList:
          trailer.trailerLoads.length !== 0
            ? trailer.trailerLoads.map(({ loadId: load }) => load).join(', ')
            : '',
        programList:
          trailer.trailerLoads.length !== 0
            ? trailer.trailerLoads.map(({ load: { program: { name } } }) => name).join(', ')
            : '',
        manifestCounts:
          trailer.trailerLoads.length !== 0
            ? trailer.trailerLoads.map(({ loadId }) => this.getManifestCount(loadId)).join(', ')
            : '',
        driverId: trailer.driverId,
      }));
    const queueForLocation = trailerQueue.filter(
      ({ physicalLocationId }) => physicalLocationId === activeLocationId
    );
    const drivers = users.filter(({ roleId }) => roleId === 6);

    this.setState({
      trailers,
      trailersAtLocation,
      trailerStatus,
      trailerLoads,
      queueForLocation,
      trailersForQueue: [],
      drivers,
      loads,
      loading: false,
    });
  };

  sendToQueue = async addNotifications => {
    const { drivers, trailersForQueue, trailersAtLocation, loads, userId, locations } = this.state;

    let newLoads = [];
    let valid = true;

    this.setState({ saving: true });

    trailersForQueue.forEach(tid => {
      const trailer = trailersAtLocation.find(tr => tr.id === tid);

      if (!trailer.destinationLocationId) {
        valid = false;
        addNotifications([
          {
            header: 'ERROR',
            content: 'Destination required',
            timestamp: new Date().toLocaleTimeString(),
            color: 'red',
          },
        ]);
      }

      const loadIds = loads.map(({ id }) => id);

      if (trailer.loadList !== '') {
        newLoads = trailer.loadList.split(/,\s*/).filter(load => load !== '');
        newLoads.forEach(load => {
          if (!loadIds.includes(Number(load))) {
            valid = false;
            addNotifications([
              {
                header: 'ERROR',
                content: `Load ID ${load} does not exist`,
                timestamp: new Date().toLocaleTimeString(),
                color: 'red',
              },
            ]);
          }
        });
      }
    });

    if (valid) {
      await Promise.all(
        trailersForQueue.map(async tid => {
          const trailer = trailersAtLocation.find(tr => tr.id === tid);

          const driverId = trailer.driverId || null;

          // add trailer to queue
          const queueResponse = await this.cargoApi.post(this.cargoApi.routes.trailerQueue, {
            ...trailer,
            trailerId: tid,
            driverId,
          });

          if (!queueResponse)
            throw `Trailer ${trailer.name} could not be added to the queue. Please try again.`;

          // update fields for trailer
          const trailerResponse = await this.cargoApi.put(
            `${this.cargoApi.routes.trailers}/${tid}`,
            {
              trailerStatusId: trailer.trailerStatusId,
              loadoutLocationId: trailer.destinationLocationId,
              destinationLocationId: trailer.destinationLocationId,
            }
          );

          if (!trailerResponse)
            throw `Trailer ${trailer.name} could not be updated Please try again.`;

          const { name: locationName } = locations.find(
            ({ id }) => id === trailer.destinationLocationId
          );

          let driverText = '';

          if (driverId) {
            const { firstName, lastName } = drivers.find(({ id: drId }) => drId === driverId);

            driverText = ` Assigned to ${firstName} ${lastName}.`;
          }

          let loadResponse = true;
          let logActionResponse = true;
          let errorMessage = '';

          // log loadAction
          if (trailer.loadList !== '') {
            await this.cargoApi.destroy(`${this.cargoApi.routes.trailerLoads}/${tid}`);
            await Promise.all(
              newLoads.map(async loadId => {
                loadResponse = await this.cargoApi.post(this.cargoApi.routes.trailerLoads, {
                  trailerId: tid,
                  loadId: Number(loadId),
                });

                if (!loadResponse)
                  throw `Load ${loadId} could not be added to ${trailer.name}. Please try again.`;
              })
            ).catch(err => (errorMessage = err));
            await Promise.all(
              newLoads.map(async loadId => {
                logActionResponse = await this.cargoApi.post(this.cargoApi.routes.loadLogs, {
                  userId,
                  action: `Assigned to trailer ${
                    trailer.name
                  }.${driverText} Dispatched to ${locationName}.`,
                  loadId: Number(loadId),
                });

                if (!logActionResponse)
                  throw `The log for load ${loadId} could not be updated. Please try again.`;
              })
            ).catch(err => (errorMessage = err));
          } else {
            await this.cargoApi.destroy(`${this.cargoApi.routes.trailerLoads}/${tid}`);
          }

          if (!loadResponse || !logActionResponse) {
            throw errorMessage;
          }
        })
      )
        .then(() => {
          // valid = true;
          addNotifications([
            {
              header: 'SUCCESS',
              content: `Trailers added to queue`,
              timestamp: new Date().toLocaleTimeString(),
              color: 'green',
            },
          ]);
        })
        .catch(err => {
          valid = false;
          addNotifications([
            {
              header: 'ERROR',
              content: err,
              timestamp: new Date().toLocaleTimeString(),
              color: 'red',
            },
          ]);
        });
    }

    this.setState({ saving: false });

    if (valid) {
      this.fetchData();
    }
  };

  handleTabClick = (e, { name }) => this.setState({ activeTab: name });

  removeFromQueue = async (id, addNotifications) => {
    const response = await this.cargoApi.destroy(`${this.cargoApi.routes.trailerQueue}/${id}`);

    this.fetchData();

    if (response)
      addNotifications([
        {
          header: 'SUCCESS',
          content: 'Trailer removed from queue',
          timestamp: new Date().toLocaleTimeString(),
          color: 'green',
        },
      ]);
    else
      addNotifications([
        {
          header: 'ERROR',
          content: 'Trailer could not be removed from queue. Please try again.',
          timestamp: new Date().toLocaleTimeString(),
          color: 'red',
        },
      ]);
  };

  trailerStatus = trailerStatusId => {
    const { name } = this.state.trailerStatus.find(({ id }) => id === trailerStatusId);

    return name;
  };

  trailerName = trailerId => {
    const { name } = this.state.trailers.find(({ id }) => id === trailerId);

    return name;
  };

  render() {
    const {
      activeLocationId,
      activeTab,
      locations,
      saving,
      trailersAtLocation,
      trailersForQueue,
      trailerStatus,
      queueForLocation,
      drivers,
      showPopup,
      loading,
    } = this.state;

    return (
      <NotificationContext.Consumer>
        {({ addNotifications }) => (
          <>
            <div className={'flex jc-center'}>
              <Button.Group>
                <Button
                  className={'border-black'}
                  content={'ALL TRAILERS'}
                  as={Link}
                  to={'/trailerEditor'}
                />
                <Button className={'border-black'} color={'blue'} content={'TRAILER CITY'} />
                <Button
                  className={'border-black'}
                  content={'TRAILER QUEUE'}
                  as={Link}
                  to={'/trailerQueue'}
                />
              </Button.Group>
            </div>

            <Loader active={loading} size={'huge'} content={'Loading'} />
            <h1 className={'Cargo-header'}>Trailer City</h1>

            <Grid columns={2}>
              <Grid.Column width={2}>
                <Button.Group widths={locations.length || 1} vertical>
                  {locations.map(location => (
                    <Button
                      key={location.id}
                      className={'border-black'}
                      content={<div className={'flex jc-center'}>{location.name}</div>}
                      color={location.id === activeLocationId ? 'blue' : null}
                      onClick={() => this.changeLocation(location.id)}
                      disabled={loading}
                    />
                  ))}
                </Button.Group>
              </Grid.Column>
              <Grid.Column width={14}>
                <Menu tabular>
                  <Menu.Item
                    name="available"
                    active={activeTab === 'available'}
                    onClick={this.handleTabClick}
                    content={
                      <>
                        Available
                        {activeTab !== 'available' &&
                          !loading &&
                          activeLocationId !== null && (
                            <Label size={'tiny'} color={'orange'} circular>
                              {trailersAtLocation.length}
                            </Label>
                          )}
                      </>
                    }
                  />
                  <Menu.Item
                    name="inQueue"
                    active={activeTab === 'inQueue'}
                    onClick={this.handleTabClick}
                    content={
                      <>
                        In Queue
                        {activeTab !== 'inQueue' &&
                          !loading &&
                          activeLocationId !== null && (
                            <Label size={'tiny'} color={'orange'} circular>
                              {queueForLocation.length}
                            </Label>
                          )}
                      </>
                    }
                  />
                </Menu>

                {!loading && (
                  <>
                    {activeTab === 'available' &&
                      trailersAtLocation.length > 0 && (
                        <div className={'mt-5 m-auto'}>
                          <Table basic={'very'} size={'small'} celled compact>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Trailer</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>
                                  <span>
                                    Load ID
                                    <Popup
                                      open={showPopup}
                                      position={'top center'}
                                      content={
                                        <div className={'mb-3'}>
                                          <div className={'flex jc-end pointer'}>
                                            <Icon
                                              name={'x'}
                                              onClick={() => this.setState({ showPopup: false })}
                                            />
                                          </div>
                                          For consolidated loads, enter a comma-separated list of
                                          load IDs (e.g. 1, 2, 3)
                                        </div>
                                      }
                                      trigger={
                                        <Icon
                                          onClick={() =>
                                            this.setState(prevState => ({
                                              showPopup: !prevState.showPopup,
                                            }))
                                          }
                                          className={'ml-2 pointer'}
                                          name={'question circle'}
                                          color={'blue'}
                                        />
                                      }
                                    />
                                  </span>
                                </Table.HeaderCell>
                                <Table.HeaderCell>Program</Table.HeaderCell>
                                <Table.HeaderCell>Manifest</Table.HeaderCell>
                                <Table.HeaderCell>Driver</Table.HeaderCell>
                                <Table.HeaderCell>Destination</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {trailersAtLocation.map(trailer => (
                                <Table.Row key={trailer.id}>
                                  <Table.Cell>
                                    <Checkbox
                                      label={{
                                        children: (
                                          <a href={`/trailerHistory/${trailer.id}`}>
                                            {trailer.name}
                                          </a>
                                        ),
                                      }}
                                      onClick={this.handleCheckboxClick}
                                      value={trailer.id}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Dropdown
                                      fluid
                                      placeholder="Set trailer status"
                                      name={`status-${trailer.id}`}
                                      selection
                                      disabled={!trailersForQueue.includes(trailer.id)}
                                      options={trailerStatus.map(t => ({
                                        key: t.id,
                                        text: t.name,
                                        value: t.id,
                                      }))}
                                      value={trailer.trailerStatusId}
                                      onChange={this.handleStatusChange}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Input
                                      onFocus={() => this.setState({ showPopup: true })}
                                      onBlur={() => this.setState({ showPopup: false })}
                                      placeholder="Enter load ID"
                                      type="string"
                                      name={`loadList-${trailer.id}`}
                                      value={trailer.loadList}
                                      disabled={!trailersForQueue.includes(trailer.id)}
                                      onChange={this.handleLoadChange}
                                    />
                                  </Table.Cell>
                                  <Table.Cell disabled={!trailersForQueue.includes(trailer.id)}>
                                    <>
                                      {trailer.programList !== ''
                                        ? trailer.programList.split(/,\s*/).map((program, key) => (
                                            <div
                                              key={key}
                                              className={`flex jc-center ${
                                                key !== trailer.programList.split(/,\s*/).length - 1
                                                  ? 'mb-1'
                                                  : ''
                                              }`}
                                            >
                                              {program}
                                            </div>
                                          ))
                                        : ''}
                                    </>
                                  </Table.Cell>
                                  <Table.Cell disabled={!trailersForQueue.includes(trailer.id)}>
                                    <>
                                      {trailer.manifestCounts !== ''
                                        ? trailer.manifestCounts.split(/,\s*/).map((count, key) => (
                                            <div key={key} className={'flex jc-center'}>
                                              {count}
                                            </div>
                                          ))
                                        : ''}
                                    </>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Dropdown
                                      fluid
                                      placeholder={'No driver assigned'}
                                      name={`driverId-${trailer.id}`}
                                      value={trailer.driverId ? trailer.driverId : ''}
                                      clearable
                                      selection
                                      disabled={!trailersForQueue.includes(trailer.id)}
                                      options={drivers.map(d => ({
                                        key: d.id,
                                        text: `${d.firstName} ${d.lastName}`,
                                        value: d.id,
                                      }))}
                                      onChange={this.handleDriverChange}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Dropdown
                                      fluid
                                      placeholder="Set trailer destination"
                                      name={`destination-${trailer.id}`}
                                      selection
                                      disabled={!trailersForQueue.includes(trailer.id)}
                                      options={locations.map(l => ({
                                        key: l.id,
                                        text: l.name,
                                        value: l.id,
                                      }))}
                                      value={trailer.destinationLocationId}
                                      onChange={this.handleDestinationChange}
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              ))}

                              <Table.Row>
                                <Table.Cell colSpan={6} />
                                <Table.Cell>
                                  <Button
                                    disabled={trailersForQueue.length === 0}
                                    className={'float-right'}
                                    onClick={() => this.sendToQueue(addNotifications)}
                                  >
                                    Save
                                  </Button>
                                </Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </div>
                      )}

                    {activeTab === 'available' &&
                      trailersAtLocation.length === 0 && (
                        <div className={'mt-5 w-90 m-auto'}>
                          <Icon name={'exclamation'} color={'red'} />
                          There are no trailers at this location
                        </div>
                      )}

                    {activeTab === 'inQueue' &&
                      queueForLocation.length > 0 && (
                        <Segment>
                          <Dimmer active={saving} inverted>
                            <Loader />
                          </Dimmer>
                          <div className={'mt-5 w-90 m-auto'}>
                            <Table basic={'very'} celled>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    Trailers in queue for this location:
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {queueForLocation.map(trailer => (
                                  <Table.Row key={trailer.trailerId}>
                                    <Table.Cell>
                                      <Icon
                                        name={'remove'}
                                        color={'red'}
                                        className={'mt-4 mb-4 pointer'}
                                        onClick={() =>
                                          this.removeFromQueue(trailer.id, addNotifications)
                                        }
                                      />
                                      <a href={`/trailerHistory/${trailer.trailerId}`}>
                                        {this.trailerName(trailer.trailerId)}
                                      </a>
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                              </Table.Body>
                            </Table>
                          </div>
                        </Segment>
                      )}

                    {activeTab === 'inQueue' &&
                      queueForLocation.length === 0 && (
                        <div className={'mt-5 w-90 m-auto'}>
                          <Icon name={'exclamation'} color={'red'} />
                          There are no trailers in the queue for this location
                        </div>
                      )}
                  </>
                )}
              </Grid.Column>
            </Grid>
          </>
        )}
      </NotificationContext.Consumer>
    );
  }
}

TrailerCity.propTypes = {
  authUser: PropTypes.object.isRequired,
};

export default TrailerCity;
