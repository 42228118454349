import React from 'react';
import PropTypes from 'prop-types';
import PhotoManager from './photo-manager';

const PhotosField = props => <PhotoManager {...props} />;

PhotosField.defaultProps = {
  field: 'photos',
  invalid: null,
  label: 'Item photos',
  required: true,
  placeholder: 'Scan or type',
};

PhotosField.propTypes = {
  field: PropTypes.string,
  invalid: PropTypes.string,
  inventoryNumber: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.array.isRequired,
};

export default PhotosField;
