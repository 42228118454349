import React, { Component } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash/fp';

class TableEditor extends Component {
  render() {
    const { records = [], edit, remove, ...rest } = this.props;

    if (records.length === 0) return null;

    return (
      <Table celled striped {...rest}>
        <Table.Header>
          <Table.Row>
            {Object.keys(records[0])
              .filter(header => header !== 'record')
              .map((header, key) => (
                <Table.HeaderCell key={key}>{startCase(header)}</Table.HeaderCell>
              ))}
            {edit && <Table.HeaderCell>Edit</Table.HeaderCell>}
            {remove && <Table.HeaderCell>Delete</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {records.map(({ record, ...data }, i) => (
            <Table.Row key={i}>
              {Object.values(data).map((field, j) => (
                <Table.Cell key={j} textAlign={'center'}>
                  {field}
                </Table.Cell>
              ))}
              {edit && (
                <Table.Cell textAlign={'center'}>
                  <Icon className={'pointer'} name={'edit'} onClick={() => edit(record)} />
                </Table.Cell>
              )}
              {remove && (
                <Table.Cell textAlign={'center'}>
                  <Icon className={'pointer'} name={'trash'} onClick={() => remove(record)} />
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

TableEditor.propTypes = {
  records: PropTypes.array.isRequired,
  edit: PropTypes.func,
  remove: PropTypes.func,
};

export default TableEditor;
