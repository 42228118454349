import React from 'react';
import PropTypes from 'prop-types';
import LogoutButton from '../logout-button';

const UserAuthStatus = ({ user, logout }) => {
  if (!user || Object.keys(user).length === 0) {
    return null;
  }

  return (
    <div className="UserAuthStatus">
      <span>
        <LogoutButton size="tiny" color="black" label={`${user.email}`} onLogout={logout} />
      </span>
    </div>
  );
};

UserAuthStatus.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default UserAuthStatus;
