import React from 'react';
import { Container, Message } from 'semantic-ui-react';
import QuickStartInput from '../quick-start-input';
import MessageBlock from '../../shared-components/message-block';
import PropTypes from 'prop-types';
import OpenSessions from '../open-sessions';

class Home extends React.Component {
  componentDidMount() {
    this.props.clearData();
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <>
        <div className="App-header">
          <Container className="AppMenuContainer">
            <QuickStartInput />
          </Container>
        </div>
        <Container className={'mt-3'}>
          <Message>Use the search bar above to load or create an item.</Message>
          <MessageBlock
            messages={this.props.messages}
            type={this.props.messageType}
            onDismiss={() => {
              this.props.setMessages([]);
            }}
            timeout={10000}
          />
          <OpenSessions />
        </Container>
      </>
    );
  }
}

Home.getRoute = () => '/';

Home.propTypes = {
  clearData: PropTypes.func.isRequired,
  messages: PropTypes.any,
  messageType: PropTypes.any,
  user: PropTypes.object.isRequired,
  setMessages: PropTypes.func,
};

export default Home;
