import { compose, split, last } from 'ramda';
import config from '../config';
import Raven from 'raven-js';

/**
 * Performs basic validation for item IDs and throws error if invalid
 * @param {String} id The item ID
 * @param {String} label The value's label for the error message
 */
const checkItemId = (id, label = 'item ID') => {
  if (typeof id !== 'string') {
    throw new TypeError(`Invalid ${label}. ${id} should be a string.`);
  }

  if (id.length < 10) {
    throw new Error(
      `Invalid ${label}. ${id} is too short. Item IDs should be at least 10 characters long.`
    );
  }
};

const getLotNumberFromInventoryNumber = inventoryNumber => inventoryNumber.slice(-6);

/**
 * A service to view, update, create, and modify auctions, items and lots
 */
class ItemManagementService {
  constructor({
    firebaseDatabase,
    firebaseStorage,
    auctionMethodApi,
    getTimestamp = () => new Date().toISOString(),
  }) {
    this.database = firebaseDatabase;
    this.storage = firebaseStorage;
    this.getTimestamp = getTimestamp;
    this.refs = {
      items: this.database.ref('items'),
    };
    this.activeObservers = {
      items: {},
    };
    this.auctionMethodApi = auctionMethodApi;
  }

  async deleteItem(itemId) {
    return await this.refs.items.child(`${itemId}`).remove();
  }

  /**
   * Retrieves item data and saves it under the given lot ID
   * @param {String} itemId The item ID
   * @param {Object} dataForAuctionMethod html data for AM api
   * @param {Object} data item data
   * @param {Object} user current logged in person
   * @return {Promise<{inventoryNumber: String, lastUpdated: *}>} A promise that indicates when the operation is complete
   */
  async saveItem(itemId, dataForAuctionMethod, data, user = {}) {
    // this should be changed to some sort of auctionId => users DB joining
    const auctionId = user.roleId === 7 ? 400 : config.auctionID;

    // See https://gitlab.com/nellisauction/cargo/wikis/auction-method-item-fields for all possible fields
    const formattedAuctionMethodData = {
      auction_id: auctionId,
      lot_number: getLotNumberFromInventoryNumber(itemId),
      title: dataForAuctionMethod.title,
      description: dataForAuctionMethod.description,
      quantity: 1,
      starting_bid: 5.0,
      price: 0,
      consignor_id: data.loadId,
      sequence_number: Math.round(Math.random() * 1000000),
      internal_id: itemId,
      extra_info: dataForAuctionMethod.extraInfo,
    };

    // because false is returned and can be destructured, we need to
    // separate the previous inline destructure and throw when result is false
    const submissionResult = data.nellisAuctionId
      ? await this.auctionMethodApi.patch(this.auctionMethodApi.routes.item, {
          item_id: data.nellisAuctionId,
          ...formattedAuctionMethodData,
        })
      : await this.auctionMethodApi.post(
          this.auctionMethodApi.routes.items,
          formattedAuctionMethodData
        );

    if (submissionResult === false) throw new Error(this.auctionMethodApi.message);

    const { id: nellisAuctionId = data.nellisAuctionId } = submissionResult;

    let images = data.stockImage !== '' ? [{ image_url: data.stockImage, sortOrder: 1 }] : [];

    const imageUrls = await Promise.all(
      data.photos.map(async ({ path, url }, key) => {
        const offset = data.stockImage === '' ? 1 : 2;

        return { image_url: url, sortOrder: key + offset };
      })
    );

    images = images.concat(imageUrls);

    await this.auctionMethodApi.post(this.auctionMethodApi.routes.images, {
      auction_id: auctionId,
      item_id: nellisAuctionId,
      make_thumbs: true,
      apply_watermark: '1',
      images,
    });

    return this.updateItem(itemId, {
      lastSubmissionTime: this.getTimestamp(),
      nellisAuctionId,
    });
  }

  /**
   * Drops a connection to an item
   * @param {String} itemId The item ID
   */
  cutItemFeed(itemId) {
    checkItemId(itemId);

    if (this.activeObservers.items[itemId]) {
      this.refs.items.child(itemId).off('value', this.activeObservers.items[itemId].then, this);
      delete this.activeObservers.items[itemId];
    }
  }

  /**
   * A generic function for retrieving from the firebase real-time database
   * @param {Object} ref The firebase.database.Reference object
   * @param {String} category The namespace to store the created observer function
   * @param {String} childKey The name of the child to fetch; Null will fetch the whole ref
   * @param {function} observer A subscription function that will continually receive data if the value changes
   * @param {String} observerKey A different key to use to store observer
   * @return {Promise<Object>} A promise with the data (or the initial data if using observer)
   */
  fetchObject(ref, category, childKey, observer, observerKey = null) {
    const child = childKey ? ref.child(childKey) : ref;
    const queryFn = child[observer ? 'on' : 'once'];
    const resolved = false;

    return new Promise((resolve, reject) => {
      let observerLocation = this.activeObservers;

      if (category) {
        observerLocation = observerLocation[category];
      }

      observerLocation[observerKey || childKey] = queryFn.call(
        child,
        'value',
        snapshot => {
          const val = snapshot.val();

          if (!resolved) {
            resolve(val);
          }

          return observer && observer(val);
        },
        error => {
          reject(error);
          delete observerLocation[observerKey || childKey];
        },
        this
      );
    });
  }

  /**
   * Retrieve item data
   * @param {String} itemId The item ID
   * @param {function} observer An observer function that will be used to subscribe to value changes
   * @return {Promise<Object>} A promise with the data (or the initial data if using observer)
   */
  async getItem(itemId, observer = null) {
    checkItemId(itemId);

    return this.fetchObject(this.refs.items, 'items', itemId, observer);
  }

  /**
   * Update item data
   * @param {String} itemId The item ID
   * @param {Object} data Non-null data to write to the item location
   * @param {Boolean} overwrite Overwrite any existing item data with this whole data object
   * @return {Promise<{lastUpdated: *}>} A promise that resolves when complete
   */
  async updateItem(itemId, data, overwrite = false) {
    checkItemId(itemId);

    if (data === null) {
      throw new TypeError(
        'The data parameter should not be null. This is equivalent to deleting. If this is desired use `remove` instead.'
      );
    }

    const newData = {
      ...data,
      lastUpdated: this.getTimestamp(),
    };

    this.refs.items.child(itemId)[overwrite ? 'set' : 'update'](newData);

    return newData;
  }

  /**
   * Update a given field in item data
   * @param {String} itemId The item ID
   * @param {String} field The field to update
   * @param {object} value The value to write to the item location
   * @param {Boolean} overwrite Overwrite any existing item data for this field with this value
   * @return {Promise<void>} A promise that resolves when complete
   */
  async updateItemField(itemId, field, value, overwrite = false) {
    checkItemId(itemId);

    if (typeof field !== 'string') {
      throw new TypeError(`Invalid field. ${field} should be a string.`);
    }

    const itemRef = this.refs.items.child(itemId);

    await itemRef.child(field)[overwrite ? 'set' : 'update'](value);

    return itemRef.child('lastUpdated').set(this.getTimestamp());
  }
}

export default ItemManagementService;
