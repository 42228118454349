import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CargoApi from '../shared-api-adapters/cargo-api';
import LoadLogsTable from './load-logs-table';

class LoadLogs extends Component {
  constructor(props) {
    super(props);

    this.cargoApi = new CargoApi();

    this.state = {
      log: [],
    };
  }

  componentDidMount = () => this.fetchData();

  fetchData = async () => {
    const { loadId } = this.props.computedMatch.params;

    this.setState({
      log: await this.cargoApi.get(`${this.cargoApi.routes.loadLogs}/${loadId}`),
    });
  };

  render() {
    const { log } = this.state;
    const { loadId } = this.props.computedMatch.params;

    return <LoadLogsTable loadId={Number(loadId)} log={log} />;
  }
}

LoadLogs.propTypes = {
  authUser: PropTypes.object.isRequired,
  computedMatch: PropTypes.object.isRequired,
};

export default LoadLogs;
