import dependencies from '../dependencies';
import ItemManagementService from './item-management-service';
import ItemValidationService from './item-validation-service';
import AuthService from './auth-service';
import DatafinitiService from './datafiniti';
import CargoApi from '../shared-api-adapters/cargo-api';

export default {
  authService: new AuthService({
    auctionMethodApi: dependencies.auctionMethodApi,
    firebaseAuth: dependencies.firebaseAuth.auth(),
  }),
  cargoApiService: new CargoApi(),
  itemManagementService: new ItemManagementService({
    firebaseDatabase: dependencies.firebaseDatabase,
    firebaseStorage: dependencies.firebaseStorage,
    auctionMethodApi: dependencies.auctionMethodApi,
  }),
  itemValidationService: new ItemValidationService(),
  semantics3Service: new DatafinitiService(),
};
