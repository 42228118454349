import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Menu } from 'semantic-ui-react';

const PhotoControls = ({ attached, controls, disabled, imageId, isButtonDisabled, onSelect }) => (
  <Menu attached={attached} icon size="small" widths={Object.keys(controls).length}>
    {Object.keys(controls).map(b => {
      const buttonDisabled = (isButtonDisabled && isButtonDisabled(b, imageId)) || disabled;

      return (
        <Menu.Item
          disabled={buttonDisabled}
          key={b}
          onClick={() => (buttonDisabled ? null : onSelect(b, imageId))}
          size="small"
        >
          <Icon {...controls[b]} />
        </Menu.Item>
      );
    })}
  </Menu>
);

PhotoControls.defaultProps = {
  attached: false,
  disabled: false,
};

PhotoControls.propTypes = {
  attached: PropTypes.string,
  controls: PropTypes.objectOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  imageId: PropTypes.number.isRequired,
  isButtonDisabled: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default PhotoControls;
