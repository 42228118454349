import React, { Component } from 'react';
import CargoApi from '../shared-api-adapters/cargo-api';
import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { asDateTime } from '../shared-components/helpers';

class TrailerHistory extends Component {
  constructor(props) {
    super(props);

    const { authUser } = this.props;

    this.cargoApi = new CargoApi();
    this.nellisAuctionApi = new NellisAuctionApi(authUser.token);

    this.state = {
      daysIdle: 0,
      history: [],
      locations: [],
      loads: [],
      users: [],
      name: '',
    };
  }

  componentDidMount = () => this.fetchData();

  displayRows = () => {
    const { history, locations, users } = this.state;

    return history.map((entry, key) => {
      const { createdAt, driverId, locationId, vrids, loadIds } = entry;

      const location = locations.find(({ id }) => id === locationId);
      const { firstName, lastName } = users.find(({ id }) => id === driverId);

      return (
        <Table.Row key={key}>
          <Table.Cell>{asDateTime(createdAt)}</Table.Cell>
          <Table.Cell>{location.name}</Table.Cell>
          <Table.Cell>
            {firstName} {lastName}
          </Table.Cell>
          <Table.Cell>{loadIds}</Table.Cell>
          <Table.Cell>{vrids}</Table.Cell>
        </Table.Row>
      );
    });
  };

  fetchData = async () => {
    const { trailerId } = this.props.computedMatch.params;
    let history = await this.cargoApi.get(this.cargoApi.routes.trailerHistory, trailerId);
    const locations = await this.cargoApi.get(this.cargoApi.routes.trailerLocations);
    const loads = await this.cargoApi.get(this.cargoApi.routes.loads);
    const users = await this.nellisAuctionApi.get(this.nellisAuctionApi.routes.users);
    const trailers = await this.cargoApi.get(this.cargoApi.routes.trailers);

    const { name = '' } = trailers.find(({ id }) => id === Number(trailerId));

    let daysIdle = 0;

    if (history.length > 0) {
      const now = new Date();
      const lastTrip = new Date(history[history.length - 1].createdAt);

      daysIdle = Math.floor((now.getTime() - lastTrip.getTime()) / 1000 / 60 / 60 / 24);

      history = history.map(h => ({
        ...h,
        vrids: h.loadIds
          ? h.loadIds
              .split(/,\s*/)
              .map(load => loads.find(({ id }) => id === Number(load)).vrid || 'None')
              .join(', ')
          : null,
      }));
    }

    this.setState({ daysIdle, history, locations, users, name });
  };

  render() {
    const { daysIdle, history, name } = this.state;

    return (
      <>
        <h1 className={'Cargo-header'}>Trailer History - {name}</h1>
        <div className={'m-1 ff-jura'}>Trips: {history.length / 2}</div>
        <div className={'m-1 ff-jura'}>Days Idle: {daysIdle}</div>

        <Table basic={'very'} striped celled className={'stats-table'} textAlign={'center'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Timestamp</Table.HeaderCell>
              <Table.HeaderCell>Location</Table.HeaderCell>
              <Table.HeaderCell>Driver</Table.HeaderCell>
              <Table.HeaderCell>Load ID</Table.HeaderCell>
              <Table.HeaderCell>VRID</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {history.length === 0 && (
              <Table.Row>
                <Table.Cell>No history found for the provided trailer</Table.Cell>
              </Table.Row>
            )}
            {history.length > 0 && this.displayRows()}
          </Table.Body>
        </Table>
      </>
    );
  }
}

TrailerHistory.propTypes = {
  authUser: PropTypes.object.isRequired,
  computedMatch: PropTypes.object.isRequired,
};

export default TrailerHistory;
