import React from 'react';
import PropTypes from 'prop-types';
import { Button, Message } from 'semantic-ui-react';

import './loaded-file-list.css';

class LoadedFileList extends React.Component {
  render() {
    const { files, onClick } = this.props;
    const ids = Object.keys(files);
    const total = ids.length;
    const totalUploading = ids.reduce(
      (num, id) => num + (files[id].status === 'uploading' ? 1 : 0),
      0
    );

    if (total === 0) {
      return null;
    }

    const noun = total === 1 ? 'file' : 'files';
    const verb = total === 1 ? 'is' : 'are';

    return (
      <Message className="LoadedFileList">
        <p>
          {total} {noun} {verb} open for editing
        </p>
        {totalUploading > 0 && (
          <p>
            {totalUploading}/{total} {verb} uploading.
          </p>
        )}
        <Button type="button" onClick={onClick}>
          Open
        </Button>
      </Message>
    );
  }
}

LoadedFileList.propTypes = {
  files: PropTypes.objectOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LoadedFileList;
