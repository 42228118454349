import React from 'react';
import PropTypes from 'prop-types';

const DescriptionHeader = ({ header }) => <h2 className={'t-center ff-jura'}>{header}</h2>;

DescriptionHeader.propTypes = {
  header: PropTypes.string.isRequired,
};

export default DescriptionHeader;
