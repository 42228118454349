import { applyTo, map } from 'ramda';
import auth from './auth';
import inventoryEditor from './inventory-editor';
import router from './router';
import services from '../../services';

export default map(applyTo(services), {
  auth,
  inventoryEditor,
  router,
});
