import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Rating,
  Button,
  Divider,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image,
  Label,
  Loader,
  Modal,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import MessageBlock from '../../shared-components/message-block';
import SavingIndicator from '../saving-indicator';
import PreviewItemButton from '../preview-item-button';
import './inventory-editor.css';
import DescriptionHeader from '../description-header';
import { Route } from 'react-router';
import API from '../api';
import Crumbs from '../crumbs';
import {
  buildContentBasedOnTableDescriptions,
  getAuctionMethodData,
} from '../../utilities/item-report';
import RetailPriceField from '../retail-price-field';
import PhotosField from '../photos-field';
import { NavLink } from 'react-router-dom';
import TransitionButton from '../transition-button';
import { camelCase, capitalize, isEmpty } from 'lodash/fp';
import ButtonCity from '../button-city';
import YesNoButtons from '../yes-no-buttons';
import ItemReport from '../item-report';
import Base from '../base/base';
import FocusChain from '../../focus-chain';
import FocusableInput from '../focusable-input';
import NellisAuctionApi from '../../shared-api-adapters/nellis-auction-api';
import UniversalIdField from '../universal-id-field';
import qs from 'qs';
import CargoApi from '../../shared-api-adapters/cargo-api';
import REGEX from '../../utilities/constants/regex';
import UrlWithAsinField from '../url-with-asin-field';

const getButtonPropsByStatus = status => {
  switch (status) {
    case 'success':
      return {
        color: 'green',
        content: 'Succeeded',
        icon: 'check',
      };
    case 'failure':
      return {
        color: 'red',
        content: 'Failed',
        icon: 'remove',
      };
    case 'no-result':
      return {
        color: 'yellow',
        content: 'No result',
        icon: 'exclamation',
      };
    default:
      return {
        color: 'grey',
        content: 'Search',
        icon: 'search',
      };
  }
};

class InventoryEditor extends React.Component {
  constructor(props) {
    super(props);

    this.cargoApi = new CargoApi();
    this.nellisAuctionApi = new NellisAuctionApi(props.user.token);

    this.state = {
      returnTypes: [],
      showLightningForm: false,
      showPreview: false,
    };
  }

  clearMessages = () => {
    this.props.setMessages([]);
  };

  /**
   * Determine if data is LPN or UPC and handle accordingly
   */
  handleSearchRequest = async (itemId, universalId) => {
    const {
      lookupItem,
      lookupItemByLPN,
      lookupItemByASIN,
      lookupItemByGtins,
      displayX00notification,
      user,
    } = this.props;

    if (!universalId) return false;

    if (this.isLPN(universalId)) {
      lookupItemByLPN(itemId, universalId, 'LPN');
    } else if (this.isASIN(universalId)) {
      lookupItemByASIN(itemId, universalId);
    } else if (this.isX00(universalId)) {
      const [result] = await this.lookupX00(universalId);

      if (!result) {
        if ([1, 45, 599, 563, 512, 57, 76, 381, 382, 384, 2, 31].includes(user.id))
          displayX00notification();
      } else {
        lookupItemByASIN(itemId, result.asin);
      }
    } else if (this.isISBN(universalId)) {
      lookupItemByGtins(itemId, universalId, 'GTIN');
    } else if (this.isUPC(universalId)) {
      lookupItem(itemId, universalId, 'UPC');
    }
    return true;
  };

  isASIN = id => (!id ? false : REGEX.ASIN.test(id.toString()));

  isISBN = id => (!id ? false : REGEX.ISBN.test(id.toString()));

  isUPC = id => (!id ? false : REGEX.UPC.test(id.toString()));

  isHTTP = id => (!id ? false : REGEX.HTTP.test(id.toString()));

  isLPN = id => (!id ? false : REGEX.LPN.test(id.toString()));

  isX00 = id => (!id ? false : REGEX.X00.test(id.toString()));

  extractASIN = (url, x00) => {
    const { hideUrlInput } = this.props;
    let matches = null;

    if (this.isHTTP(url)) {
      [matches = null] = url.match(/B0(\D|\d){8}/g) || [];

      if (matches) {
        this.saveX00toAsin(x00, matches);
        hideUrlInput();
      }
    }

    return matches;
  };

  lookupX00 = async x00 => await this.cargoApi.get(this.cargoApi.routes.skuToAsin, x00);

  saveX00toAsin = async (x00, asin) => {
    await this.cargoApi.post(this.cargoApi.routes.skuToAsin, { sku: x00, asin });
  };

  onInputEvent = (event, inputData = {}) => {
    let { name, value } = inputData;

    if (event.type === 'blur' && name !== 'returnType') {
      name = event.target.name;
      value = name === 'retailPrice' ? Number(event.target.value).toFixed(2) : event.target.value;
    }

    return this.performDataUpdate({ [name]: value }, event.type);
  };

  onFormCheckboxGroupEvent = (name, indexes) => {
    const selections = indexes.reduce((acc, val) => ({ ...acc, [val]: 1 }), {});

    return this.performDataUpdate({ [name]: selections });
  };

  onFormSubmit = dataForAuctionMethod => {
    const {
      setMessages,
      submit,
      submitReturnData,
      data: {
        userId, // user that created item
        prevInventoryNumber,
        returnNotes,
        returnType,
      },
      computedMatch: { params },
      user, // current user logged in
    } = this.props;

    if (prevInventoryNumber) {
      if (!returnType || !returnNotes) {
        setMessages(['Please fill out return information!']);
        window.scrollTo(0, 0);
        return false;
      }

      submitReturnData(
        params.itemId,
        prevInventoryNumber,
        userId,
        user.id,
        returnNotes,
        returnType
      );
    }

    return submit(params.itemId, dataForAuctionMethod, !!prevInventoryNumber).then(
      successful => (!successful ? window.scrollTo(0, 0) : true)
    );
  };

  performDataUpdate(data, type) {
    const { itemId } = this.props.computedMatch.params;

    return this.props.update(itemId, data, type).then(successful => {
      if (!successful) {
        window.scrollTo(0, 0);
      }

      return successful;
    });
  }

  toggleItemPreview = () => this.setState(({ showPreview }) => ({ showPreview: !showPreview }));

  toggleLightningForm = () => this.setState({ showLightningForm: !this.state.showLightningForm });

  componentWillUnmount() {
    const { itemId } = this.props.computedMatch.params;

    this.props.disconnect(itemId);
  }

  componentDidMount() {
    const { itemId } = this.props.computedMatch.params;

    const { prevInventoryNumber } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    this.props.connect(itemId, prevInventoryNumber);
    this.getReturnTypes();
    this.setUsers();
  }

  getReturnTypes = async () => {
    const returnTypes = await this.cargoApi.get(this.cargoApi.routes.returnTypes);

    this.setState({ returnTypes });
  };

  setUsers = async () => {
    const users = await this.nellisAuctionApi.get(this.nellisAuctionApi.routes.users);

    this.setState({ users });
  };

  getCreatedBy(userId) {
    const { users } = this.state;

    if (!users) return '';

    const user = users.find(u => u.id === userId);

    if (user) {
      return `${user.firstName} ${user.lastName} - `;
    }

    return '';
  }

  static formatDateTime(dateString) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);
    const day = dateString.substring(8, 10);

    const time = dateString.substring(11, 19);

    return `${month}/${day}/${year} ${time}`;
  }

  /**
   * @param fieldContents - array of field contents
   */
  withColors = fieldContents => {
    const colors = {
      Condition: 'teal',
      Assembly: 'brown',
      Damage: 'violet',
      'Missing Parts': 'orange',
      Functional: 'pink',
      Packaging: 'red',
    };

    return fieldContents.map(content => ({ ...content, color: colors[content.label] }));
  };

  withMain = fieldContents => {
    const { data } = this.props;

    if (data.retailPrice && data.leadDescription && !isEmpty(data.photos)) {
      return [{ label: 'Main', content: 'Preview', color: 'black' }, ...fieldContents];
    }
    return fieldContents;
  };

  withItemReport = fieldContents =>
    this.props.data.packageTypeId
      ? [...fieldContents, { label: 'Item Report', content: 'Preview', color: 'black' }]
      : fieldContents;

  withPaths = contents =>
    contents.map(content => ({
      path: `${this.props.computedMatch.url}/form/${camelCase(content.label)}`,
      ...content,
    }));

  resolveRating = (y1 = 100, y2 = 5) => {
    const { weights } = this.props.data;
    const x1 = Object.values(weights).reduce((acc, val) => acc + val, 0);

    return Math.ceil(x1 * y2 / y1);
  };

  render() {
    const {
      onInputEvent,
      withPaths,
      withColors,
      withMain,
      withItemReport,
      props: {
        data,
        loading,
        messages,
        messageType,
        updating,
        submitting,
        validationErrors,
        requiredFields,
        itemSearchStatus,
        computedMatch,
        user,
        push,
        showUrlInput,
        displayAsinNotFound,
      },
      state: { returnTypes, showPreview },
    } = this;

    const inventoryNumber = computedMatch.params.itemId;

    if (loading || submitting) {
      return <Loader active>{loading ? 'Loading' : 'Submitting'}</Loader>;
    }

    return (
      <div className="Home">
        <API.InventoryEditorData>
          {containers => (
            <div>
              {this.lightningForm()}
              <Segment className="Home-body" basic attached="bottom">
                <MessageBlock
                  messages={messages}
                  type={messageType}
                  onDismiss={this.clearMessages}
                />
                <Label className="Home-itemRibbon" size="large" ribbon color="orange">
                  Item: #{inventoryNumber}
                </Label>
                <SavingIndicator pending={updating} />
                {data.createdAt &&
                  data.userId && (
                    <div className={'flex ai-center fd-row jc-end mb-3 mtn-3 fs-09 mr-1'}>
                      <strong>Created:</strong>
                      <span className={'ml-1'}>
                        {this.getCreatedBy(data.userId)}
                        {InventoryEditor.formatDateTime(data.createdAt)}
                      </span>
                    </div>
                  )}
                <div className={'flex ai-center fd-row jc-end mb-2'}>
                  <Button
                    basic
                    content={'Perfect Item'}
                    icon={'lightning'}
                    primary
                    onClick={() => {
                      const perfectData = {
                        assemblyTypeId: 1,
                        conditionTypeId: 5,
                        damageTypeId: 7,
                        functionalTypeId: 1,
                        missingPartsTypeId: 6,
                        packageTypeId: 5,
                      };

                      const z = buildContentBasedOnTableDescriptions(containers, {
                        ...this.props.data,
                        ...perfectData,
                      });

                      z.forEach(d => {
                        this.props.updateWeighted({ name: d.field, value: d.ratingWeight });
                      });

                      this.performDataUpdate({
                        ...perfectData,
                        lightningSubmission: 1,
                      });

                      push(`/item/${inventoryNumber}/form/assembly`);
                    }}
                    size={'small'}
                  />
                  <PreviewItemButton active={showPreview} onClick={this.toggleItemPreview} />
                </div>

                {showPreview ? (
                  <ItemReport
                    inventoryNumber={inventoryNumber}
                    containers={containers}
                    data={data}
                  />
                ) : (
                  <div className="ItemInventoryForm">
                    <Form>
                      <FocusChain total={2}>
                        {({ refs: [ref1, ref2], focusRef }) => (
                          <Fragment>
                            {data.prevInventoryNumber && (
                              <>
                                <div className={'mt-3'}>
                                  <span className={'fw-700'}>Reason for Refund / Return</span>
                                  <Dropdown
                                    fluid
                                    selection
                                    placeholder={'Please select a reason'}
                                    name={'returnType'}
                                    onChange={onInputEvent}
                                    options={returnTypes.map(rt => ({
                                      key: rt.id,
                                      text: rt.name,
                                      value: rt.id,
                                    }))}
                                    value={data.return.returnType}
                                  />
                                </div>
                                <div className={'mt-3 mb-3'}>
                                  <span className={'fw-700'}>Return / Refund Notes</span>
                                  <TextArea
                                    placeholder={'Enter notes about return / refund'}
                                    className={'border round w-100 p-2'}
                                    name={'returnNotes'}
                                    onChange={onInputEvent}
                                    onBlur={onInputEvent}
                                    value={data.return.notes}
                                  />
                                </div>
                              </>
                            )}
                            <Base
                              label={'UPC / LPN / ASIN / X00 / ISBN'}
                              field={'universalId'}
                              invalid={validationErrors.universalId}
                              required={!!requiredFields.universalId}
                            >
                              <UniversalIdField
                                onValidScan={uid => {
                                  this.performDataUpdate({ universalId: uid });
                                  this.handleSearchRequest(inventoryNumber, uid);
                                }}
                              >
                                {({ handleUniversalIdChange, uid }) => (
                                  <Fragment>
                                    <div className="ui fluid action input">
                                      <Label
                                        content={data.universalId || '************'}
                                        className={'lh-1-75r'}
                                        onRemove={() => {
                                          this.performDataUpdate({ universalId: '' });
                                        }}
                                      />
                                      <FocusableInput
                                        inputRef={ref1}
                                        focusOnMount={true}
                                        focus={() => focusRef(0)}
                                        name={'universalId'}
                                        placeholder={'Scan or type'}
                                        type={'text'}
                                        onBlur={handleUniversalIdChange}
                                        onChange={handleUniversalIdChange}
                                        value={uid}
                                      />
                                      <Button
                                        onClick={() =>
                                          this.handleSearchRequest(
                                            inventoryNumber,
                                            data.universalId
                                          )
                                        }
                                        disabled={itemSearchStatus !== 'inactive'}
                                        loading={itemSearchStatus === 'pending'}
                                        type="button"
                                        {...getButtonPropsByStatus(itemSearchStatus)}
                                      />
                                    </div>
                                  </Fragment>
                                )}
                              </UniversalIdField>
                            </Base>
                            {showUrlInput && (
                              <Base
                                label={'URL w/ ASIN'}
                                field={'urlToParse'}
                                invalid={validationErrors.http}
                                required={!!requiredFields.http}
                              >
                                <UrlWithAsinField
                                  onValidScan={url => {
                                    this.performDataUpdate({ urlToParse: url });
                                    const matches = this.extractASIN(url, data.universalId);

                                    if (matches) {
                                      this.performDataUpdate({
                                        universalId: matches,
                                      });
                                      this.handleSearchRequest(inventoryNumber, matches);
                                    } else {
                                      displayAsinNotFound();
                                    }
                                  }}
                                >
                                  {({ handleUniversalIdChange, urlToParse }) => (
                                    <Fragment>
                                      <div className="ui fluid action input">
                                        <FocusableInput
                                          inputRef={ref1}
                                          focusOnMount={true}
                                          focus={() => focusRef(0)}
                                          name={'urlToParse'}
                                          placeholder={'Enter URL'}
                                          type={'text'}
                                          onBlur={handleUniversalIdChange}
                                          onChange={handleUniversalIdChange}
                                          value={urlToParse}
                                        />
                                        <Button
                                          onClick={() => {
                                            if (this.isHTTP(data.urlToParse)) {
                                              const matches = this.extractASIN(
                                                data.urlToParse,
                                                data.universalId
                                              );

                                              if (matches) {
                                                this.handleSearchRequest(inventoryNumber, matches);
                                              }
                                            }
                                          }}
                                          disabled={itemSearchStatus !== 'inactive'}
                                          loading={itemSearchStatus === 'pending'}
                                          type="button"
                                          {...getButtonPropsByStatus(itemSearchStatus)}
                                        />
                                      </div>
                                    </Fragment>
                                  )}
                                </UrlWithAsinField>
                              </Base>
                            )}
                            <Base
                              label={'Load Id'}
                              field={'loadId'}
                              invalid={validationErrors.loadId}
                              required={!!requiredFields.loadId}
                            >
                              <FocusableInput
                                inputRef={ref2}
                                focusWhen={!!(data.uidType && data.universalId)}
                                focus={() => focusRef(1)}
                                name={'loadId'}
                                placeholder={'Scan or type'}
                                type={'text'}
                                value={data.loadId ? data.loadId : ''}
                                onBlur={event =>
                                  onInputEvent(event, {
                                    name: event.target.name,
                                    value: event.target.value,
                                  })
                                }
                                onChange={event =>
                                  onInputEvent(event, {
                                    name: event.target.name,
                                    value: event.target.value,
                                  })
                                }
                              />
                            </Base>

                          </Fragment>
                        )}
                      </FocusChain>
                    </Form>
                    {data.merchantUrls.map(merchant => (
                      <Label
                        key={merchant.name}
                        className={'mt-3 mb-2 blue'}
                        content={merchant.name.replace('.com', '')}
                        onClick={() => {
                          window.open(merchant.url, '_blank');
                        }}
                      />
                    ))}
                    {data.stockImage && (
                      <div>
                        <Label
                          className={'my-2 bg-clear fs-1 c-black p-0'}
                          onClick={() => {
                            this.performDataUpdate({ stockImage: '' });
                          }}
                        >
                          Stock Image
                          <Icon className={'ml-2'} color="red" name="remove" />
                        </Label>
                        <Image
                          bordered={true}
                          className={'ItemInventoryForm-image'}
                          rounded={true}
                          src={data.stockImage}
                          size={'small'}
                          onClick={() => window.open(data.stockImage, '_blank')}
                        />
                      </div>
                    )}
                    <Divider className={'mt-3'} />
                    <Route
                      path={`${computedMatch.path}/form/:piece`}
                      render={props => {
                        const isPath = path => props.match.params.piece === path;
                        const resolvePath = path =>
                          `/item/${props.match.params.itemId}/form/${path}${props.location.search}`;

                        return (
                          <>
                            <Rating
                              maxRating={5}
                              rating={this.resolveRating()}
                              icon={'star'}
                              size={'huge'}
                              disabled
                            />
                            <Crumbs
                              crumbs={withPaths(
                                withItemReport(
                                  withMain(
                                    withColors(
                                      buildContentBasedOnTableDescriptions(containers, data)
                                    )
                                  )
                                )
                              )}
                              search={props.location.search}
                            />
                            {isPath('main') && (
                              <>
                                <div className={'mt-3'}>
                                  <span className={'fw-700'}>Lead Description</span>
                                  <TextArea
                                    placeholder={'Enter item description'}
                                    className={'border round w-100 p-2 '}
                                    name={'leadDescription'}
                                    onChange={onInputEvent}
                                    onBlur={onInputEvent}
                                    value={data.leadDescription}
                                  />
                                </div>
                                <RetailPriceField
                                  invalid={validationErrors.retailPrice}
                                  onChange={onInputEvent}
                                  onBlur={onInputEvent}
                                  required={!!requiredFields.retailPrice}
                                  value={data.retailPrice}
                                />
                                <PhotosField
                                  invalid={validationErrors.photos}
                                  inventoryNumber={inventoryNumber}
                                  required={!!requiredFields.photos}
                                  value={data.photos}
                                />
                                <NavLink
                                  to={resolvePath('condition')}
                                  className={'flex jc-end mt-2'}
                                >
                                  <TransitionButton
                                    visible={
                                      !!(
                                        data.leadDescription &&
                                        data.retailPrice &&
                                        (Object.keys(data.photos).length || data.stockImage)
                                      )
                                    }
                                    text={'Next'}
                                  />
                                </NavLink>
                              </>
                            )}
                            {isPath('condition') && (
                              <ButtonCity
                                to={resolvePath('damage')}
                                color={'teal'}
                                name={'conditionTypeId'}
                                header={capitalize(props.match.params.piece)}
                                onClick={(event, inputData, ratingWeight) => {
                                  onInputEvent(event, inputData);
                                  this.props.updateWeighted({
                                    name: inputData.name,
                                    value: ratingWeight,
                                  });
                                }}
                              >
                                {({ resolveData }) => {
                                  const { state: { response } } = containers.find(
                                    container => container.id === 'conditionTypes'
                                  );

                                  return resolveData(response.filter(c => c.active));
                                }}
                              </ButtonCity>
                            )}
                            {isPath('damage') && (
                              <ButtonCity
                                to={resolvePath('missingParts')}
                                color={'violet'}
                                name={'damageTypeId'}
                                header={capitalize(props.match.params.piece)}
                                onClick={(event, inputData, ratingWeight) => {
                                  onInputEvent(event, inputData);
                                  this.props.updateWeighted({
                                    name: inputData.name,
                                    value: ratingWeight,
                                  });
                                }}
                              >
                                {({ resolveData }) => {
                                  const { state: { response } } = containers.find(
                                    container => container.id === 'damageTypes'
                                  );

                                  return resolveData(response.filter(c => c.active));
                                }}
                              </ButtonCity>
                            )}
                            {isPath('missingParts') && (
                              <ButtonCity
                                to={resolvePath('functional')}
                                color={'orange'}
                                name={'missingPartsTypeId'}
                                header={'Missing Parts'}
                                onClick={(event, inputData, ratingWeight) => {
                                  onInputEvent(event, inputData);
                                  this.props.updateWeighted({
                                    name: inputData.name,
                                    value: ratingWeight,
                                  });
                                }}
                              >
                                {({ resolveData }) => {
                                  const { state: { response } } = containers.find(
                                    container => container.id === 'missingPartsTypes'
                                  );

                                  return resolveData(response.filter(c => c.active));
                                }}
                              </ButtonCity>
                            )}
                            {isPath('functional') && (
                              <ButtonCity
                                to={resolvePath('packaging')}
                                color={'pink'}
                                name={'functionalTypeId'}
                                header={capitalize(props.match.params.piece)}
                                onClick={(event, inputData, ratingWeight) => {
                                  onInputEvent(event, inputData);
                                  this.props.updateWeighted({
                                    name: inputData.name,
                                    value: ratingWeight,
                                  });
                                }}
                              >
                                {({ resolveData }) => {
                                  const { state: { response } } = containers.find(
                                    container => container.id === 'functionalTypes'
                                  );

                                  return resolveData(response.filter(c => c.active));
                                }}
                              </ButtonCity>
                            )}
                            {isPath('packaging') && (
                              <ButtonCity
                                to={resolvePath('assembly')}
                                color={'red'}
                                name={'packageTypeId'}
                                header={capitalize(props.match.params.piece)}
                                onClick={(event, inputData, ratingWeight) => {
                                  onInputEvent(event, inputData);
                                  this.props.updateWeighted({
                                    name: inputData.name,
                                    value: ratingWeight,
                                  });
                                }}
                              >
                                {({ resolveData }) => {
                                  const { state: { response } } = containers.find(
                                    container => container.id === 'packageTypes'
                                  );

                                  return resolveData(response.filter(c => c.active));
                                }}
                              </ButtonCity>
                            )}
                            {isPath('assembly') && (
                              <ButtonCity
                                to={resolvePath('itemReport')}
                                color={'brown'}
                                name={'assemblyTypeId'}
                                header={capitalize(props.match.params.piece)}
                                onClick={(event, inputData, ratingWeight) => {
                                  onInputEvent(event, inputData);
                                  this.props.updateWeighted({
                                    name: inputData.name,
                                    value: ratingWeight,
                                  });
                                }}
                              >
                                {({ resolveData }) => {
                                  const { state: { response } } = containers.find(
                                    container => container.id === 'assemblyTypes'
                                  );

                                  return resolveData(response.filter(c => c.active));
                                }}
                              </ButtonCity>
                            )}
                            {isPath('itemReport') && (
                              <>
                                <Grid columns={2}>
                                  <Grid.Column width={10}>
                                    <ItemReport
                                      inventoryNumber={inventoryNumber}
                                      containers={containers}
                                      data={{
                                        ...data,
                                        inventoryNumber,
                                        locationId: user.locationId,
                                      }}
                                    />
                                  </Grid.Column>
                                  <Grid.Column width={6} verticalAlign={'bottom'}>
                                    {/* <Button*/}
                                    {/*  fluid*/}
                                    {/*  content={'AS IS'}*/}
                                    {/*  onClick={event =>*/}
                                    {/*    onInputEvent(event, {*/}
                                    {/*      name: 'asIs',*/}
                                    {/*      value: data.asIs ? 0 : 1,*/}
                                    {/*    })*/}
                                    {/*  }*/}
                                    {/* />*/}
                                  </Grid.Column>
                                </Grid>
                                <TextArea
                                  placeholder={'Please add any important details here...'}
                                  className={'border round w-100 p-2 mt-2'}
                                  name={'notes'}
                                  value={data.notes || ''}
                                  onChange={onInputEvent}
                                />
                                <Button
                                  fluid
                                  color={'green'}
                                  onClick={() => {
                                    this.onFormSubmit(
                                      getAuctionMethodData(containers, {
                                        ...data,
                                        inventoryNumber,
                                        locationId: user.locationId,
                                      })
                                    );
                                  }}
                                  content={'Submit'}
                                />
                              </>
                            )}
                          </>
                        );
                      }}
                    />
                  </div>
                )}
              </Segment>
            </div>
          )}
        </API.InventoryEditorData>
      </div>
    );
  }

  /**
   * Lightning Form - Simple modal and form created to indicate current item is brand new and skip questions related to
   *  packaging, condition, etc. Assembly required question still relevant.
   * @return {Modal}
   */
  lightningForm = () => (
    <Modal
      className={'t-20'}
      closeIcon
      onClose={this.toggleLightningForm}
      open={this.state.showLightningForm}
    >
      <Modal.Header>The current item is in perfect condition!</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <DescriptionHeader header={'Assembly'} />
          <YesNoButtons
            to={`/item/${this.props.computedMatch.params.itemId}/form/itemReport`}
            name={'assemblyTypeId'}
            onClick={(event, inputData) => {
              this.toggleLightningForm();
              this.performDataUpdate({
                assemblyRequired: inputData.value,
                usageTypeId: 1,
                damageTypeId: 1,
                functional: 1,
                missingParts: { none: 1 },
                packageTypeId: 1,
                lightningSubmission: 1,
              });
            }}
            color={'brown'}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

InventoryEditor.getRoute = itemId => `/item/${itemId}/form/main`;

InventoryEditor.defaultProps = {
  computedMatch: {
    params: {
      itemId: '',
    },
  },
};

InventoryEditor.propTypes = {
  location: PropTypes.object.isRequired,
  computedMatch: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.shape({
      itemId: PropTypes.string,
    }),
  }),
  updateWeighted: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  connect: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  disconnect: PropTypes.func.isRequired,
  itemSearchStatus: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  lookupItem: PropTypes.func.isRequired,
  lookupItemByLPN: PropTypes.func.isRequired,
  lookupItemByASIN: PropTypes.func.isRequired,
  lookupItemByGtins: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
  messageType: PropTypes.string.isRequired,
  requiredFields: PropTypes.objectOf(PropTypes.bool).isRequired,
  setMessages: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitReturnData: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  validationErrors: PropTypes.objectOf(PropTypes.string).isRequired,
  push: PropTypes.func.isRequired,
  displayX00notification: PropTypes.func.isRequired,
  hideUrlInput: PropTypes.func.isRequired,
  showUrlInput: PropTypes.bool.isRequired,
  displayAsinNotFound: PropTypes.func.isRequired,
};

export default InventoryEditor;
